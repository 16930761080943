import React, { useState } from 'react'
import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Select from 'react-select'

/**
 * @param {boolean} show
 * @param {boolean} isOfficialTravel
 * @param {?string} officialTravelInformation
 * @param {Object} directReviewer
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} onChooseDirectReviewer
 * @param {Object[]} otherReviewerOptions
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} onChooseOtherReviewer
 * @param {() => void} onSelectedOtherReviewer
 * @param {boolean} isEchelon1
 * @param {Object[]} drafterUnitOptions
 * @param {() => void} onSelectedDrafterUnit
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} onChangedNote
 * @param {() => void} onClose
 * @param {() => void} onToggle
 * @param {() => void} onCancel
 * @param {() => void} onSend
 * @return {React.ReactElement}
 * @constructor
 */
export default function ModalSendReview ({
    show,

    isOfficialTravel = false,
    officialTravelInformation = null,

    directReviewer,
    onChooseDirectReviewer = null,

    otherReviewerOptions,
    onChooseOtherReviewer = null,
    onSelectedOtherReviewer = null,

    isEchelon1 = false,
    drafterUnitOptions = [],
    onSelectedDrafterUnit = null,

    onChangedNote = null,

    onClose,
    onToggle = onClose,
    onCancel = onClose,
    onSend,
}) {
    const [isDirectReviewerSelected, setDirectReviewerSelected] = useState(true)

    return (
        <Modal
            size="md"
            isOpen={show}
            toggle={onToggle}
        >
            <ModalHeader>
                <h5
                    className="modal-title mt-0"
                    id="modal-send-review"
                >
                    Kirim Review Surat ke Atasan
                </h5>

                <button
                    onClick={onClose}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </ModalHeader>

            <ModalBody>
                <Col md="12">
                    <div className="mb-1">
                        <label>
                            Surat ini akan dikirimkan untuk di review oleh :
                        </label>
                    </div>
                </Col>

                <Col md="12">
                    <div className="mb-3">
                        <div className="form-check form-check-end">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                value="atasan"
                                id="toAtasan"
                                onChange={(event) => {
                                    setDirectReviewerSelected(true)

                                    if (onChooseDirectReviewer) {
                                        onChooseDirectReviewer(event)
                                    }
                                }}
                                defaultChecked={isDirectReviewerSelected}
                            />

                            <label
                                className="form-check-label"
                                htmlFor="toAtasan"
                            >
                                Atasan Langsung
                            </label>

                            <br/>
                            Nama &emsp;&emsp;: {directReviewer ? directReviewer.position_employee_name : ''}

                            <br/>
                            Jabatan &emsp;: {directReviewer ? directReviewer.position_name : ''}

                            {isEchelon1 ? (
                                <>
                                    <span><br/>Konseptor :</span>
                                    <Select
                                        name="letter_out_drafter_uuid"
                                        placeholder="Pilih Unit Konseptor Surat"
                                        options={drafterUnitOptions}
                                        onChange={onSelectedDrafterUnit}
                                    />
                                </>
                            ) : null}
                        </div>
                    </div>
                </Col>

                <Col md="12">
                    <div className="mb-3">
                        <div className="form-check form-check-end">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                value="delegasi"
                                id="toDelegation"
                                onChange={(event) => {
                                    setDirectReviewerSelected(false)

                                    if (onChooseOtherReviewer) {
                                        onChooseOtherReviewer(event)
                                    }
                                }}
                                defaultChecked={! isDirectReviewerSelected}
                            />

                            <label
                                className="form-check-label"
                                htmlFor="toDelegation"
                            >
                                Reviewer Lain
                            </label>
                        </div>

                        {! isDirectReviewerSelected ? (
                            <Select
                                name="position_delegation"
                                placeholder="Pilih Reviewer Lain"
                                options={otherReviewerOptions}
                                onChange={onSelectedOtherReviewer}
                            />
                        ) : null}
                    </div>
                </Col>

                <Col md="12">
                    <div className="mb-3">
                        <label>Catatan</label>

                        <Input
                            name="review_note"
                            placeholder="Isi Catatan.."
                            type="textarea"
                            className="form-control"
                            onChange={onChangedNote}
                            id="review_note"
                        />
                    </div>
                </Col>

                {isOfficialTravel ? (
                    <Col md="12">
                        <div className="mb-0" style={{ color: 'red' }}>
                            <span style={{ fontSize: '14px' }}>
                                <i className="fas fa-info-circle"></i> INFORMASI
                            </span>
                            <br/>
                            <span>{officialTravelInformation}</span>
                        </div>
                    </Col>
                ) : null}
            </ModalBody>

            <ModalFooter>
                <button
                    type="button"
                    onClick={onCancel}
                    className="btn btn-danger waves-effect"
                    data-dismiss="modal"
                >
                    Batal
                </button>

                <button
                    type="button"
                    onClick={onSend}
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                >
                    Kirim
                </button>
            </ModalFooter>
        </Modal>
    )
}
