import {
    apiRequest,
    detailst,
    cancelparticipant
} from "../../../../../services/adapters/common/laporanService"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", detailst, {
            params: query
        }, true)
    }

    fetchCancel = async (query) => {
        return await apiRequest("post", cancelparticipant, {
            body: query
        }, true)
    }
}

export default DetailRepository