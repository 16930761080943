import {
    apiRequest,
    suratkeluarList,
    letterTypeList,
    unitList,
    suratkeluarExport,
    cancelST,
    cancelGeneral,
    announceLetter, employeeUnit
} from '../../../../../services/adapters/common/laporanService'

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", suratkeluarList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchInactive = async (query) => {
        return await apiRequest("post", cancelST, {
            body: query
        }, true)
    }

    fetchGeneralInactive = async (query) => {
        return await apiRequest("post", cancelGeneral, { body: query }, true)
    }

    fetchLetterTypeList = async (query, cancelToken) => {
        return await apiRequest("get", letterTypeList, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchUnitList = async (query, cancelToken) => {
        return await apiRequest("get", unitList, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchExportSurat = async (query, cancelToken) => {
        return await apiRequest("get", suratkeluarExport, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchAnnounce = async (query) => {
        return await apiRequest("post", announceLetter, {
            body: query
        }, true)
    }

    fetchEmployeeUnit = async (query) => {
        return await apiRequest("get", employeeUnit, {
            params: query
        }, true)
    }

}

export default DataTableRepository
