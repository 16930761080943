export function accessRole(action) {
    const uriPath = window.location.pathname
    const accessPath = JSON.parse(sessionStorage.getItem('accessPath'))
    const accessValue = JSON.parse(sessionStorage.getItem('accessValue'))
    const accessData = accessValue[Object.keys(accessPath).find(key => accessPath[key] === uriPath)]
    if(typeof accessData !== "undefined") {
        let accessPermission = 0
        switch(action) {
            case 'create':
                accessPermission = accessData.create
                break;
            case 'read':
                accessPermission = accessData.read
                break;
            case 'update':
                accessPermission = accessData.update
                break;
            case 'delete':
                accessPermission = accessData.delete
                break;
            case 'approve':
                accessPermission = accessData.approve
                break;
            case 'print':
                accessPermission = accessData.print
                break;
            default:
                accessPermission = 0
        }
        return accessPermission===1?true:false
    } else {
        return false
    }
}

export function getCurrentRoleId() {
    const roleId = localStorage.getItem('currentRoleIdreal')

    return roleId ? parseInt(roleId) : null
}

export  function isRoleAdministrator()
{
    return getCurrentRoleId() === 1
}

export  function isRoleTuPusat()
{
    return getCurrentRoleId() === 3
}

export  function isRoleTuUnit()
{
    return getCurrentRoleId() === 4
}

export  function isRoleEmployee()
{
    return getCurrentRoleId() === 5
}

export  function isRolePlt()
{
    return getCurrentRoleId() === 6
}
