import {
    apiRequest,
    letterPriorityList,
    letterTypeList,
    letterSignerList,
    attachmentTypeList,
    originSenderList,
    redactionList,
    instansiList,
    positionList,
    internalReceiverGroupList,
    senderList,
    regionList,
    revisiSTList,
    cityList,
    participantList,
    cekParticipantST,
    suratKeluarCreate,
    suratKeluarUpdate,
} from '../../../../../services/adapters/suratkeluar/outboxService'

class CreateRepository {

    fetchLetterPriorityList = async (query, cancelToken) => {
        return await apiRequest("get", letterPriorityList, {
            params: query,
            
        }, true)
    }

    fetchLetterTypeList = async (query, cancelToken) => {
        return await apiRequest("get", letterTypeList, {
            params: query,
            
        }, true)
    }

    /**
     * Query untuk fetch data penandatangan.
     *
     * @typedef {Object} fetchSignerList~Query
     * @property {string} employee_position_uuid
     * @property {number|string} letter_type_id
     */

    /**
     * Response fetch data penandatangan.
     *
     * @typedef {Object} fetchSignerList~Response
     * @extends apiRequest~Response
     * @property {{result: {signers: Array<{position_uuid: string, position_name: string}>}}} response
     */

    /**
     * Digunakan untuk mengambil daftar penandatangan berdasarkan pegawai/bawahan dan jenis surat.
     *
     * @param {fetchSignerList~Query} query
     * @param {apiRequest~RequestCancelToken?} cancelToken
     * @returns {Promise<fetchSignerList~Response>}
     */
    fetchSignerList = async (query, cancelToken = null)=> {
        return await apiRequest("get", letterSignerList, { params: query }, true)
    }

    fetchAttachmentTypeList = async (query, cancelToken) => {
        return await apiRequest("get", attachmentTypeList, {
            params: query,
            
        }, true)
    }

    fetchOriginSenderList = async (query, cancelToken) => {
        return await apiRequest("get", originSenderList, {
            params: query,
            
        }, true)
    }

    fetchRedactionList = async (query, cancelToken) => {
        return await apiRequest("get", redactionList, {
            params: query,
            
        }, true)
    }

    fetchInstansiList = async (query, cancelToken) => {
        return await apiRequest("get", instansiList, {
            params: query,
            
        }, true)
    }

    fetchPositionList = async (query, cancelToken) => {
        return await apiRequest("get", positionList, {
            params: query,
            
        }, true)
    }

    fetchInternalReceiverGroupList = async (query, cancelToken) => {
        return await apiRequest("get", internalReceiverGroupList, { params: query }, true)
    }

    fetchSenderList = async (query, cancelToken) => {
        return await apiRequest("get", senderList, {
            params: query,
            
        }, true)
    }

    fetchRegionList = async (query, cancelToken) => {
        return await apiRequest("get", regionList, {
            params: query,
            
        }, true)
    }

    fetchCityList = async (query, cancelToken) => {
        return await apiRequest("get", cityList, {
            params: query,
            
        }, true)
    }
    fetchRevisiSTList = async (query, cancelToken) => {
        return await apiRequest("get", revisiSTList, {
            params: query,
            
        }, true)
    }
    fetchParticipantList = async (query, cancelToken) => {
        return await apiRequest("get", participantList, {
            params: query,
            
        }, true)
    }

    fetchCekParticipant = async (query) => {
        return await apiRequest("post", cekParticipantST, {
            body: query
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", suratKeluarCreate, {
            body: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", suratKeluarUpdate, {
            body: query
        }, true)
    }

}

export default CreateRepository
