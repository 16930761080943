import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"
import Shimmer from "react-shimmer-effect";
import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'
import DetailBloc from '../Detail/Blocs/DetailBloc'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import moment from "moment"
import 'moment/locale/id'

var reformatOptions = function(raw, label, value, additionalvalue) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      //newObj["value"] = data[value] + (data[additionalvalue] != null ? '|' + data[additionalvalue] : null);
      newObj["value"] = data[value];
      return newObj;
    });
};

class IndexView extends Component {

    createBloc = new CreateBloc();
    detailBloc = new DetailBloc();

    constructor(props) {
        super(props)
        this.state = {
            // optionGender : [
            //     { label: "Laki-laki", value: "L" },
            //     { label: "Perempuan", value: "P" }
            // ],
            detail: {},
            instructionOption: [],
            receiverOption: [],
            positionOption: [],
            dataForm: {},
            submitStatus: 0 ,
            logsurat: [],   
            multiValue:[],

            // CC disposisi
            forwardReceiverOptions: [],
            selectedForwardReceivers: [],
        }
        this.handleMultiChange = this.handleMultiChange.bind(this);
    }

    // End Select Area

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratmasuk/attachment'})
        await this.createBloc.fetchCreate(formData)       
    }

    handleSaveDraft = (s) => {
        //console.log(s);
        this.setState({
            submitStatus: s
        })
    }

    
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.employee_uuid,
                disposition_status: this.state.submitStatus,
                disposition_qrcode: this.state.detail.letter_in_qrcode,
                ...values
            },
            confirm: true
        })
    }
    handleMultiChange(option) {
        this.setState({
            multiValue: (option != null) ? option : []
        });
    }

    handleForwardReceiverSelected = (selectedOptions) => {
        this.setState({ selectedForwardReceivers: selectedOptions || [] })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.letter_in,
                            receiver: response.receiver,
                            attachment: response.attachment,
                            logsurat: response.logs
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

       
        // get data referensi
        this.createBloc.instructionListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            instructionOption: response.result
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchInstructionList({})

        
        this.createBloc.positionDisposisiListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        const response = result.data.response
                        const options = reformatOptions(
                          response.result,
                          'position_name_text',
                          'position_uuid',
                          'position_employee_nip'
                        )

                        this.setState({
                            positionOption: options,
                            forwardReceiverOptions: [...this.state.forwardReceiverOptions || [], ...options],
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchPositionDisposisiList({})

        this.createBloc.receiverDisposisiListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        // console.log(response)

                        this.setState({
                            receiverOption: response.result,
                            forwardReceiverOptions: [
                                ...reformatOptions(
                                  response.result,
                                  'position_name_text',
                                  'position_uuid',
                                  'position_employee_nip',
                                ),
                                ...this.state.forwardReceiverOptions || []
                            ],
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchReceiverDisposisiList({})

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }

    render() {
        let limit = 5;
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Buat Disposisi Surat Masuk</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <CardSubtitle className="mb-3">
                                        Informasi Surat Masuk
                                    </CardSubtitle>
                                    <Row className="">
                                        <label className="col-md-2">
                                            Nomor Agenda
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_agenda_number !== "" ? this.state.detail.letter_in_agenda_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <label className="col-md-2">
                                            Nomor Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_number !== "" ? this.state.detail.letter_in_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <label className="col-md-2">
                                            Asal Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_sender !== "" ? this.state.detail.letter_in_sender : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <label className="col-md-2">
                                            Perihal
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_title !== "" ? this.state.detail.letter_in_title : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Diteruskan Kepada <span className="text-danger">*</span>
                                                </label>
                                                <AvCheckboxGroup name="disposition_position_to">
                                                    {
                                                    this.state.receiverOption.map(function (value, i) {
                                                        return  <AvCheckbox 
                                                                value={value.position_uuid} 
                                                                label={value.position_name_text}                                                                     
                                                                />
                                                        })
                                                    }
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                {/* <CustomSelect 
                                                    name="disposition_position_to_select"
                                                    id="disposition_position_to_select"
                                                    errorMessage="Pilih Penerima Lain"
                                                    options={this.state.positionOption} 
                                                    placeholder="Pilih Penerima Lain"
                                                    isMulti = "true"
                                                /> */}
                                                <Select
                                                    name="position_select_multi"
                                                    placeholder="Pilih Penerima Lain"
                                                    value={this.state.multiValue}
                                                    options={this.state.positionOption}
                                                    onChange={this.handleMultiChange}
                                                    isMulti="true"
                                                />
                                                {
                                                    this.state.multiValue.length > 0 ?
                                                        this.state.multiValue.map((data, index) => {
                                                            return (
                                                                <AvField
                                                                    key={index}
                                                                    name={`disposition_position_to_select[${index}]`}
                                                                    type="hidden"
                                                                    errorMessage="Masukkan Filter"
                                                                    className="form-control"
                                                                    //validate={{ required: { value: true } }}
                                                                    value={data.value}
                                                                />
                                                            )
                                                        })
                                                    :
                                                    <AvField
                                                        name='multiselectvalidation'
                                                        type="hidden"
                                                        errorMessage="Masukkan Filter"
                                                        className="form-control"
                                                        //validate={{ required: { value: true } }}
                                                        value={this.state.multiValue.length > 0 ? this.state.multiValue.length : ""}
                                                    />
                                                }
                                                </div>
                                            </Col>                                        
                                        </Row>

                                        <Row>
                                            <Col md="12">
                                                <label>CC Kepada</label>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12">
                                                <Select
                                                  name="forward_position"
                                                  placeholder="Pilih Penerima CC"
                                                  value={ this.state.selectedForwardReceivers }
                                                  options={ this.state.forwardReceiverOptions }
                                                  onChange={ this.handleForwardReceiverSelected }
                                                  isMulti={true}
                                                />

                                                { this.state.selectedForwardReceivers?.map(({ value }, index ) => <>
                                                    <AvField
                                                      key={`forward-receiver-${index}`}
                                                      name={`forward_receivers[${index}]`}
                                                      type="hidden"
                                                      value={ value }
                                                    />
                                                </>) }
                                            </Col>
                                        </Row>

                                        <hr />

                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >Perintah Disposisi <span className="text-danger">*</span>
                                                </label>
                                                <AvCheckboxGroup name="disposition_instruction_1">
                                                    {
                                                    this.state.instructionOption.map(function (value, i) {
                                                        return  (i < 7 ? 
                                                                <AvCheckbox                                                                     
                                                                label={value.instruction_desc}                                                                   
                                                                value={value.instruction_desc}
                                                                />
                                                                :
                                                                null
                                                            )                                                            
                                                        })
                                                    }    
                                                </AvCheckboxGroup>                                                                                                 
                                                </div>
                                            </Col>           
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >{'.'}
                                                </label>
                                                <AvCheckboxGroup name="disposition_instruction_2">
                                                    {
                                                    this.state.instructionOption.map(function (value, i) {
                                                        return   (i > 6 && i < 14 ? 
                                                                <AvCheckbox                                                                     
                                                                label={value.instruction_desc}                                                                   
                                                                value={value.instruction_desc}
                                                                />
                                                                :
                                                                null
                                                            )                                                            
                                                        })
                                                    }    
                                                </AvCheckboxGroup>                                                                                                   
                                                </div>
                                            </Col>        
                                            <Col md="4">
                                                <div className="mb-3">
                                                <label >{'.'}
                                                </label>
                                                <AvCheckboxGroup name="disposition_instruction_3">
                                                    {
                                                    this.state.instructionOption.map(function (value, i) {
                                                        return   (i > 13 && i < 50 ? 
                                                                <AvCheckbox  
                                                                label={value.instruction_desc}                                                                   
                                                                value={value.instruction_desc} 
                                                                />
                                                                :
                                                                null
                                                            )                                                            
                                                        })
                                                    }    
                                                </AvCheckboxGroup>                                              
                                                </div>
                                            </Col>                                                                             
                                        </Row>
                                        
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Catatan Disposisi
                                                </label>
                                                <AvField
                                                    name="disposition_note"
                                                    placeholder="Catatan Disposisi ..."
                                                    type="textarea"
                                                    errorMessage="Masukkan Catatan Disposisi"
                                                    className="form-control"
                                                    id="disposition_note"
                                                />
                                                </div>
                                            </Col>                                        
                                        </Row>
                                        
                                        <Row>                                            
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >File Lampiran 
                                                </label>
                                                <FileInput 
                                                    name="document"
                                                    //validate={{ required: { value: true } }}
                                                    isMultiple
                                                />
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Link to={"/inbox/daftarsurat.html?detail&qrcode="+this.state.detail.letter_in_qrcode}>
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                {/* <Button color="success" type="submit" onClick={() => this.handleSaveDraft(0)}>
                                                    <i className="uil-arrow-up-right"></i> Simpan Draft
                                                </Button> */}
                                                {' '}
                                                <Button color="primary" type="submit" onClick={() => this.handleSaveDraft(1)}>
                                                    <i className="uil-arrow-up-right"></i> Kirim
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle>Riwayat Surat</CardTitle>
                                    <hr />
                                    <ul className="activity-feed mb-0 ps-2">                                    
                                        {
                                            this.state.logsurat.map(function (value, i) {
                                            return  <li className="feed-item" key="1">
                                                        <div className="feed-item-list">
                                                            <p className="text-muted mb-1">{moment(value.histories_date).format("DD MMMM YYYY HH:mm:ss")}</p>
                                                            <h5 className="font-size-16" style={{ whiteSpace: 'pre-line' }}>{value.histories_desc}</h5>
                                                            {/* <p>Ahmad Bajuri</p>
                                                            <p className="text-muted">Surat Masuk diinput</p>                                                        */}
                                                        </div>
                                                    </li>
                                            })
                                        }                                    
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    this.state.submitStatus == 1 ? 
                    <Redirect to='/inbox/daftarsurat.html'/>
                    : 
                    <Redirect to='/inbox/daftarsurat.html'/>                   
                : null }
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);
