import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button
} from "reactstrap"
import { Preview } from '../../../../helpers/ui/FileInput'

import { Link, useHistory, useParams } from "react-router-dom"
import { withRouter } from "react-router";
import moment from "moment"
import 'moment/locale/id'

import apiResponse from '../../../../services/apiResponse'

import DetailBloc from './Blocs/DetailBloc'

class DetailView extends Component {

    detailBloc = new DetailBloc()

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.letter_in,
                            receiver: response.receiver,
                            attachment: response.attachment
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Detail Surat Masuk</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi Detail Surat Masuk
                                    </CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Nomor Agenda
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_agenda_number !== "" ? this.state.detail.letter_in_agenda_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Nomor Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_number !== "" ? this.state.detail.letter_in_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Sifat Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.priority_name !== "" ? this.state.detail.priority_name : '-'}</>
                                                : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Tanggal Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_date !== "" ?  moment(this.state.detail.letter_in_date).format("DD MMMM YYYY") : '-'}</>
                                                : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Tanggal Diterima
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_receive_date !== "" ? moment(this.state.detail.letter_in_receive_date).format("DD MMMM YYYY") : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Lampiran
                                        </label>
                                        <Col md="9">
                                            {this.state.detail
                                                ? this.state.detail.letter_in_number_of_attachment
                                                    ? `${this.state.detail.letter_in_number_of_attachment} ${this.state.detail.attachment_type_name}`
                                                    : '-'
                                                : (
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Jenis Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_type_name !== "" ? this.state.detail.letter_type_name : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Asal Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_sender !== "" ? this.state.detail.letter_in_sender : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Perihal
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_title !== "" ? this.state.detail.letter_in_title : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Catatan Mengenai Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_notes !== "" ? this.state.detail.letter_in_notes : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Unit yang dituju
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.receiver ?
                                                    <>{this.state.receiver.work_unit_name !== "" ? this.state.receiver.work_unit_name: '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            File Lampiran
                                        </label>
                                        <Col md="9">
                                        { this.state.attachment ?
                                                <Preview
                                                    value={this.state.attachment}
                                                    fileName='attachment_filename'
                                                    filePath='attachment_filepath'
                                                    fileMimeType='attachment_mime'
                                                    fileSource='attachment_source'
                                                    col='4'
                                                /> : <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="3"></Col>
                                        <Col md="9">
                                            <Link to="/inbox/daftardraft.html" className="waves-effect">
                                                <Button color="danger"><i className="uil-arrow-left"></i> Kembali ke Daftar</Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
