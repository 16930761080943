import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import ReviewRepository from '../Repositories/ReviewRepository'


class DetailBloc {

    detailChannel = new Subject();
    sendLetterChannel = new Subject();
    returnLetterChannel = new Subject();
    cancelLetterChannel = new Subject();
    signLetterChannel = new Subject();
    signNonDsLetterChannel = new Subject();
    requestNomorChannel = new Subject();
    addAttachmentChannel = new Subject();
    deleteAttachmentChannel = new Subject();
    updateChannel = new Subject();
    cancelParticipantChannel = new Subject();
    rollbackLetterChannel = new Subject();
    markDraftRegenerateChannel = new Subject();
    pdfDraftRegenerateChannel = new Subject();
    repository = new ReviewRepository()

    constructor() {
        this.detailChannel.next({status: apiResponse.INITIAL})
        this.sendLetterChannel.next({status: apiResponse.INITIAL})
        this.returnLetterChannel.next({status: apiResponse.INITIAL})
        this.signLetterChannel.next({status: apiResponse.INITIAL})
        this.signNonDsLetterChannel.next({status: apiResponse.INITIAL})
        this.requestNomorChannel.next({status: apiResponse.INITIAL})
        this.cancelLetterChannel.next({status: apiResponse.INITIAL})
        this.addAttachmentChannel.next({status: apiResponse.INITIAL})
        this.deleteAttachmentChannel.next({status: apiResponse.INITIAL})
        this.cancelParticipantChannel.next({status: apiResponse.INITIAL})
        this.rollbackLetterChannel.next({status: apiResponse.INITIAL})
        this.updateChannel.next({status: apiResponse.INITIAL})
        this.markDraftRegenerateChannel.next({status: apiResponse.INITIAL})
        this.pdfDraftRegenerateChannel.next({status: apiResponse.INITIAL})
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }



    fetchSendReview = async (query) => {
        this.sendLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchSendReview(query).then((result) => {
                this.sendLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.sendLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchReturnReview = async (query) => {
        this.returnLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchReturnReview(query).then((result) => {
                this.returnLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.returnLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchSignLetter = async (query) => {
        this.signLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchSignLetter(query).then((result) => {
                this.signLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.signLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchSignNonDsLetter = async (query) => {
        this.signNonDsLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchSignNonDsLetter(query).then((result) => {
                this.signNonDsLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.signNonDsLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchRequestNomor = async (query) => {
        this.signLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRequestNomor(query).then((result) => {
                this.signLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.signLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchCancelLetter = async (query) => {
        this.cancelLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCancelLetter(query).then((result) => {
                this.cancelLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.cancelLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchAddAttachment = async (query) => {
        this.addAttachmentChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchAddAttachment(query).then((result) => {
                this.addAttachmentChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.addAttachmentChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchDeleteAttachment = async (query) => {
        this.deleteAttachmentChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDeleteAttachment(query).then((result) => {
                this.deleteAttachmentChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.deleteAttachmentChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchUpdate(query).then((result) => {
                this.updateChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.updateChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchCancelParticipant = async (query) => {
        this.cancelParticipantChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCancelParticipant(query).then((result) => {
                this.cancelParticipantChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.cancelParticipantChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchRollbackLetter = async (query) => {
        this.rollbackLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRollbackLetter(query).then((result) => {
                this.rollbackLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rollbackLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchMarkDraftRegenerate = async (query) => {
        this.markDraftRegenerateChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchMarkDraftRegenerate(query).then((result) => {
                this.markDraftRegenerateChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.markDraftRegenerateChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'markDraftRegenerateChannel.unsubscribed')}
    }

    fetchPdfDraftRegenerate = async (query) => {
        this.pdfDraftRegenerateChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchPdfDraftRegenerate(query).then((result) => {
                this.pdfDraftRegenerateChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.pdfDraftRegenerateChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'pdfDraftRegenerateChannel.unsubscribed')}
    }
}

export default DetailBloc
