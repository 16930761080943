import { accessRole } from "../../../../helpers/access_role"
import { config } from "../../../../helpers/config_global"
import React, { Fragment, useState } from "react"
import classnames from "classnames"
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

import { Link } from "react-router-dom"


import TableActiveComponent from './Components/TableActiveComponent'

/* class IndexView extends Component {
    
} */

const IndexView = props => {

    const [activeTab, setactiveTab] = useState("1")    
    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }
    /* if{!accessRole('delete')) {

    } */
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                {
                                    (accessRole('create')) ?
                                        <Link to="/master/role.html?create" className="waves-effect">
                                            <Button color="primary">Create New</Button>
                                        </Link>
                                    :
                                        <></>
                                }
                                </div>
                                <CardTitle>List Nama Menu</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Keterangan menu-menu. Lorem ipsum dolor sit amet...
                                </CardSubtitle>
                                <hr />
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Aktif</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                            active: activeTab === "2",
                                                })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                            <span className="d-none d-sm-block">Akses</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="1">
                                        <TableActiveComponent />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        Akses:
                                        <div className="button-items">
                                            <Button disabled={!accessRole('create')} color="primary" className="btn btn-sm btn-info waves-effect waves-light">Create</Button>
                                            <Button disabled={!accessRole('read')} color="primary" className="btn btn-sm btn-info waves-effect waves-light">Read</Button>
                                            <Button disabled={!accessRole('update')} color="primary" className="btn btn-sm btn-info waves-effect waves-light">Update</Button>
                                            <Button disabled={!accessRole('delete')} color="primary" className="btn btn-sm btn-info waves-effect waves-light">Delete</Button>
                                            <Button disabled={!accessRole('approve')} color="primary" className="btn btn-sm btn-info waves-effect waves-light">Approve</Button>
                                            <Button disabled={!accessRole('print')} color="primary" className="btn btn-sm btn-info waves-effect waves-light">Print</Button>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView