import React from 'react'
import { Button, Col, Label, Row } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import cn from 'clsx'

import CustomSelect from '../../../helpers/ui/CustomSelect'
import { useLinkedSchedule } from '../../../helpers/linkedSchedule'

class CountrySchedule {
    /**
     * @type {?CountrySchedule}
     */
    prev

    /**
     * @type {?string}
     */
    startDate = null

    /**
     * @type {?string}
     */
    endDate = null

    /**
     * @type {?string}
     */
    countryUuid = null

    constructor (prev) {
        this.prev = prev
    }
}

/**
 * @typedef {Object} CountryScheduleShortModel
 * @property {string} start_date
 * @property {string} end_date
 * @property {string} country_uuid
 */

/**
 * @typedef {Object} CountryScheduleModel
 * @property {string} country_schedule_start_date
 * @property {string} country_schedule_end_date
 * @property {string} country_schedule_country_uuid
 */

/**
 * @param {CountryScheduleShortModel[]|CountryScheduleModel[]} countrySchedules
 * @return {CountrySchedule[]}
 */
function fromArrayObjectToCountrySchedules (countrySchedules) {
    /**
     * @type {?CountrySchedule}
     */
    let prev = null

    return countrySchedules.map(raw => {
        const obj =  new CountrySchedule(prev)

        obj.startDate = raw.start_date || raw.country_schedule_start_date
        obj.endDate = raw.end_date || raw.country_schedule_end_date
        obj.countryUuid = raw.country_uuid || raw.country_schedule_country_uuid

        prev = obj

        return obj
    })
}

/**
 * @param {string} minStartDate
 * @param {string} maxEndDate
 * @param {{ label: string, value: string }[]} countryOptions
 * @param {CountryScheduleShortModel[]|CountryScheduleModel[]|null} values
 * @param {boolean} readOnly
 * @param {boolean} readOnlyDate
 * @param {boolean} readOnlySelect
 * @return {React.ReactElement}
 * @constructor
 */
export default function AssignmentOverseasCountriesComponent ({
    minStartDate,
    maxEndDate,
    countryOptions,
    values = null,
    readOnly = false,
    readOnlyDate = false,
    readOnlySelect = false,
}) {
    const {
        schedules: countries,
        setSchedules: setCountries,

        add: addCountry,
        removeByIndex: removeCountryByIndex,
        updateByIndex: updateCountryByIndex,

        calculateStartDate,
        calculateMinStartDate,
        calculateMaxStartDate,
        calculateEndDate,
    } = useLinkedSchedule({
        minStartDate,
        maxEndDate,
        values,
        valueMapper: fromArrayObjectToCountrySchedules,
        creator: (previous) => new CountrySchedule(previous),
    })

    /**
     * @param {CountrySchedule} country
     * @return {string|undefined}
     */
    function calculateCountryUuid (country) {
        return country.countryUuid || undefined
    }

    return (
        <>
            <Row>
                <Label md={2} className="pt-0">
                    Tanggal Mulai
                </Label>
                <Label md={2} className="pt-0">
                    Tanggal Akhir <span className="text-danger">*</span>
                </Label>
                <Label md={3} className="pt-0">
                    Negara <span className="text-danger">*</span>
                </Label>
            </Row>

            {countries.map((country, index, list) => {
                const currentStartDate = calculateStartDate(country)
                const currentMinStartDate = calculateMinStartDate(country)
                const currentMaxStartDate = calculateMaxStartDate(country)
                const currentEndDate = calculateEndDate(country)
                const canShowButton = ! readOnly && ! readOnlySelect

                return (
                    <Row key={`${index}-input`}>
                        <Col md={2}>
                            <AvField
                                id={`official_travel_countries_start_date-${index}`}
                                name={`official_travel_countries_start_date[${index}]`}
                                placeholder="Tanggal Mulai"
                                type="date"
                                errorMessage="Masukkan Tanggal Mulai Perjalanan Dinas"
                                className="form-control"
                                value={currentStartDate}
                                min={currentMinStartDate}
                                max={currentMaxStartDate}
                                readOnly={readOnly || readOnlyDate || index === 0}
                            />
                        </Col>

                        <Col md={2}>
                            <AvField
                                id={`official_travel_countries_end_date-${index}`}
                                name={`official_travel_countries_end_date[${index}]`}
                                placeholder="Tanggal Akhir"
                                type="date"
                                errorMessage="Masukkan Tanggal Akhir Perjalanan Dinas"
                                className="form-control"
                                value={currentEndDate}
                                min={currentStartDate}
                                max={maxEndDate}
                                validate={{ required: { value: true } }}
                                readOnly={readOnly || readOnlyDate || index === list.length - 1}
                                onChange={(e) => {
                                    country.endDate = e.target.value

                                    updateCountryByIndex(index, country)
                                }}
                            />
                        </Col>

                        <Col md={3}>
                            <CustomSelect
                                id={`official_travel_countries_country-${index}`}
                                name={`official_travel_countries_country_uuid[${index}]`}
                                errorMessage="Pilih Negara"
                                options={countryOptions}
                                defaultValue={calculateCountryUuid(country)}
                                placeholder="Pilih Negara"
                                validate={{ required: { value: true } }}
                                isDisabled={readOnly || readOnlySelect}
                            />
                        </Col>

                        <Col>
                            {list.length > 1 ? (
                                <Button
                                    className={cn(
                                        'btn-block waves-effect waves-light mb-1 me-1',
                                        {'invisible': ! canShowButton}
                                    )}
                                    color="danger"
                                    onClick={() => removeCountryByIndex(index)}
                                >
                                    <i className="uil-trash-alt"></i>
                                </Button>
                            ) : null}

                            {index === 0 ? (
                                <Button
                                    className={cn(
                                        'btn-block waves-effect waves-light mb-1',
                                        {'invisible': ! canShowButton}
                                    )}
                                    color="success"
                                    onClick={addCountry}
                                >
                                    <i className="uil-plus-circle"></i>
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                )
            })}

            <div className="mb-3" aria-hidden="true"></div>
        </>
    )
}
