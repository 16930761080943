import {
    apiRequest,
    sampleList,
    sampleActive,
    sampleInactive
} from "../../../../services/adapters/base"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", sampleList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchInactive = async (query) => {
        return await apiRequest("post", sampleInactive, {
            body: query
        }, true)
    }

    fetchActive = async (query) => {
        return await apiRequest("post", sampleActive, {
            body: query
        }, true)
    }
}

export default DataTableRepository