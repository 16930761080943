import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'

import moment from "moment"
import 'moment/locale/id'

/*import ReactTimeAgo from 'react-time-ago'*/
var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];
  
      return newObj;
    });
};
class TableActiveComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyReportSMActive'
    defaultOrder = 'letter_in_receive_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            unitOption: [],
            letterTypeOption: [],
            tooltip: false,
            detailUuid: null,
            updateUuid: null,
            inactiveUuid: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: false,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))
        let additionalFilter = this.props.additionalFilter;
        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const mysession = JSON.parse(localStorage.getItem('userdata'));
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            ...additionalFilter
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }
    
    inactiveData = async () => {
        await this.dataTableBloc.fetchInactive({ uuid: this.state.inactiveUuid })
    }

    componentDidMount() {
        //this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.dataTableBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            unitOption: reformatOptions(
                                response.result, 
                                'work_unit_name',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.dataTableBloc.fetchUnitList({})

        this.dataTableBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            letterTypeOption: reformatOptions(
                                response.result, 
                                'letter_type_name',
                                'letter_type_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.dataTableBloc.fetchLetterTypeList({})
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        // {
        //     key: "letter_in_id",
        //     text: "UUID",
        //     className: "address",
        //     sortable: true,
        //     width: '20%'
        // },
        {
            key: "letter_in_agenda_number",
            text: "No Agenda",
            className: "address",
            sortable: true,
            width: '150'
        },
        {
            key: "letter_in_number",
            text: "Nomor Surat",
            className: "postcode",
            sortable: true,
            width: '150'
        },        
        {
            key: "letter_in_sender",
            text: "Asal Surat",
            className: "address",
            sortable: true,
        },
        {
            key: "letter_in_title",
            text: "Perihal",
            className: "address",
            sortable: true,
        },
        {
            key: "letter_type_name",
            text: "Jenis Surat",
            className: "address",
            sortable: true,
        },
        {
            key: "priority_name",
            text: "Sifat Surat",
            className: "address",
            sortable: true
        },
        {
            key: "letter_in_date",
            text: "Tanggal Surat",
            className: "address",
            sortable: true,
            width: '150',
            cell: record => {
                const date = record.letter_in_date
                return moment(date).format("DD MMMM YYYY")
            }
        },
        {
            key: "letter_in_receive_date",
            text: "Tanggal Terima",
            className: "address",
            sortable: true,
            width: '150',
            cell: record => {
                const date = record.letter_in_receive_date
                return moment(date).format("DD MMMM YYYY")
            }
        },
        {
            key: "receiver_list",
            text: "Penerima",
            className: "address",
            sortable: true,
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menghapus data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.detailUuid !== null ?
                    <Redirect to={"/inbox/daftarsurat.html?detail&qrcode=" + this.state.detailUuid}/>
                : null }
                { this.state.updateUuid !== null ?
                    <Redirect to={"/inbox/daftarsurat.html?update&qrcode=" + this.state.updateUuid}/>
                : null }
            </>
        )
    }

}

export default TableActiveComponent