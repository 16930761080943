import { apiWrapper, apiCancel } from '../../wrapper'

export const initial = 'setting'
//const baseUrl = 'http://192.168.18.83:8080/'
const baseUrl = process.env.REACT_APP_SERVICE_COMMON_URL
const appCode = process.env.REACT_APP_SERVICE_COMMON_APPCODE
const outputType = 'json'

export const usertuList = 'officer/list/'
export const usertuCreate = 'officer/create/'
export const usertuInactive = 'officer/remove/'

//Referensi
export const unitList = 'referensi/unitListTU/'
export const letterTypeList = 'referensi/letterTypeList/'

// User Preference
export const preferenceGet = 'preference/get/'
export const preferencePostEmailPreference = 'preference/postEmailPreference/'

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken=true) => {
    if(needToken) {
        const thisTime = (Math.floor(Date.now()/1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if(expire < thisTime) {
           await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/')
           .then(result => {
                localStorage.setItem(initial + 'accessToken', result.response.access_token)
                return apiWrapper(baseUrl, initial, appCode, outputType, method, route, {})
            })
            .catch(result => {
                return Promise.reject('Tidak dapat mengambil accessToken');
            })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}
