import { accessRole, isRoleAdministrator, isRoleTuUnit } from '../../../../helpers/access_role'
import React, { Fragment, useState, useRef, useEffect, useMemo } from 'react'
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input
} from "reactstrap"
import Select from "react-select"
import TableActiveComponent from './Components/TableActiveComponent'
import DataTableBloc from "./Blocs/DataTableBloc"
import apiResponse from "../../../../services/apiResponse"
import AssignmentType from './Components/AssignmentType'
import { EmployeeUnit } from './Components/EmployeeUnit'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];

      return newObj;
    });
};


const IndexView = (props) => {
    let dataTableBloc = new DataTableBloc();
    const [activeTab, setactiveTab] = useState("1")
    const activeRef = useRef();
    const inactiveRef = useRef();
    const mysession = JSON.parse(localStorage.getItem('userdata'))
    //const reloadActiveRef = () => activeRef.current.setTokenAPI()
    //const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()
    const [optionLetterType, setOptionLetterType] = useState([
    ]);

    const [optionUnitList, setOptionUnitList] = useState([
    ]);
    const [selectedUnit, setSelectedUnit] = useState("")
    const [selectedType, setSelectedType] = useState("")
    const [selectedStType, setSelectedStType] = useState(null)
    const [selectedStSubtype, setSelectedStSubtype] = useState(null)
    const [employees, setEmployees] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const isAssignment = useMemo(() => Number(selectedType?.value) === 6, [selectedType])

    const handleSelectUnit = (selectedUnit) => {
        setSelectedUnit(selectedUnit)
      }
    const handleSelectType = (selectedType) => {
        setSelectedType(selectedType)
    }

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }
    useEffect( () => {
        dataTableBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        setOptionUnitList(
                            reformatOptions( response.result,
                                'work_unit_name',
                                'work_unit_uuid')
                        )
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        dataTableBloc.fetchUnitList({})

        dataTableBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        setOptionLetterType(
                            reformatOptions( response.result,
                                'letter_type_name',
                                'letter_type_id')
                        )

                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        dataTableBloc.fetchLetterTypeList({})
        //setSelectedUnit(mysession.employee_current_work_unit_uuid)

    }, [])

    // Filter
    //const [nip, setNip] = useState('')
    //const [name, setName] = useState('')
    const [tgl_awal, setTglAwal] = useState('')
    const [tgl_akhir, setTglAkhir] = useState('')
    let additionalFilter = {
        unitid: (localStorage.getItem('currentRoleIdreal') < 4 ? (selectedUnit != null ? selectedUnit.value || "": "") : mysession.employee_current_work_unit_uuid ) || "",
        lettertype: (selectedType != null ? selectedType.value || "": ""),
        letter_st_type: selectedStType?.value,
        letter_st_subtype: selectedStSubtype?.value,
        employees_in: selectedEmployees?.map((i) => i.value).join(','),
        tglawal : tgl_awal,
        tglakhir : tgl_akhir
    };

    const reloadActiveRef = () => activeRef.current.setTokenAPI()
    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()

    function handleButtonSearch() {
      reloadActiveRef()
    }

    function handleButtonReset() {
        setTglAwal('');
        setTglAkhir('');
        setSelectedUnit('')
        setSelectedType('')

        setTimeout(function() {
            reloadActiveRef()
        }, 100);
    }
    function handleButtonExport () {
        //alert('assdddd')
        dataTableBloc.fetchExportSurat({
            ...additionalFilter
        });
        dataTableBloc.exportSuratChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const link = result.data.response
                    //alert(link.file_url);
                    window.open(link.file_url, "_blank")
                    //window.location.href = link.file_url

                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        });
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                {
                                    (accessRole('create')) ?
                                        /*<Link to="/sample.html?create">
                                            <Button color="primary">Tambah Data</Button>
                                        </Link>*/
                                        null
                                    : null
                                }
                                </div>
                                <CardTitle>Laporan Surat Keluar</CardTitle>
                                <hr />
                                <Row className="mb-3" style={{display:(mysession.employee_position_type == 2 && localStorage.getItem('currentRoleIdreal') == 5 ? "none":"")}}>
                                    <label
                                        htmlFor="gender"
                                        className="col-md-2 col-form-label"
                                    >
                                    Unit Kerja
                                    </label>
                                    <Col md="5">
                                        <Select
                                            value={optionUnitList.filter(option => option.value ===
                                                (localStorage.getItem('currentRoleIdreal') < 4 ? (selectedUnit != null ? selectedUnit.value : "") : mysession.employee_current_work_unit_uuid) )}
                                            onChange={handleSelectUnit}
                                            required="required"
                                            options={optionUnitList}
                                            classNamePrefix="select2-unit"
                                            isDisabled={(localStorage.getItem('currentRoleIdreal') < 4 ? false : true)}
                                            isClearable={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label
                                        htmlFor="gender"
                                        className="col-md-2 col-form-label"
                                    >
                                    Jenis Surat
                                    </label>
                                    <Col md="5">
                                        <Select
                                            value={optionLetterType.filter(option =>
                                            option.value === (selectedType != null ? selectedType.value : ""))}
                                            onChange={handleSelectType}
                                            required="required"
                                            options={optionLetterType}
                                            classNamePrefix="select2-unit"
                                            isClearable={true}
                                        />
                                    </Col>

                                    {isAssignment && (
                                        <AssignmentType
                                            onTypeChange={setSelectedStType}
                                            onSubtypeChange={setSelectedStSubtype}
                                        />
                                    )}
                                </Row>

                                {isAssignment && (isRoleAdministrator() || isRoleTuUnit()) && (
                                    <EmployeeUnit
                                        employees={employees}
                                        setEmployees={setEmployees}
                                        onEmployeeSelected={setSelectedEmployees}
                                    />
                                )}

                                <Row className="mb-3">
                                    <label
                                        htmlFor="gender"
                                        className="col-md-2 col-form-label"
                                    >
                                    Tanggal Surat
                                    </label>
                                    <Col md="2">
                                        <Input
                                            name="tgl_awal"
                                            placeholder="Tanggal Surat"
                                            type="date"
                                            value={tgl_awal}
                                            onChange={event => setTglAwal(event.target.value)}
                                        />
                                    </Col>
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label text-center"
                                    >
                                    s.d
                                    </label>
                                    <Col md="2">
                                        <Input
                                            name="tgl_akhir"
                                            placeholder="Tanggal Surat"
                                            type="date"
                                            value={tgl_akhir}
                                            onChange={event => setTglAkhir(event.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="2"></Col>
                                    <Col md="10">
                                        <Button
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                handleButtonSearch()
                                            }}
                                        >
                                            <i className="uil-search-alt"></i> Cari
                                        </Button>
                                        {' '}
                                        <Button
                                          color="success"
                                          type="button"
                                          onClick={() => {
                                            handleButtonExport()
                                          }}>
                                            <i className="uil-refresh"></i> Export Xls
                                        </Button>
                                        {' '}
                                        {/* <Button
                                          color="warning"
                                          type="button"
                                          onClick={() => {
                                              handleButtonReset()
                                          }}>
                                            <i className="uil-refresh"></i> Reset
                                        </Button> */}
                                    </Col>
                                </Row>

                                <hr />
                                <TableActiveComponent ref={activeRef} handleInactiveSuccess={reloadInactiveRef} additionalFilter={additionalFilter}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView
