import {
    apiRequest,
    unitList,
    usertuCreate
} from "../../../../../services/adapters/common/settingService"

class CreateRepository {

    fetchUnitList = async (query, cancelToken) => {
        return await apiRequest("get", unitList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", usertuCreate, {
            body: query
        }, true)
    }

}

export default CreateRepository