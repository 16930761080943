import { Subject } from "rxjs"

import apiResponse from "../../../../../services/apiResponse"

import PreferenceRepository from "../Repositories/PreferenceRepository"

class PreferenceBloc {
    repository = new PreferenceRepository()

    preferenceChannel = new Subject()
    emailPreferenceChannel = new Subject()

    constructor() {
        this.preferenceChannel.next({ status: apiResponse.INITIAL })
        this.emailPreferenceChannel.next({ status: apiResponse.INITIAL })
    }

    fetchPreference = async () => {
        this.preferenceChannel.next({ status: apiResponse.LOADING })

        try {
            await this.repository.fetchPreference()
                .then((result) => {
                    this.preferenceChannel.next({ status: apiResponse.COMPLETED, data: result })

                    return result
                })
                .catch((error) => {
                    console.warn({ message: "Failed to get user preference", error })

                    this.preferenceChannel.next({ status: apiResponse.ERROR, data: error })
                })
        }
        catch (error) {
            console.warn({ message: "Unexpected error on get user preference", error })
        }
    }

    fetchEmailPreference = async (query) => {
        this.preferenceChannel.next({ status: apiResponse.LOADING })

        try {
            await this.repository.fetchPostEmailPreference(query)
                .then((result) => {
                    this.preferenceChannel.next({ status: apiResponse.COMPLETED, data: result })

                    return result
                })
                .catch((error) => {
                    console.warn({ message: "Failed to post user email preference", error })

                    this.preferenceChannel.next({ status: apiResponse.ERROR, data: error })
                })
        }
        catch (error) {
            console.warn({ message: "Unexpected error on post user email preference", error })
        }
    }
}

export default PreferenceBloc
