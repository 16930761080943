import { apiWrapper, apiCancel } from '../../wrapper'

export const initial = 'inbox'
//const baseUrl = 'http://192.168.18.83:8080/'
const baseUrl = process.env.REACT_APP_SERVICE_INBOX_URL
const appCode = process.env.REACT_APP_SERVICE_INBOX_APPCODE
const outputType = 'json'

export const daftarDisposisiList = 'daftardisposisi/listnew/'
export const disposisiTerkirimList = 'disposisiterkirim/listnew/'
export const draftDisposisiList = 'draftdisposisi/list/'

export const disposisiDetail = 'daftardisposisi/detail/'
export const draftDetail = 'draftdisposisi/detail/'

// //Referensi
export const instructionList = 'referensi/instructionList/'
export const receiverDisposisiList = 'referensi/receiverDisposisiList/'
export const positionDisposisiList = 'referensi/positionDisposisiList/'
// export const letterTypeList = 'referensi/letterTypeList/'
// export const attachmentTypeList = 'referensi/attachmentTypeList/'
// export const originSenderList = 'referensi/originSenderList/'
// export const redactionList = 'referensi/redactionList/'
// export const instansiList = 'referensi/instansiList/'
// export const unitList = 'referensi/unitList/'

// // process
export const disposisiCreate = 'draftdisposisi/create/'
export const disposisiResponse = 'draftdisposisi/process/'
export const disposisiUpdate = 'draftdisposisi/update/'
// export const suratMasukUpdate = 'daftardraft/update/'
// export const suratMasukReturned = 'daftardraft/returned/'

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken=true) => {
    if(needToken) {
        const thisTime = (Math.floor(Date.now()/1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if(expire < thisTime) {
           await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/')
           .then(result => {
                localStorage.setItem(initial + 'accessToken', result.response.access_token)
                return apiWrapper(baseUrl, initial, appCode, outputType, method, route, {})
            })
            .catch(result => {
                return Promise.reject('Tidak dapat mengambil accessToken');
            })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)        
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}