import {
    apiRequest,
    preferenceGet,
    preferencePostEmailPreference,
} from "../../../../../services/adapters/common/settingService"

class PreferenceRepository {
    /**
     * Get user preference
     */
    fetchPreference = async () => {
        return await apiRequest("get", preferenceGet, {}, true)
    }

    /**
     * Update user notification email preference
     *
     * @param {{ receive_email: boolean }} body
     */
    fetchPostEmailPreference = async (body) => {
        return await apiRequest("post", preferencePostEmailPreference, { body }, true)
    }
}

export default PreferenceRepository
