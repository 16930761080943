import React from "react"
import { Redirect } from "react-router-dom"

import { default as MasterRole } from "../pages/Master/Role/RouteView"
import { default as Sample } from "../pages/Sample/RouteView"

import { default as DaftarSuratMasuk } from "../pages/Inbox/DaftarSurat/RouteView"
import { default as DaftarSuratMasukTerkirim } from "../pages/Inbox/DaftarTerkirim/RouteView"
import { default as DaftarSuratMasukDraft } from "../pages/Inbox/DaftarDraft/RouteView"
import { default as DaftarSuratMasukDikembalikan } from "../pages/Inbox/DaftarDikembalikan/RouteView"

import { default as DaftarDisposisi } from "../pages/Disposition/DaftarDisposisi/RouteView"
import { default as DaftarDisposisiTerkirim } from "../pages/Disposition/DisposisiTerkirim/RouteView"
import { default as DraftDisposisi } from "../pages/Disposition/DraftDisposisi/RouteView"


import { default as DaftarSuratKeluar } from "../pages/Outbox/DaftarSurat/RouteView"
import { default as DaftarSuratKeluarTerkirim } from "../pages/Outbox/DaftarTerkirim/RouteView"
import { default as DaftarSuratKeluarDraft } from "../pages/Outbox/DaftarDraft/RouteView"
import { default as DaftarSuratKeluarDikembalikan } from "../pages/Outbox/DaftarDikembalikan/RouteView"
import { default as Penomoran } from "../pages/Outbox/Penomoran/RouteView"
import OutboxSign from "../pages/Outbox/Sign/RouteView"

import { default as LaporanSuratMasuk } from "../pages/Laporan/SuratMasuk/RouteView"
import { default as LaporanSuratKeluar } from "../pages/Laporan/SuratKeluar/RouteView"
import { default as LaporanDisposisi } from "../pages/Laporan/Disposisi/RouteView"
// import { default as DaftarSuratMasukTerkirim } from "../pages/Inbox/DaftarTerkirim/RouteView"
// import { default as DaftarSuratMasukDraft } from "../pages/Inbox/DaftarDraft/RouteView"
// import { default as DaftarSuratMasukDikembalikan } from "../pages/Inbox/DaftarDikembalikan/RouteView"
import { default as InitialNomor } from "../pages/Master/Initialnomor/RouteView"
import { default as GrupPenerima } from "../pages/Master/GrupPenerima/RouteView"
import { default as UserTU } from "../pages/Setting/Usertatausaha/RouteView" 

import Dashboard from "../pages/Home/Dashboard/RouteView"

import DaftarNotification from "../pages/Home/Notification/RouteView"

import Logout from "../pages/Authentication/Logout"
import NewDashboard from "../pages/Dashboard/View"
const userRoutes = [
  { path: "/", exact: true, component: () => <Redirect to="/home/dashboard.html" /> },
  { path: "/home/dashboard.html", component: Dashboard },
  { path: "/sample.html", component: Sample },
  
  { path: "/inbox/daftarsurat.html", component: DaftarSuratMasuk },
  { path: "/inbox/daftarterkirim.html", component: DaftarSuratMasukTerkirim },
  { path: "/inbox/daftardraft.html", component: DaftarSuratMasukDraft },
  { path: "/inbox/daftardikembalikan.html", component: DaftarSuratMasukDikembalikan },
  { path: "/disposition/daftardisposisi.html", component: DaftarDisposisi },
  { path: "/disposition/disposisiterkirim.html", component: DaftarDisposisiTerkirim },
  { path: "/disposition/draftdisposisi.html", component: DraftDisposisi },
  { path: "/outbox/daftarsurat.html", component: DaftarSuratKeluar },
  { path: "/outbox/daftarterkirim.html", component: DaftarSuratKeluarTerkirim },
  { path: "/outbox/daftardraft.html", component: DaftarSuratKeluarDraft },
  { path: "/outbox/daftardikembalikan.html", component: DaftarSuratKeluarDikembalikan },
  { path: "/outbox/penomoran.html", component: Penomoran },
  { path: "/laporan/suratmasuk.html", component: LaporanSuratMasuk },
  { path: "/laporan/suratkeluar.html", component: LaporanSuratKeluar },
  { path: "/laporan/disposisi.html", component: LaporanDisposisi },
  { path: "/master/role.html", component: MasterRole },
  { path: "/master/initialnomor.html", component: InitialNomor },
  { path: "/master/gruppenerima.html", component: GrupPenerima },
  //{ path: "/master/jenissurat.html", component: JenisSurat },
  //{ path: "/master/templatesurat.html", component: TemplateSurat },
  { path: "/setting/usertatausaha.html", component: UserTU },
  { path: "/home/notification.html", component: DaftarNotification}
]

const authRoutes = [

  { path: "/logout.html", component: Logout },
  { path: "/dashboard.html", component: NewDashboard },
  { path: "/outbox/sign.html", component: OutboxSign },

]

export const isAuthRoute = (path = window.location.pathname) => {
  return authRoutes.findIndex(route => route.path === path) !== -1
}

export { userRoutes, authRoutes }
