import {
    apiRequest,
    usertuList,
    usertuInactive
} from "../../../../../services/adapters/common/settingService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", usertuList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }
    fetchInactive = async (query) => {
        return await apiRequest("post", usertuInactive, {
            body: query
        }, true)
    }

   
}

export default DataTableRepository