import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {

    /**
     * Genetic channel.
     *
     * @typedef {Object} CreateBloc~Channel
     * @property {apiResponse} status
     */

    createChannel = new Subject()
    trainingListChannel = new Subject()
    letterPriorityListChannel = new Subject()
    letterTypeListChannel = new Subject()
    attachmentTypeListChannel = new Subject()
    originSenderListChannel = new Subject()
    instansiListChannel = new Subject()
    redactionListChannel = new Subject()
    positionListChannel = new Subject()
    internalReceiverGroupListChannel = new Subject()
    senderListChannel = new Subject()
    regionListChannel = new Subject()
    cityListChannel = new Subject()
    revisiSTListChannel = new Subject()
    participantListChannel = new Subject()
    cekParticipantChannel = new Subject()
    repository = new CreateRepository()
    updateChannel = new Subject()

    /**
     * Penanda tangan surat.
     *
     * @typedef {Object} CreateBloc~Channel~LetterSigner
     * @extends CreateBloc~Channel
     * @property {Object | undefined} data
     */

    /**
     * Daftar pegawai/pejabat yang bisa menanda tangan surat terkait berdasarkan pegawai (bawahan) dan tipe surat yang dipilih.
     *
     * @type {Subject<CreateBloc~Channel~LetterSigner>}
     */
    letterSignerListChannel = new Subject()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.updateChannel.next({ status: apiResponse.INITIAL })
        this.letterPriorityListChannel.next({ status: apiResponse.INITIAL })
        this.letterTypeListChannel.next({ status: apiResponse.INITIAL })
        this.attachmentTypeListChannel.next({ status: apiResponse.INITIAL })
        this.originSenderListChannel.next({ status: apiResponse.INITIAL })
        this.redactionListChannel.next({ status: apiResponse.INITIAL })
        this.instansiListChannel.next({ status: apiResponse.INITIAL })
        this.positionListChannel.next({ status: apiResponse.INITIAL })
        this.internalReceiverGroupListChannel.next({ status: apiResponse.INITIAL })
        this.senderListChannel.next({ status: apiResponse.INITIAL })
        this.regionListChannel.next({ status: apiResponse.INITIAL })
        this.cityListChannel.next({ status: apiResponse.INITIAL })
        this.revisiSTListChannel.next({ status: apiResponse.INITIAL })
        this.participantListChannel.next({ status: apiResponse.INITIAL })
        this.cekParticipantChannel.next({ status: apiResponse.INITIAL })
        this.letterSignerListChannel.next({ status: apiResponse.INITIAL })
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUpdate(query).then((result) => {
                this.updateChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.updateChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchLetterPriorityList = async (query) => {
        this.letterPriorityListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchLetterPriorityList(query).then((result) => {
                this.letterPriorityListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.letterPriorityListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchLetterTypeList = async (query) => {
        this.letterTypeListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchLetterTypeList(query).then((result) => {
                this.letterTypeListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.letterTypeListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    /**
     * Digunakan untuk mengambil penanda tangan berdasarkan pegawai (bawahan) dan tipe surat.
     *
     * @param {fetchSignerList~Query} query
     * @returns {Promise<void>}
     */
    fetchLetterSignerList = async (query) => {
        this.letterSignerListChannel.next({ status: apiResponse.LOADING })

        try {
            await this.repository
              .fetchSignerList(query)
              .then(result => {
                  this.letterSignerListChannel.next({ status: apiResponse.COMPLETED, data: result })
              })
              .catch(error => {
                  this.letterSignerListChannel.next({ status: apiResponse.ERROR, data: error })
              })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchAttachmentTypeList = async (query) => {
        this.attachmentTypeListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchAttachmentTypeList(query).then((result) => {
                this.attachmentTypeListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.attachmentTypeListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchOriginSenderList = async (query) => {
        this.originSenderListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchOriginSenderList(query).then((result) => {
                this.originSenderListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.originSenderListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchRedactionList = async (query) => {
        this.redactionListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRedactionList(query).then((result) => {
                this.redactionListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.redactionListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchInstansiList = async (query) => {
        this.instansiListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchInstansiList(query).then((result) => {
                this.instansiListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.instansiListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchPositionList = async (query) => {
        this.positionListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchPositionList(query).then((result) => {
                this.positionListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.positionListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchInternalReceiverGroupList = async (query = {}) => {
        this.internalReceiverGroupListChannel.next({ status: apiResponse.LOADING })

        try {
            await this.repository
              .fetchInternalReceiverGroupList(query)
              .then((result) => {
                  this.internalReceiverGroupListChannel.next({ status: apiResponse.COMPLETED, data: result })
              })
              .catch((error) => {
                  this.internalReceiverGroupListChannel.next({ status: apiResponse.ERROR, data: error })
              })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }

    fetchSenderList = async (query) => {
        this.senderListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchSenderList(query).then((result) => {
                this.senderListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.senderListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchRegionList = async (query) => {
        this.regionListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRegionList(query).then((result) => {
                this.regionListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.regionListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCityList = async (query) => {
        this.cityListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCityList(query).then((result) => {
                this.cityListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.cityListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchRevisiSTList = async (query) => {
        this.revisiSTListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRevisiSTList(query).then((result) => {
                this.revisiSTListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.revisiSTListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchParticipantList = async (query) => {
        this.participantListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchParticipantList(query).then((result) => {
                this.participantListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.participantListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchCekParticipant = async (query) => {
        this.cekParticipantChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCekParticipant(query).then((result) => {
                this.cekParticipantChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.cekParticipantChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

}

export default CreateBloc
