import React, { useEffect } from "react"
import { authContext } from '../../services/adalConfig'

const Logout = props => {
  useEffect(() => {
    authContext.logOut()
    sessionStorage.clear()
    localStorage.clear()
    props.logoutUser(props.history)
  })

  return <></>
}

export default Logout
