import {
    apiRequest as apiRequestCommon,
    receiverGroupCreate,
} from "../../../../../services/adapters/common/masterService"
import {
    apiRequest as apiRequestOutbox,
    positionList as internalReceiverList
} from "../../../../../services/adapters/suratkeluar/outboxService"

class CreateRepository {
    fetchCreate = async (query) => {
        return await apiRequestCommon("post", receiverGroupCreate, {
            body: query
        }, true)
    }

    fetchInternalReceivers = async (query) => {
        return await apiRequestOutbox("get", internalReceiverList, {
            body: query
        }, true)
    }
}

export default CreateRepository
