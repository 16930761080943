import {
    apiRequest,
    daftarSuratDetail
} from "../../../../../services/adapters/suratmasuk/inboxService"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", daftarSuratDetail, {
            params: query
        }, true)
    }
}

export default DetailRepository