import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import TableActiveRepository from '../Repositories/IndexTableActiveRepository'


class TableActiveBloc {

    channel = new Subject();
    repository = new TableActiveRepository()

    constructor() {
        this.channel.next({status: apiResponse.INITIAL})
    }

    fetchData = async (query, cancelToken) => {
        this.channel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchData(query, cancelToken).then((result) => {
            this.rowtableChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'channel.unsubscribed')}
    }
}

export default TableActiveBloc