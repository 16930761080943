import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannel = new Subject();
    letterTypeListChannel = new Subject();
    //activeChannel = new Subject();
    repository = new DataTableRepository()

    constructor() {
        this.rowtableChannel.next({status: apiResponse.INITIAL})
        //this.inactiveChannel.next({status: apiResponse.INITIAL})
        //this.activeChannel.next({status: apiResponse.INITIAL})
    }
   
    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
            this.rowtableChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }  

    fetchLetterTypeList  = async (query) => {
        this.letterTypeListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchLetterTypeList (query).then((result) => {
                this.letterTypeListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.letterTypeListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
}

export default DataTableBloc