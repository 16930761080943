import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import { formatOption } from '../../../../helpers/formatOption'

var reformatOptions = formatOption;
const mysession = JSON.parse(localStorage.getItem('userdata'))
console.log(mysession)
class IndexView extends Component {

    createBloc = new CreateBloc();

    constructor(props) {
        super(props)
        this.state = {
            unitOption: [],
            pegawaiOption: [],
            jenisTu: [
                { value: '0', label : 'TU PUSAT BKPM'},
                { value: '1', label : 'TU UNIT KERJA'},
            ],
            dataForm: {},
            showTuUnit : false,
            filterByUnit: true,
        }
        //this.handleJenisTU = this.handleJenisTU.bind(this);
    }

    handleTipeAsalSurat = (option) => {
        let selected = option.value;
        if(selected == 1){
            this.setState ({
                showTuUnit : true
            })
        }else{
            this.setState ({
                showTuUnit : false
            })
        }
    }
    // End Select Area

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, '') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        //const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratmasuk/attachment'})
        await this.createBloc.fetchCreate(formData)
    }


    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.employee_uuid,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })


        this.createBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            unitOption: reformatOptions(
                                response.result,
                                'work_unit_name',
                                'work_unit_uuid'
                            ),
                            employeeOption: reformatOptions(
                                response.pegawai,
                                'position_name_text',
                                'position_employee_nip',
                                'position_work_unit_uuid',
                            ),
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchUnitList({})
        // get data marital status

    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Tambah Daftar User Tata Usaha Masuk</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            Akses Role
                                            </label>
                                            <Col md="5">
                                            <CustomSelect
                                                    name="officer_adm_type"
                                                    errorMessage="Pilih Akses Role"
                                                    options={this.state.jenisTu}
                                                    placeholder="Pilih Akses Role"
                                                    onChange={(opt) => this.handleTipeAsalSurat(opt)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3" style={this.state.showTuUnit ? {} : { display: 'none' }}>
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            Unit Kerja
                                            </label>
                                            <Col md="5">
                                            <CustomSelect
                                                    name="officer_adm_unit_uuid"
                                                    errorMessage="Pilih Unt"
                                                    options={this.state.unitOption}
                                                    //optionValue="region_uuid"
                                                    //optionLabel="region_name"
                                                    placeholder="Pilih Unt"
                                                    //validate={{ required: { value: true } }}
                                                    onChange={(opt) => this.setState({ selectedUnitUuid: opt.value })}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            Nama Pegawai
                                            </label>
                                            <Col md="5">
                                            <CustomSelect
                                                name="officer_adm_nip"
                                                errorMessage="Pilih Pegawai"
                                                options={
                                                    this.state.showTuUnit && this.state.filterByUnit
                                                        ? this.state.employeeOption?.filter(opt => opt.position_work_unit_uuid === this.state.selectedUnitUuid) || []
                                                        : this.state.employeeOption
                                                }
                                                //optionValue="region_uuid"
                                                //optionLabel="region_name"
                                                placeholder="Pilih Pegawai"
                                                //validate={{ required: { value: true } }}
                                            />
                                            </Col>

                                            {this.state.showTuUnit ? (
                                                <Col>
                                                    <FormGroup check inline style={{ marginBlockStart: '.5rem' }}>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={this.state.filterByUnit}
                                                                onChange={e => {
                                                                    this.setState({ filterByUnit: e.target.checked })
                                                                }}
                                                            />
                                                            Filter by unit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            ) : null}
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            .
                                            </label>
                                            <Col md="10">
                                                <Link to="/setting/usertatausaha.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/setting/usertatausaha.html'/>
                : null }
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);
