import React, { Fragment, Component } from "react"
import { 
    Container, Row, Col, CardBody, Card,CardHeader,
    CardTitle, Nav, NavItem, NavLink, TabPane, TabContent, Button
} from "reactstrap"
import classnames from 'classnames'
import { Link } from "react-router-dom"
import apiResponse from '../../services/apiResponse'
import { withRouter } from "react-router"
import DetailBloc from './Blocs/DetailBloc'
import ErrorAccess from '../../components/Error/Content/Access'
import Doughnut from "./Chart/Doughnut"
import Pie from "./Chart/Pie"
import Bar from "./Chart/Bar"

import moment from "moment"
import 'moment/locale/id'

class DetailView extends Component {
    DetailBloc = new DetailBloc()
    
    constructor(props) {
        super(props)
        
        this.state = {
            lastSync:null,
            letterin:[],
            letterout:[],
            showEselon1:true,
            showEselon2:false
        }
    }
    
    loadData = () => {
        this.DetailBloc.fetchDetail()
    }

    drillData = (uuid) => {
        //alert(uuid)
        this.setState({
            showEselon1 : false,
            showEselon2 : true,
        })
        this.DetailBloc.fetchDrill({uuid: uuid })
    }
    componentDidMount() {
        this.DetailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            all: response.all,
                            lastSync: response.all.sync_date,
                            eselon1: response.eselon1,
                            letterin: response.letterin,
                            letterout: response.letterout,                         
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.DetailBloc.drillChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            eselon2: response.eselon2
                        })
                        //console.log(this.state.urlPdf);
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
       
        this.DetailBloc.fetchDetail()
        
    }

    render() {
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        {/* <Card>
                            <CardHeader> */}
                            
                            <h2 className="text-center mt-2">Dashboard Persuratan</h2>
                            <h6 className="text-center">Last Sync : {this.state.lastSync != null ? <span>{moment(this.state.lastSync).format("DD MMMM YYYY, HH:mm:ss")}</span> : <b>Gagal Mengambil Data..</b> } 
                                &nbsp;<Button className="btn-sm btn-info waves-effect btn-rounded" onClick={() => this.loadData()}><i className="uil-refresh"></i></Button>
                            </h6>
                            
                            
                            {/* </CardHeader> */}
                        {/* <CardBody> */}
                            <Row>
                                <Col md={12}>
                                    <Card>
                                    <CardBody>
                                        <h5>Kementerian Investasi/BKPM</h5>    
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h6>Surat Masuk</h6>
                                                <div id="doughnut-chart" className="e-chart">
                                                    <Pie process={this.state.all != null ? this.state.all.process_in : 0} done={this.state.all != null ? this.state.all.done_in : 0}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <h6>Surat Keluar</h6>
                                                <div id="doughnut-chart" className="e-chart">
                                                <Pie process={this.state.all != null ? this.state.all.process_out : 0} done={this.state.all != null ? this.state.all.done_out : 0}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h6>Grafik Perkembangan Surat Masuk & Surat Keluar</h6>
                                                <div id="doughnut-chart" className="e-chart">
                                                <Bar letter_in={this.state.letterin} letter_out={this.state.letterout} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </CardBody>
                                    </Card>
                                </Col>
                                <Col md={12} style={this.state.showEselon1 ? {} : { display: 'none' }}>                                    
                                    <div className="row">   
                                        {
                                            this.state.eselon1 ? 
                                                this.state.eselon1.map((data, index) => {
                                                    return (
                                                        <div className="col-md-3">
                                                            <Card>
                                                                <CardBody>
                                                                <h6><a href="javascript:void(0)" onClick={() => this.drillData(data.unit)}>{data.unit_name}</a></h6>
                                                                <div className="row">
                                                                    <div id="doughnut-chart" className="col-sm-6 e-chart">
                                                                    <h6>Surat Masuk</h6>
                                                                    <Doughnut process={data.process_in} done={data.done_in}/>
                                                                    </div>
                                                                    <div id="doughnut-chart" className="col-sm-6 e-chart">
                                                                    <h6>Surat Keluar</h6>
                                                                    <Doughnut process={data.process_out} done={data.done_out}/>
                                                                    </div>
                                                                </div>
                                                                </CardBody>
                                                            </Card>
                                                        </div>                                                       
                                                    );
                                                })
                                                
                                            : 
                                            null
                                        }
                                    </div>
                                </Col>
                                <Col md={12} style={this.state.showEselon2 ? {} : { display: 'none' }}>
                                   
                                    <div className="row">   
                                        <span><Button className="btn-sm btn-info waves-effect btn-rounded" onClick={() => this.setState({showEselon1:true,showEselon2:false})}><i className="uil-previous"></i> Back</Button></span>
                                        <br/>
                                        {
                                            this.state.eselon2 ? 
                                                this.state.eselon2.map((data, index) => {
                                                    return (
                                                        <div className="col-md-3">
                                                            <Card>
                                                                <CardBody>
                                                                <h6>{data.unit_name}</h6>
                                                                <div className="row">
                                                                    <div id="doughnut-chart" className="col-sm-6 e-chart">
                                                                    <h6>Surat Masuk</h6>
                                                                    <Doughnut process={data.process_in} done={data.done_in}/>
                                                                    </div>
                                                                    <div id="doughnut-chart" className="col-sm-6 e-chart">
                                                                    <h6>Surat Keluar</h6>
                                                                    <Doughnut process={data.process_out} done={data.done_out}/>
                                                                    </div>
                                                                </div>
                                                                </CardBody>
                                                            </Card>
                                                        </div>                                                        
                                                    );
                                                })
                                                
                                            : 
                                            null
                                        }
                                        
                                    </div>
                                </Col>
                            </Row>
                        {/* </CardBody> */}
                        {/* </Card> */}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
}

//export default IndexView
export default withRouter(DetailView);