import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput, Modal
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import TableActiveComponent from './Components/TableActiveComponent'
import TimePicker from 'react-time-picker';
import AssignmentOutOfTownCitiesComponent from '../../../../components/Outbox/Draft/AssignmentOutOfTownCitiesComponent'
import AssignmentOverseasCountriesComponent from '../../../../components/Outbox/Draft/AssignmentOverseasCountriesComponent'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];

      return newObj;
    });
};
var reformatParticipant = function(raw, remove) {
    return raw.map(function(data,index) {
      var newObj = {};
      newObj["participant_nip"] = (index == remove ? '' : data['participant_nip']);
      return newObj;
    });
};

var reformatPenerima = function(raw, label, uuid, nip, type) {
    return raw.map(function(data,index) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[type] == 1 ? data[uuid] : data[nip];
      return newObj;
    });
};

var reformatInternalReceiverGroupOptions = (raw, label, value) => {
    return raw.map((data) => ({
        label: `Grup: ${data[label]}`,
        value: `group:${data[value]}`,
    }))
}

//const mysession = JSON.parse(localStorage.getItem('userdata'))
//console.log(mysession)

class IndexView extends Component {
    //mysession = JSON.parse(localStorage.getItem('userdata'))
    createBloc = new CreateBloc();
    selectRef = null;

    stNonPerjadinSubtypes = /** @type {const} */ ([
        ['TUGAS_BELAJAR', 'Tugas Belajar'],
        ['PENDIDIKAN_DAN_PELATIHAN', 'Pendidikan dan Pelatihan'],
        ['WFH', 'WFH'],
        ['LAINNYA', 'Lainnya'],
    ])

    /**
     * @type {{
     *   [key: number]: ({
     *     signer: number,
     *     signerText: string[],
     *   } | {
     *     saksi: number,
     *     saksiText: string[],
     *   })
     * }}
     */
    maxSignerByLetterType = {
        19: { signer: 2, signerText: ['Pemberi Kuasa', 'Penerima Kuasa'] },
        20: {
            signer: 2, signerText: ['Pihak Pertama', 'Pihak Kedua'],
            saksi: 2, saksiText: ['Saksi Pertama', 'Saksi Kedua'],
        },
    }

    constructor(props) {
        super(props)
        this.state = {
            // optionGender : [
            //     { label: "Laki-laki", value: "L" },
            //     { label: "Perempuan", value: "P" }
            // ],
            dataparticipant:[],
            listParticipant: [
                {
                    participant_nip: ''
                }
            ],
            listParticipantExt: [
                {
                    participant_name: '',
                    participant_instansi: '',
                    participant_rank: ''
                }
            ],
            listReceiverExternal: [
                {
                    receiver_external_type: '',
                    receiver_external: '',
                    receiver_external_detail: '',
                    show_receiver_external: true
                }
            ],
            listTembusanExternal:  [
                {
                    receiver_tembusan_ext: ''
                }
            ],
            letterPriorityOption: [],
            letterPriorityOptionAttributes: {},
            letterTypeOption: [],
            letterTypeOptionAttributes: {},
            letterSignerOption: [],
            attachmentTypeOption: [],
            originSenderOption: [],
            redactionOption: [],
            instansiOption: [],
            positionOption: [],
            senderOption: [],
            employeeOption: [],
            countryOption: [],
            provinceOption: [],
            revisiSTOption: [],
            dataForm: {},
            submitStatus: 0,

            letter_in_sender_validate : true,
            letter_in_sender_mandatory_teks : "",
            letter_in_sender_show : true,
            letter_in_sender_detail_validate : true,
            letter_in_sender_detail_mandatory_teks : "",
            setReferenceLetter: false,
            showReferenceLetter: false,
            selectedNoAgenda:"",
            selectedRefQrCode:"",
            multiPenerima:[],
            multiTembusan:[],
            multiPenerimaTugas:[],
            participantST:[],
            listSTRevisi:[],
            logoOption : [
                { value: '5', label : 'Logo BKPM'},
                { value: '6', label : 'Logo BKPM Bahasa Inggris'},
                { value: '1', label : 'Logo Garuda Bahasa Indonesia'},
                { value: '2', label : 'Logo Garuda Bahasa Inggris'},
                { value: '3', label : 'Logo Kepala Bahasa Indonesia'},
                { value: '4', label : 'Logo Kepala Bahasa Inggris'}
            ],
            logoOptionAttributes: {},
            overseasOption : [
                { value: '1', label : 'Dalam Negeri'},
                { value: '2', label : 'Luar Negeri'}
            ],
            domicileOption : [
                { value: '1', label : 'Dalam Kota'},
                { value: '2', label : 'Luar Kota'}
            ],
            allowanceOption : [
                { value: '0', label : 'Kurang dari 8 jam'},
                { value: '1', label : 'Lebih dari 8 jam'}
            ],
            rankOption : [
                { value: '1', label : 'Golongan I'},
                { value: '2', label : 'Golongan II'},
                { value: '3', label : 'Golongan III'},
                { value: '4', label : 'Golongan IV'}
            ],
            showNegara: false,
            showLokasi: false,
            showProvinsi: false,
            showKota: false,
            showAllowance : false,
            showRevisiST : false,
            jenisST: 1,
            tujuanST: 1,
            minDateST: null,
            maxDateST: null,
            minDateSTNew: null,
            minDateSTRev: null,
            minDate: null,
            showSuratNonST:true,
            showLogo : false,
            infoST:"",
            infoSTdll:"",
            infoSTNondin: false,
            mysession : JSON.parse(localStorage.getItem('userdata')),
            jamMulai : "08:00",
            jamSelesai : "16:00",
            readonlyDate : false,
            btnAddParticipant : true,
            isSTRevisi: false,
            isiPerihal:"",
            showJenisSTNonPerjadin: false,
            jenisSTNonPerjadin: "TUGAS_BELAJAR", // default checked

            // Penandatangan
            showSigner: false,
            suggestedSigner: null,
            isDelegateSign: false,
            maxSigner: 1,
            maxSaksi: 0,

            /**
             * @type {Array<({
             *   sign_type: 'DIRECT',
             *   sign_as: 'SIGNER',
             * } | {
             *   sign_type: 'DELEGATION_INTERNAL',
             *   sign_as: 'SIGNER' | 'SAKSI',
             * } | {
             *   sign_type: 'DELEGATION_EXTERNAL',
             *   sign_as: 'SIGNER' | 'SAKSI',
             * }) & { text ?: string }>}
             */
            signers: [],

            autoinput: null,
            autoinputUnready: {},
            inventoryRoomUuid: null,
        }
        this.handleMultiPenerima = this.handleMultiPenerima.bind(this);
        this.handleMultiTembusan = this.handleMultiTembusan.bind(this);
        this.handleMultiPenerimaTugas = this.handleMultiPenerimaTugas.bind(this);
    }

    // End Select Area

    submitData = async () => {
        //console.log(this.state.dataForm)
        const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratkeluar/attachment'}) // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        await this.createBloc.fetchCreate(formData)
    }

    handleMultiPenerima(option) {
        this.setState({
            multiPenerima: (option != null) ? option : []
        });

    }

    handleMultiTembusan(option) {
        this.setState({
            multiTembusan: (option != null) ? option : []
        });

        //console.log(option)
    }

    handleMultiPenerimaTugas(option) {
        console.log(option)
        this.setState({
            multiPenerimaTugas: (option != null) ? option : []
        });

        //console.log(option)
    }

    handleSaveDraft = (s) => {
        //console.log(s);
        this.setState({
            submitStatus: s
        })
    }

    handleGetQRCode = (qrcode, noagenda) => {
        //alert(qr);
        this.setState({
            showReferenceLetter : false,
            selectedRefQrCode : qrcode,
            selectedNoAgenda : noagenda
        })
        // //console.log(s);
        // this.setState({
        //     submitStatus: s
        // })
    }

    handleTipeAsalSurat = (option,index) => {
        let selected = option.value
        let show = true
        if(selected == 1){
            show = false
        }else{
            this.getInstansiOptions()
        }
        let items = [...this.state.listReceiverExternal];
        let item = {...items[index]};
        item.show_receiver_external = show;
        items[index] = item;
        this.setState({listReceiverExternal : items});
    }

    handleTipeSurat = (option) => {
        //console.log(option);
        let selected = option.value;
        let jns = new Array(4,5,12,14,16,17,19,20);
        if(jns.indexOf(selected) >= 0){
            this.setState({showLogo:false})
        }else{
            this.setState({showLogo:true})
        }

        if(selected > 4){
            this.setState ({
                setReferenceLetter : false,
                selectedNoAgenda:"",
                selectedRefQrCode:"",
                showSuratTugas : (selected == 6 ? true : false),
                showJenisSuratTugas : (selected == 6 ? true : false),
                showSuratUndangan : (selected == 7 ? true : false),
                showSuratNonST: (selected == 6 ? false : true),
                isiPerihal:"",
                infoST : (selected == 6 ? "Surat Tugas Perjalanan Dinas akan dialirkan ke Sistem Kepegawaian sebagai dasar Presensi, dan Sistem Keuangan sebagai dasar Pembayaran Dinas. Pastikan data yang di inputkan sudah sesuai. " : "")
            })
            if(selected == 6){
                this.setState.showSuratNonST = false;
            }
        }else{
            this.setState ({
                setReferenceLetter : true,
                showSuratNonST:true,
                showSuratTugas : false,
                showJenisSuratTugas : false,
                showSuratUndangan : false,
                infoST:"",
                isiPerihal:""
            })
        }

        this.setState({ showSigner: true })
        this.setMaxSignerByLetterTypeId(selected)
        this.fetchPenandatangan({ letterTypeId: selected })
    }

    /**
     * @param {number} letterTypeId
     */
    setMaxSignerByLetterTypeId = (letterTypeId) => {
        const maxSigner = this.maxSignerByLetterType[letterTypeId]?.signer ?? 1
        const signerText = this.maxSignerByLetterType[letterTypeId]?.signerText ?? null

        const maxSaksi = this.maxSignerByLetterType[letterTypeId]?.saksi ?? 0
        const saksiText = this.maxSignerByLetterType[letterTypeId]?.saksiText ?? null

        // Buat array dengan size `maxSigner` + `maxSaksi`
        // Coba isi dengan value dari `signers`
        /**
         * @type {Array<{
         *   sign_type: string,
         *   sign_as: string,
         *   text: string | null,
         * }>}
         */
        const signers = Array.from(
          Array(maxSigner + maxSaksi),
          (_, idx) => {
              const isSigner = idx < maxSigner
              const textIdx = isSigner ? idx : idx - maxSigner
              const texts = isSigner ? signerText : saksiText

              return {
                  // Jika value tidak valid dan merupakan value pertama, maka isi dengan `sign_type` 'DIRECT'
                  // Jika value tidak valid dan bukan value pertama, maka isi dengan `sign_type` 'DELEGATION_INTERNAL'
                  sign_type: this.state.signers[idx]?.sign_type || (idx === 0 ? 'DIRECT' : 'DELEGATION_INTERNAL'),

                  // Jika masih mengenerate penandatangan maka isi dengan 'SIGNER', selain itu 'SAKSI'
                  sign_as: this.state.signers[idx]?.sign_as || (isSigner ? 'SIGNER' : 'SAKSI'),

                  // Ambil text yang sudah ditentukan
                  text: texts?.[textIdx],
              }
          }
        )

        this.setState({ maxSigner, maxSaksi, signers })
    }

    fetchPenandatangan = ({ letterTypeId }) => {
        this.createBloc.fetchLetterSignerList({
            employee_nip: this.state.mysession.employee_nip,
            letter_type_id: letterTypeId,
        })
    }

    handleSignerTypeChange = e => {
        const { value, dataset: { index } } = e.target
        const signers = this.state.signers

        signers[index].sign_type = value

        this.setState({ signers })
    }

    handleTypeST = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked);
        //let selected = option.value;
        if(isChecked){
            this.setState ({
                //readonlyDate: false,
                showJenisSuratTugas : true,
                showJenisSTNonPerjadin: false,
                showJenisSuratTugasLembur : false,
                jenisST:1,
                infoST:"Surat Tugas Perjalanan Dinas akan dialirkan ke Sistem Kepegawaian sebagai dasar Presensi, dan Sistem Keuangan sebagai dasar Pembayaran Dinas. Pastikan data yang di inputkan sudah sesuai. ",
                onlyHideButtonAddParticipant: false,
            })
        }else{
            this.setState ({
                showJenisSuratTugas : false,
                showJenisSTNonPerjadin: false,
                showJenisSuratTugasLembur : false,
                showRevisiST : false,
                onlyHideButtonAddParticipant: false,
            })
        }
    }
    handleTypeNonST = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked);
        //let selected = option.value;
        if(isChecked){
            this.setState ({
                readonlyDate: false,
                minDateST: this.state.minDateSTNew,
                maxDateST: null,
                showJenisSuratTugas : false,
                showJenisSTNonPerjadin: true,
                showJenisSuratTugasLembur : false,
                showRevisiST : false,
                jenisST:0,
                infoST:"Surat Tugas Non Perjalanan Dinas tidak akan diproses pembayaran dinas di sistem keuangan. Pastikan data yang di inputkan sudah sesuai. ",
                infoSTdll:"Surat Tugas Non Perjalanan Dinas tidak akan diproses Pembayaran Dinas di Sistem Keuangan.",
                infoSTNondin:true,
                btnAddParticipant:true,
                isSTRevisi: false,
                isiPerihal:""
            })
        }else{
            this.setState ({
                showJenisSuratTugas : false,
                showJenisSTNonPerjadin: false,
                showJenisSuratTugasLembur : false
            })
        }
    }
    handleTypeSTLembur = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked);
        //let selected = option.value;
        if(isChecked){
            this.setState ({
                readonlyDate: false,
                minDateST: this.state.minDateSTNew,
                maxDateST: null,
                showJenisSuratTugas : false,
                showJenisSTNonPerjadin: false,
                showJenisSuratTugasLembur: true,
                showRevisiST : false,
                jenisST:2,
                infoST:"Surat Tugas Lembur akan dialirkan ke Sistem Kepegawaian sebagai dasar Data Lembur, dan Sistem Keuangan sebagai dasar Pembayaran Lembur. Pastikan data yang di inputkan sudah sesuai. ",
                //infoSTNondin:true
                btnAddParticipant:true,
                isSTRevisi: false,
                isiPerihal:""
            })
        }else{
            this.setState ({
                showJenisSuratTugas : false,
                showJenisSTNonPerjadin: false,
                showJenisSuratTugasLembur : false,
                showRevisiST : false,
            })
        }
    }
    handleTempatDinas = (option) => {
        //console.log(option);
        let selected = option.value;
        if(selected == 1){
            this.setState ({
                showLokasi : true,
                showNegara : false,
                showAllowance : false,
            })
        }else{
            this.setState ({
                showLokasi : false,
                showProvinsi : false,
                showKota : false,
                showAllowance : false,
                showNegara : true
            })
        }
    }

    handleLokasiDinas = (option) => {
        //console.log(option);
        let selected = option.value;
        if(selected == 1){
            this.setState ({
                showProvinsi : false,
                showKota : false,
                showAllowance : true,
            })
        }else{
            this.setState ({
                showProvinsi : true,
                showKota : true,
                showAllowance : false,
            })
        }
    }

    handleGetCity = (province_uuid) => {
        this.setState((prev) => ({
            cityByProvinceOptions: {
                ...prev.cityByProvinceOptions,
                [province_uuid]: { loading: true },
            }
        }))

        const query = {
            province : province_uuid
        }

        this.createBloc.fetchCityList(query, "")
    }

    handleTujuanST = (selected) => {
        // Revisi ST perjadin
        if(selected == 2 || selected == 3){
            var addinfo = "";
            if(selected == 2){
                addinfo +="Revisi Surat Tugas :";
                addinfo +="<ul>";
                addinfo +="<li>Merubah pelaksana tugas tanpa merubah periode perjalanan dinas.</li>";
                addinfo +="<li>Revisi ini akan membatalkan ST Induk.</li>";
                addinfo +="<li>Dilakukan paling lambat 1 (satu) hari kerja setelah tanggal perjalanan dinas dilaksanakan.</li>";
                addinfo +="</ul>";
            }else{
                addinfo +="Revisi Tanggal Pelaksana Tugas :";
                addinfo +="<ul>";
                addinfo +="<li>Merubah periode perjalanan dinas atas pegawai yang tercantum dalam ST Induk.</li>";
                addinfo +="<li>Tidak membatalkan ST Induk.</li>";
                addinfo +="<li>Dilakukan paling lambat 1 (satu) hari kerja setelah tanggal perjalanan dinas dilaksanakan.</li>";
                addinfo +="</ul>";
            }
            this.setState({
                showRevisiST : true,
                tujuanST: selected,
                //minDateST : this.state.minDateSTRev,
                readonlyDate : (selected == 2 ? true: false),
                infoSTdll: addinfo,
                infoSTNondin:true,
                btnAddParticipant: (selected == 2 ? true: false),
                isSTRevisi: true,
                onlyHideButtonAddParticipant: false,
            },function(){
                this.createBloc.revisiSTListChannel.subscribe((result) => {
                    switch (result.status) {
                        case apiResponse.COMPLETED:
                            if(result.data.status) {
                                let response = result.data.response
                                //console.log(response)

                                this.setState({
                                    revisiSTOption: reformatOptions(
                                        response.revisist,
                                        'letter_out_number',
                                        'letter_out_number'
                                    ),
                                    minDateST : response.min_stdate,
                                    minDateSTNew : response.min_stdate,
                                    minDateSTRev : response.min_stdate_rev,
                                    letter_assignment_start_date:response.min_stdate,
                                    letter_assignment_end_date:response.min_stdate,
                                    listSTRevisi : response.revisist
                                })
                            }
                            break
                        case apiResponse.ERROR:

                            break
                        default:
                            break
                    }
                })
                const query = {
                    jenisST : 1
                }
                this.createBloc.fetchRevisiSTList(query, "")
            })
        }
        // Revisi ST lembur
        else if(selected == 5){
            var addinfo = "";
            addinfo +="Revisi ST Lembur :";
            addinfo +="<ul>";
            addinfo +="<li>Merubah pelaksana tugas tanpa merubah periode lembur.</li>";
            addinfo +="<li>Revisi ini akan membatalkan ST Induk.</li>";
            addinfo +="<li>Dilakukan paling lambat 1 (satu) hari kerja setelah tanggal lembur dilaksanakan.</li>";
            addinfo +="</ul>";
            this.setState({
                showRevisiST : true,
                tujuanST: selected,
                //minDateST : this.state.minDateSTRev,
                readonlyDate : true,
                infoSTdll: addinfo,
                infoSTNondin:true,
                btnAddParticipant: true,
                isSTRevisi: true,
                onlyHideButtonAddParticipant: false,
            },function(){
                this.createBloc.revisiSTListChannel.subscribe((result) => {
                    switch (result.status) {
                        case apiResponse.COMPLETED:
                            if(result.data.status) {
                                let response = result.data.response
                                //console.log(response)

                                this.setState({
                                    revisiSTOption: reformatOptions(
                                        response.revisist,
                                        'letter_out_number',
                                        'letter_out_number'
                                    ),
                                    minDateST : response.min_stdate,
                                    minDateSTNew : response.min_stdate,
                                    minDateSTRev : response.min_stdate_rev,
                                    letter_assignment_start_date:response.min_stdate,
                                    letter_assignment_end_date:response.min_stdate,
                                    listSTRevisi : response.revisist
                                })
                            }
                            break
                        case apiResponse.ERROR:

                            break
                        default:
                            break
                    }
                })

                const query = {
                    jenisST : 2
                }
                this.createBloc.fetchRevisiSTList(query, "")
            })
        }else{
            this.setState({
                showRevisiST : false,
                tujuanST: 1,
                minDateST : this.state.minDateSTNew,
                readonlyDate: false,
                maxDateST:null,
                btnAddParticipant:true,
                isSTRevisi: false,
                isiPerihal:"",
                onlyHideButtonAddParticipant: selected === 'TUGAS_BELAJAR',
            })
        }
    }

    handleMinDateST = (e) => {
        this.setState ({
            minDate : e.target.value,
            letter_assignment_start_date : e.target.value
        })
    }

    handleMaxDateST = (e) => {
        this.setState ({
            letter_assignment_end_date : e.target.value
        })
    }

    handleSetFormST = (option) =>{
        //alert(this.state.tujuanST)
        this.state.listSTRevisi.map((data, index) => {
            if(data.letter_out_number == option.value){
                this.createBloc.participantListChannel.subscribe((result) => {
                    switch (result.status) {
                        case apiResponse.COMPLETED:
                            if(result.data.status) {
                                let response = result.data.response
                                this.setState(
                                    {
                                        listParticipant: [],
                                        listParticipantExt: [],
                                        dataparticipant:[]
                                    }
                                )

                                response.participant.map((data, index) => {
                                    this.setState({
                                        listParticipant: [
                                            ...this.state.listParticipant,
                                            {
                                                participant_nip : data.letter_participant_emp_nip+"|"+data.letter_participant_position_type+"|"+data.letter_participant_position_uuid
                                            }
                                        ],
                                        dataparticipant: [
                                            ...this.state.dataparticipant,
                                            {
                                                participant_nip : data.letter_participant_emp_nip+"|"+data.letter_participant_position_type+"|"+data.letter_participant_position_uuid
                                            }
                                        ]
                                    })
                                });

                                response.participant_ext.map((data, index) => {
                                    this.setState({
                                        listParticipantExt: [
                                            ...this.state.listParticipantExt,
                                            {
                                                participant_name : data.letter_participant_ext_name,
                                                participant_instansi : data.letter_participant_ext_position,
                                                participant_rank : data.letter_participant_ext_rank
                                            }
                                        ]
                                    })
                                });
                                if(response.participant_ext.length == 0){
                                    this.setState(
                                        {
                                            listParticipantExt: [{
                                                participant_name : '',
                                                participant_instansi : '',
                                                participant_rank : ''
                                            }]
                                        }
                                    )
                                }


                            }
                            break
                        case apiResponse.ERROR:

                            break
                        default:
                            break
                    }
                })
                const query = {
                    qrcode : data.letter_assignment_qrcode
                }
                this.createBloc.fetchParticipantList(query, "")

                for (const { city_schedule_province_uuid } of data.city_schedules) {
                    this.createBloc.fetchCityList({ province: city_schedule_province_uuid }, "")
                }

                console.log(data)
                this.setState({
                    letter_assignment_start_date : data.letter_assignment_start_date,
                    letter_assignment_end_date : data.letter_assignment_end_date,
                    letter_assignment_destination : data.letter_assignment_destination,
                    letter_assignment_description : data.letter_assignment_description,
                    letter_assignment_oversea : data.letter_assignment_oversea,
                    letter_assignment_domicile : data.letter_assignment_domicile,
                    letter_assignment_province_uuid : data.letter_assignment_province_uuid,
                    letter_assignment_city_uuid : data.letter_assignment_city_uuid,
                    letter_assignment_country_uuid : data.letter_assignment_country_uuid,
                    letter_assignment_allowance : data.letter_assignment_allowance,
                    citySchedules : data.city_schedules,
                    countrySchedules : data.country_schedules,
                    showLokasi : (data.letter_assignment_oversea == 1 ? true : false),
                    showNegara : (data.letter_assignment_oversea == 2 ? true : false),
                    showAllowance : (data.letter_assignment_domicile == 1 ? true : false),
                    showProvinsi : (data.letter_assignment_domicile == 2 ? true : false),
                    showKota : (data.letter_assignment_domicile == 2 ? true : false),
                    readonlyDate : (this.state.tujuanST == 2 || this.state.tujuanST == 5 ? true: false),
                    minDateST: data.letter_assignment_start_date,
                    maxDateST: data.letter_assignment_end_date,
                    isiPerihal: data.letter_out_title,
                    jamMulai : data.letter_assignment_start_time,
                    jamSelesai : data.letter_assignment_end_time,
                })
            }
        })
        console.log(this.state.listSTRevisi)
    }

    addParticipant = () => {
        let count = this.state.listParticipant.length;

        this.setState({
            listParticipant: [
                ...this.state.listParticipant,
                {
                    participant_nip : ""
                }
            ],
        })

        //console.log(this.state.listParticipant)
    }

    addParticipantExt = () => {
        let count = this.state.listParticipantExt.length;
        this.setState({
            listParticipantExt: [
                ...this.state.listParticipantExt,
                {
                    // participant_name : count == 0 ? (count+1) : this.state.listParticipantExt.slice(-1)[0].participant_name,
                    // participant_instansi : count == 0 ? (count+1) : this.state.listParticipantExt.slice(-1)[0].participant_instansi,
                    // participant_rank : count == 0 ? (count+1) : this.state.listParticipantExt.slice(-1)[0].participant_rank
                    participant_name : "",
                    participant_instansi : "",
                    participant_rank : ""
                }
            ]
        })
    }

    delParticipant = (data) => {
        console.log(data)
        let count = this.state.listParticipant.length-1;
        this.setState({
            listParticipant: [
                ...this.state.listParticipant.filter((val) => val!=data)
            ],
            dataparticipant: [
                ...this.state.dataparticipant.filter((val) => val!=data)
            ]

        })
    }

    delParticipantExt = (data) => {
        let count = this.state.listParticipantExt.length-1;
        this.setState({
            listParticipantExt: [
                ...this.state.listParticipantExt.filter((val) => val!=data)

                // (count-1)
            ]
        })
    }

    addReceiver = () => {
        let count = this.state.listReceiverExternal.length;
        this.setState({
            listReceiverExternal: [
                ...this.state.listReceiverExternal,
                {
                    receiver_external_type : count == 0 ? (count+1) : this.state.listReceiverExternal.slice(-1)[0].receiver_external_type,
                    receiver_external : count == 0 ? (count+1) : this.state.listReceiverExternal.slice(-1)[0].receiver_external,
                    receiver_external_detail : count == 0 ? (count+1) : this.state.listReceiverExternal.slice(-1)[0].receiver_external_detail,
                    show_receiver_external : count == 0 ? (count+1) : this.state.listReceiverExternal.slice(-1)[0].show_receiver_external,
                }
            ]
        })
    }
    delReceiver = (data) => {
        let count = this.state.listReceiverExternal.length-1;
        this.setState({
            listReceiverExternal: [
                ...this.state.listReceiverExternal.filter((val) => val!=data)

                // (count-1)
            ]
        })
    }

    addTembusan = () => {
        let count = this.state.listTembusanExternal.length;
        this.setState({
            listTembusanExternal: [
                ...this.state.listTembusanExternal,
                {
                    receiver_tembusan_ext : count == 0 ? (count+1) : this.state.listTembusanExternal.slice(-1)[0].participant_nip
                }
            ]
        })

        //console.log(this.state.listParticipant)
    }
    delTembusan = (data) => {
        let count = this.state.listTembusanExternal.length-1;
        this.setState({
            listTembusanExternal: [
                ...this.state.listTembusanExternal.filter((val) => val!=data)

                // (count-1)
            ]
        })
    }

    handleCekParticipantExt = (evt,index) => {
        //console.log( evt.target.value)
        let items = [...this.state.listParticipantExt];
        let item = {...items[index]};
        item.participant_name = evt.target.value;
        items[index] = item;
        this.setState({listParticipantExt : items});
    }
    clearValue = () => {
        this.selectRef.select.clearValue();
    }

    resetParticipant = (idx, add) => {
        let participant = this.state.dataparticipant
        this.setState({
            listParticipant: participant

        }, function(){
            if(add == 1){
                this.addParticipant()
            }
        })
    }

    handleCekParticipant = (option,index) => {

        if(this.state.tujuanST != 3){
            if(option != null){
                let nip = option.value

                const query = {
                    nip : nip,
                    start : this.state.letter_assignment_start_date,
                    end : this.state.letter_assignment_end_date,
                    j_start : this.state.jamMulai,
                    j_end : this.state.jamSelesai,
                    type: this.state.jenisST,
                    index: index
                }
                //console.log(query)
                //alert(province_uuid)
                this.createBloc.fetchCekParticipant(query, "")
            }else{
                let items = [...this.state.dataparticipant];
                let item = {...items[index]};
                item.participant_nip = "";
                items[index] = item;
                this.setState({ dataparticipant : items}, function(){
                    this.setState({
                        dataparticipant: [
                            ...this.state.dataparticipant.filter((val) => val!=item)
                        ]
                    })
                })
            }
        }
    }
    //handleSetMinDate = () =>
    handleShowReferenceLetter = () => {
        //console.log(option);
        this.setState ({
            showReferenceLetter : true
        })
    }

    getInstansiOptions = () => {
        this.createBloc.instansiListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            instansiOption: reformatOptions(
                                response.result,
                                'agency_name',
                                'agency_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchInstansiList({})
    }

    getpositionOptions = () => {
        this.createBloc.positionListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            instansiOption: reformatOptions(
                                response.result,
                                'work_unit_name_tabs',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchPositionList({})
    }

    handleOnDodrafting = (url) => {
        window.open(url,"_blank", "width=1000, height=1200, scrollbars=yes");
    };

    hidePopUpSenderUnset = (hidden = true) => {
        this.setState({
            failed: ! hidden,
            failedInfo: "Mohon untuk mengisi Penandatangan terlebih dahulu",
        })
    }

    validateSigners = ({ values }) => {
        const max = this.state.maxSigner + this.state.maxSaksi;

        for (let index = 0; index < max; index++) {
            const { sign_type: type } = this.state.signers[index]
            let error = false

            switch (type) {
                case 'DIRECT':
                    error = ! values?.letter_out_sender_position_uuid_direct?.[index]
                    break
                case 'DELEGATION_INTERNAL':
                    error = ! values?.letter_out_sender_position_uuid?.[index]
                    break
                case 'DELEGATION_EXTERNAL':
                    error =
                        ! values?.letter_out_sender_name?.[index] ||
                        ! values?.letter_out_sender_position?.[index]
                    break
            }

            if (error) {
                this.hidePopUpSenderUnset(false)
                break
            }
        }
    }

    handleUncheckSubmit = (event, errors, values) => {
        this.validateSigners({ values })
    }

    transformSigners = ({ values }) => {
        const signers = this.state.signers.map(({ sign_type, sign_as }, idx) => {
            /**
             * @type {{
             *   position_uuid: string,
             * } | {
             *   employee_name: string,
             *   position_name: string,
             *   email: string,
             * }}
             */
            let info = {}

            switch (sign_type) {
                case 'DIRECT':
                    info.position_uuid = values.letter_out_sender_position_uuid_direct[idx]
                    break
                case 'DELEGATION_INTERNAL':
                    info.position_uuid = values.letter_out_sender_position_uuid[idx]
                    break
                case 'DELEGATION_EXTERNAL':
                    info.employee_name = values.letter_out_sender_name[idx]
                    info.position_name = values.letter_out_sender_position[idx]
                    info.email = values.letter_out_sender_email[idx]
                    break
            }

            /**
             * @type {{
             *   sign_type: 'DIRECT',
             *   sign_as: 'SIGNER',
             *   position_uuid: string,
             * } | {
             *   sign_type: 'DELEGATION_INTERNAL',
             *   sign_as: 'SIGNER' | 'SAKSI',
             *   position_uuid: string,
             * } | {
             *   sign_type: 'DELEGATION_EXTERNAL',
             *   sign_as: 'SIGNER' | 'SAKSI',
             *   employee_name: string,
             *   position_name: string,
             *   email: string,
             * }}
             */
            const result = { sign_type, sign_as, ...info }

            return result
        })

        return signers
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()

        this.setState({
            dataForm: {
                inventory_room_uuid: this.state.inventoryRoomUuid,
                uuid: this.state.dataForm.employee_uuid,
                letter_out_status: this.state.submitStatus,
                ...values,
                letter_out_senders: JSON.stringify(this.transformSigners({ values })),
            },
            confirm: true
        })
    }

    handleSetJamMulai = (e) => {
        this.setState({jamMulai: e})
    }

    handleSetJamSelesai = (e) => {
        this.setState({jamSelesai: e})
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    handleAutoInputReady = (stateName, ready = true) => {
        const state = this.state.autoinputUnready

        if (ready) {
            delete state[stateName]
        } else {
            state[stateName] = false
        }

        const callback = Object.keys(state).length === 0 ? this.handleAutoInput : () => {}

        this.setState({ autoinputUnready: state }, callback)
    }

    handleAutoInput = () => {
        if (this.state.autoinput === null) {
            return
        }

        const autoInput = this.state.autoinput
        const fromInventory = autoInput?.inv

        if (fromInventory) {
            const letterTypeInvitation = 7

            const state = {
                letterTypeOptionAttributes: {
                    defaultValue: letterTypeInvitation,
                },
                logoOptionAttributes: {
                    defaultValue: [...this.state.logoOption || []].shift().value,
                },
                letterPriorityOptionAttributes: {
                    defaultValue: [...this.state.letterPriorityOption || []].shift().value,
                },
                letterInvitationDate: fromInventory.date || '',
                letterInvitationStartHour: fromInventory.startHour || '08:00',
                letterInvitationEndHour: fromInventory.endHour || '17:00',
                letterInvitationLocation: fromInventory.location || '',
                letterInvitationAgenda: fromInventory.agenda || '',
                inventoryRoomUuid: fromInventory.mroomloan_uuid || '',
            }

            this.setState(state, () => {
                this.handleTipeSurat({ value: letterTypeInvitation })
            })
        }

        this.setState({
            autoinput: null,
            loading: false,
        })
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    let status = result.data.status;
                    let response = result.data.response;
                    console.log(response);
                    if(status == 1){
                        if(response.doc.status == 1){
                            this.setState({
                                loading: false,
                                success: true,
                                qrcode: response.qrcode
                            });
                            //this.handleOnDodrafting(response.doc.url)
                        }else{
                            this.setState({
                                loading: false,
                                //success: true,
                                failed: true,
                                failedInfo : "Gagal menyiapakan halaman editor dokumen"
                            });
                        }
                    }else{
                        this.setState({
                            loading: false,
                            //success: true,
                            failed: true,
                            failedInfo : "Gagal Simpan Data " + (response.error != "" ? response.error : "")
                        });
                    }

                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })


        // get data referensi
        this.createBloc.letterPriorityListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            letterPriorityOption: reformatOptions(
                                response.result,
                                'priority_name',
                                'priority_id'
                            )
                        }, () => this.handleAutoInputReady('LETTER_PRIORITY'))
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchLetterPriorityList({})


        this.createBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            letterTypeOption: reformatOptions(
                                response.result,
                                'letter_type_name',
                                'letter_type_id'
                            ),
                            minDateST : response.min_stdate,
                            minDateSTNew : response.min_stdate,
                        }, () => this.handleAutoInputReady('LETTER_TYPE'))
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchLetterTypeList({})

        this.createBloc.letterSignerListChannel.subscribe(result => {
            switch (result?.status) {
                case apiResponse.COMPLETED:
                    if (! result?.data?.status) break

                    const response = result.data.response

                    this.setState({
                        suggestedSigner: response.result.suggested_signer,
                        letterSignerOption: reformatOptions(
                          response.result.signers,
                          'position_name_text',
                          'position_uuid',
                        ),
                    })

                    break
                default:
                    break
            }
        })

        this.createBloc.attachmentTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            attachmentTypeOption: reformatOptions(
                                response.result,
                                'attachment_type_name',
                                'attachment_type_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchAttachmentTypeList({})

        this.createBloc.originSenderListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            originSenderOption: reformatOptions(
                                response.result,
                                'origin_sender_name',
                                'origin_sender_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchOriginSenderList({})

        this.createBloc.redactionListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            redactionOption: reformatOptions(
                                response.result,
                                'redaction_name',
                                'redaction_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchRedactionList({})

        // this.createBloc.instansiListChannel.subscribe((result) => {
        //     switch (result.status) {
        //         case apiResponse.COMPLETED:
        //             if(result.data.status) {
        //                 let response = result.data.response
        //                 //console.log(response)

        //                 this.setState({
        //                     instansiOption: reformatOptions(
        //                         response.result,
        //                         'agency_name',
        //                         'agency_uuid'
        //                     )
        //                 })
        //             }
        //             break
        //         case apiResponse.ERROR:

        //             break
        //         default:
        //             break
        //     }
        // })
        //this.createBloc.fetchInstansiList({})

        this.createBloc.positionListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            positionOption: [
                                ...this.state.positionOption || [],
                                ...reformatPenerima(
                                  response.result,
                                  'position_name_text',
                                  'position_uuid',
                                  'position_employee_nip',
                                  'position_type'
                                ),
                            ],
                            employeeOption: reformatOptions(
                                response.all,
                                'position_name_text',
                                'position_employee_nip'
                            ),
                            participantOption: reformatOptions(
                                response.participant,
                                'position_name_text',
                                'position_employee_nip'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchPositionList({})

        this.createBloc.internalReceiverGroupListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        const response = result.data.response

                        this.setState({
                            positionOption: [
                                ...reformatInternalReceiverGroupOptions(
                                  response.group, 'receiver_group_name', 'receiver_group_uuid',
                                ),
                                ...this.state.positionOption || [],
                            ],
                        })
                    }
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchInternalReceiverGroupList()

        this.createBloc.senderListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            senderOption: reformatOptions(
                                response.result,
                                'position_name',
                                'position_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchSenderList({})

        this.createBloc.regionListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            countryOption: reformatOptions(
                                response.country,
                                'region_name',
                                'region_uuid'
                            ),
                            provinceOption: reformatOptions(
                                response.province,
                                'region_name',
                                'region_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.createBloc.fetchRegionList({})

        this.createBloc.cityListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response
                        const cityOptions = reformatOptions(response.city, 'region_name', 'region_uuid')

                        this.setState((prev) => ({
                            cityByProvinceOptions: {
                                ...prev.cityByProvinceOptions,
                                [response.province]: { loading: false, value: cityOptions },
                            }
                        }))
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.createBloc.cekParticipantChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        var index = response.index
                        var nip = response.nip
                        if(response.cek == 1){
                            //alert(index)
                            this.setState({
                                loading: false,
                                failed: true,
                                failedInfo : response.cuti,
                                listParticipant:[],
                            }, function(){

                                if(index == 0){
                                    this.addParticipant()
                                }else{
                                    this.resetParticipant(index, 1)
                                    //this.addParticipant()
                                }

                            });

                        }else{
                            this.setState({
                                dataparticipant: [
                                    ...this.state.dataparticipant,
                                    {
                                        participant_nip : nip
                                    }
                                ],
                                listParticipant:[]
                            }, function(){
                                this.resetParticipant(index, 0)
                            });

                            //var participant = this.state.dataparticipant
                            //participant[index] = nip
                            // this.setState({dataparticipant: participant,
                            //     //listParticipant:[]
                            // }, function(){
                            //     this.resetParticipant(index)
                            // });
                        }



                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        // get data marital status

        const queryParams = new URLSearchParams(this.props.location.search)

        try {
            const autoinputInv = JSON.parse(queryParams.get('autoinput.inv'))

            this.setState({
                autoinput: { inv: autoinputInv },
                autoinputUnready: {
                    LETTER_TYPE: false,
                    LETTER_PRIORITY: false,
                },
                loading: true,
            })
        } catch {
            // ignored
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Buat Surat Keluar</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onSubmit={ this.handleUncheckSubmit }
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >Jenis Surat<span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect
                                                        name="letter_out_type"
                                                        errorMessage="Pilih Jenis Surat"
                                                        options={this.state.letterTypeOption}
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Pilih Jenis Surat"
                                                        onChange={this.handleTipeSurat}
                                                        {...this.state.letterTypeOptionAttributes}
                                                    />
                                                </div>

                                            </Col>
                                            <Col md="3" style={this.state.showLogo ? {} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >Logo<span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect
                                                        name="letter_out_logo_id"
                                                        errorMessage="Pilih Logo Surat"
                                                        options={this.state.logoOption}
                                                        validate={{ required: { value: this.state.showLogo } }}
                                                        //defaultValue={this.state.mysession.employee_current_position_uuid}
                                                        placeholder="Pilih Logo"
                                                        {...this.state.logoOptionAttributes}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="4" style={this.state.setReferenceLetter ? {} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >Referensi Surat Masuk
                                                </label>

                                                <AvField
                                                    name="letter_out_ref_agenda"
                                                    placeholder="No Agenda Surat Masuk"
                                                    type="text"
                                                    errorMessage="Pilih No Agenda Surat Masuk"
                                                    className="form-control"
                                                    //validate={{ required: { value: true } }}
                                                    id="letter_out_ref_agenda"
                                                    readOnly
                                                    value={this.state.selectedNoAgenda}
                                                />
                                                <AvField
                                                    name="letter_out_ref_qrcode"
                                                    type="hidden"
                                                    className="form-control"
                                                    //validate={{ required: { value: true } }}
                                                    id="letter_out_ref_qrcode"
                                                    value={this.state.selectedRefQrCode}
                                                />


                                                {/* <CustomSelect
                                                        name="letter_out_ref_qrcode"
                                                        errorMessage="Pilih No Agenda Surat Masuk"
                                                        options={this.state.letterTypeOption}
                                                        validate={{ required: { value: this.state.setReferenceLetter } }}
                                                        placeholder="Pilih No Agenda Surat Masuk"
                                                    /> */}
                                                </div>
                                            </Col>
                                            <Col md="2" style={this.state.setReferenceLetter ? {} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >.
                                                </label>
                                                <div>

                                                <button type="button" class="btn btn-info waves-effect waves-light btn" onClick={() => this.handleShowReferenceLetter() }><i class="uil-file-search-alt"></i> Cari No Agenda</button>
                                                </div>

                                                {/* <CustomSelect
                                                        name="letter_out_ref_qrcode"
                                                        errorMessage="Pilih No Agenda Surat Masuk"
                                                        options={this.state.letterTypeOption}
                                                        validate={{ required: { value: this.state.setReferenceLetter } }}
                                                        placeholder="Pilih No Agenda Surat Masuk"
                                                    /> */}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="3" style={this.state.showSuratNonST ? {} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >Sifat Surat <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect
                                                        name="letter_out_priority"
                                                        errorMessage="Pilih Sifat Surat"
                                                        options={this.state.letterPriorityOption}
                                                        validate={{ required: { value: this.state.showSuratNonST }}}
                                                        placeholder="Pilih Sifat Surat"
                                                        {...this.state.letterPriorityOptionAttributes}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="9">
                                                <div className="mb-3">
                                                <label >Tempat <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_out_place"
                                                    placeholder="Isi Tempat"
                                                    type="text"
                                                    errorMessage="Masukkan Tempat"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value="Jakarta"
                                                    id="letter_out_place"
                                                />
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row >
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >{this.state.showSuratNonST ?  'Perihal' :  'Menimbang' }  <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_out_title"
                                                    placeholder={this.state.showSuratNonST ?  'Isi Perihal Surat' :  'Isi Menimbang Surat' }
                                                    type="textarea"
                                                    errorMessage={this.state.showSuratNonST ?  'Masukkan Perihal Surat' :  'Masukkan Menimbang' }
                                                    className="form-control"
                                                    validate={{ required: { value: true }}}
                                                    value={this.state.isiPerihal}
                                                    id="letter_out_title"
                                                />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12">
                                                <fieldset>
                                                    <legend className="fs-6 fw-medium mb-2">
                                                        Penandatangan
                                                        <span className="ms-1 text-danger">*</span>
                                                    </legend>

                                                    <div className="border border-secondary-subtle p-2 rounded">
                                                        {! this.state.showSigner ? (
                                                            <em>Silakan pilih jenis surat terlebih dahulu.</em>
                                                        ) : [...Array(this.state.maxSigner).keys()].map(idx => {
                                                            /**
                                                             * @type {string | null}
                                                             */
                                                            const text = this.state.signers[idx].text ?? null
                                                            /**
                                                             * @type {'DIRECT' | 'DELEGATION_INTERNAL' | 'DELEGATION_EXTERNAL'}
                                                             */
                                                            const signerType = this.state.signers[idx].sign_type

                                                            return (
                                                                <div
                                                                    key={`penandatangan_${idx}`}
                                                                    style={{
                                                                        display: 'grid',
                                                                        gridTemplateAreas: `
                                                                            "line line"
                                                                            "text text"
                                                                            ". direct"
                                                                            ". delegation"
                                                                            ". delegation_input"
                                                                            ". delegation_ext"
                                                                            ". delegation_ext_input"
                                                                        `,
                                                                        gridTemplateColumns: 'auto 1fr',
                                                                        justifyContent: 'start',
                                                                     }}
                                                                >
                                                                    {idx >= 1 && <hr style={{ gridArea: 'line' }} />}
                                                                    {text && (
                                                                        <span
                                                                            className="fw-medium mb-2"
                                                                            style={{ gridArea: 'text' }}
                                                                        >
                                                                            {text}
                                                                        </span>
                                                                    )}

                                                                    {idx === 0 && (
                                                                        <div className="form-check form-check-end" style={{ gridArea: 'direct' }}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`tipe_penandatangan[${idx}]`}
                                                                                value="DIRECT"
                                                                                id={`penandatangan__atasan-langsung__${idx}`}
                                                                                checked={signerType === 'DIRECT'}
                                                                                onChange={this.handleSignerTypeChange}
                                                                                data-index={idx}
                                                                            />

                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`penandatangan__atasan-langsung__${idx}`}
                                                                            >
                                                                                Atasan Langsung
                                                                            </label>

                                                                            <p>
                                                                                Nama &emsp;: {this.state.suggestedSigner?.position_employee_name || ""}<br/>
                                                                                Jabatan : {this.state.suggestedSigner?.position_name || ""}

                                                                                {! this.state.suggestedSigner?.position_employee_name && <>
                                                                                    <br/>
                                                                                    <em className="text-danger">
                                                                                        Jabatan tersebut tidak ada yang mengisi.
                                                                                    </em>
                                                                                </>}
                                                                            </p>

                                                                            {signerType === 'DIRECT' && (
                                                                                <AvInput
                                                                                    type="hidden"
                                                                                    name={`letter_out_sender_position_uuid_direct[${idx}]`}
                                                                                    value={this.state.suggestedSigner?.position_uuid}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                    <div className="form-check form-check-end" style={{ gridArea: 'delegation' }}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name={`tipe_penandatangan[${idx}]`}
                                                                            value="DELEGATION_INTERNAL"
                                                                            id={`penandatangan__delegasi__${idx}`}
                                                                            checked={signerType === 'DELEGATION_INTERNAL'}
                                                                            onChange={this.handleSignerTypeChange}
                                                                            data-index={idx}
                                                                        />

                                                                        <label
                                                                            className="form-check-label mb-2"
                                                                            htmlFor={`penandatangan__delegasi__${idx}`}
                                                                        >
                                                                            Penandatangan Lain (Internal)
                                                                        </label>
                                                                    </div>

                                                                    {signerType === 'DELEGATION_INTERNAL' && (
                                                                        <div style={{ gridArea: 'delegation_input' }}>
                                                                            <CustomSelect
                                                                                name={`letter_out_sender_position_uuid[${idx}]`}
                                                                                errorMessage="Pilih Penandatangan Surat"
                                                                                options={this.state.letterSignerOption}
                                                                                validate={{ required: { value: true } }}
                                                                                placeholder="Pilih Penandatangan Surat"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {idx !== 0 && <>
                                                                        <div className="form-check form-check-end mt-3" style={{ gridArea: 'delegation_ext' }}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`tipe_penandatangan[${idx}]`}
                                                                                value="DELEGATION_EXTERNAL"
                                                                                id={`penandatangan__delegasi-external__${idx}`}
                                                                                checked={signerType === 'DELEGATION_EXTERNAL'}
                                                                                onChange={this.handleSignerTypeChange}
                                                                                data-index={idx}
                                                                            />

                                                                            <label
                                                                                className="form-check-label mb-2"
                                                                                htmlFor={`penandatangan__delegasi-external__${idx}`}
                                                                            >
                                                                                Penandatangan Lain (External)
                                                                            </label>
                                                                        </div>

                                                                        {signerType === 'DELEGATION_EXTERNAL' && (
                                                                            <Row style={{ gridArea: 'delegation_ext_input' }}>
                                                                                <Col xs='4'>
                                                                                    <AvInput
                                                                                        name={`letter_out_sender_name[${idx}]`}
                                                                                        validate={{ required: { value: true } }}
                                                                                        placeholder="Nama Penandatangan Surat"
                                                                                    />
                                                                                </Col>

                                                                                <Col xs='4'>
                                                                                    <AvInput
                                                                                        name={`letter_out_sender_position[${idx}]`}
                                                                                        validate={{ required: { value: true } }}
                                                                                        placeholder="Jabatan Penandatangan Surat"
                                                                                    />
                                                                                </Col>

                                                                                <Col xs='4'>
                                                                                    <AvInput
                                                                                        name={`letter_out_sender_email[${idx}]`}
                                                                                        validate={{ required: { value: true } }}
                                                                                        placeholder="Email Penandatangan Surat"
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        )}
                                                                    </>}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </fieldset>
                                            </Col>
                                        </Row>

                                        {this.state.maxSaksi >= 1 && (
                                            <Row className="mt-3">
                                                <Col md="12">
                                                    <fieldset>
                                                        <legend className="fs-6 fw-medium mb-2">
                                                            Saksi
                                                            <span className="ms-1 text-danger">*</span>
                                                        </legend>

                                                        <div className="border border-secondary-subtle p-2 rounded">
                                                            {[...Array(this.state.maxSaksi).keys()].map(realIdx => {
                                                                /**
                                                                 * @type {number}
                                                                 */
                                                                const idx = this.state.maxSaksi + realIdx
                                                                /**
                                                                 * @type {string | null}
                                                                 */
                                                                const text = this.state.signers[idx].text ?? null
                                                                /**
                                                                 * @type {'DIRECT' | 'DELEGATION_INTERNAL' | 'DELEGATION_EXTERNAL'}
                                                                 */
                                                                const signerType = this.state.signers[idx].sign_type

                                                                return (
                                                                    <div
                                                                        key={`penandatangan-saksi_${idx}`}
                                                                        style={{
                                                                            display: 'grid',
                                                                            gridTemplateAreas: `
                                                                                "line line"
                                                                                "text text"
                                                                                ". delegation"
                                                                                ". delegation_input"
                                                                                ". delegation_ext"
                                                                                ". delegation_ext_input"
                                                                            `,
                                                                            gridTemplateColumns: 'auto 1fr',
                                                                            justifyContent: 'start',
                                                                         }}
                                                                    >
                                                                        {realIdx >= 1 && <hr style={{ gridArea: 'line' }} />}
                                                                        {text && (
                                                                            <span
                                                                                className="fw-medium mb-2"
                                                                                style={{ gridArea: 'text' }}
                                                                            >
                                                                                {text}
                                                                            </span>
                                                                        )}

                                                                        <div className="form-check form-check-end" style={{ gridArea: 'delegation' }}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`tipe_penandatangan_saksi[${idx}]`}
                                                                                value="DELEGATION_INTERNAL"
                                                                                id={`penandatangan-saksi__delegasi__${idx}`}
                                                                                checked={signerType === 'DELEGATION_INTERNAL'}
                                                                                onChange={this.handleSignerTypeChange}
                                                                                data-index={idx}
                                                                            />

                                                                            <label
                                                                                className="form-check-label mb-2"
                                                                                htmlFor={`penandatangan-saksi__delegasi__${idx}`}
                                                                            >
                                                                                Penandatangan Lain (Internal)
                                                                            </label>
                                                                        </div>

                                                                        {signerType === 'DELEGATION_INTERNAL' && (
                                                                            <div style={{ gridArea: 'delegation_input' }}>
                                                                                <CustomSelect
                                                                                    name={`letter_out_sender_position_uuid[${idx}]`}
                                                                                    errorMessage="Pilih Penandatangan Surat"
                                                                                    options={this.state.letterSignerOption}
                                                                                    validate={{ required: { value: true } }}
                                                                                    placeholder="Pilih Penandatangan Surat"
                                                                                />
                                                                            </div>
                                                                        )}

                                                                        <div className="form-check form-check-end mt-3" style={{ gridArea: 'delegation_ext' }}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`tipe_penandatangan_saksi[${idx}]`}
                                                                                value="DELEGATION_EXTERNAL"
                                                                                id={`penandatangan-saksi__delegasi-external__${idx}`}
                                                                                checked={signerType === 'DELEGATION_EXTERNAL'}
                                                                                onChange={this.handleSignerTypeChange}
                                                                                data-index={idx}
                                                                            />

                                                                            <label
                                                                                className="form-check-label mb-2"
                                                                                htmlFor={`penandatangan-saksi__delegasi-external__${idx}`}
                                                                            >
                                                                                Penandatangan Lain (External)
                                                                            </label>
                                                                        </div>

                                                                        {signerType === 'DELEGATION_EXTERNAL' && (
                                                                            <Row style={{ gridArea: 'delegation_ext_input' }}>
                                                                                <Col xs='4'>
                                                                                    <AvInput
                                                                                        name={`letter_out_sender_name[${idx}]`}
                                                                                        validate={{ required: { value: true } }}
                                                                                        placeholder="Nama Penandatangan Surat"
                                                                                    />
                                                                                </Col>

                                                                                <Col xs='4'>
                                                                                    <AvInput
                                                                                        name={`letter_out_sender_position[${idx}]`}
                                                                                        validate={{ required: { value: true } }}
                                                                                        placeholder="Jabatan Penandatangan Surat"
                                                                                    />
                                                                                </Col>

                                                                                <Col xs='4'>
                                                                                    <AvInput
                                                                                        name={`letter_out_sender_email[${idx}]`}
                                                                                        validate={{ required: { value: true } }}
                                                                                        placeholder="Email Penandatangan Surat"
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        )}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                        )}

                                        <hr style={this.state.showSuratNonST ? {} : { display: 'none' }}/>
                                        <Row style={this.state.showSuratNonST ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-0">
                                                <label >Tujuan Internal
                                                </label>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3">

                                                <Select
                                                    name="receiver_select_multi"
                                                    placeholder="Pilih Penerima Surat"
                                                    value={this.state.multiPenerima}
                                                    options={this.state.positionOption}
                                                    onChange={this.handleMultiPenerima}
                                                    isMulti="true"
                                                />
                                                {
                                                    this.state.multiPenerima.length > 0 ?
                                                        this.state.multiPenerima.map((data, index) => {
                                                            return (
                                                                <AvField
                                                                    key={index}
                                                                    name={`receiver_internal[${index}]`}
                                                                    type="hidden"
                                                                    errorMessage="Masukkan Filter"
                                                                    className="form-control"
                                                                    //validate={{ required: { value: true } }}
                                                                    value={data.value}
                                                                />
                                                            )
                                                        })
                                                    :
                                                    <AvField
                                                        name='multiselectvalidation'
                                                        type="hidden"
                                                        errorMessage="Masukkan Filter"
                                                        className="form-control"
                                                        //validate={{ required: { value: true } }}
                                                        value={this.state.multiPenerima.length > 0 ? this.state.multiPenerima.length : ""}
                                                    />
                                                }
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr style={this.state.showSuratNonST ? {} : { display: 'none' }}/>
                                        <Row style={this.state.showSuratNonST ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-0">
                                                <label >Tujuan Eksternal
                                                </label>
                                                </div>
                                            </Col>

                                        </Row>
                                        {
                                            this.state.listReceiverExternal.map((data, index) => {
                                                return (
                                                    <div className="row" style={this.state.showSuratNonST ? {} : { display: 'none' }}>
                                                        <div className="col-md-2">
                                                            <CustomSelect
                                                                name={`receiver_external_type[${index}]`}
                                                                errorMessage="Pilih Kategori Penerima"
                                                                options={this.state.originSenderOption}
                                                                onChange={e => this.handleTipeAsalSurat(e,index)}
                                                                placeholder="Pilih Kategori Penerima"
                                                                defaultValue={data.receiver_external_type}
                                                            />
                                                        </div>
                                                        <div className="col-md-4" style={data.show_receiver_external ? {} : { display: 'none' }}>
                                                            <CustomSelect
                                                                name={`receiver_external[${index}]`}
                                                                errorMessage="Pilih Penerima Surat"
                                                                options={this.state.instansiOption}
                                                                placeholder="Pilih Penerima Surat"
                                                                defaultValue={data.receiver_external}
                                                            />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <AvField
                                                                name={`receiver_external_detail[${index}]`}
                                                                placeholder="Isi Rincian"
                                                                errorMessage="Masukkan Rincian Asal Surat"
                                                                className="form-control"
                                                                value={data.receiver_external_detail}
                                                            />
                                                        </div>
                                                        <div className="col-md-1 text-right">
                                                            {
                                                                index == 0 ?
                                                                <div className="col-md-2 text-right">
                                                                    <Button className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                        color="success"
                                                                        type="button"
                                                                        onClick={this.addReceiver}
                                                                        ><i className="uil-plus-circle"></i>
                                                                    </Button>
                                                                </div> :
                                                                    <Button className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                        color="danger"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.delReceiver(data)
                                                                        }}
                                                                        ><i className="uil-trash-alt"></i>
                                                                    </Button>
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <hr style={this.state.showSuratNonST ? {} : { display: 'none' }}/>
                                        <Row style={this.state.showSuratNonST ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-0">
                                                <label >Tembusan
                                                </label>
                                                </div>
                                            </Col>
                                            <Col md="8">
                                                <div className="mb-3">
                                                <Select
                                                    name="tembusan_select_multi"
                                                    placeholder="Pilih Penerima Tembusan"
                                                    value={this.state.multiTembusan}
                                                    options={this.state.positionOption}
                                                    onChange={this.handleMultiTembusan}
                                                    isMulti="true"
                                                />
                                                {
                                                    this.state.multiTembusan.length > 0 ?
                                                        this.state.multiTembusan.map((data, index) => {
                                                            return (
                                                                <AvField
                                                                    key={index}
                                                                    name={`receiver_tembusan[${index}]`}
                                                                    type="hidden"
                                                                    errorMessage="Masukkan Filter"
                                                                    className="form-control"
                                                                    //validate={{ required: { value: true } }}
                                                                    value={data.value}
                                                                />
                                                            )
                                                        })
                                                    :
                                                    <AvField
                                                        name='multiselectvalidation_copy'
                                                        type="hidden"
                                                        errorMessage="Masukkan Filter"
                                                        className="form-control"
                                                        //validate={{ required: { value: true } }}
                                                        value={this.state.multiTembusan.length > 0 ? this.state.multiTembusan.length : ""}
                                                    />
                                                }
                                                </div>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.listTembusanExternal.map((data, index) => {
                                                return (
                                                    <div className="row" style={this.state.showSuratNonST ? {} : { display: 'none' }}>
                                                        <div className="col-md-8">
                                                            <AvField
                                                                name={`receiver_tembusan_ext[${index}]`}
                                                                placeholder="Input Manual Tembusan"
                                                                errorMessage="Masukkan Rincian Asal Surat"
                                                                className="form-control"
                                                                value={data.receiver_tembusan_ext}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 text-right">
                                                            {
                                                                index == 0 ?
                                                                <div className="col-md-2 text-right">
                                                                    <Button className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                        color="success"
                                                                        type="button"
                                                                        onClick={this.addTembusan}
                                                                        ><i className="uil-plus-circle"></i>
                                                                    </Button>
                                                                </div> :
                                                                    <Button className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                        color="danger"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            this.delTembusan(data)
                                                                        }}
                                                                        ><i className="uil-trash-alt"></i>
                                                                    </Button>
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <hr/>
                                        <Row>
                                        <Col md="2">
                                                <div className="mb-3">
                                                <label >Lampiran
                                                </label>
                                                <AvField
                                                    name="letter_out_number_of_attachment"
                                                    placeholder="Jumlah"
                                                    type="number"
                                                    errorMessage="Masukkan Nomor Surat"
                                                    className="form-control"
                                                    validate={{
                                                        //required: { value: true },
                                                        pattern: {
                                                        value: "^[0-9]+$",
                                                        errorMessage: "Only Digits",
                                                        },
                                                    }}
                                                    id="letter_out_number_of_attachment"
                                                />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                <label >&nbsp;
                                                </label>
                                                <CustomSelect
                                                        name="letter_out_attachment_type"
                                                        errorMessage="Pilih Tipe Berkas"
                                                        options={this.state.attachmentTypeOption}
                                                        placeholder="Tipe Berkas"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >File Lampiran
                                                </label>
                                                <FileInput
                                                    name="document"
                                                    //validate={{ required: { value: true } }}
                                                    isMultiple
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row style={this.state.showSuratTugas ? {} : { display: 'none' }}>
                                            <Col md="12" style={{padding:'10px',background:'#ffa5009e',color:'black'}}>
                                                <div className="mb-0">
                                                <label ><span style={{fontSize:'20px'}}><i className="fas fa-info-circle"></i> DETAIL INFORMASI SURAT TUGAS</span> <hr/>
                                                <span>•	PNS dan Calon PNS yang mendapat penugasan, wajib membuat ST yang ditandatangani oleh minimal Eselon II</span><br/>
                                                <span>•	ST wajib ditandatangani paling lambat hari pertama dinas atau hari pertama masa berlaku ST</span><br/>
                                                <span>•	Surat Tugas terdiri dari 3 :<br/>
                                                &emsp;1)	Surat Tugas Perjalanan Dinas<br/>
                                                &emsp;&emsp;-	Dinas Dalam Kota Kurang dari 8 jam<br/>
                                                &emsp;&emsp;-	Dinas Dalam Kota Lebih dari 8 jam<br/>
                                                &emsp;&emsp;-	Dinas Luar Kota<br/>
                                                &emsp;&emsp;-	Dinas Luar Negeri<br/>
                                                &emsp;&emsp;-	Dinas Luar Negeri<br/></span>
                                                &emsp;2)	Surat Tugas Non Perjalanan Dinas (Selain Perjalanan Dinas. Contoh : WFH)<br/>
                                                &emsp;3)	Surat Tugas Lembur<br/><br/>
                                                <span>•  <b>Surat Tugas Perjalanan Dinas</b> yang telah dibuat dan ditandatangani akan dialirkan ke <b>Sistem Kepegawaian</b> sebagai dasar <b>Presensi</b>, dan <b>Sistem Keuangan</b> sebagai dasar <b>Pembayaran Dinas</b>.</span><br/>
                                                <span>•  <b>Surat Tugas Non Perjalanan Dinas</b> tidak akan diproses Pembayaran Dinas di Sistem Keuangan.</span><br/>
                                                <span>•  <b>Surat Tugas Lembur</b> yang telah dibuat dan ditandatangani akan dialirkan ke <b>Sistem Kepegawaian</b> sebagai dasar <b>Data Lembur</b>, dan <b>Sistem Keuangan</b> sebagai dasar <b>Pembayaran Uang Lembur</b>.</span><br/>
                                                <span>•  Apabila terdapat Pelaksana Tugas Eksternal mohon agar dilengkapi Golongan nya (Koordinasikan dengan unit keuangan)</span><br/><br/>
                                                <b style={{fontSize:'16px'}}>Pastikan data yang diinputkan sudah sesuai.</b>
                                                </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={this.state.showSuratTugas ? {} : { display: 'none' }}>
                                            <Col md="3" style={{marginTop:'20px'}}>
                                                <div className="mb-3">
                                                    <label >Jenis Surat Tugas <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="mb-3">
                                                        <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="radio_jenisST"
                                                                id="exampleRadios1"
                                                                value="1"
                                                                defaultChecked
                                                                onChange={e => this.handleTypeST(e)}
                                                            />
                                                            &nbsp;&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios1"
                                                        >
                                                            Perjalanan Dinas
                                                        </label>
                                                        <br/>
                                                        <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="radio_jenisST"
                                                                id="exampleRadios2"
                                                                value="0"
                                                                onChange={e => this.handleTypeNonST(e)}
                                                            />
                                                            &nbsp;&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios2"
                                                        >
                                                            Non Perjalanan Dinas
                                                        </label>
                                                        <br/>
                                                        <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="radio_jenisST"
                                                                id="exampleRadios3"
                                                                value="2"
                                                                onChange={e => this.handleTypeSTLembur(e)}
                                                            />
                                                            &nbsp;&nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios3"
                                                        >
                                                            ST Lembur
                                                        </label>
                                                        <AvField
                                                            name="official_travel_type"
                                                            placeholder="Tanggal Perjalanan Dinas"
                                                            type="hidden"
                                                            errorMessage="Masukkan Tanggal Perjalanan Dinas"
                                                            className="form-control"
                                                            value={this.state.jenisST}
                                                            //validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="3" style={this.state.showJenisSuratTugas ? {marginTop:'20px'} : { display: 'none' }}>
                                                <div className="mb-3">
                                                    <label >Tujuan Pembuatan ST Perjadin<span className="text-danger">*</span>
                                                    </label>
                                                    <div className="mb-3">
                                                    <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="radio_tujuanST"
                                                            id="exampleRadiosTujuan1"
                                                            value="1"
                                                            defaultChecked
                                                            onChange={e => this.handleTujuanST(1)}
                                                        />
                                                        &nbsp;&nbsp;
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadiosTujuan1"
                                                    >
                                                        Surat Tugas Baru (ST Induk)
                                                    </label>
                                                    <br/>
                                                    <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="radio_tujuanST"
                                                            id="exampleRadiosTujuan2"
                                                            value="2"
                                                            onChange={e => this.handleTujuanST(2)}
                                                        />
                                                        &nbsp;&nbsp;
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadiosTujuan2"
                                                    >
                                                        Revisi Surat Tugas
                                                    </label>
                                                    <br/>
                                                    <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="radio_tujuanST"
                                                            id="exampleRadiosTujuan3"
                                                            value="3"
                                                            onChange={e => this.handleTujuanST(3)}
                                                        />
                                                        &nbsp;&nbsp;
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadiosTujuan3"
                                                    >
                                                        Revisi Tanggal Pelaksana Tugas
                                                    </label>
                                                    <AvField
                                                        name="official_travel_is_revision"
                                                        placeholder="Tujuan Pembuatan"
                                                        type="hidden"
                                                        errorMessage="Masukkan Tujuan Pembuatan"
                                                        className="form-control"
                                                        value={this.state.tujuanST}
                                                        //validate={{ required: { value: true } }}
                                                    />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col
                                              md="3"
                                              style={this.state.showJenisSTNonPerjadin ? { marginTop: '20px' } : { display: 'none' }}
                                            >
                                                <div className="mb-3">
                                                    <label>
                                                        Tujuan Pembuatan ST Non Perjadin
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>

                                                    <div className="mb-3">
                                                        <AvField
                                                            type="hidden"
                                                            name="tujuan_st_non_perjadin"
                                                            value={this.state.jenisSTNonPerjadin}
                                                        />

                                                        {this.stNonPerjadinSubtypes.map(([value, label], index) => <>
                                                            {index >= 1 && <br/>}
                                                            <input
                                                              className="form-check-input me-2"
                                                              type="radio"
                                                              name="radio_tujuan_st_non_perjadin"
                                                              id={`tujuan-st-non-perjadin-${index}`}
                                                              value={value}
                                                              onChange={({ target: { value } }) => {
                                                                  this.setState({ jenisSTNonPerjadin: value })
                                                                  this.handleTujuanST(value)
                                                              }}
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor={`tujuan-st-non-perjadin-${index}`}
                                                            >
                                                                {label}
                                                            </label>
                                                        </>)}
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md="3" style={this.state.showJenisSuratTugasLembur ? {marginTop:'20px'} : { display: 'none' }}>
                                                <div className="mb-3">
                                                    <label >Tujuan Pembuatan ST Lembur<span className="text-danger">*</span>
                                                    </label>
                                                    <div className="mb-3">
                                                    <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="radio_tujuanSTLembur"
                                                            id="exampleRadiosTujuan4"
                                                            value="4"
                                                            defaultChecked
                                                            onChange={e => this.handleTujuanST(4)}
                                                        />
                                                        &nbsp;&nbsp;
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadiosTujuan4"
                                                    >
                                                        ST Lembur Baru (ST Induk)
                                                    </label>
                                                    <br/>
                                                    <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="radio_tujuanSTLembur"
                                                            id="exampleRadiosTujuan5"
                                                            value="5"
                                                            onChange={e => this.handleTujuanST(5)}
                                                        />
                                                        &nbsp;&nbsp;
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadiosTujuan5"
                                                    >
                                                        Revisi ST Lembur
                                                    </label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="3" style={this.state.showRevisiST ? {marginTop:'20px'} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >No. ST Induk yang akan direvisi <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect
                                                    name="official_travel_revision_number"
                                                    errorMessage="Pilih Nomor ST"
                                                    options={this.state.revisiSTOption}
                                                    placeholder="Pilih Nomor ST"
                                                    onChange={e => this.handleSetFormST(e)}
                                                    validate={{ required: { value: this.state.isSTRevisi } }}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={this.state.showSuratTugas ? {} : { display: 'none' }}>
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >Tanggal Mulai Tugas<span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="official_travel_date"
                                                    placeholder="Tanggal Perjalanan Dinas"
                                                    type="date"
                                                    errorMessage="Masukkan Tanggal Perjalanan Dinas"
                                                    className="form-control"
                                                    onChange={e => this.handleMinDateST(e)}
                                                    value={this.state.letter_assignment_start_date}
                                                    min={this.state.minDateST}
                                                    max={this.state.maxDateST}
                                                    validate={{ required: { value: this.state.showSuratTugas } }}
                                                    readOnly={this.state.readonlyDate}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >Tanggal Selesai Tugas<span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="official_travel_end_date"
                                                    placeholder="Tanggal Selesai Perjalanan Dinas"
                                                    type="date"
                                                    errorMessage="Masukkan Tanggal Selesai Perjalanan Dinas"
                                                    className="form-control"
                                                    onChange={e => this.handleMaxDateST(e)}
                                                    value={this.state.letter_assignment_end_date}
                                                    min={(this.state.minDateST < this.state.minDate ? this.state.minDate : this.state.minDateST)}
                                                    max={this.state.maxDateST}
                                                    readOnly={this.state.readonlyDate}
                                                    validate={{ required: { value: this.state.showSuratTugas } }}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="3" style={this.state.jenisST == 2 ? {} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >Jam Mulai {this.state.readonlyDate == true ? "True" : "False"} <span className="text-danger">*</span>
                                                </label>
                                                <TimePicker
                                                    name="official_travel_start_time_x"
                                                    disableClock={true}
                                                    format="HH:mm"
                                                    value={this.state.jamMulai}
                                                    clearIcon=""
                                                    className="form-control"
                                                    errorMessage="Masukkan Jam Mulai"
                                                    onChange={e => this.handleSetJamMulai(e)}
                                                    disabled={this.state.readonlyDate}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="3" style={this.state.jenisST == 2 ? {} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >Jam Selesai <span className="text-danger">*</span>
                                                </label>
                                                <TimePicker
                                                    name="official_travel_end_time_x"
                                                    disableClock={true}
                                                    format="HH:mm"
                                                    value={this.state.jamSelesai}
                                                    clearIcon=""
                                                    className="form-control"
                                                    errorMessage="Masukkan Jam Selesai"
                                                    onChange={e => this.handleSetJamSelesai(e)}
                                                    disabled={this.state.readonlyDate}
                                                />
                                                 <AvField
                                                    name="official_travel_start_time"
                                                    type="hidden"
                                                    className="form-control"
                                                    value={this.state.jamMulai}
                                                />
                                                <AvField
                                                    name="official_travel_end_time"
                                                    type="hidden"
                                                    className="form-control"
                                                    value={this.state.jamSelesai}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={this.state.showJenisSuratTugas == true && this.state.jenisST == 1 ? {} : { display: 'none' }}>
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >Lokasi Penugasan <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect
                                                    name="official_travel_oversea"
                                                    placeholder="Pilih Lokasi Penugasan"
                                                    errorMessage="Masukkan Tempat Dinas"
                                                    options={this.state.overseasOption}
                                                    onChange={this.handleTempatDinas}
                                                    defaultValue={this.state.letter_assignment_oversea}
                                                    validate={{ required: { value: this.state.showJenisSuratTugas == true && this.state.jenisST == 1 ? true : false } }}
                                                    isDisabled={this.state.isSTRevisi}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="3" style={this.state.showLokasi ? {} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >{'.'}
                                                </label>
                                                    <CustomSelect
                                                        name="official_travel_domicile"
                                                        placeholder="Pilih Lokasi Penugasan"
                                                        errorMessage="Masukkan Tempat Dinas"
                                                        options={this.state.domicileOption}
                                                        onChange={this.handleLokasiDinas}
                                                        defaultValue={this.state.letter_assignment_domicile}
                                                        isDisabled={this.state.isSTRevisi}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3" style={this.state.showAllowance ? {} : { display: 'none' }}>
                                                <div className="mb-3">
                                                <label >Keterangan <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect
                                                    name="letter_assignment_allowance"
                                                    errorMessage="Pilih Kondisi"
                                                    options={this.state.allowanceOption}
                                                    placeholder="Pilih Kondisi"
                                                    defaultValue={this.state.letter_assignment_allowance}
                                                    isDisabled={this.state.isSTRevisi}
                                                />
                                                </div>
                                            </Col>
                                        </Row>

                                        {this.state.showProvinsi ? (
                                            <AssignmentOutOfTownCitiesComponent
                                                minStartDate={this.state.letter_assignment_start_date}
                                                maxEndDate={this.state.letter_assignment_end_date}
                                                provinceOptions={this.state.provinceOption}
                                                fetchCity={this.handleGetCity}
                                                cityByProvinceOptions={this.state.cityByProvinceOptions}
                                                values={this.state.citySchedules}
                                                readOnly={this.state.isSTRevisi}
                                            />
                                        ) : null}

                                        {this.state.showNegara ? (
                                            <AssignmentOverseasCountriesComponent
                                                minStartDate={this.state.letter_assignment_start_date}
                                                maxEndDate={this.state.letter_assignment_end_date}
                                                countryOptions={this.state.countryOption}
                                                values={this.state.countrySchedules}
                                                readOnly={this.state.isSTRevisi}
                                            />
                                        ) : null}

                                        <Row style={this.state.showSuratTugas == true && this.state.jenisST == 1 ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Rincian Lokasi <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="official_travel_destination"
                                                    placeholder="Rincian Lokasi"
                                                    type="textarea"
                                                    errorMessage="Masukkan Rincian Lokasi"
                                                    className="form-control"
                                                    value={this.state.letter_assignment_destination}
                                                    validate={{ required: { value: this.state.showJenisSuratTugas == true && this.state.jenisST == 1 ? true : false } }}
                                                    readOnly={this.state.isSTRevisi}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={this.state.showSuratTugas == true && this.state.jenisST == 1 ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Untuk/Tujuan <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="official_travel_description"
                                                    placeholder="Tujuan Tugas"
                                                    type="textarea"
                                                    errorMessage="Masukkan Tujuan Perjalanan Dinas"
                                                    className="form-control"
                                                    value={this.state.letter_assignment_description}
                                                    validate={{ required: { value: this.state.showJenisSuratTugas == true && this.state.jenisST == 1 ? true : false } }}
                                                    readOnly={this.state.isSTRevisi}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={this.state.showSuratTugas ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >{this.state.btnAddParticipant ? "Pelaksana Tugas (Internal)":"Pilih Pelaksan Tugas yang akan di batalkan dari ST induk"} <span className="text-danger">*</span>
                                                </label>
                                                {
                                                    this.state.listParticipant.map((data, index) => {
                                                        return (
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <CustomSelect
                                                                        ref={ref => {
                                                                            this.selectRef = ref;
                                                                        }}
                                                                        name={`participant_nip[${index}]`}
                                                                        errorMessage="Pilih Pelaksana Tugas"
                                                                        options={this.state.participantOption}
                                                                        placeholder="Pilih Pelaksana Tugas"
                                                                        defaultValue={data.participant_nip}
                                                                        isClearable={this.state.btnAddParticipant}
                                                                        //onChange={this.handleCekParicipant()}
                                                                        isDisabled={this.state.btnAddParticipant ? false : true}
                                                                        onChange={e => this.handleCekParticipant(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2 text-right">
                                                                    {
                                                                        index == 0 ?
                                                                        <>
                                                                            {this.state.listParticipant.length > 1 ?
                                                                            <Button className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                                color="danger"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    this.delParticipant(data)
                                                                                }}
                                                                                ><i className="uil-trash-alt"></i>
                                                                            </Button> : null
                                                                            }
                                                                            &nbsp;
                                                                            {this.state.btnAddParticipant && ! this.state.onlyHideButtonAddParticipant ?
                                                                            <Button className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                                color="success"
                                                                                type="button"
                                                                                onClick={this.addParticipant}
                                                                                ><i className="uil-plus-circle"></i>
                                                                            </Button> : null
                                                                            }
                                                                        </> :
                                                                            <Button className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                                color="danger"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    this.delParticipant(data)
                                                                                }}
                                                                                ><i className="uil-trash-alt"></i>
                                                                            </Button>
                                                                    }

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Eksternal
                                                </label>
                                                {
                                                    this.state.listParticipantExt.map((data, index) => {
                                                        return (
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <AvField
                                                                        name={`participant_ext_name[${index}]`}
                                                                        errorMessage="Input Nama Pelakasana Tugas"
                                                                        placeholder="Nama Pelaksana Tugas"
                                                                        value={data.participant_name}
                                                                        onChange={e => this.handleCekParticipantExt(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <AvField
                                                                        name={`participant_ext_position[${index}]`}
                                                                        errorMessage="Input Jabatan Asal"
                                                                        placeholder="Jabatan"
                                                                        value={data.participant_instansi}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <CustomSelect
                                                                        name={`participant_ext_rank[${index}]`}
                                                                        errorMessage="Pilih Golongan (koordinasikan dengan unit keuangan)"
                                                                        options={this.state.rankOption}
                                                                        placeholder="Golongan"
                                                                        defaultValue={data.participant_rank}
                                                                        validate={{ required: { value: (data.participant_name != '' ? true:false) } }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2 text-right">
                                                                    {data.participant_name ? (
                                                                        <>
                                                                            <Button
                                                                                className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                                color="danger"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    this.delParticipantExt(data)

                                                                                    if (this.state.listParticipantExt.length <= 1) {
                                                                                        this.addParticipantExt()
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <i className="uil-trash-alt"></i>
                                                                            </Button>
                                                                            &nbsp;
                                                                        </>
                                                                    ) : null}

                                                                    {index == 0 && this.state.btnAddParticipant ? (
                                                                        <Button
                                                                            className="btn-block waves-effect waves-light mb-1 btn btn-primary"
                                                                            color="success"
                                                                            type="button"
                                                                            onClick={this.addParticipantExt}
                                                                        >
                                                                            <i className="uil-plus-circle"></i>
                                                                        </Button>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row style={this.state.showSuratUndangan ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-0">
                                                <label ><i className="fas fa-info-circle"></i> Detail Informasi Surat Undangan
                                                </label>
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >Tanggal Kegiatan<span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_invitation_date"
                                                    placeholder="Tanggal Kegiatan"
                                                    type="date"
                                                    errorMessage="Masukkan Kegiatan"
                                                    className="form-control"
                                                    value={this.state.letterInvitationDate}
                                                    //validate={{ required: { value: true } }}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                <label >Jam Mulai <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_invitation_hours"
                                                    placeholder="Jam Mulai kegiatan"
                                                    type="time"
                                                    errorMessage="Masukkan Jam Mulai kegiatan"
                                                    className="form-control"
                                                    defaultValue="08:00"
                                                    format="HH:i"
                                                    value={this.state.letterInvitationStartHour}
                                                    //validate={{ required: { value: true } }}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                <label >Jam Selesai
                                                </label>
                                                <AvField
                                                    name="letter_invitation_end_hours"
                                                    placeholder="Jam Selesai Kegiatan"
                                                    type="time"
                                                    errorMessage="Masukkan Jam Selesai Kegiatan"
                                                    className="form-control"
                                                    //defaultValue="17:00"
                                                    format="HH:i"
                                                    value={this.state.letterInvitationEndHour}
                                                    //validate={{ required: { value: true } }}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={this.state.showSuratUndangan ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Tempat <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_invitation_place"
                                                    placeholder="Tempat Pelaksanaan Kegiatan"
                                                    type="textarea"
                                                    errorMessage="Masukkan Tempat Pelaksanaan Kegiatan"
                                                    className="form-control"
                                                    value={this.state.letterInvitationLocation}

                                                    {...(this.state.showSuratUndangan ? {
                                                        validate: {
                                                            required: {value: true},
                                                            maxLength: {value: 250},
                                                        }
                                                    } : null)}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={this.state.showSuratUndangan ? {} : { display: 'none' }}>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Agenda <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_invitation_description"
                                                    placeholder="Uraian Agenda"
                                                    type="textarea"
                                                    errorMessage="Masukkan Uraian Agenda"
                                                    className="form-control"
                                                    value={this.state.letterInvitationAgenda}
                                                    //validate={{ required: { value: true } }}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Link to="/outbox/daftardraft.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="success" type="submit" onClick={() => this.handleSaveDraft(0)}>
                                                    <i className="uil-arrow-up-right"></i> Selanjutnya
                                                </Button>
                                                {' '}
                                                {/* <Button color="primary" type="submit" onClick={() => this.handleSaveDraft(1)}>
                                                    <i className="uil-arrow-up-right"></i> Simpan Kirim
                                                </Button> */}
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="xl"
                    isOpen={this.state.showReferenceLetter}
                    toggle={() => {
                        this.setState({
                            showReferenceLetter: this.state.showReferenceLetter
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Daftar Surat Masuk
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TableActiveComponent changeQRCode={this.handleGetQRCode}/>
                    </div>
                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                            </button>

                        </div>
                </Modal>
                <Modal
                    size="sm"
                    isOpen={this.state.infoSTNondin}
                    toggle={() => {
                        this.setState({
                            infoSTNondin: this.state.infoSTNondin
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="infoModal"
                        >
                            Informasi
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    infoSTNondin: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div dangerouslySetInnerHTML={{ __html: this.state.infoSTdll ? this.state.infoSTdll : "" }} />
                    </div>
                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    infoSTNondin: false
                                })
                            }}
                            className="btn btn-info waves-effect"
                            data-dismiss="modal"
                          >
                            OK
                            </button>

                        </div>
                </Modal>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.infoST + " Apakah anda yakin akan menyimpan data dan melanjutkan proses drafting surat ini?"}

                    loadingTitle="Memproses data..."
                    loadingInfo="Tunggu beberapa saat, sedang menyiapkan editor dokumen"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    //failedInfo="Data gagal disimpan"
                    failedInfo={this.state.failedInfo}

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                 <Redirect to={'/outbox/daftardraft.html?detail&uuid=' + this.state.qrcode}/>
                    // this.state.submitStatus == 1 ?
                    // <Redirect to='/inbox/daftarterkirim.html'/>
                    // :
                    // <Redirect to='/inbox/daftardraft.html'/>
                : null }
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);
