import {
  apiRequest,
  checkSignAccess,
  externalSignData,
  externalSign,
  signNonDsLetter
} from '../../../../../services/adapters/suratkeluar/outboxService'

class DetailRepository {
  fetchDetail = async (query) => {
    return await apiRequest('get', externalSignData, {
      params: query
    }, true)
  }

  fetchSignLetter = async (query) => {
    return await apiRequest('post', externalSign, {
      body: query
    }, true)
  }

  fetchSignNonDsLetter = async (query) => {
    return await apiRequest('post', signNonDsLetter, {
      body: query
    }, true)
  }

  /**
   * Digunakan untuk mengecek akses tanda tangan sesuai QR code dan token.
   *
   * @param {{
   *   qrcode: string,
   *   token: string
   * }} query
   * @return {Promise<{
   *   status: boolean,
   *   message: string,
   *   response: { allowed: boolean },
   *   generated: int,
   *   tokenExpire: int,
   * }>}
   */
  fetchCheckSignAccess = async (query) => {
    return await apiRequest('get', checkSignAccess, { params: query }, true)
  }
}

export default DetailRepository
