import {
    apiRequest,
    daftarTerkirimList,
    letterTypeList
} from "../../../../../services/adapters/suratmasuk/inboxService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", daftarTerkirimList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchLetterTypeList = async (query, cancelToken) => {
        return await apiRequest("get", letterTypeList, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }


   
}

export default DataTableRepository