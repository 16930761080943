import {
    apiRequest,
    disposisiTerkirimList
} from "../../../../../services/adapters/suratmasuk/dispositionService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", disposisiTerkirimList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

   
}

export default DataTableRepository