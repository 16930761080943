import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import Select from 'react-select'
import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'

export function EmployeeUnit ({
    employees,
    setEmployees,
    onEmployeeSelected,
}) {
    let dataTableBloc = new DataTableBloc()

    const [employeeLoading, setEmployeeLoading] = useState(!!employees)

    useEffect(() => {
        const employeeUnitSubscription = dataTableBloc.employeeUnitChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:
                    setEmployeeLoading(true)

                    break
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        const response = result.data.response
                        const rawEmployees = response.employees
                        const employees = rawEmployees.map((i) => ({
                            label: i['position_name_text'],
                            value: `${i['position_type']}|${i['position_uuid']}|${i['position_employee_nip']}`
                        }))

                        setEmployees(employees)
                    }

                    setEmployeeLoading(false)

                    break
                case apiResponse.ERROR:
                    console.error('Failed to fetch employee unit')
                    setEmployeeLoading(false)

                    break
                default:
                    break
            }
        })

        dataTableBloc.fetchEmployeeUnit({})

        return () => {
            employeeUnitSubscription.unsubscribe()
        }
    }, [])

    return (
        <Row className="mb-3">
            <label className="col-md-2 col-form-label">
                Pegawai
            </label>

            <Col md="5">
                <Select
                    classNamePrefix="select2-employee-unit"
                    isClearable={true}
                    isLoading={employeeLoading}
                    options={employees}
                    isMulti
                    onChange={onEmployeeSelected}
                />
            </Col>
        </Row>
    )
}
