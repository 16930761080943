import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'

import moment from "moment"
import 'moment/locale/id'
import PreviewLetterOutPdf from '../../../../../helpers/ui/outbox/PreviewLetterOutPdf'

/*import ReactTimeAgo from 'react-time-ago'*/
var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];

      return newObj;
    });
};
class TableActiveComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyReportSKActive'
    defaultOrder = 'letter_out_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            unitOption: [],
            letterTypeOption: [],
            tooltip: false,
            detailUuid: null,
            updateUuid: null,
            inactiveUuid: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: false,
            showPrevFile: false,
            urlPdf:null,
            modalDoAnnounce: false,
            confirmInfo: "Apakah anda yakin akan membatalkan surat tugas ini?",
            generalInactive: false,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))
        let additionalFilter = this.props.additionalFilter;
        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const mysession = JSON.parse(localStorage.getItem('userdata'));
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            ...additionalFilter
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false,
                    generalInactive: false,
                }

                if (! this.state.generalInactive) this.announceData()
                else this.inactiveGeneralData()

                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    inactiveData = async () => {
        console.log(this.state.inactiveUuid)
        await this.dataTableBloc.fetchInactive({ qrcode: this.state.inactiveUuid })
    }

    inactiveGeneralData = async () => {
        await this.dataTableBloc.fetchGeneralInactive({ qrcode: this.state.inactiveUuid })
    }

    announceData = async () => {
        console.log(this.state.inactiveUuid)
        await this.dataTableBloc.fetchAnnounce({ qrcode: this.state.inactiveUuid })
    }

    isAdministrator = () => {
        return 1 === Number(localStorage.getItem('currentRoleIdreal'))
    }

    componentDidMount() {
        //this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.dataTableBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            unitOption: reformatOptions(
                                response.result,
                                'work_unit_name',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.dataTableBloc.fetchUnitList({})

        this.dataTableBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            letterTypeOption: reformatOptions(
                                response.result,
                                'letter_type_name',
                                'letter_type_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.dataTableBloc.fetchLetterTypeList({})

        this.dataTableBloc.inactiveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        loadingTable: true
                    }, function () {
                        this.loadData()
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.dataTableBloc.generalInactiveChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        loadingTable: true
                    }, function () {
                        this.loadData()
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.dataTableBloc.announceChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        loadingTable: true
                    }, function () {
                        this.loadData()
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "letter_out_qrcode",
            text: "Kode Surat",
            className: "address",
            sortable: true,
            width: '150'
        },
        // {
        //     key: "redaction_name",
        //     text: "Tipe Surat",
        //     className: "postcode",
        //     sortable: true,
        //     width: '150'
        // },
        {
            key: "letter_out_number",
            text: "Nomor Surat",
            className: "status",
            width: 200,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisStatus = record.letter_out_status
                const announced = record.letter_out_announce
                return (
                    <Fragment>
                        {
                            thisStatus == 9 ?
                            <>
                            <del class="" style={{color:'red'}}>{record.letter_out_number}</del>
                            <br/>
                            <small>{record.letter_out_cancel_reason}</small>
                            </>
                            :
                            <span>{record.letter_out_number}
                            </span>
                        }
                    </Fragment>
                );
            }
        },
        {
            key: "letter_out_date_bahasa",
            text: "Tanggal Surat",
            className: "address",
            sortable: true,
        },
        {
            key: "letter_out_title",
            text: "Perihal",
            className: "status",
            sortable: true,
            cell: record => {
                const announced = record.letter_out_announce
                return (
                    <Fragment>
                        {
                            <span>{record.letter_out_title}
                            {announced == 1 ?
                                <><br/><small>(diumumkan di portal e-office)</small></>
                                : null
                            }
                            </span>
                        }
                    </Fragment>
                );
            }
        },
        {
            key: "letter_type_name",
            text: "Jenis Surat",
            className: "address",
            sortable: true,
        },
        {
            key: "priority_name",
            text: "Sifat Surat",
            className: "address",
            sortable: true
        },
        {
            key: "letter_out_sender_name",
            text: "Penanda Tangan",
            className: "address",
            sortable: true
        },
        {
            key: "status",
            text: "Status",
            className: "address",
            sortable: true,
            align: 'center',
            cell: record => {
                const thisStatus = record.letter_out_status
                const announced = record.letter_out_announce
                return (
                    <Fragment>
                        {
                            thisStatus == 9 ?
                            <>
                            <span class="badge bg-danger font-size-14">cancel</span>
                            </>
                            :
                            (thisStatus == 1 ? <span class="badge bg-success font-size-14">release</span> : <span class="badge bg-soft-dark font-size-14">draft</span>)
                        }
                    </Fragment>
                );
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const {
                    letter_out_qrcode: thisId,
                    letter_out_status: thisStatus,
                    letter_out_existing: isExisting,
                    st_can_deleted: canDeleteSt,
                    can_delete: canDelete,
                    do_announce: doAnnounce,
                } = record

                return (
                    <Fragment>
                        {thisStatus == 1 || thisStatus == 9 ?
                        (isExisting == 1 ?
                            <span>
                            <button
                                className="btn btn-outline-secondary"
                                id={"preview-" + thisId}
                                onClick={() => {
                                    window.open(record.letter_out_filepath,'_blank')

                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["preview" + thisId]} target={"preview-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["preview" + thisId] : !this.state["preview" + thisId]
                                    })
                                }}
                            >Preview</Tooltip>
                            </span>
                        :
                        <span>
                        <button
                            className="btn btn-outline-secondary"
                            id={"preview-" + thisId}
                            onClick={() => {
                                this.setState({
                                    urlPdf: record.letter_out_filepath, // sudah
                                    showPrevFile: true
                                })
                                console.log(this.state.detailUuid);
                            }}
                            style={{
                                marginRight: '2px',
                                padding: '2px 5px'
                            }}>
                                <i className="uil-align-left"></i>
                        </button>
                        <Tooltip
                            style={{
                                marginBottom: 2
                            }}
                            placement="top" isOpen={this.state["preview" + thisId]} target={"preview-" + thisId}
                            toggle={() => {
                                this.setState({
                                    ["preview" + thisId] : !this.state["preview" + thisId]
                                })
                            }}
                        >Preview</Tooltip>
                        </span>
                        )
                        : null
                        }
                        {' '}
                        { canDeleteSt == 1 ?
                            <span>
                                <button
                                    className="btn btn-danger"
                                    id={"detail-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            detailUuid: record.letter_out_qrcode
                                        })
                                    }}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                        <i className="uil-times"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["detail" + thisId] : !this.state["detail" + thisId]
                                        })
                                    }}
                                >Cancel ST</Tooltip>
                            </span>
                            : null
                        }
                        {' '}
                        { canDelete && this.isAdministrator() ? <>
                            <span>
                                <button
                                  className="btn btn-danger"
                                  id={"detail-" + thisId}
                                  onClick={() => {
                                      this.setState({
                                          inactiveUuid: thisId,
                                          confirm: true,
                                          generalInactive: true,
                                          confirmInfo: "Apakah Anda yakin akan membatalkan surat keluar ini?",
                                      })
                                  }}
                                  style={{
                                      marginRight: '2px',
                                      padding: '2px 5px'
                                  }}>
                                        <i className="uil-times"></i>
                                </button>
                                <Tooltip
                                  style={{
                                      marginBottom: 2
                                  }}
                                  placement="top"
                                  isOpen={this.state["detail" + thisId]}
                                  target={"detail-" + thisId}
                                  toggle={() => {
                                      this.setState({
                                          ["detail" + thisId] : !this.state["detail" + thisId]
                                      })
                                  }}
                                >
                                    Batal
                                </Tooltip>
                            </span>
                        </> : null }
                        {' '}
                        { doAnnounce == 1 ?
                            <span>
                                <button
                                    className="btn btn-info"
                                    id={"announce-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            inactiveUuid: thisId,
                                            confirm: true,
                                            confirmInfo: "Apakah anda yakin akan mengirimkan pengumuman ini ke Portal E-Office?"
                                        })
                                        console.log(this.state.detailUuid);
                                    }}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                        <i className="uil-telegram-alt"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["announce" + thisId]} target={"announce-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["announce" + thisId] : !this.state["announce" + thisId]
                                        })
                                    }}
                                >Kirim ke Portal E-Office</Tooltip>
                            </span>
                            : null
                        }

                    </Fragment>
                );
            }
        }
    ]

    closeModalHandler = () => {
        this.setState({
            showPrevFile: false
        })
    }

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.confirmInfo}

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.detailUuid !== null ?
                    <Redirect to={"/laporan/suratkeluar.html?detail&qrcode=" + this.state.detailUuid}/>
                : null }
                { this.state.updateUuid !== null ?
                    <Redirect to={"/inbox/daftarsurat.html?update&qrcode=" + this.state.updateUuid}/>
                : null }

                <PreviewLetterOutPdf isOpen={this.state.showPrevFile} path={this.state.urlPdf} type={"modal"} closeModal={this.closeModalHandler}/>

            </>
        )
    }

}

export default TableActiveComponent
