import {
    apiRequest,
    detailsurat,
    updatesurat
} from "../../../../../services/adapters/common/laporanService"

class UpdateRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", detailsurat, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", updatesurat, {
            body: query
        }, true)
    }
}

export default UpdateRepository