import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import moment from 'moment/moment'
import 'moment/locale/id'

export default function LetterTimeline ({
    values,
}) {
    return (
        <Card>
            <CardBody>
                <CardTitle>Timeline Surat</CardTitle>
                <hr />
                <ul className="verti-timeline list-unstyled">
                    {values.map((value, i) => (
                        <li key={i} className="event-list" style={{ paddingBottom: '5px' }}>
                            <div className="event-date text-primar">
                                {moment(value.histories_date).format('DD MMM')}
                            </div>

                            <p className="text-muted" style={{ fontSize: '12px', marginBottom: '5px' }}>
                                {moment(value.histories_date).format('YYYY, HH:mm:ss')}
                            </p>

                            <h6 style={{ whiteSpace: 'pre-line' }}>
                                {value.histories_desc}
                            </h6>

                            <p className="text-muted" style={{ fontSize: '12px' }}>
                                {value.histories_created}
                            </p>
                        </li>
                    ))}
                </ul>
            </CardBody>
        </Card>
    )
}
