/** @format */

import React, { useState, Fragment, Component, useCallback } from 'react'

import { withRouter } from 'react-router'
import { Link, useHistory, useParams, Redirect } from 'react-router-dom'

import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardTitle,
    CardSubtitle,
    Button,
    Modal,
    Spinner,
    Input,
} from 'reactstrap'
import {
  AvForm,
  AvGroup,
  AvInput,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation'
import Select from 'react-select'

import Shimmer from 'react-shimmer-effect'

import moment from 'moment'
import 'moment/locale/id'

import apiResponse from '../../../../services/apiResponse'

import logoBsre from '../../../../assets/images/logo-bsre.png'
import ErrorAccess from '../../../../components/Error/Access'
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData, Preview } from '../../../../helpers/ui/FileInput'
import PreviewLetterOutPdf from '../../../../helpers/ui/outbox/PreviewLetterOutPdf'

import ReviewBloc from './Blocs/ReviewBloc'
import DetailSuratMasuk from './Components/DetailSuratMasuk'
import LetterTimeline from '../../../../components/Outbox/LetterTimeline'

var reformatOptions = function (raw, label, value) {
    return raw.map(function (data) {
        var newObj = {}
        newObj['label'] = data[label]
        newObj['value'] = data[value]

        return newObj
    })
}
var reformatOptionsDelegation = function (raw, label, value, nip) {
    return raw.map(function (data) {
        var newObj = {}
        newObj['label'] = data[label]
        newObj['nip'] = data[nip]
        newObj['value'] = data[value]

        return newObj
    })
}
class DetailView extends Component {
    reviewBloc = new ReviewBloc()

    constructor(props) {
        super(props)
        this.state = {
            showReferenceLetter: false,
            doDrafting: false,
            loading: true,
            loadingInfo: 'Tunggu beberapa saat, sedang menyelaraskan dokumen',
            loadingTitle: 'Memproses Dokumen...',
            failedInfo: 'Gagal Menyimpan Data',
            successInfo: 'Data berhasil disimpan',
            document_url: '',
            confirmInfo: '',
            uuid: '',
            reviewer_nip: '',
            reviewer_position: '',
            random: 0,
            can_sign: false,
            modalSendReview: false,
            modalSendBack: false,
            modalSignLetter: false,
            modalBookingNomor: false,
            response_note: '',
            statusproses: 0,
            passphrase: '',
            chooseReviewDirect: false,
            chooseReviewDelegation: false,
            modalNotifSign: false,
            jmlReceiver: 0,
            jmlCopy: 0,
            delegationOption: [],
            signType: 0,
            signerType: 1,
            unitPenomoran: '',
            thisQrcode: '',
            reviewType: 1,
            logsurat: [],
            iseselon1: false,
            drafter_unit: null,
            eselonsatu_unit: null,
            chooseub: false,
            modalAddAttachment: false,
            deleteparticipant: 0,
            currentRole: localStorage.getItem('currentRoleIdreal'),

            // Pilihan Penerima Revisi
            isDelegateRevision: false,
            letterRevisionReceiverList: [],
            hideWarningOtherRevisionReceiver: true,
            sendRevisionToPositionUuid: null,

            hideWarningEmptyNote: true,
            loaddata: {},
            openprev: false,

            invalidRequest: false,
            currentSigner: {},
        }
        this.handleChangePassphrase = this.handleChangePassphrase.bind(this)
    }

    handleShowReferenceLetter = () => {
        //console.log(option);
        this.setState({
            showReferenceLetter: true,
        })
    }

    validate = () => {
        let isValid = true

        if (!this.state.sendRevisionToPositionUuid) {
            this.hideWarningOtherRevisionReceiver(false)
            isValid = false
        }

        if (!this.state.response_note) {
            this.hideWarningEmptyNote(false)
            isValid = false
        }

        return isValid
    }

    handleShowModalSign = () => {
        this.setState({
            modalSignLetter: true,
        })
    }
    handleChangePassphrase(event) {
        this.setState({ passphrase: event.target.value })
    }

    handleSignNoDS = () => {
        this.setState({
            modalNotifSign: false,
            failedInfo: 'Proses Tandatangan belum berhasil',
            failed: true,
        })
    }

    handleSignLetter = () => {
        this.setState({
            dataForm: {
                passphrase: this.state.passphrase,
                signerType: this.state.signerType,
                eselonsatu: this.state.eselonsatu_unit,
                qrcode: this.state.thisQrcode,
                token: this.state.token,
                without_account: true,
            },
            confirmInfo: (
                <Fragment>
                    <span>Apakah anda yakin akan menandatangani surat ini ?</span>
                </Fragment>
            ),
            loadingInfo: 'Tunggu beberapa saat',
            loadingTitle: 'Mengirim data...',
            confirm: true,
            modalSignLetter: false,
            statusproses: 4,
        })
    }

    handleSignNonDs = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.uuid,
            },
            confirmInfo: 'Apakah anda yakin akan menandatangani surat ini?',
            loadingInfo: 'Tunggu beberapa saat',
            loadingTitle: 'Mengirim data...',
            confirm: true,
            statusproses: 6,
        })
        //console.log(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                qrcode: this.state.thisQrcode,
                ...values,
            },
            confirm: true,
            statusproses: 8,
            modalAddAttachment: false,
        })
    }

    submitData = async () => {
        console.log(this.state.dataForm)
        const formData = generateFormData(this.state.dataForm, { field: 'document', name: 'suratkeluar/attachment' })
        // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']

        if (this.state.statusproses == 4) {
            await this.reviewBloc.fetchSignLetter(formData)
        }
        if (this.state.statusproses == 6) {
            await this.reviewBloc.fetchSignNonDsLetter(formData)
        }
        if (this.state.statusproses == 3) {
            this.setState({
                loading: false,
                failed: true,
            })
            // this.setState({
            //     loading: false
            // }, ()=> {
            //     this.setState({
            //         failed: true
            //     })
            // });
        }
    }

    confirmResponse = (response) => {
        let forState = {}
        switch (response) {
            case 'cancel':
                forState = {
                    confirm: false,
                }
                break
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false,
                }
                this.submitData()
                break
            case 'success':
                forState = {
                    success: false,
                    can_sign: false,
                    sign_success: true,
                }
                break
            case 'failed':
                forState = {
                    failed: false,
                }
                break
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.reviewBloc.signLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    result.data.status
                        ? this.setState({
                            loading: false,
                            success: true,
                            successInfo: result.data.message,
                          })
                        : this.setState({
                              loading: false,
                              modalNotifSign: true,
                              failedInfo: result.data.message,
                          })

                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })
        this.reviewBloc.signNonDsLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    result.data.status
                        ? this.setState({
                              loading: false,
                              success: true,
                          })
                        : this.setState({
                              loading: false,
                              modalNotifSign: true,
                              failedInfo: result.data.message,
                          })

                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    })
                    break
                default:
                    break
            }
        })

        this.reviewBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response

                        this.setState({
                            loaddata: response.lout,
                            letter_process: response.letter_out_process,
                            detail: response.letter_out,
                            receiver: response.receiver,
                            signers: response.signers,
                            participant_ext: response?.participant_ext || [],
                            attachment: response.attachment,
                            refQrcode: response.letter_out.letter_out_ref_qrcode,
                            thisQrcode: response.letter_out.letter_out_qrcode,
                            tembusan: response.tembusan,
                            uuid: response.letter_out_process?.letter_out_process_uuid,
                            can_sign: response.can_sign,
                            jmlReceiver: response?.receiver?.length + response?.participant_ext?.length,
                            jmlCopy: response.tembusan.length,
                            signType: response.letter_type_sign_type,
                            logsurat: response.logs,
                            drafter: response.drafter,
                            deleteparticipant: 0,
                            loading: false,
                            openprev: true,
                        })
                    }
                    break
                default:
                    break
            }
        })

        this.reviewBloc.checkSignAccessChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result?.data?.response?.allowed) {
                        this.setState({ currentSigner: result.data.response.signer })
                        this.reviewBloc.fetchDetail({
                            qrcode: queryParams.get('qrcode'),
                            token: queryParams.get('token'),
                            isweb: 1,
                            reset: 0,
                            without_account: true,
                        })

                        break
                    }
                case apiResponse.ERROR:
                    this.setState({ invalidRequest: true })

                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('token')) {
            this.setState({ token: queryParams.get('token') })
        }

        if (queryParams.get('qrcode') && queryParams.get('token')) {
            this.reviewBloc.fetchCheckSignAccess({
                qrcode: queryParams.get('qrcode'),
                token: queryParams.get('token'),
                without_account: true,
            })
        }
    }

    render() {
        if (this.state.invalidRequest) {
            return (
                <div id="layout-wrapper">
                    <div
                        className="main-content"
                        style={{ margin: 0 }}
                    >
                        <ErrorAccess />
                    </div>
                </div>
            )
        }

        return (
            <div id="layout-wrapper">
                <div
                    className="main-content"
                    style={{ margin: 0 }}
                >
                    <Fragment>
                        <Container fluid>
                            <Row>
                                <Col className="col-12">
                                    <Card className="">
                                        <CardBody>
                                            <CardTitle>Review Surat Keluar</CardTitle>
                                            <CardSubtitle className="mb-3">Informasi & Pratinjau Surat Keluar</CardSubtitle>
                                            <hr />
                                            <Row className="mb-3">
                                                <Col md="5">
                                                    <Row className="mb-3 mt-2">
                                                        <label className="col-md-4">Kode Surat</label>
                                                        <Col md="8">
                                                            {this.state.detail ? (
                                                                <>{this.state.detail.letter_out_qrcode !== '' ? this.state.detail.letter_out_qrcode : '-'}</>
                                                            ) : (
                                                                <Shimmer>
                                                                    <div style={{ width: '80%', height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <label className="col-md-4">Jenis Surat</label>
                                                        <Col md="8">
                                                            {this.state.detail ? (
                                                                <>{this.state.detail.letter_type_name !== '' ? this.state.detail.letter_type_name : '-'}</>
                                                            ) : (
                                                                <Shimmer>
                                                                    <div style={{ width: '80%', height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    {this.state.detail ? (
                                                        this.state.detail.letter_out_ref_qrcode !== '' ? (
                                                            <Row className="mb-3 mt-2">
                                                                <label className="col-md-4">Ref Surat Masuk</label>
                                                                <Col md="8">
                                                                    <button
                                                                        type="button"
                                                                        class="btn-sm btn-rounded waves-effect waves-light btn btn-info"
                                                                        onClick={() => this.handleShowReferenceLetter()}
                                                                    >
                                                                        <i class="uil-eye"></i> {this.state.detail.letter_out_ref_agenda}
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        ) : null
                                                    ) : null}
                                                    <Row className="mb-3">
                                                        <label className="col-md-4">Nomor Surat</label>
                                                        <Col md="8">
                                                            {this.state.detail ? (
                                                                <>
                                                                    {this.state.detail.letter_out_number !== null ? (
                                                                        this.state.detail.letter_out_number
                                                                    ) : (
                                                                        <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <Shimmer>
                                                                    <div style={{ width: 68, height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <label className="col-md-4">Tanggal Surat</label>
                                                        <Col md="8">
                                                            {this.state.detail ? (
                                                                <>{this.state.detail.letter_out_date !== null ? this.state.detail.letter_out_date_text : '-'}</>
                                                            ) : (
                                                                <Shimmer>
                                                                    <div style={{ width: '40%', height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <label className="col-md-4">Sifat Surat</label>
                                                        <Col md="8">
                                                            {this.state.detail ? (
                                                                <>{this.state.detail.priority_name !== '' ? this.state.detail.priority_name : '-'}</>
                                                            ) : (
                                                                <Shimmer>
                                                                    <div style={{ width: '40%', height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <label className="col-md-4">Perihal</label>
                                                        <Col md="8">
                                                            {this.state.detail ? (
                                                                <>{this.state.detail.letter_out_title !== '' ? this.state.detail.letter_out_title : '-'}</>
                                                            ) : (
                                                                <Shimmer>
                                                                    <div style={{ width: '80%', height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <label className="col-md-4">Penandatangan</label>
                                                        <Col md="8">
                                                            {Array.isArray(this.state.signers) ? (
                                                                this.state.signers.length <= 1 ? (
                                                                    this.state.signers.map(({ signer_position_text }) => signer_position_text)
                                                                ) : (
                                                                    <ul>
                                                                        {this.state.signers.map(({ signer_position_text }) => <li>{signer_position_text}</li>)}
                                                                    </ul>
                                                                )
                                                            ) : (
                                                                <Shimmer>
                                                                    <div style={{ width: '80%', height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    {this.state.receiver ? (
                                                        <Row className="mb-3">
                                                            <label className="col-md-4">{this.state.detail.letter_out_type == 6 ? 'Partisipan' : 'Penerima'}</label>
                                                            <Col md="8">
                                                                {this.state.jmlReceiver > 1 ? (
                                                                    <ul>
                                                                        {this.state.receiver.map((value, i) => (
                                                                            <li>
                                                                                {value.employee_name && `${value.employee_name}, `}
                                                                                {value.receiver_name}
                                                                            </li>
                                                                        ))}

                                                                        {this.state.participant_ext.map((value) => (
                                                                            <li>
                                                                                {value.letter_participant_ext_name && `${value.letter_participant_ext_name}, `}
                                                                                {value.letter_participant_ext_position} (Eksternal)
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                ) : (
                                                                    this.state.receiver.map((value, i) => (
                                                                        <span>
                                                                            {value.employee_name && `${value.employee_name}, `}
                                                                            {value.receiver_name}
                                                                        </span>
                                                                    ))
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    ) : null}
                                                    {this.state.tembusan ? (
                                                        <Row className="mb-3">
                                                            <label className="col-md-4">Tembusan</label>
                                                            <Col md="8">
                                                                {this.state.jmlCopy > 1 ? (
                                                                    <ul>
                                                                        {this.state.tembusan.map(function (value, i) {
                                                                            return <li>{value.receiver_name}</li>
                                                                        })}
                                                                    </ul>
                                                                ) : (
                                                                    this.state.tembusan.map(function (value, i) {
                                                                        return <span>{value.receiver_name}</span>
                                                                    })
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    ) : null}
                                                    <Row className="mb-3">
                                                        <label className="col-md-4">File Lampiran</label>
                                                        <Col md="8">
                                                            {this.state.attachment ? (
                                                                <Preview
                                                                    value={this.state.attachment}
                                                                    fileName="attachment_filename"
                                                                    filePath="attachment_filepath"
                                                                    fileMimeType="attachment_mime"
                                                                    fileSource="attachment_source"
                                                                    fileId="attachment_id"
                                                                    col="4"
                                                                />
                                                            ) : (
                                                                <Shimmer>
                                                                    <div style={{ width: '80%', height: 15 }}></div>
                                                                </Shimmer>
                                                            )}
                                                        </Col>
                                                        <label className="col-md-4"></label>
                                                        <label className="col-md-8"></label>
                                                    </Row>
                                                    <hr />
                                                    <Row className="mb-3">
                                                        <Col md="4"></Col>
                                                        <Col md="8">
                                                            {this.state.currentSigner && (
                                                                <p>Tanda tangani sebagai <b>{this.state.currentSigner.signer_employee_name} - {this.state.currentSigner.signer_position_name}</b></p>
                                                            )}

                                                            {this.state.can_sign && (
                                                                this.state.detail.letter_type_sign_type !== 0 ? (
                                                                    <Button
                                                                        color="primary"
                                                                        className="waves-effect"
                                                                        type="button"
                                                                        onClick={() => this.handleShowModalSign()}
                                                                    >
                                                                        <i className="uil-check-circle"></i> Tanda Tangan
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        color="primary"
                                                                        className="waves-effect"
                                                                        type="button"
                                                                        onClick={() => this.handleSignNonDs()}
                                                                    >
                                                                        <i className="uil-check-circle"></i> Tanda Tangan
                                                                    </Button>
                                                                )
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col
                                                    md="7"
                                                    style={{ marginTop: '-16px' }}
                                                >
                                                    {this.state.loaddata ? (
                                                        this.sign_success ? (
                                                            <PreviewLetterOutPdf
                                                                isOpen={this.state.openprev}
                                                                path={this.state.loaddata.letter_out_draftpdf}
                                                                data-sign-success={true}
                                                                key='pdf-viewer-sign-success'
                                                            />
                                                        ) : (
                                                            <PreviewLetterOutPdf
                                                                isOpen={this.state.openprev}
                                                                path={this.state.loaddata.letter_out_draftpdf}
                                                                data-sign-success={false}
                                                                key='pdf-viewer-sign-pending'
                                                            />
                                                        )
                                                    ) : (
                                                        <span>Proses Menampilkan File</span>
                                                    )}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <LetterTimeline values={this.state.logsurat} />
                                </Col>
                            </Row>
                        </Container>

                        <Modal
                            size="lg"
                            isOpen={this.state.showReferenceLetter}
                            toggle={() => {
                                this.setState({
                                    showReferenceLetter: this.state.showReferenceLetter,
                                })
                            }}
                        >
                            <div className="modal-header">
                                <h5
                                    className="modal-title mt-0"
                                    id="myLargeModalLabel"
                                >
                                    Informasi Surat Masuk
                                </h5>
                                <button
                                    onClick={() => {
                                        this.setState({
                                            showReferenceLetter: false,
                                        })
                                    }}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.refQrcode != null ? <DetailSuratMasuk reqQrode={this.state.refQrcode} /> : null}
                                {/* <TableActiveComponent changeQRCode={this.handleGetQRCode}/> */}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => {
                                        this.setState({
                                            showReferenceLetter: false,
                                        })
                                    }}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Tutup
                                </button>
                            </div>
                        </Modal>

                        <Modal
                            size="md"
                            isOpen={this.state.modalSignLetter}
                            toggle={() => {
                                this.setState({
                                    modalSignLetter: this.state.modalSignLetter,
                                })
                            }}
                        >
                            <div className="modal-header">
                                <h5
                                    className="modal-title mt-0"
                                    id="myLargeModalLabelzzz"
                                >
                                    Tanda Tangan Elektronik
                                </h5>
                                <button
                                    onClick={() => {
                                        this.setState({
                                            modalSignLetter: false,
                                        })
                                    }}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Col md="12">
                                    <div className="mb-1">
                                        <label>Surat ini akan ditanda tangani untuk :</label>
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="mb-3">
                                        <div className="form-check form-check-end">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="exampleRadiosSign"
                                                value="1"
                                                id="selfSign"
                                                onChange={() => this.handleChooseSigner(1)}
                                                defaultChecked={true}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="selfSign"
                                            >
                                                Diri Sendiri
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <div
                                className="modal-footer"
                                style={{ display: 'block' }}
                            >
                                {/* <img src={logoBsre} alt="" height="42" /> */}
                                <div className="float-end">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            this.setState({
                                                modalSignLetter: false,
                                            })
                                        }}
                                        className="btn btn-danger waves-effect"
                                        data-dismiss="modal"
                                    >
                                        Batal
                                    </button>{' '}
                                    <button
                                        type="button"
                                        onClick={() => this.handleSignLetter()}
                                        className="btn btn-primary waves-effect"
                                        data-dismiss="modal"
                                    >
                                        Tandatangan
                                    </button>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            size="md"
                            isOpen={this.state.modalNotifSign}
                            toggle={() => {
                                this.setState({
                                    modalNotifSign: this.state.modalNotifSign,
                                })
                            }}
                            centered={true}
                        >
                            <div className="modal-body">
                                <Col md="12">
                                    <div
                                        className="mb-3"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <i className="uil uil-exclamation-octagon display-5 mt-2 mb-3 text-danger"></i>
                                        <h5>GAGAL</h5>
                                        <label>
                                            {this.state.failedInfo}.
                                            {/* <br/>Apakah anda ingin menandatangani Surat ini tanpa <b>TTE</b>? */}
                                            <br />
                                            Klik Kirim untuk mencoba Lagi.
                                        </label>
                                    </div>
                                </Col>
                            </div>
                            <div
                                className="modal-footer"
                                style={{ display: 'block' }}
                            >
                                <div className="float-end">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            this.setState({
                                                modalNotifSign: false,
                                            })
                                        }}
                                        className="btn btn-danger waves-effect"
                                        data-dismiss="modal"
                                    >
                                        Batal
                                    </button>{' '}
                                    <button
                                        type="button"
                                        //onClick={() =>this.handleSignNoDS()}
                                        onClick={() => this.handleSignLetter()}
                                        className="btn btn-primary waves-effect"
                                        data-dismiss="modal"
                                    >
                                        Kirim
                                    </button>
                                </div>
                            </div>
                        </Modal>

                        <ConfirmAlert
                            confirmTitle="Konfirmasi!"
                            confirmInfo={this.state.confirmInfo}
                            showConfirm={this.state.confirm}

                            loadingTitle={this.state.loadingTitle}
                            loadingInfo={this.state.loadingInfo}
                            showLoading={this.state.loading}

                            successTitle="Berhasil!"
                            successInfo={this.state.successInfo}
                            showSuccess={this.state.success}

                            failedTitle="Gagal!"
                            //failedInfo="Data gagal disimpan"
                            failedInfo={this.state.failedInfo}
                            showFailed={this.state.failed}

                            response={this.confirmResponse}
                        />
                    </Fragment>
                </div>
            </div>
        )
    }
}

export default withRouter(DetailView)
