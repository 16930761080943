import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Modal
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { Preview,generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router"
import moment from "moment"
import 'moment/locale/id'
import FeatherIcon from 'feather-icons-react'
import PreviewLetterOutPdf  from '../../../../helpers/ui/outbox/PreviewLetterOutPdf'
import apiResponse from '../../../../services/apiResponse'
import DetailSuratMasuk from '../../DaftarSurat/Review/Components/DetailSuratMasuk'
import DetailBloc from './Blocs/DetailBloc'
import LetterTimeline from '../../../../components/Outbox/LetterTimeline'

class DetailView extends Component {

    detailBloc = new DetailBloc()

    constructor(props) {
        super(props)
        this.state = {
            detail: {},
            dataForm: {},
            submitStatus: 0,
            submitProses: 0,
            textconfirm : "Apakah anda yakin akan menyimpan data ini?",
            logsurat: [],
            receiver: [],
            jmlReceiver : 0,
            jmlCopy : 0,
            showPrevFile: false,
            showReferenceLetter: false,
            loading: true,
            autoReloadTimeout: 20_000,
        }
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, 'document') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        await this.detailBloc.fetchReturned(formData)
    }

    handleSaveDraft = (s) => {
        //console.log(s);
        this.setState({
            submitStatus: s
        })
        if(s == 9){
            this.setState({
                textconfirm : "Apakah anda yakin akan mengembalikan surat ini?"
            })
        }
        if(s == 2){
            this.setState({
                textconfirm : "Apakah anda yakin akan menyelesaiakan surat ini?"
            })
        }
    }

    handlePrevPdf = () => {
        this.setState({
            showPrevFile : true
        })
    }
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.employee_uuid,
                letter_in_status: this.state.submitStatus,
                letter_in_qrcode: this.state.detail.letter_in_qrcode,
                ...values
            },
            confirm: true
        })
    }
    handleShowReferenceLetter = () => {
        //console.log(option);
        this.setState ({
            showReferenceLetter : true
        })
    }

    handleOnReloadRegenerate = () => {
        this.setState({ loading: true, autoReloadTimeout: 120_000 })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
    }

    handleOnAutoReloadRegenerate = () => {
        this.setState({ showPreviewLoading: true, autoReloadTimeout: 120_000 })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.letter_out,
                            receiver: response.receiver,
                            signers: response.signers,
                            participant_ext: response?.participant_ext || [],
                            tembusan: response.tembusan,
                            attachment: response.attachment,
                            logsurat: response.logs,
                            urlPdf: response.letter_out.letter_out_filepath, // unused
                            jmlReceiver : response.receiver.length + response?.participant_ext?.length,
                            jmlCopy : response.tembusan.length,
                            refQrcode : response.letter_out.letter_out_ref_qrcode,
                            IurlDoc : response.document_url + "/:w:/r/_layouts/15/Doc.aspx?sourcedoc={" +  response.letter_out.letter_out_sharepoint_doc_id + "}&action=embedview",
                            loading: false,
                            showPreviewLoading: false,
                        })
                        //console.log(this.state.urlPdf);
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Detail Surat Keluar</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi Detail Surat Keluar
                                    </CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <Col md="5">
                                            <Row className="mb-3 mt-2">
                                                <label className="col-md-4">
                                                    Kode Surat
                                                </label>
                                                <Col md="8">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.letter_out_qrcode !== "" ? this.state.detail.letter_out_qrcode : '-'}</>
                                                    :
                                                        <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Jenis Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_type_name !== "" ? this.state.detail.letter_type_name : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            {
                                                this.state.detail ?
                                                    this.state.detail.letter_out_ref_qrcode !== "" ?
                                                        <Row className="mb-3 mt-2">
                                                            <label className="col-md-4">
                                                                Ref Surat Masuk
                                                            </label>
                                                            <Col md="8">
                                                            <button type="button" class="btn-sm btn-rounded waves-effect waves-light btn btn-info" onClick={() => this.handleShowReferenceLetter()}><i class="uil-eye"></i> {this.state.detail.letter_out_ref_agenda}</button>
                                                            </Col>
                                                        </Row>
                                                    : null
                                                :
                                                null
                                            }
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Nomor Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_number !== null ? this.state.detail.letter_out_number : <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>}</>
                                                        : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tanggal Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_date !== null ?  this.state.detail.letter_out_date_text : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Sifat Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.priority_name !== "" ? this.state.detail.priority_name : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Perihal
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_title !== "" ? this.state.detail.letter_out_title : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Penandatangan
                                                </label>
                                                <Col md="8">
                                                    {Array.isArray(this.state.signers) ? (
                                                        this.state.signers.length <= 1 ? (
                                                            this.state.signers.map(({ signer_position_text }) => signer_position_text)
                                                        ) : (
                                                            <ul>
                                                                {this.state.signers.map(({ signer_position_text, signer_token }) => (
                                                                    <li>
                                                                        {signer_position_text}
                                                                        {this.state.detail.letter_out_qrcode && signer_token && (
                                                                            <a
                                                                                className='btn px-2'
                                                                                href={`/outbox/sign.html?qrcode=${this.state.detail.letter_out_qrcode}&token=${signer_token}`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault()

                                                                                    navigator.clipboard.writeText(e.currentTarget.href)
                                                                                }}
                                                                            >
                                                                                <FeatherIcon icon='clipboard' size={16} />
                                                                            </a>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )
                                                    ) : (
                                                        <Shimmer>
                                                            <div style={{ width: '80%', height: 15 }}></div>
                                                        </Shimmer>
                                                    )}
                                                </Col>
                                            </Row>
                                            {this.state.receiver ?
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Penerima
                                                </label>
                                                <Col md="8">
                                                    {this.state.jmlReceiver > 1 ?
                                                        <ul >
                                                        {
                                                            this.state.receiver.map(function (value, i) {
                                                            return  <li >
                                                                        {value.receiver_name}
                                                                    </li>
                                                            })
                                                        }

                                                            {this.state.participant_ext.map(value => (
                                                              <li>
                                                                  {value.letter_participant_ext_name && `${value.letter_participant_ext_name}, `}
                                                                  {value.letter_participant_ext_position}
                                                                  {' '}
                                                                  (Eksternal)
                                                              </li>
                                                            ))}
                                                        </ul>
                                                        :
                                                        this.state.receiver.map(function (value, i) {
                                                        return  <span>{value.receiver_name}</span>
                                                        })

                                                    }

                                                </Col>
                                            </Row>
                                            : null
                                            }
                                            {this.state.tembusan ?
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tembusan
                                                </label>
                                                <Col md="8">
                                                    {this.state.jmlCopy > 1 ?
                                                        <ul >
                                                        {
                                                            this.state.tembusan.map(function (value, i) {
                                                            return  <li >
                                                                        {value.receiver_name}
                                                                    </li>
                                                            })
                                                        } </ul>
                                                        :
                                                        this.state.tembusan.map(function (value, i) {
                                                        return  <span>{value.receiver_name}</span>
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                             : null
                                            }
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Status
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <span class="badge bg-success font-size-14">{this.state.detail.letter_out_status_text}</span>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }

                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    File Lampiran
                                                </label>
                                                <Col md="8">
                                                    { this.state.attachment ?
                                                        <Preview
                                                            value={this.state.attachment}
                                                            fileName='attachment_filename'
                                                            filePath='attachment_filepath'
                                                            fileMimeType='attachment_mime'
                                                            fileSource='attachment_source'
                                                            col='4'
                                                        /> : <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row className="mb-3">
                                                <Col md="4">
                                                    <Link to="/outbox/daftarterkirim.html">
                                                        <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="7" style={{marginTop: '-16px', height:'980px'}}>
                                            {! this.state.detail ?
                                                <Shimmer>
                                                    <div style={{ width: '80%', height: 15 }}></div>
                                                </Shimmer>
                                            : this.state.detail.letter_out_status == 1 ?
                                                <PreviewLetterOutPdf
                                                    path={this.state.detail.letter_out_filepath}
                                                    isNeedRegenerate={this.state.detail.letter_out_filepath_need_regen}
                                                    onReloadRegenerate={this.handleOnReloadRegenerate}
                                                />
                                            :
                                                <PreviewLetterOutPdf
                                                    path={this.state.detail.letter_out_draftpdf}
                                                    isNeedRegenerate={this.state.detail.letter_out_draftpdf_need_regen}
                                                    onReloadRegenerate={this.handleOnReloadRegenerate}
                                                    onAutoReloadRegenerate={this.handleOnAutoReloadRegenerate}
                                                    showLoading={this.state.showPreviewLoading}
                                                    timeout={this.state.autoReloadTimeout}
                                                />
                                            }
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <LetterTimeline values={this.state.logsurat} />
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showReferenceLetter}
                    toggle={() => {
                        this.setState({
                            showReferenceLetter: this.state.showReferenceLetter
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Informasi Surat Masuk
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    { this.state.refQrcode != null ?
                        <DetailSuratMasuk reqQrode={this.state.refQrcode}/>
                        : null
                    }
                        {/* <TableActiveComponent changeQRCode={this.handleGetQRCode}/> */}
                    </div>
                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Tutup
                            </button>

                        </div>
                </Modal>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.textconfirm}

                    loadingTitle="Memuat data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/inbox/daftarsurat.html'/>
                : null }
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
