import React, { useState } from 'react'
import { Col } from 'reactstrap'
import Select from 'react-select'

export default function AssignmentType ({ onTypeChange, onSubtypeChange }) {
    const types = [
        { label: 'Perjalanan Dinas', value: 1 },
        {
            label: 'Non Perjalanan Dinas',
            value: 0,
            subtypes: [
                { label: 'Tugas Belajar', value: 'TUGAS_BELAJAR' },
                { label: 'Pendidikan dan Pelatihan', value: 'PENDIDIKAN_DAN_PELATIHAN' },
                { label: 'WFH', value: 'WFH' },
                { label: 'Lainnya', value: 'LAINNYA' },
            ],
        },
        { label: 'ST Lembur', value: 2 },
    ]

    const [selectedType, setSelectedType] = useState(null)
    const [selectedSubtype, setSelectedSubtype] = useState(null)

    return (
        <>
            <Col md="2">
                <Select
                    value={selectedType}
                    onChange={(option) => {
                        setSelectedType(option)

                        if (onTypeChange) onTypeChange(option)
                    }}
                    options={types}
                    classNamePrefix="select2-st-type"
                    isClearable
                    required
                />
            </Col>

            {selectedType?.subtypes && (
                <Col md="2">
                    <Select
                        value={selectedSubtype}
                        onChange={(option) => {
                            setSelectedSubtype(option)

                            if (onSubtypeChange) onSubtypeChange(option)
                        }}
                        options={selectedType?.subtypes}
                        classNamePrefix="select2-st-subtype"
                        isClearable
                        required
                    />
                </Col>
            )}
        </>
    )
}
