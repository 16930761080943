import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Modal, Spinner, Input
} from "reactstrap"
import Select from "react-select"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { Preview } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router";
import moment from "moment"
import 'moment/locale/id'
import FeatherIcon from 'feather-icons-react'

import apiResponse from '../../../../services/apiResponse'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'

import DetailBloc from './Blocs/DetailBloc'
import ReviewBloc from '../../DaftarSurat/Review/Blocs/ReviewBloc'
import PdfViewer  from '../../../../helpers/ui/PdfViewer'
import DetailSuratMasuk from './Components/DetailSuratMasuk'
import logoBsre from "../../../../assets/images/logo-bsre.png"
import ModalCannotSign from "../../../../components/Outbox/Sign/ModalCannotSign"
import LetterTimeline from '../../../../components/Outbox/LetterTimeline'
import ModalSendReview from '../../../../components/Outbox/ModalSendReview'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];

      return newObj;
    });
};

var reformatOptionsDelegation = function(raw, label, value, nip) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["nip"] = data[nip];
      newObj["value"] = data[value];

      return newObj;
    });
};
class DetailView extends Component {

    reviewBloc = new ReviewBloc()

    constructor(props) {
        super(props)
        this.state = {
            showReferenceLetter: false,
            doDrafting: false,
            loading: true,
            loadingInfo : "Tunggu beberapa saat, sedang menyelaraskan dokumen",
            loadingTitle: "Memproses Dokumen...",
            failedInfo: "Gagal Menyimpan Data",
            successInfo : "Data berhasil disimpan",
            document_url:"",
            confirmInfo:"",
            uuid:"",
            reviewer_nip:"",
            reviewer_position:"",
            random : 0,
            show_sign : false,
            can_sign : false,
            cannot_sign_message : null,
            modalSendReview: false,
            modalSendBack : false,
            modalSignLetter : false,
            modalBookingNomor : false,
            modalAddAttachment : false,
            modalUbahForm : false,
            response_note:"",
            statusproses:0,
            passphrase:"",
            chooseReviewDirect: false,
            chooseReviewDelegation: false,
            modalNotifSign: false,
            jmlReceiver : 0,
            jmlCopy : 0,
            delegationOption: [],
            signType: 0,
            signerType: 1,
            unitPenomoran:"",
            thisQrcode:"",
            reviewType:1,
            logsurat: [],
            iseselon1 : false,
            drafter_unit:null,
            infoST:"",
            isSTPerjadin:false,
            urlredirect2:"",
            current_signer: null,
        }
        this.handleChangeNote = this.handleChangeNote.bind(this);
        this.handleChangePassphrase = this.handleChangePassphrase.bind(this);
    }
    handleOnDodrafting = () => {
        this.reviewBloc.fetchMarkDraftRegenerate({ qrcode: this.state.detail.letter_out_qrcode })

        const myWindowDoc = window.open(this.state.detail.letter_out_filepath,"_blank",);
        const timer = setInterval(() => {
            if(myWindowDoc.closed) {
                clearInterval(timer);

                this.setState({ loading: true }, () => {
                    setTimeout(() => {
                        this.setState({ random: Math.floor((Math.random() * 100) + 1) })
                    }, 1_000)
                })

                this.reviewBloc.fetchPdfDraftRegenerate({ qrcode: this.state.detail.letter_out_qrcode })
            }
        }, 1000);

    };
    handleOnDoupdateform = () => {
        this.setState ({
            modalUbahForm : true
        })

    };
    handleRefreshIFrame = () => {
        //alert("show");
    }

    handleShowReferenceLetter = () => {
        //console.log(option);
        this.setState ({
            showReferenceLetter : true
        })
    }
    hideLoad = () => {
        //alert("close frame");
        this.setState({
          loading: false
        });
    };

    handleChooseReviewDirect = () => {
        this.setState ({
            chooseReviewDirect: true,
            chooseReviewDelegation: false,
            reviewType: 1,
            reviewer_nip: this.state.atasan.position_employee_nip,
            reviewer_position: this.state.atasan.position_uuid,
        })
    }

    handleChooseReviewDelegation = () => {
        this.setState ({
            chooseReviewDirect: false,
            chooseReviewDelegation: true,
            reviewType: 2
        })
    }

    handleSetReviewer = (option) => {
        //console.log(option);
        let selected = option.value
        //alert(selected)
        this.setState ({
            reviewer_nip: option.nip,
            reviewer_position: selected,
        })
    }
    handleSendReview = () => {
        this.setState ({
            modalSendReview : true,
            chooseReviewDirect: true,
            chooseReviewDelegation: false
        })
    }
    handleSendLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                qrcode: this.state.thisQrcode,
                uuid: this.state.uuid,
                reviewer_nip : this.state.reviewer_nip,
                reviewer_position : this.state.reviewer_position,
                reviewer_type : this.state.reviewType,
                drafter_unit : this.state.drafter_unit,
                note: this.state.review_note || null,
            },
            confirmInfo: "Apakah anda yakin akan mengirim surat ini?",
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            statusproses:2,
            modalSendReview : false
        })
        //console.log(this.state.dataForm)
    }
    handleSendBack = () => {
        this.setState ({
            modalSendBack : true
        })
    }
    handleChangeNote(event) {
        this.setState({response_note: event.target.value});
    }

    handleCancelLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.uuid,
                //request_nip : this.state.reviewer_nip,
                //request_position : this.state.reviewer_nip,
                response_note : this.state.response_note
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan membatalkan surat ini ?</span> </Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            modalSendBack:false,
            statusproses:99
        })
        //console.log(this.state.dataForm)
    }
    handleReturnLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.uuid,
                //request_nip : this.state.reviewer_nip,
                //request_position : this.state.reviewer_nip,
                response_note : this.state.response_note
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan mengembalikan surat ini ?</span> </Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            modalSendBack:false,
            statusproses:9
        })
        //console.log(this.state.dataForm)
    }

    handleOnSignClick = () => {
        if (this.state.can_sign) {
            this.handleShowModalSign()
        } else {
            this.handleShowCannotSignMessage()
        }
    }

    handleShowModalSign = () => {
        this.setState ({
            modalSignLetter : true
        })

    }

    handleShowCannotSignMessage = () => {
        this.setState ({ showModalCannotSign : true })
    }

    handleHideCannotSignMessage = () => {
        this.setState ({ showModalCannotSign : false })
    }

    handleChangePassphrase(event) {
        this.setState({passphrase: event.target.value});
    }

    handleSignNoDS  = () => {
        this.setState ({
            modalNotifSign : false,
            failedInfo:"Proses Tandatangan belum berhasil",
            failed: true
        })
    }
    handleChooseSigner = (x) => {

        this.setState({
            signerType:x
        })

    }
    handleSignLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.uuid,
                passphrase : this.state.passphrase,
                signerType: this.state.signerType
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan menandatangani surat ini ?</span> </Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            modalSignLetter:false,
            statusproses:4
        })
        //console.log(this.state.dataForm)
    }

    handleBookingNomor= () => {
        this.setState ({
            modalBookingNomor : true
        })
    }

    handleRequestNomor = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                qrcode: this.state.thisQrcode,
                unit_uuid : this.state.unitPenomoran
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan melakukan penomoran untuk surat ini ?</span> </Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            modalBookingNomor:false,
            statusproses:5
        })
        //console.log(this.state.dataForm)
    }

    handleSetKonseptor = (option) => {
        //console.log(option);
        let selected = option.value
        //alert(selected)
        this.setState ({
            drafter_unit: selected,
        })
    }

    handleTambahAttachemnt= () => {
        this.setState ({
            modalAddAttachment : true
        })
    }
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                qrcode: this.state.thisQrcode,
                ...values
            },
            confirm: true,
            statusproses:8,
            modalAddAttachment:false,
        })
    }
    handleRequestUpdateForm = () =>{
        const queryParams = new URLSearchParams(this.props.location.search)
        window.location.href = '/outbox/daftardraft.html?update&uuid='+queryParams.get('uuid');
    }

    deleteFileHandler = (file_id) => {
        this.setState({
            dataForm: {
                uuid: file_id
            },
            confirmInfo: "Apakah anda yakin akan menghapus lampiran ini?",
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            statusproses:81
        })
    }
    // handleSubmitAttachment = (e, values) => {
    //     //e.preventDefault()
    //     //alert(this.state.submitStatus)
    //     //return;
    //     this.setState({
    //         dataForm: {
    //             qrcode: this.state.thisQrcode,
    //             ...values
    //         },
    //         confirmInfo: <Fragment><span>Apakah anda yakin akan menambahkan lampiran surat ini ?</span> </Fragment>,
    //         loadingInfo: "Tunggu beberapa saat",
    //         loadingTitle: "Mengirim data...",
    //         confirm: true,
    //         modalAddAttachment:false,
    //         statusproses:8
    //     })
    //     //console.log(this.state.dataForm)
    // }

    submitData = async () => {
        //console.log(this.state.dataForm)
        const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratkeluar/attachment'}) // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        //console.log(formData)
        if(this.state.statusproses == 2){
            await this.reviewBloc.fetchSendReview(formData)
        }
        if(this.state.statusproses == 3){
            this.setState({
                loading: false,
                failed: true
            });
        }

        if(this.state.statusproses == 4){
            await this.reviewBloc.fetchSignLetter(formData)
        }
        if(this.state.statusproses == 5){
            await this.reviewBloc.fetchRequestNomor(formData)
        }
        if(this.state.statusproses == 9){
            await this.reviewBloc.fetchReturnReview(formData)
        }
        if(this.state.statusproses == 99){
            await this.reviewBloc.fetchCancelLetter(formData)
        }
        if(this.state.statusproses == 8){
            await this.reviewBloc.fetchAddAttachment(formData)
        }
        if(this.state.statusproses == 81){
            await this.reviewBloc.fetchDeleteAttachment(formData)
        }

    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.reviewBloc.signLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    (result.data.status ?
                        this.setState({
                            loading: false,
                            success: true,
                            successInfo:result.data.message
                        })
                        :
                        this.setState({
                            loading: false,
                            modalNotifSign: true,
                            failedInfo:result.data.message
                        })
                    )

                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.sendLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                        failedInfo: "Gagal Mengirim ke Atasan"
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.returnLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.cancelLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.requestNomorChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        //success: true,
                    });
                    const queryParams = new URLSearchParams(this.props.location.search)
                    this.reviewBloc.fetchDetail({uuid: queryParams.get('uuid') })
                    this.setState({
                        random : Math.floor((Math.random() * 100) + 1),
                        loading : true
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.addAttachmentChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const queryParams = new URLSearchParams(this.props.location.search)
                    var url = '/outbox/daftardraft.html?detail&uuid='+queryParams.get('uuid');
                    this.setState({
                        loading: false,
                        //redirect2: true,
                        //urlredirect2: url
                    });
                    window.location.reload();
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.deleteAttachmentChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const queryParams = new URLSearchParams(this.props.location.search)
                    var url = '/outbox/daftardraft.html?detail&uuid='+queryParams.get('uuid');
                    this.setState({
                        loading: false,
                        //redirect2: true,
                        //urlredirect2: url
                    });
                    window.location.reload();
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.rollbackLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    // this.setState({
                    //     loading: false,
                    //     success: true,
                    // });
                    break
                case apiResponse.ERROR:
                    // this.setState({
                    //     loading: false,
                    //     failed: true,
                    //     failedInfo: "Gagal Mengirim ke Atasan"
                    // });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            letter_process: response.letter_out_process,
                            detail: response.letter_out,
                            signers: response.signers,
                            current_signer: response.current_signer,
                            receiver: response.receiver,
                            participant_ext: response?.participant_ext || [],
                            attachment: response.attachment,
                            refQrcode : response.letter_out.letter_out_ref_qrcode,
                            thisQrcode : response.letter_out.letter_out_qrcode,
                            atasan: response.reviewer,
                            atas_nama: response.atas_nama,
                            tembusan: response.tembusan,
                            delegation : response.delegation,
                            delegationOption: reformatOptionsDelegation(
                                response.delegation,
                                'position_name_text',
                                'position_uuid',
                                'position_employee_nip'
                            ),
                            signOption: reformatOptions(
                                response.signer,
                                'work_unit_name',
                                'position_work_unit_uuid'
                            ),
                            IurlDoc : response.document_url + "/:w:/r/_layouts/15/Doc.aspx?sourcedoc={" +  response.letter_out.letter_out_sharepoint_doc_id + "}&action=embedview",
                            IurlDocEdit : response.document_url + "/:w:/r/_layouts/15/Doc.aspx?sourcedoc={" +  response.letter_out.letter_out_sharepoint_doc_id + "}&action=default",
                            uuid:response.letter_out_process.letter_out_process_uuid,
                            reviewer_nip: (response.reviewer ? response.reviewer.position_employee_nip : ""),
                            reviewer_position: (response.reviewer ? response.reviewer.position_uuid : ""),
                            show_sign : response.show_sign,
                            can_sign : response.can_sign,
                            cannot_sign_message : response.cannot_sign_message,
                            jmlReceiver : response?.receiver?.length + response?.participant_ext?.length,
                            jmlCopy : response.tembusan.length,
                            signType : response.letter_type_sign_type,
                            logsurat : response.logs,
                            iseselon1 : response.iseselon1,
                            konseptorOption: reformatOptions(
                                response.uniteselon2,
                                'work_unit_name',
                                'work_unit_uuid'
                            ),
                            infoST : (response.isperjadin ? "Surat Tugas Perjalanan Dinas akan dialirkan ke Sistem Kepegawaian sebagai dasar Presensi, dan Sistem Keuangan sebagai dasar Pembayaran Dinas. Pastikan data yang di inputkan sudah sesuai. " : ""),
                            isSTPerjadin : response.isperjadin
                            //random:  Math.floor((Math.random() * 100) + 1)
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('uuid')) {
            this.reviewBloc.fetchDetail({ uuid: queryParams.get('uuid'), isweb: 1 })
        }
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Pratinjau Surat Keluar</CardTitle>
                                    <CardSubtitle className="mb-3">
                                    Informasi & Pratinjau Surat Keluar
                                    </CardSubtitle>
                                    <hr />

                                    <Row className="mb-3">
                                        <Col md="5">
                                            <Row className="mb-3 mt-2">
                                                <label className="col-md-4">
                                                    Kode Surat
                                                </label>
                                                <Col md="8">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.letter_out_qrcode !== "" ? this.state.detail.letter_out_qrcode : '-'}</>
                                                    :
                                                        <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Jenis Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_type_name !== "" ? this.state.detail.letter_type_name : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            {
                                                this.state.detail ?
                                                    this.state.detail.letter_out_ref_qrcode !== "" ?
                                                        <Row className="mb-3 mt-2">
                                                            <label className="col-md-4">
                                                                Ref Surat Masuk
                                                            </label>
                                                            <Col md="8">
                                                            <button type="button" class="btn-sm btn-rounded waves-effect waves-light btn btn-info" onClick={() => this.handleShowReferenceLetter()}><i class="uil-eye"></i> {this.state.detail.letter_out_ref_agenda}</button>
                                                            </Col>
                                                        </Row>
                                                    : null
                                                :
                                                null
                                            }
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Nomor Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_number !== null ? this.state.detail.letter_out_number : <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>}</>
                                                        : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tanggal Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_date !== null ?  this.state.detail.letter_out_date_text : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Sifat Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.priority_name !== "" ? this.state.detail.priority_name : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Perihal
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_title !== "" ? this.state.detail.letter_out_title : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Penandatangan
                                                </label>
                                                <Col md="8">
                                                    {Array.isArray(this.state.signers) ? (
                                                        this.state.signers.length <= 1 ? (
                                                            this.state.signers.map(({ signer_position_text }) => signer_position_text)
                                                        ) : (
                                                            <ul>
                                                                {this.state.signers.map(({ signer_position_text, signer_token }) => (
                                                                    <li>
                                                                        {signer_position_text}
                                                                        {this.state.detail.letter_out_qrcode && signer_token && (
                                                                            <a
                                                                                className='btn px-2'
                                                                                href={`/outbox/sign.html?qrcode=${this.state.detail.letter_out_qrcode}&token=${signer_token}`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault()

                                                                                    navigator.clipboard.writeText(e.currentTarget.href)
                                                                                }}
                                                                            >
                                                                                <FeatherIcon icon='clipboard' size={16} />
                                                                            </a>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )
                                                    ) : (
                                                        <Shimmer>
                                                            <div style={{ width: '80%', height: 15 }}></div>
                                                        </Shimmer>
                                                    )}
                                                </Col>
                                            </Row>
                                            {this.state.receiver ?
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                {this.state.detail.letter_out_type == 6 ? "Partisipan" : "Penerima"}
                                                </label>
                                                <Col md="8">
                                                    {this.state.jmlReceiver > 1 ? (
                                                        <ul>
                                                            {this.state.receiver.map((value, i) => (
                                                                <li>
                                                                    {value.employee_name && `${value.employee_name}, `}
                                                                    {value.receiver_name}
                                                                </li>
                                                            ))}

                                                            {this.state.participant_ext.map(value => (
                                                                <li>
                                                                    {value.letter_participant_ext_name && `${value.letter_participant_ext_name}, `}
                                                                    {value.letter_participant_ext_position}
                                                                    {' '}
                                                                    (Eksternal)
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        this.state.receiver.map((value, i) => (
                                                            <span>
                                                                {value.employee_name && `${value.employee_name}, `}
                                                                {value.receiver_name}
                                                            </span>
                                                        ))
                                                    )}
                                                </Col>
                                            </Row>
                                            : null
                                            }
                                            {this.state.tembusan ?
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tembusan
                                                </label>
                                                <Col md="8">
                                                    {this.state.jmlCopy > 1 ?
                                                        <ul >
                                                        {
                                                            this.state.tembusan.map(function (value, i) {
                                                            return  <li >
                                                                        {value.receiver_name}
                                                                    </li>
                                                            })
                                                        } </ul>
                                                        :
                                                        this.state.tembusan.map(function (value, i) {
                                                        return  <span>{value.receiver_name}</span>
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                             : null
                                            }
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    File Lampiran
                                                </label>
                                                <Col md="8">
                                                    { this.state.attachment ?
                                                        <Preview
                                                            value={this.state.attachment}
                                                            fileName='attachment_filename'
                                                            filePath='attachment_filepath'
                                                            fileMimeType='attachment_mime'
                                                            fileSource='attachment_source'
                                                            fileDeleted={true}
                                                            fileId='attachment_id'
                                                            col='4'
                                                            deleteFile={this.deleteFileHandler}
                                                        /> : <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }

                                                </Col>
                                                <label className="col-md-4">

                                                </label>
                                                <label className="col-md-8">
                                                    <Button color="success"  className="waves-effect btn-sm" type="button" onClick={() => this.handleTambahAttachemnt()}>
                                                        <i className="uil-plus"></i> tambah lampiran
                                                    </Button>
                                                </label>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Status
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.letter_process ?
                                                            <span class="badge bg-soft-dark font-size-14">Drafting</span>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }

                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md="4">

                                                </Col>
                                                <Col md="8">
                                                    <Button color="success"  className="waves-effect btn-sm" type="button" onClick={() => this.handleOnDoupdateform()}>
                                                        <i className="uil-pen"></i> Ubah Form
                                                    </Button>
                                                    {' '}
                                                    <Button color="success"  className="waves-effect btn-sm" type="button" onClick={() => this.handleOnDodrafting()}>
                                                        <i className="uil-pen"></i> Ubah Draft
                                                    </Button>
                                                    {' '}
                                                    {
                                                        // this.state.detail ?
                                                        //     this.state.detail.letter_out_number !== null ? ''
                                                        //     :
                                                        //     <Button color="success"  className="waves-effect btn-sm" type="button" onClick={() => this.handleBookingNomor()}>
                                                        //         <i className="uil-sync-exclamation"></i> Ambil Nomor
                                                        //     </Button>
                                                        // : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                                    }

                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row className="mb-3">
                                                <Col md="4">
                                                    <Link to="/outbox/daftardraft.html">
                                                        <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                    </Link>

                                                </Col>
                                                <Col md="8">
                                                    {
                                                        this.state.atasan && ! this.state.show_sign ?
                                                        <Button color="info" className="waves-effect" type="button" onClick={() => this.handleSendReview()}>
                                                            <i className="uil-envelope-send"></i> Kirim ke Atasan
                                                        </Button>
                                                        : null
                                                    }
                                                    {' '}
                                                    {
                                                        this.state.show_sign  ?
                                                    <Button color="primary"  className="waves-effect" type="button" onClick={() => this.handleOnSignClick()}>
                                                        <i className="uil-check-circle"></i> Tanda Tangan
                                                    </Button>
                                                    : null
                                                    }


                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md="4">
                                                    <Button color="warning" className="waves-effect" type="button" onClick={() => this.handleCancelLetter()}>
                                                            <i className="uil-envelope-send"></i> Batalkan Surat
                                                    </Button>

                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="7" style={{marginTop: '-16px'}}>
                                            {this.state.IurlDoc ?
                                                <iframe
                                                    key={this.state.random}
                                                    src={this.state.IurlDoc}
                                                    width={"100%"}
                                                    height={"880"}
                                                    onLoad={this.hideLoad}
                                                    frameBorder="10"
                                                    marginHeight="0"
                                                    marginWidth="0"
                                                >
                                                </iframe>
                                            : null}
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <LetterTimeline values={this.state.logsurat} />
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="lg"
                    isOpen={this.state.showReferenceLetter}
                    toggle={() => {
                        this.setState({
                            showReferenceLetter: this.state.showReferenceLetter
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Informasi Surat Masuk
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    { this.state.refQrcode != null ?
                        <DetailSuratMasuk reqQrode={this.state.refQrcode}/>
                        : null
                    }
                        {/* <TableActiveComponent changeQRCode={this.handleGetQRCode}/> */}
                    </div>
                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Tutup
                            </button>

                        </div>
                </Modal>
                <Modal
                    size="md"
                    isOpen={this.state.modalSendBack}
                    toggle={() => {
                        this.setState({
                            modalSendBack: this.state.modalSendBack
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelXXX"
                        >
                            Kembalikan Surat
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalSendBack: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Col md="12">
                            <div className="mb-3">
                            <label >Catatan <span className="text-danger">*</span>
                            </label>
                            <Input
                             name="response_note"
                             placeholder="Isi Catatan"
                             type="textarea"
                             errorMessage="Masukkan Catatan"
                             className="form-control"
                             validate={{ required: { value: true } }}
                             //value={this.state.response_note}
                             onChange={this.handleChangeNote}
                             id="response_note"
                            />
                            </div>
                        </Col>
                    </div>
                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    modalSendBack: false
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                            </button>
                            <button
                            type="button"
                            onClick={() =>this.handleReturnLetter()}
                            className="btn btn-primary waves-effect"
                            data-dismiss="modal"
                          >
                            Kirim
                            </button>
                        </div>
                </Modal>

                <ModalSendReview
                    show={this.state.modalSendReview}

                    isEchelon1={this.state.iseselon1}
                    drafterUnitOptions={this.state.konseptorOption}
                    onSelectedDrafterUnit={this.handleSetKonseptor}

                    isOfficialTravel={this.state.isSTPerjadin}
                    officialTravelInformation={this.state.infoST}

                    directReviewer={this.state.atasan}
                    onChooseDirectReviewer={this.handleChooseReviewDirect}

                    otherReviewerOptions={this.state.delegationOption}
                    onChooseOtherReviewer={this.handleChooseReviewDelegation}
                    onSelectedOtherReviewer={this.handleSetReviewer}

                    onChangedNote={(event) => {
                        this.setState({ review_note: event.target.value })
                    }}

                    onClose={() => this.setState({ modalSendReview: false })}
                    onSend={this.handleSendLetter}
                />

                <Modal
                    size="md"
                    isOpen={this.state.modalSignLetter}
                    toggle={() => {
                        this.setState({
                            modalSignLetter: this.state.modalSignLetter
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelzzz"
                        >
                            Tanda Tangan Elektronik
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalSignLetter: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Col md="12">
                            <div className="mb-1">
                            <label >Surat ini akan ditanda tangani untuk :
                            </label>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="mb-3">
                                <div className="form-check form-check-end">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadiosSign"
                                        value="1"
                                        id="selfSign"
                                        onChange={() => this.handleChooseSigner(1)}
                                        defaultChecked={true}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="selfSign"
                                    >
                                        Diri Sendiri
                                    </label>
                                </div>
                            </div>
                        </Col>

                        {! this.state.current_signer || this.state.current_signer.signer_order === 1 ? <>
                            <Col md="12">
                                <div className="mb-3">
                                    <div className="form-check form-check-end">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadiosSign"
                                            value="2"
                                            id="behalfSign"
                                            onChange={() => this.handleChooseSigner(2)}
                                        />

                                        <label
                                            className="form-check-label"
                                            htmlFor="behalfSign"
                                        >
                                            Atas Nama {this.state.atas_nama ? this.state.atas_nama.position_name : ""}
                                        </label>

                                        {this.state.iseselon1 ? <>
                                            <Select
                                                name="letter_out_drafter_uuid"
                                                placeholder="Pilih Unit Konseptor Surat"
                                                options={this.state.konseptorOption}
                                                onChange={this.handleSetKonseptor}
                                            />
                                        </> : null}
                                    </div>
                                </div>
                            </Col>
                        </> : null}

                        {/* <Col md="12">
                            <div className="mb-3">
                            <label >Surat ini akan ditandatangani secara elektronik, Silahkan input Passphrase untuk melanjutkan <span className="text-danger">*</span>
                            </label>
                            <Input
                             name="passphrase"
                             placeholder="******"
                             type="password"
                             errorMessage="Masukkan Catatan"
                             className="form-control"
                             validate={{ required: { value: true } }}
                             //value={this.state.response_note}
                             onChange={this.handleChangePassphrase}
                             id="passphrase"
                            />
                            </div>
                        </Col> */}
                    </div>
                    <div className="modal-footer" style={{display:'block'}}>
                        {/* <img src={logoBsre} alt="" height="42" /> */}
                        <div className="float-end">
                            <button
                                type="button"
                                onClick={() => {
                                    this.setState({
                                        modalSignLetter: false
                                    })
                                }}
                                className="btn btn-danger waves-effect"
                                data-dismiss="modal"
                            >
                            Batal
                            </button>
                            {' '}
                            <button
                                type="button"
                                onClick={() =>this.handleSignLetter()}
                                className="btn btn-primary waves-effect"
                                data-dismiss="modal"
                            >
                            Tandatangan
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    size="md"
                    isOpen={this.state.modalNotifSign}
                    toggle={() => {
                        this.setState({
                            modalNotifSign: this.state.modalNotifSign
                        })
                    }}
                    centered={true}
                >

                    <div className="modal-body">
                        <Col md="12">
                            <div className="mb-3" style={{textAlign:"center"}}>
                            <i className="uil uil-exclamation-octagon display-5 mt-2 mb-3 text-danger"></i>
                            <h5>GAGAL</h5>
                            <label >{this.state.failedInfo}.
                            {/* <br/>Apakah anda ingin menandatangani Surat ini tanpa <b>TTE</b>? */}
                            <br/>Klik Kirim untuk mencoba Lagi.
                            </label>
                            </div>
                        </Col>
                    </div>
                    <div className="modal-footer" style={{display:'block'}}>
                        <div className="float-end">
                            <button
                                type="button"
                                onClick={() => {
                                    this.setState({
                                        modalNotifSign: false
                                    })
                                }}
                                className="btn btn-danger waves-effect"
                                data-dismiss="modal"
                            >
                            Batal
                            </button>
                            {' '}
                            <button
                                type="button"
                                //onClick={() =>this.handleSignNoDS()}
                                onClick={() =>this.handleSignLetter()}
                                className="btn btn-primary waves-effect"
                                data-dismiss="modal"
                            >
                            Kirim
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    size="md"
                    isOpen={this.state.modalBookingNomor}
                    toggle={() => {
                        this.setState({
                            modalBookingNomor: this.state.modalBookingNomor
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelzzz"
                        >
                            Booking Nomor
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalBookingNomor: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Col md="12">
                            <div className="mb-1">
                            <label >Anda yakin akan akan mengambil Nomor & Tanggal Surat sekarang ?
                            </label>
                            </div>
                            <div className="mb-1">
                            <label >Silahkan Pilih Unit Penomoran Surat :
                            </label>
                            </div>
                        </Col>

                        <Col md="12">
                            <div className="mb-3">
                                <Select
                                    name="unit_select"
                                    placeholder="Pilih Unit Penomoran"
                                    options={this.state.signOption}
                                    //value={this.state.unitPenomoran}
                                />
                            </div>
                        </Col>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    modalBookingNomor: false
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                        </button>
                        <button
                            type="button"
                            onClick={() =>this.handleRequestNomor()}
                            className="btn btn-primary waves-effect"
                            data-dismiss="modal"
                          >
                            Ambil Nomor
                            </button>
                        </div>
                </Modal>

                <ModalCannotSign
                    isOpen={this.state.showModalCannotSign}
                    onClose={this.handleHideCannotSignMessage}
                    message={this.state.cannot_sign_message}
                />

                <Modal
                    size="lg"
                    isOpen={this.state.modalAddAttachment}
                    toggle={() => {
                        this.setState({
                            modalAddAttachment: this.state.modalAddAttachment
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelzzzXX"
                        >
                            Tambah Lampiran
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalAddAttachment: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <AvForm
                        className="needs-validation"
                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                    >
                    <div className="modal-body">
                            <Col md="12">
                                <div className="mb-3">
                                <FileInput
                                    name="document"
                                    //validate={{ required: { value: true } }}
                                    isMultiple
                                />
                                </div>
                            </Col>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    modalAddAttachment: false
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                        </button>
                        <Button color="success" type="submit">
                            <i className="uil-arrow-up-right"></i> Simpan Lampiran
                        </Button>
                    </div>
                    </AvForm>
                </Modal>
                <Modal
                    size="md"
                    isOpen={this.state.modalUbahForm}
                    toggle={() => {
                        this.setState({
                            modalUbahForm: this.state.modalUbahForm
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelzzz"
                        >
                            Konfirmasi Ubah Form
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalUbahForm: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Col md="12">
                            <div className="mb-1">
                            <label >Proses Ubah Form akan menghapus draft Surat yang sudah di buat. <br/>Apakah Anda yakin akan melanjutkan proses ini ?
                            </label>
                            </div>
                        </Col>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    modalUbahForm: false
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                        </button>
                        <button
                            type="button"
                            onClick={() =>this.handleRequestUpdateForm()}
                            className="btn btn-primary waves-effect"
                            data-dismiss="modal"
                          >
                            Lanjutkan
                            </button>
                        </div>
                </Modal>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.confirmInfo}

                    loadingTitle={this.state.loadingTitle}
                    loadingInfo={this.state.loadingInfo}

                    successTitle="Berhasil!"
                    successInfo={this.state.successInfo}

                    failedTitle="Gagal!"
                    //failedInfo="Data gagal disimpan"
                    failedInfo={this.state.failedInfo}

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                <Redirect to={'/outbox/daftardraft.html'}/>
                //null
                //null
                    // this.state.submitStatus == 1 ?
                    // <Redirect to='/inbox/daftarterkirim.html'/>
                    // :
                    // <Redirect to='/inbox/daftardraft.html'/>
                : null }
                { this.state.redirect2 ?
                <Redirect to={this.state.urlredirect2}/>
                //null
                //null
                    // this.state.submitStatus == 1 ?
                    // <Redirect to='/inbox/daftarterkirim.html'/>
                    // :
                    // <Redirect to='/inbox/daftardraft.html'/>
                : null }
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
