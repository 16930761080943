import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'

import moment from "moment"
import 'moment/locale/id'


/*import ReactTimeAgo from 'react-time-ago'*/

class TableActiveComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyOutboxDraftTableActive'
    defaultOrder = 'letter_out_process_create_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            detailUuid: null,
            updateUuid: null,
            inactiveUuid: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))
        let additionalFilter = this.props.additionalFilter;
        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const mysession = JSON.parse(localStorage.getItem('userdata'));
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            unit_id : mysession.pegawai_satker_uuid,
            ...additionalFilter
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }
    
    inactiveData = async () => {
        await this.dataTableBloc.fetchInactive({ uuid: this.state.inactiveUuid })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        // this.dataTableBloc.inactiveChannel.subscribe((result) => {
        //     switch (result.status) {
        //         case apiResponse.COMPLETED:
        //             this.setState({
        //                 loading: false,
        //                 success: true,
        //                 loadingTable: true
        //             }, function () {
        //                 this.setTokenAPI()
        //                 this.props.handleInactiveSuccess()
        //             });
        //             break
        //         case apiResponse.ERROR:
        //             this.setState({
        //                 loading: false,
        //                 failed: true,
        //             });
        //             break
        //         default:
        //             break
        //     }
        // })
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "letter_out_qrcode",
            text: "Kode Surat",
            className: "address",
            sortable: true,
            width: '150'
        },
        // {
        //     key: "redaction_name",
        //     text: "Tipe Surat",
        //     className: "postcode",
        //     sortable: true,
        //     width: '150'
        // },
        {
            key: "letter_out_number",
            text: "Nomor Surat",
            className: "status",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisStatus = record.letter_out_number
                return (
                    <Fragment>
                        {
                            thisStatus == null ?
                            <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>
                            :
                            <span>{record.letter_out_number}</span>
                        }
                    </Fragment>
                );
            }
        },             
        {
            key: "letter_out_title",
            text: "Perihal",
            className: "address",
            sortable: true,
        },
        {
            key: "priority_name",
            text: "Sifat Surat",
            className: "address",
            sortable: true
        },
        {
            key: "letter_type_name",
            text: "Jenis Surat",
            className: "address",
            sortable: true,
        },
        {
            key: "letter_out_process_create_date",
            text: "Tanggal",
            className: "address",
            sortable: true,
            width: '150',
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.letter_out_process_create_date
                return (<Fragment>
                    <span>{moment(datashow).format("DD MMMM YYYY")}</span>
                    </Fragment>                    
                )
            }
        },
        {
            key: "profil_name",
            text: "Profil",
            className: "address",
            sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        <span>{record.profil_name} <br />{record.profil_position}</span>
                    </Fragment>
                );                
            }
        },  
        {
            key: "letter_out_process_response",
            text: "Catatan",
            className: "address",
            sortable: true,
            cell: ({ letter_out_process_response }) => {
                const response = letter_out_process_response || '-'
                const maxLength = 60

                return response.length > maxLength
                  ? `${response.substring(0, maxLength).trim()}…`
                  : response.trim()
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.letter_out_process_uuid
                return (
                    <Fragment>
                        { accessRole('update') && record.letter_out_process_status == 3 ? 
                            <span>
                                <button
                                    className="btn btn-primary"
                                    id={"update-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            updateUuid: record.letter_out_process_uuid
                                        })
                                    }}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                        <i className="uil-pen"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["update" + thisId]} target={"update-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["update" + thisId] : !this.state["update" + thisId]
                                        })
                                    }}
                                >Lanjutkan</Tooltip>
                            </span>
                            : null
                        }
                        
                    </Fragment>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menghapus data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.detailUuid !== null ?
                    <Redirect to={"/outbox/daftardikembalikan.html?detail&uuid=" + this.state.detailUuid}/>
                : null }
                { this.state.updateUuid !== null ?
                    // <Redirect to={"/outbox/daftardraft.html?update&qrcode=" + this.state.updateUuid}/>
                    <Redirect to={"/outbox/daftardikembalikan.html?detail&uuid=" + this.state.updateUuid}/>
                : null }
            </>
        )
    }

}

export default TableActiveComponent
