import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import ReviewRepository from '../Repositories/ReviewRepository'

class DetailBloc {
  repository = new ReviewRepository()

  detailChannel = new Subject()
  signLetterChannel = new Subject()
  signNonDsLetterChannel = new Subject()
  checkSignAccessChannel = new Subject()

  constructor () {
    this.detailChannel.next({ status: apiResponse.INITIAL })
    this.signLetterChannel.next({ status: apiResponse.INITIAL })
    this.signNonDsLetterChannel.next({ status: apiResponse.INITIAL })
    this.checkSignAccessChannel.next({ status: apiResponse.INITIAL })
  }

  fetchDetail = async (query) => {
    this.detailChannel.next({ status: apiResponse.LOADING })
    try {
      await this.repository.fetchDetail(query).then((result) => {
        this.detailChannel.next({ status: apiResponse.COMPLETED, data: result })
      }).catch((error) => {
        this.detailChannel.next({ status: apiResponse.ERROR, data: error })
      })
    } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
  }

  fetchSignLetter = async (query) => {
    this.signLetterChannel.next({ status: apiResponse.LOADING })
    try {
      await this.repository.fetchSignLetter(query).then((result) => {
        this.signLetterChannel.next({ status: apiResponse.COMPLETED, data: result })
      }).catch((error) => {
        this.signLetterChannel.next({ status: apiResponse.ERROR, data: error })
      })
    } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
  }

  fetchSignNonDsLetter = async (query) => {
    this.signNonDsLetterChannel.next({ status: apiResponse.LOADING })
    try {
      await this.repository.fetchSignNonDsLetter(query).then((result) => {
        this.signNonDsLetterChannel.next({ status: apiResponse.COMPLETED, data: result })
      }).catch((error) => {
        this.signNonDsLetterChannel.next({ status: apiResponse.ERROR, data: error })
      })
    } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
  }

  /**
   * Digunakan untuk mengecek akses tanda tangan sesuai QR code dan token.
   *
   * @param {{
   *   qrcode: string,
   *   token: string
   * }} query
   * @return {Promise<void>}
   */
  fetchCheckSignAccess = async (query) => {
    this.checkSignAccessChannel.next({ status: apiResponse.LOADING })

    try {
      await this.repository
        .fetchCheckSignAccess(query)
        .then((result) => {
          this.checkSignAccessChannel.next({ status: apiResponse.COMPLETED, data: result })
        })
        .catch((error) => {
          this.checkSignAccessChannel.next({ status: apiResponse.ERROR, data: error })
        })
    } catch (error) {
      console.log('rxjs', 'checkSignAccessChannel.unsubscribed')
    }
  }
}

export default DetailBloc
