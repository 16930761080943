import React, { Component, createRef } from 'react'
import { Alert } from 'reactstrap'

import PdfViewer from '../PdfViewer'
import { baseUrl as outboxServiceBaseUrl } from '../../../services/adapters/suratkeluar/outboxService'

export default class PreviewLetterOutPdf extends Component {
    autoReloadTimeoutId = createRef()

    constructor (props) {
        super(props)

        this.state = {
            restProps: null,

            isMinio: false,
            finalPath: null,

            countdownAutoReloadInSec: 0,
        }
    }

    getAutoReloadTimeout() {
        return parseInt(this.props.timeout) || 30_000
    }

    getIsNeedRegenerate() {
        return Boolean(this.props.isNeedRegenerate) || false
    }

    getIsLoading() {
        return this.props.showLoading || false
    }

    getOnReloadHandler() {
        return this.props.onReloadRegenerate || null
    }

    getOnAutoReloadHandler() {
        return this.props.onAutoReloadRegenerate || this.getOnReloadHandler()
    }

    getRestProps () {
        const {
            path,
            isNeedRegenerate,
            onReloadRegenerate,
            ...restProps
        } = this.props

        return restProps
    }

    assignPropertiesToState () {
        this.setState({ restProps: this.getRestProps() })
    }

    updatePathIfNeeded () {
        if (this.props.path) {
            const isMinio = ! this.props.path.startsWith('upload-temp')
            const path = isMinio ? this.props.path : outboxServiceBaseUrl + this.props.path

            this.setState({ isMinio, finalPath: path })
        }
    }

    shouldActivateAutoReload () {
        if (! this.getIsNeedRegenerate()) {
            return false
        }

        if (! this.getOnAutoReloadHandler()) {
            return false
        }

        if (! this.getAutoReloadTimeout()) {
            return false
        }

        return true
    }

    registerAutoReloadIfNeeded () {
        this.clearAutoReload()

        if (! this.shouldActivateAutoReload()) {
            return
        }

        this.registerAutoReload()
    }

    /**
     * Auto-reload setiap 30 detik untuk mengambil PDF terbaru.
     * 30 detik diambil dari rata-rata waktu job generate PDF.
     */
    registerAutoReload () {
        let timeout = this.getAutoReloadTimeout()

        const updateTimeout = () => {
            this.setState({
                countdownAutoReloadInSec: Math.max(Math.trunc(timeout / 1_000), 0),
            })
        }

        const autoReloadTimeoutId = setInterval(() => {
            console.debug({ autoReloadTimeoutId, timeout })

            timeout -= 1_000

            updateTimeout()

            if (timeout <= 0) {
                clearInterval(autoReloadTimeoutId)

                this.getOnAutoReloadHandler()()
            }
        }, Math.min(timeout, 1_000))

        updateTimeout()

        this.autoReloadTimeoutId.current = autoReloadTimeoutId
    }

    clearAutoReload () {
        clearInterval(this.autoReloadTimeoutId.current)
    }

    componentDidMount () {
        this.assignPropertiesToState()
        this.updatePathIfNeeded()
        this.registerAutoReloadIfNeeded()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.assignPropertiesToState()
            this.registerAutoReloadIfNeeded()
        }

        if (this.props.path !== prevProps.path) {
            this.updatePathIfNeeded()
        }
    }

    componentWillUnmount () {
        this.clearAutoReload()
    }

    render () {
        return <>
            {this.getIsNeedRegenerate() ? (
                <Alert className="d-flex align-items-center justify-content-between gap-3">
                    <p className="m-0">
                        <b>
                            Sedang menunggu generate PDF.
                            {' '}
                            {this.state.finalPath ? (
                                <>
                                    Preview PDF mungkin belum yang terbaru.<br/>
                                </>
                            ) : null}

                            Silakan reload
                            {' '}
                            {this.getOnReloadHandler() ? (
                                <a href="#" onClick={this.getOnReloadHandler()} role="button">di sini</a>
                            ) : null}
                            {' '}
                            untuk mendapatkan hasil terbaru.

                            {this.shouldActivateAutoReload() ? (
                                <>
                                    {! this.state.finalPath ? <br/> : ' '}

                                    Otomatis reload dalam
                                    {' '}
                                    <span className="text-danger">
                                        {this.state.countdownAutoReloadInSec} detik
                                    </span>
                                </>
                            ) : null}
                        </b>
                    </p>

                    {this.getIsLoading() ? (
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : null}
                </Alert>
            ) : null}

            {this.state.finalPath ?
                <PdfViewer
                    key={`${this.getIsNeedRegenerate() ? 'true' : 'false'}-${this.state.finalPath}-${this.state.isMinio}`}
                    path={this.state.finalPath}
                    minio={this.state.isMinio}
                    {...this.state.restProps}
                />
                : 'Preview belum tersedia'}
        </>
    }
}
