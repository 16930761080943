import { accessRole } from "../../../helpers/access_role"
import ErrorAccess from '../../../components/Error/Content/Access'

import IndexView from "./Index/View"
import DetailView from "./Detail/View"
import UpdateView from "./Update/View"
import ReviewView from "./Review/View"

const RouteView = (props) => {

  const queryParams = new URLSearchParams(props.location.search);
  const _create = queryParams.get('create')
  const _detail = queryParams.get('detail')
  const _update = queryParams.get('update')
  const _delete = queryParams.get('delete')
  const _approve = queryParams.get('approve')
  const _print = queryParams.get('print')

  const _review = queryParams.get('review')

  if (_review != null) {
    return accessRole('create') ? <ReviewView/> : <ErrorAccess />
  } else if (_create != null) {
    return accessRole('create') ? <UpdateView/> : <ErrorAccess />
  } else if (_detail != null) {
    return accessRole('read') ? <DetailView /> : <ErrorAccess />
  } else if (_update != null) {
    return accessRole('update') ?  <UpdateView />: <ErrorAccess />
  } else if (_delete != null) {
    return null
  } else if (_approve != null) {
    return null
  } else if (_print != null) {
    return null
  }
  return accessRole('read') ? <IndexView /> : <ErrorAccess />
}
export default RouteView