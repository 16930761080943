import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Modal, Tooltip
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput,generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router"
import moment from "moment"
import 'moment/locale/id'
import PdfViewer  from '../../../../helpers/ui/PdfViewer'
import apiResponse from '../../../../services/apiResponse'
import UpdateBloc from './Blocs/UpdateBloc'

class DetailView extends Component {
    
    updateBloc = new UpdateBloc()

    constructor(props) {
        super(props)
        this.state = {
            detail: {},
            dataForm: {},
            submitStatus: 0,
            submitProses: 0,
            textconfirm : "Apakah anda yakin akan membatalkan pegawai ini?",
            logsurat: [],
            receiver: [],
            jmlReceiver : 0,
            jmlCopy : 0,
            showPrevFile: false,
            showReferenceLetter: false,
            loading: true,
            thisQrcode:""
        }
    }

    
    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratkeluar/attachment'}) // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto'] 
        await this.updateBloc.fetchUpdate(formData)       
    }

    

    handlePrevPdf = () => {
        this.setState({
            showPrevFile : true
        })
    }
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                qrcode : this.state.thisQrcode,
                ...values
            },
            confirm: true
        })
    }
    
    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
               
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.letter_out,
                            loading: false,
                            thisQrcode: response.letter_out.letter_out_qrcode
                        })
                        //console.log(this.state.urlPdf);
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                        redirect: true
                    });
                    
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.updateBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Detail Surat Keluar</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi Detail Surat Keluar
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                    <Row className="mb-3">
                                        <Col md="12">
                                            <Row className="mb-3 mt-2">
                                                <label className="col-md-3">
                                                    Kode Surat
                                                </label>
                                                <Col md="8">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.letter_out_qrcode !== "" ? this.state.detail.letter_out_qrcode : '-'}</>
                                                    :
                                                        <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3 mt-2">
                                                <label className="col-md-3">
                                                    Unit Kerja
                                                </label>
                                                <Col md="8">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.work_unit_name !== "" ? this.state.detail.work_unit_name : '-'}</>
                                                    :
                                                        <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3 mt-2">
                                                <label className="col-md-3">
                                                    Jenis Surat
                                                </label>
                                                <Col md="8">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.letter_type_name !== "" ? this.state.detail.letter_type_name : '-'}</>
                                                    :
                                                        <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-3">
                                                    Nomor
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_number !== null ? this.state.detail.letter_out_number : <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>}</>
                                                        : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>     
                                            <Row className="mb-3">
                                                <label className="col-md-3">
                                                    Tanggal
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_date !== null ? moment(this.state.detail.letter_out_date).format("DD MMMM YYYY") : <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>}</>
                                                        : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>     
                                            <Row className="mb-3">
                                                <label className="col-md-3">
                                                    Perihal
                                                </label>
                                                <Col md="4">
                                                <AvField
                                                    name="letter_out_title"
                                                    placeholder="Isi Perihal Surat"
                                                    type="textarea"
                                                    errorMessage="Masukkan Perihal Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    //value={""}
                                                    id="letter_out_title"
                                                />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-3">
                                                    Penandatangan
                                                </label>
                                                <Col md="4">
                                                <AvField
                                                    name="letter_out_sender"
                                                    placeholder="Isi Penandatangan Surat"
                                                    type="text"
                                                    errorMessage="Masukkan Penandatangan Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="letter_out_sender"
                                                />
                                                </Col>
                                            </Row> 
                                            <Row className="mb-3">
                                                <label className="col-md-3">
                                                    File Surat
                                                </label>
                                                <Col md="4">
                                                <FileInput 
                                                        name="document"
                                                        validate={{ required: { value: true } }}
                                                        
                                                    />
                                                </Col>
                                            </Row> 
                                            <hr/>
                                            <Row className="mb-3">  
                                                <label className="col-md-3">
                                                    {' '}
                                                </label>
                                                <Col md="4">
                                                    <Button color="primary" type="submit">
                                                        <i className="uil-save"></i> Simpan
                                                    </Button>
                                                    {' '}
                                                    <Link to="/outbox/penomoran.html">
                                                        <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                    </Row>                                    
                                    </AvForm>
                                </CardBody>
                            </Card>                            
                        </Col>
                    </Row>
                </Container>
                
                
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.textconfirm}

                    loadingTitle="Memuat data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    // <Redirect to={'/laporan/suratkeluar.html?detail&qrcode=' + this.state.thisQrcode}/>
                    <Redirect to={'/outbox/penomoran.html'}/>                  
                : null }
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
