import {
    apiRequest,
    receiverGroupDetail,
} from "../../../../../services/adapters/common/masterService"

class DetailRepository {
    fetchDetail = async (query) => {
        return await apiRequest("get", receiverGroupDetail, {
            params: query
        }, true)
    }
}

export default DetailRepository
