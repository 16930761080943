import React, { Fragment } from "react"
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
} from "reactstrap"

const UpdateView = (props) => {

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Test Update Form</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Keterangan menu-menu. Lorem ipsum dolor sit amet...
                                </CardSubtitle>
                                <hr />
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default UpdateView