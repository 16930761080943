import React from 'react'
import { Button, Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap'

export default function ModalCannotSign ({
    isOpen,
    onClose,
    header = 'Tidak diizinkan menandatangani surat',
    message,
}) {
    return (
        <Modal size="md" isOpen={isOpen} toggle={onClose} centered={true}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <div className="mb-3 text-center">
                            <i className="uil uil-exclamation-octagon display-5 mt-2 mb-3 text-danger"></i>
                            <h5>{header}</h5>
                            <p>{message}</p>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end align-items-center gap-3">
                    <Button
                        color="danger"
                        onClick={onClose}
                        className="btn-danger waves-effect"
                        data-dismiss="modal"
                    >
                        Batal
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}
