import { apiWrapper, apiCancel } from '../../wrapper'

export const initial = 'laporan'
//const baseUrl = 'http://192.168.18.83:8080/'
const baseUrl = process.env.REACT_APP_SERVICE_COMMON_URL
const appCode = process.env.REACT_APP_SERVICE_COMMON_APPCODE
const outputType = 'json'

export const suratmasukList = 'suratmasuk/list/'
export const suratmasukExport = 'suratmasuk/export/'
export const suratkeluarList = 'suratkeluar/list/'
export const suratkeluarExport = 'suratkeluar/export/'
export const disposisiList = 'disposisi/list/'
export const disposisiExport = 'disposisi/export/'
//Referensi
export const unitList = 'referensi/unitList/'
export const letterTypeList = 'referensi/letterTypeList/'
export const getDrafter = 'referensi/unites2/'
export const employeeUnit = 'referensi/employeeUnit/'

export const cancelST = 'suratkeluar/cancelst/'
export const cancelGeneral = 'suratkeluar/cancel/'
export const cancelparticipant = 'suratkeluar/cancelparticipant/'
export const detailst = 'suratkeluar/detailst/'

export const dashboard = 'dashboard/summaries/'

export const penomoranList = 'penomoran/list/'
export const requestNomor = 'penomoran/create/'
export const detailsurat = 'penomoran/detail/'
export const updatesurat = 'penomoran/update/'
export const getLastNomor = 'penomoran/getlast/'

export const sumeries = 'auth/dashboard/'
export const driildown = 'auth/dashboarddrill/'

export const announceLetter = 'suratkeluar/announceLetter/'
// process

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken=true) => {
    if(needToken) {
        const thisTime = (Math.floor(Date.now()/1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if(expire < thisTime) {
           await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/')
           .then(result => {
                localStorage.setItem(initial + 'accessToken', result.response.access_token)
                return apiWrapper(baseUrl, initial, appCode, outputType, method, route, {})
            })
            .catch(result => {
                return Promise.reject('Tidak dapat mengambil accessToken');
            })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}
