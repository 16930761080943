import {
    apiRequest,
    draftDisposisiList
} from "../../../../../services/adapters/suratmasuk/dispositionService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", draftDisposisiList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }   
}

export default DataTableRepository