import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput,
    Table, Tooltip,
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"
import CustomSelect from '../../../../helpers/ui/CustomSelect'

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import CreateBloc from '../Create/Blocs/CreateBloc'
import UpdateBloc from './Blocs/UpdateBloc'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
        return {
            label: data[label],
            value: data[value],
        }
    });
};
var reformatPenerima = (raw, label, uuid, nip, type) => {
    return raw.map((data, index) => ({
        ...data,
        label: data[label],
        value: 1 === Number(data[type]) ? data[uuid] : data[nip],
    }))
}

const mysession = JSON.parse(localStorage.getItem('userdata') || '[]')

class IndexView extends Component {
    createBloc = new CreateBloc();
    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)

        this.state = {
            uuid: null,
            groupName: null,
            filterText: null,
            excludeText: null,
            internalReceiverOption: [],
            selectedInternalReceiver: [],
            dataForm: {},
        }
    }

    filterInternalReceiver = ({ label }, search = undefined) => {
        label = (label?.toString() || '').toLowerCase()
        search = typeof search === 'number' ? '' : (search?.toString() || '').toLowerCase()

        const satisfySearch = () => search.length <= 0 || label.indexOf(search) !== -1

        const satisfyFilter = () => {
            const filterText = (this.state.filterText?.toString() || '').toLowerCase()

            return filterText.length <= 0 || label.indexOf(filterText) !== -1
        }

        const satisfyExclude = () => {
            const excludeText = (this.state.excludeText?.toString() || '').toLowerCase()

            return excludeText.length <= 0 || label.indexOf(excludeText) === -1
        }

        return satisfySearch() && satisfyFilter() && satisfyExclude()
    }

    handleReceiverSelected = (selectedOptions) => {
        this.setState({ selectedInternalReceiver: selectedOptions || [] })
    }

    removeInternalReceiver = (removeValue) => {
        const initReceivers = this.state.selectedInternalReceiver?.slice(0) || []
        const removeIndex = initReceivers.findIndex(({ value }) => value === removeValue)

        if (initReceivers.length <= 0 || -1 === removeIndex) {
            return
        }

        initReceivers.splice(removeIndex, 1)

        this.setState({ selectedInternalReceiver: initReceivers })
    }

    handleSelectAllReceiver = () => {
        const selectedReceiverValues = this.state.selectedInternalReceiver?.map(({ value }) => value) || []
        const filteredReceiverValues = this.state.internalReceiverOption?.filter(this.filterInternalReceiver) || []
        const shownReceiverOptions = filteredReceiverValues.filter(({ value }) => ! selectedReceiverValues.includes(value)) || []

        this.setState({
            selectedInternalReceiver: [
              ...this.state.selectedInternalReceiver || [],
              ...shownReceiverOptions,
            ],
        })
    }

    handleRemoveAllReceiver = () => {
        this.setState({ selectedInternalReceiver: [] })
    }

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, '') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        await this.updateBloc.fetchUpdate(formData)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()

        this.setState({
            dataForm: { uuid: this.state.uuid, ...values },
            confirm: true,
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
            case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search)
        const uuid = queryParams.get('uuid')

        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.internalReceiverChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response

                        this.setState({
                            internalReceiverOption: reformatPenerima(
                              response.result,
                              'position_name_text',
                              'position_uuid',
                              'position_employee_nip',
                              'position_type'
                            ),
                        })
                    }
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchInternalReceiver()

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response

                        this.setState({
                            uuid: response.group.receiver_group_uuid,
                            groupName: response.group.receiver_group_name,
                            selectedInternalReceiver: reformatPenerima(
                              response.receivers,
                              'position_name_text',
                              'receiver_group_member_position_uuid',
                              'receiver_group_member_employee_nip',
                              'position_type'
                            ),
                        })
                    }
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchDetail({ uuid })
    }

    render() {
        return (
          <Fragment>
              <Container fluid>
                  <Row>
                      <Col className="col-12">
                          <Card style={{ marginBlockEnd: '10rem' }}>
                              <CardBody>
                                  <CardTitle>Update Grup Penerima</CardTitle>
                                  <CardSubtitle className="mb-3">
                                      Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                  </CardSubtitle>
                                  <hr />
                                  <AvForm
                                    className="needs-validation"
                                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                  >
                                      <Row className="mb-3">
                                          <label
                                            htmlFor="receiver-group-name"
                                            className="col-md-2 col-form-label"
                                          >
                                              Nama
                                              <span className="text-danger ms-1">*</span>
                                          </label>
                                          <Col md="5">
                                              <AvField
                                                name="name"
                                                placeholder="Nama Grup Penerima"
                                                type="text"
                                                errorMessage="Masukan Nama Grup Penerima"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="receiver-group-name"
                                                value={ this.state.groupName }
                                              />
                                          </Col>
                                      </Row>

                                      <hr/>

                                      <Row className="mb-3">
                                          <label
                                            htmlFor="receiver-group-members"
                                            className="col-md-2 col-form-label"
                                          >
                                              Penerima
                                              <span className="text-danger ms-1">*</span>
                                          </label>

                                          <Col md="5">
                                              <AvField
                                                type="text"
                                                className="form-control mb-3"
                                                name="filter-receiver"
                                                placeholder="Filter Penerima"
                                                onChange={({ target: { value }}) => this.setState({ filterText: value })}
                                              />

                                              <AvField
                                                type="text"
                                                className="form-control mb-3"
                                                name="exclude-receiver"
                                                placeholder="Exclude Penerima"
                                                onChange={({ target: { value }}) => this.setState({ excludeText: value })}
                                              />

                                              <Row style={{ position: 'relative' }}>
                                                  <Col xs={12}>
                                                      <Select
                                                        name="members"
                                                        className="mb-3"
                                                        placeholder="Pilih Penerima"
                                                        validate={{ required: { value: true } }}
                                                        options={this.state.internalReceiverOption}
                                                        filterOption={this.filterInternalReceiver}
                                                        value={this.state.selectedInternalReceiver}
                                                        onChange={this.handleReceiverSelected}
                                                        isMulti={true}
                                                        controlShouldRenderValue={false}
                                                        isClearable={false}
                                                      />
                                                  </Col>
                                                  <Col style={{ position: 'absolute', left: '100%' }}>
                                                      <Button
                                                        color="primary"
                                                        className="me-2"
                                                        onClick={this.handleSelectAllReceiver}
                                                      >
                                                          Select All
                                                      </Button>

                                                      <Button color="danger" onClick={this.handleRemoveAllReceiver}>
                                                          Remove All
                                                      </Button>
                                                  </Col>
                                              </Row>

                                              { this.state.selectedInternalReceiver?.length <= 0 ? <>
                                                  <AvField
                                                    name="members-validation"
                                                    type="hidden"
                                                    errorMessage="Pilih Grup Penerima"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    value=""
                                                  />
                                              </> : null }

                                              <Table bordered striped hover>
                                                  <thead>
                                                  <tr>
                                                      <th style={{ width: '5ch' }} className="text-center">No.</th>
                                                      <th>Pegawai</th>
                                                      <th>Jabatan</th>
                                                      <th style={{ width: '5ch' }} className="text-center">Aksi</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody>
                                                  {this.state.selectedInternalReceiver?.length <= 0 ? <>
                                                      <tr>
                                                          <td colSpan={4} className="text-center">
                                                              <em>Tidak ada penerima</em>
                                                          </td>
                                                      </tr>
                                                  </> : this.state.selectedInternalReceiver?.map(({
                                                      value,
                                                      position_employee_fullname: employeeName,
                                                      position_name_full: positionName,
                                                  }, index) => <>
                                                      <tr>
                                                          <td className="text-center">
                                                              {index + 1}
                                                              <AvField
                                                                key={`members-${index}`}
                                                                name={`members[${index}]`}
                                                                type="hidden"
                                                                value={value}
                                                              />
                                                          </td>
                                                          <td>{employeeName || <center>-</center>}</td>
                                                          <td>{positionName || <center>-</center>}</td>
                                                          <td className="text-center">
                                                              <span>
                                                                  <Button
                                                                    color="danger"
                                                                    id={`detail-${value}`}
                                                                    style={{ padding: '2px 5px' }}
                                                                    onClick={() => this.removeInternalReceiver(value)}
                                                                  >
                                                                      <i className="uil-times"></i>
                                                                  </Button>

                                                                  <Tooltip
                                                                    style={{ marginBlockEnd: 2 }}
                                                                    placement="top"
                                                                    isOpen={this.state[`detail_${value}`]}
                                                                    target={`detail-${value}`}
                                                                    toggle={() => this.setState({ [`detail_${value}`]: !this.state[`detail_${value}`] })}
                                                                  >
                                                                      Hapus
                                                                  </Tooltip>
                                                            </span>
                                                          </td>
                                                      </tr>
                                                  </>)}
                                                  </tbody>
                                              </Table>
                                          </Col>
                                      </Row>
                                      <Row className="mb-3">
                                          <Col md={{ size: 9, offset: 2 }}>
                                              <Link to="/master/gruppenerima.html">
                                                  <Button color="danger" type="button">
                                                      <i className="uil-arrow-left me-1"></i>
                                                      Kembali
                                                  </Button>
                                              </Link>
                                              {' '}
                                              <Button color="success" type="submit">
                                                  <i className="uil-arrow-up-right me-1"></i>
                                                  Simpan Data
                                              </Button>
                                          </Col>
                                      </Row>
                                  </AvForm>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              </Container>
              <ConfirmAlert
                confirmTitle="Konfirmasi!"
                confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                loadingTitle="Mengirim data..."
                loadingInfo="Tunggu beberapa saat"

                successTitle="Berhasil!"
                successInfo="Data berhasil disimpan"

                failedTitle="Gagal!"
                failedInfo="Data gagal disimpan"

                showConfirm={this.state.confirm}
                showLoading={this.state.loading}
                showSuccess={this.state.success}
                showFailed={this.state.failed}

                response={this.confirmResponse}
              />
              { this.state.redirect ?
                <Redirect to='/master/gruppenerima.html'/>
                : null }
          </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(IndexView);
