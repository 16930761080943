import { apiWrapper, apiCancel } from '../wrapper'

export const initial = 'base'
//const baseUrl = 'http://192.168.18.83:8080/'
const baseUrl = process.env.REACT_APP_SERVICE_BASE_URL
const appCode = process.env.REACT_APP_SERVICE_BASE_APPCODE
const outputType = 'json'

export const authConnect = 'auth/connect/'
export const notificationCheck = 'notification/check/'
export const authGetAccessRole = 'auth/getAccessRole/'
export const authGetAccessMenus = 'auth/getAccessMenus/'

export const accessCreate = 'access/create/'
export const menuList = 'menu/list/'

export const sampleCreate = 'sample/sample/create/'
export const sampleList = 'sample/sample/list/'
export const sampleDetail = 'sample/sample/detail/'
export const sampleUpdate = 'sample/sample/update/'
export const sampleInactive = 'sample/sample/inactive/'
export const sampleActive = 'sample/sample/active/'

export const notificationFlagRead = 'notification/read/'
export const notificationFlagReadAll = 'notification/read_all/'
export const notificationList = 'notification/list/'

export const listplt = 'user/getAssignPosition/'

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken=true) => {
    if(needToken) {
        const thisTime = (Math.floor(Date.now()/1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if(expire < thisTime) {
           await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/', data)
           .then(result => {
                localStorage.setItem(initial + 'accessToken', result.response.access_token)
                return apiWrapper(baseUrl, initial, appCode, outputType, method, route, {})
            })
            .catch(result => {
                return Promise.reject('Tidak dapat mengambil accessToken');
            })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)        
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}
