import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Link, Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc.jsx'
import apiResponse from '../../../../../services/apiResponse'

//import ReactTimeAgo from 'react-time-ago'
import moment from "moment"
import 'moment/locale/id'
class DataTableComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyNotificationDataTable'
    defaultOrder = 'notification_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            gotoLink: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))

        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            read: 0
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.dataTableBloc.flagReadChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:

                    break
                case apiResponse.COMPLETED:
                    const response = result.data
                    if(response.status) {
                        this.setState({
                            loadingTable: true,
                            gotoLink: this.state.notificationSelectedLink
                        })       
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.dataTableBloc.listPltChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.LOADING:

                    break
                case apiResponse.COMPLETED:
                    const response = result.data
                    
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        
        this.dataTableBloc.fetchListPlt({})
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
        this.dataTableBloc.flagReadChannel.unsubscribe()
        this.dataTableBloc.listPltChannel.unsubscribe()
    }

    columns = [
        {
            key: "notification_title",
            text: "Judul",
            sortable: true,
            width: '20%',
            cell: record => {
                return <span className={record.notification_read === 0 ? "fw-bold" : "" }>{record.notification_title}</span>
            }
        },
        {
            key: "notification_description",
            text: "Keterangan",
            sortable: true,
            cell: record => {
                return (
                    <a
                        href="javascript:void(0)"
                        onClick={() => {
                            if(record.notification_read === 0) {
                                this.setState({
                                    loadingTable: true,
                                    notificationSelectedLink: record.notification_link
                                }, async () => {
                                    await this.dataTableBloc.fetchFlagRead({'notification_id': record.notification_id})
                                })
                            } else {
                                this.setState({
                                    gotoLink: record.notification_link
                                })
                            }
                        }}
                    >
                        <span className={(record.notification_read === 0) ? "fw-bold" : ""}>{record.notification_description}</span>
                    </a>
                )
            }
        },
        {
            key: "notification_date",
            text: "Tanggal",
            className: "address",
            sortable: true,
            width: '150',
            cell: record => {
                const date = record.notification_date
                return moment(date).format("DD MMM YYYY hh:mm:ss")
            }
        },
        // {
        //     key: "notification_date",
        //     text: "Tanggal",
        //     align: 'center',
        //     sortable: true,
        //     width: '160',
        //     cell: record => {
        //         const thisId = record.sample_id
        //         return (
        //             <center>
        //                 moment(date).format("DD MMMM YYYY")
        //                 <ReactTimeAgo id={"create-" + thisId} date={record.notification_date} locale="id" timeStyle="round-minute" />
        //                 <Tooltip
        //                     style={{
        //                         marginBottom: 2
        //                     }}
        //                     placement="top" isOpen={this.state["create" + thisId]} target={"create-" + thisId}
        //                     toggle={() => {
        //                         this.setState({
        //                             ["create" + thisId] : !this.state["create" + thisId]
        //                         })
        //                     }}
        //                 >{record.notification_date}</Tooltip>
        //             </center>
        //         )
        //     }
        // }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    minWidth={1000}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                { this.state.gotoLink !== null ?
                    // <Redirect to={this.state.gotoLink}/>
                    window.open(this.state.gotoLink,'_self')
                : null }
            </>
        )
    }

}

export default DataTableComponent
