import { accessRole } from "../../../../../helpers/access_role"
import { config } from "../../../../../helpers/config_global"
import React, { useState, Component, Fragment } from "react"
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'

import moment from "moment"
import 'moment/locale/id'
import PreviewLetterOutPdf from '../../../../../helpers/ui/outbox/PreviewLetterOutPdf'

/*import ReactTimeAgo from 'react-time-ago'*/
var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];

      return newObj;
    });
};
class TableActiveComponent extends Component {
    dataTableBloc = new DataTableBloc();

    source
    historyTable = 'historyPenomoranTable'
    defaultOrder = 'letter_out_create_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            unitOption: [],
            letterTypeOption: [],
            tooltip: false,
            detailUuid: null,
            updateUuid: null,
            inactiveUuid: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            showPrevFile: false,
            urlPdf:null,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))
        let additionalFilter = this.props.additionalFilter;
        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const mysession = JSON.parse(localStorage.getItem('userdata'));
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            ...additionalFilter
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    inactiveData = async () => {
        console.log(this.state.inactiveUuid)
        await this.dataTableBloc.fetchInactive({ qrcode: this.state.inactiveUuid })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.dataTableBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            unitOption: reformatOptions(
                                response.result,
                                'work_unit_name',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.dataTableBloc.fetchUnitList({})

        this.dataTableBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)

                        this.setState({
                            letterTypeOption: reformatOptions(
                                response.result,
                                'letter_type_name',
                                'letter_type_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })
        this.dataTableBloc.fetchLetterTypeList({})


    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
    }

    columns = [
        {
            key: "letter_out_qrcode",
            text: "Kode Surat",
            className: "address",
            sortable: true,
            width: '150'
        },
        // {
        //     key: "redaction_name",
        //     text: "Tipe Surat",
        //     className: "postcode",
        //     sortable: true,
        //     width: '150'
        // },
        {
            key: "work_unit_name",
            text: "Unit Kerja",
            className: "address",
            sortable: true
        },
        {
            key: "letter_type_name",
            text: "Jenis Surat",
            className: "address",
            sortable: true,
        },
        {
            key: "letter_out_number",
            text: "Nomor Surat",
            className: "status",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisStatus = record.letter_out_status
                return (
                    <Fragment>
                        {
                            thisStatus == 9 ?
                            <del class="" style={{color:'red'}}>{record.letter_out_number}</del>
                            :
                            <span>{record.letter_out_number}</span>
                        }
                    </Fragment>
                );
            }
        },
        {
            key: "letter_out_date",
            text: "Tanggal Surat",
            className: "address",
            sortable: true,
        },
        {
            key: "letter_out_title",
            text: "Perihal",
            className: "address",
            sortable: true,
        },
        {
            key: "letter_out_sender",
            text: "Penanda Tangan",
            className: "address",
            sortable: true
        },
        {
            key: "status",
            text: "Status",
            className: "address",
            sortable: true,
            align: 'center',
            cell: record => {
                const thisStatus = record.letter_out_status
                return (
                    <Fragment>
                        {
                            thisStatus == 0 ?
                            <span class="badge bg-danger font-size-14">Belum Input</span>
                            :
                            <span class="badge bg-success font-size-14">Sudah Input</span>
                        }
                    </Fragment>
                );
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.letter_out_qrcode
                const thisStatus = record.letter_out_status
                return (
                    <Fragment>
                        {thisStatus == 1 ?
                        <span>
                            <button
                                className="btn btn-outline-secondary"
                                id={"preview-" + thisId}
                                onClick={() => {
                                    this.setState({
                                        urlPdf: record.letter_out_filepath, // sudah
                                        showPrevFile: true
                                    })
                                    console.log(this.state.detailUuid);
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["preview" + thisId]} target={"preview-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["preview" + thisId] : !this.state["preview" + thisId]
                                    })
                                }}
                            >Preview</Tooltip>
                        </span>
                        : null
                        }
                        {' '}
                        { thisStatus == 0 ?
                            // <span>
                            //     <button
                            //         className="btn btn-danger"
                            //         id={"delete-" + thisId}
                            //         onClick={() => {
                            //             this.setState({
                            //                 inactiveUuid: record.letter_out_qrcode,
                            //                 confirm: true
                            //             })
                            //         }}
                            //         style={{
                            //             padding: '2px 5px'
                            //         }}>
                            //         <i className="uil-times"></i>
                            //     </button>
                            //     <Tooltip
                            //         style={{
                            //             marginBottom: 2
                            //         }}
                            //         placement="top" isOpen={this.state["delete" + thisId]} target={"delete-" + thisId}
                            //         toggle={() => {
                            //             this.setState({
                            //                 ["delete" + thisId] : !this.state["delete" + thisId]
                            //             })
                            //         }}
                            //     >Cancel ST</Tooltip>
                            // </span>
                            <span>
                                <button
                                    className="btn btn-primary"
                                    id={"detail-" + thisId}
                                    onClick={() => {
                                        this.setState({
                                            detailUuid: record.letter_out_qrcode
                                        })
                                    }}
                                    style={{
                                        marginRight: '2px',
                                        padding: '2px 5px'
                                    }}>
                                        <i className="uil-pen"></i>
                                </button>
                                <Tooltip
                                    style={{
                                        marginBottom: 2
                                    }}
                                    placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                    toggle={() => {
                                        this.setState({
                                            ["detail" + thisId] : !this.state["detail" + thisId]
                                        })
                                    }}
                                >Input Data</Tooltip>
                            </span>
                            : null
                        }

                    </Fragment>
                );
            }
        }
    ]

    closeModalHandler = () => {
        this.setState({
            showPrevFile: false
        })
    }

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan membatalkan surat tugas ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.detailUuid !== null ?
                    <Redirect to={"/outbox/penomoran.html?update&qrcode=" + this.state.detailUuid}/>
                : null }

                <PreviewLetterOutPdf isOpen={this.state.showPrevFile} path={this.state.urlPdf} type={"modal"} closeModal={this.closeModalHandler}/>

            </>
        )
    }

}

export default TableActiveComponent
