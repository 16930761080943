import {
    apiRequest,
    unitList,
    letterTypeList,
    initialCreate
} from "../../../../../services/adapters/common/masterService"

class CreateRepository {

    fetchUnitList = async (query, cancelToken) => {
        return await apiRequest("get", unitList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchLetterTypeList = async (query, cancelToken) => {
        return await apiRequest("get", letterTypeList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", initialCreate, {
            body: query
        }, true)
    }

}

export default CreateRepository