import {
    apiRequest,
    receiverGroupList,
    receiverGroupDeactivate,
} from "../../../../../services/adapters/common/masterService"

class DataTableRepository {
    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", receiverGroupList, {
            cancelToken,
            params: query,
        }, true)
    }

    fetchInactive = async (query, cancelToken) => {
        return await apiRequest("post", receiverGroupDeactivate, {
            cancelToken,
            body: query,
        }, true)
    }
}

export default DataTableRepository
