import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannel = new Subject();
    flagReadChannel = new Subject();
    listPltChannel = new Subject();
    repository = new DataTableRepository()

    constructor() {
        this.rowtableChannel.next({status: apiResponse.INITIAL})
        this.flagReadChannel.next({status: apiResponse.INITIAL})
        this.listPltChannel.next({status: apiResponse.INITIAL})
    }

    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
                this.rowtableChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannel.next({status: apiResponse.ERROR, data: error})
            })    
        } catch (error) {}
        
    }

    fetchListPlt = async (query) => {
        this.listPltChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchListPlt(query).then((result) => {
                this.listPltChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.listPltChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
        
    }

    fetchFlagRead = async (query) => {
        this.flagReadChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchFlagRead(query).then((result) => {
                this.flagReadChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.flagReadChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {}
        
    }
}

export default DataTableBloc
