import moment from 'moment'

/**
 * Return string tanggal paling kecil.
 *
 * @param {string} dateString
 */
export function minDateString(...dateString) {
    const dates = dateString
        .map(date => moment(date))
        .filter(date => date.isValid())

    if (dates.length <= 0) {
        return null
    }

    const minDate = moment.min(dates)

    return minDate.format(moment.HTML5_FMT.DATE)
}

/**
 * Return string tanggal paling besar.
 *
 * @param {string} dateString
 */
export function maxDateString(...dateString) {
    const dates = dateString
        .map(date => moment(date))
        .filter(date => date.isValid())

    if (dates.length <= 0) {
        return null
    }

    const minDate = moment.max(dates)

    return minDate.format(moment.HTML5_FMT.DATE)
}
