import { apiWrapper, apiCancel } from '../../wrapper'

export const initial = 'outbox'
export const baseUrl = process.env.REACT_APP_SERVICE_OUTBOX_URL
const appCode = process.env.REACT_APP_SERVICE_OUTBOX_APPCODE
const outputType = 'json'

export const daftarSuratList = 'daftarsurat/listnew/'
export const daftarSuratDetail = 'daftarsurat/detail/'
export const daftarTerkirimList = 'daftarterkirim/list/'
export const daftarTerkirimDetail = 'daftarterkirim/detail/'
export const daftarDraftList = 'daftardraft/list/'
export const daftarDraftDetail = 'daftardraft/detail/'
export const daftarDikembalikanList = 'daftardikembalikan/list/'
export const daftarDikembalikanDetail = 'daftardikembalikan/detail/'

//Referensi
export const letterPriorityList = 'referensi/letterPriorityList/'
export const letterTypeList = 'referensi/letterTypeList/'
export const letterSignerList = 'referensi/letterSignerList/'
export const attachmentTypeList = 'referensi/attachmentTypeList/'
export const originSenderList = 'referensi/originSenderList/'
export const redactionList = 'referensi/redactionList/'
export const instansiList = 'referensi/instansiList/'
export const positionList = 'referensi/positionList/'
export const internalReceiverGroupList = 'referensi/internalReceiverGroupList/'
export const senderList = 'referensi/senderList/'
export const regionList = 'referensi/regionList/'
export const cityList = 'referensi/cityList/'
export const revisiSTList = 'referensi/revisiSTList/'
export const participantList = 'referensi/participantST/'
export const cekParticipantST = 'referensi/cekParticipantST/'
// process
export const suratKeluarCreate = 'daftardraft/create/'
export const markDraftRegenerate = 'daftardraft/mark_draft_regenerate/'
export const pdfDraftRegenerate = 'daftardraft/pdf_draft_regenerate/'
export const sendReview = 'daftardraft/sendreview/'
export const returnReview = 'daftarsurat/returnreview/'
export const sendReviewAgain = 'daftardikembalikan/sendreview/'
export const suratMasukUpdate = 'daftardraft/update/'
export const suratMasukReturned = 'daftardraft/returned/'
export const prosesReview = 'daftarsurat/review/'
export const signLetter = 'daftarsurat/sign/'
export const signNonDsLetter = 'daftarsurat/signnonds/'
export const requestNomor = 'daftarsurat/requestNomor/'
export const cancelLetter = 'daftarsurat/cancelLetter/'
export const checkSignAccess = 'daftarsurat/checksignaccess/'
export const externalSignData = 'daftarsurat/sign_external_data/'
export const externalSign = 'daftarsurat/sign_external/'
export const addattachment = 'daftardraft/addattachment/'
export const deleteattachment = 'daftardraft/deleteattachment/'
export const suratKeluarUpdate = 'daftardraft/update/'
export const suratKeluarDetail = 'daftardraft/updatedata/'
export const cancelparticipant = 'daftardraft/cancelparticipant/'
export const rollbackLetter = 'daftarsurat/rollbackLetter/'
export const referensiList = 'referensi/listsurat/'


//export const cancelToken = () => apiCancel
export const cancelToken = () => {
    return apiCancel()
}

/**
 * API request cancel token.
 *
 * @typedef {apiWrapper~RequestCancelToken} apiRequest~RequestCancelToken
 */

/**
 * Generic response outbox backend service.
 *
 * @typedef {Object} apiRequest~Response
 * @extends apiWrapper~Response
 */

/**
 * Digunakan untuk request ke outbox backend service.
 * @param {apiWrapper~RequestMethod} method
 * @param {string} route
 * @param {apiWrapper~RequestData} data
 * @param {boolean} needToken
 * @returns {Promise<apiRequest~Response>}
 */
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken=true) => {
    if(needToken) {
        const thisTime = (Math.floor(Date.now()/1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if(expire < thisTime) {
           await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/')
           .then(result => {
                localStorage.setItem(initial + 'accessToken', result.response.access_token)
                return apiWrapper(baseUrl, initial, appCode, outputType, method, route, {}) // fix penttest (remove param data)
            })
            .catch(result => {
                return Promise.reject('Tidak dapat mengambil accessToken');
            })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}
