/**
 * @template {{ [from: string]: any }} TOptionSource
 * @template {keyof TOptionSource} TKeyLabel
 * @template {keyof TOptionSource} TKeyValue
 * @param {TOptionSource[]} options
 * @param {TKeyLabel} labelKey
 * @param {TKeyValue} valueKey
 * @param {keyof TOptionSource | { [to: string]: keyof TOptionSource }} additionalProps
 * @return {{ label: TOptionSource[TKeyLabel], value: TOptionSource[TKeyValue] }[]}
 */
export function formatOption (
    options,
    labelKey,
    valueKey,
    ...additionalProps
) {
    return options.map(opt => {
        const result = {
            label: opt[labelKey],
            value: opt[valueKey],
        }

        for (const prop of additionalProps) {
            if (typeof prop === 'string' || prop instanceof String) {
                result[prop.toString()] = opt[prop.toString()] || null

                continue
            }

            for (const [keyTo, keyFrom] of Object.entries(prop)) {
                result[keyTo] = opt[keyFrom] || null
            }
        }

        return result
    })
}
