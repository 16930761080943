import {
    apiRequest,
    initialList
} from "../../../../../services/adapters/common/masterService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", initialList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

   
}

export default DataTableRepository