import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {
    repository = new CreateRepository()

    createChannel = new Subject()
    internalReceiverChannel = new Subject()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.internalReceiverChannel.next({ status: apiResponse.INITIAL })
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })

        try {
            await this.repository
              .fetchCreate(query)
              .then((result) => {
                  this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
              })
              .catch((error) => {
                  this.createChannel.next({ status: apiResponse.ERROR, data: error })
              })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchInternalReceiver = async (query = {}) => {
        this.internalReceiverChannel.next({ status: apiResponse.LOADING })

        try {
            await this.repository
              .fetchInternalReceivers(query)
              .then((result) => {
                  this.internalReceiverChannel.next({ status: apiResponse.COMPLETED, data: result })
              })
              .catch((error) => {
                  this.internalReceiverChannel.next({ status: apiResponse.ERROR, data: error })
              })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed')
        }
    }
}

export default CreateBloc
