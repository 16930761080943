import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Modal, Spinner, Input
} from 'reactstrap'
import Select from "react-select"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { Preview } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router";
import moment from "moment"
import 'moment/locale/id'
import FeatherIcon from 'feather-icons-react'

import apiResponse from '../../../../services/apiResponse'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'

import DetailBloc from './Blocs/DetailBloc'
import PdfViewer  from '../../../../helpers/ui/PdfViewer'
import DetailSuratMasuk from './Components/DetailSuratMasuk'
import ReviewBloc from '../../DaftarSurat/Review/Blocs/ReviewBloc'
import PreviewLetterOutPdf from '../../../../helpers/ui/outbox/PreviewLetterOutPdf'
import LetterTimeline from '../../../../components/Outbox/LetterTimeline'
import ModalSendReview from '../../../../components/Outbox/ModalSendReview'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];

      return newObj;
    });
};
var reformatOptionsDelegation = function(raw, label, value, nip) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["nip"] = data[nip];
      newObj["value"] = data[value];

      return newObj;
    });
};
class DetailView extends Component {
    detailBloc = new DetailBloc()
    reviewBloc = new ReviewBloc()

    constructor(props) {
        super(props)
        this.state = {
            showReferenceLetter: false,
            doDrafting: false,
            loading: true,
            loadingInfo : "Tunggu beberapa saat, sedang menyelaraskan dokumen",
            loadingTitle: "Memproses Dokumen...",
            document_url:"",
            confirmInfo:"",
            uuid:"",
            reviewer_nip:"",
            reviewer_position:"",
            random : 0,
            chooseReviewDirect: false,
            chooseReviewDelegation: false,
            delegationOption: [],
            modalSendReview: false,
            logsurat : [],
            modalAddAttachment : false,
            thisQrcode:"",
            modalUbahForm:false,

            canSendRevision: false,

            // Modal Revisi
            modalSendBack: false,
            statusproses: 0,

            // Pilihan Penerima Revisi
            isDelegateRevision: false,
            letterRevisionReceiverList: [],
            hideWarningOtherRevisionReceiver: true,
            sendRevisionToPositionUuid: null,

            // Catatan Revisi
            response_note: null,
            hideWarningEmptyNote: true,
            autoReloadTimeout: 20_000,
        }
    }
    handleOnDodrafting = () => {
        this.reviewBloc.fetchMarkDraftRegenerate({ qrcode: this.state.detail.letter_out_qrcode })

        const myWindowDoc = window.open(this.state.detail.letter_out_filepath,"_blank",);
        const timer = setInterval(() => {
            if(myWindowDoc.closed) {
                clearInterval(timer);
                this.setState({
                    random : Math.floor((Math.random() * 100) + 1),
                    loading : true,
                })

                this.reviewBloc.fetchPdfDraftRegenerate({ qrcode: this.state.detail.letter_out_qrcode })
            }
        }, 1000);

    };
    handleOnDoupdateform = () => {
        this.setState ({
            modalUbahForm : true
        })

    };
    handleRefreshIFrame = () => {
        alert("show");
    }
    handleShowRefInbox = () => {
        alert("show");
    }
    handleShowReferenceLetter = () => {
        //console.log(option);
        this.setState ({
            showReferenceLetter : true
        })
    }
    hideLoad = () => {
        //alert("close frame");
        this.setState({
          loading: false
        });
    };
    handleChooseReviewDirect = () => {
        this.setState ({
            chooseReviewDirect: true,
            chooseReviewDelegation: false,
            reviewType: 1,
            reviewer_nip: this.state.atasan.position_employee_nip,
            reviewer_position: this.state.atasan.position_uuid,
        })
    }

    handleChooseReviewDelegation = () => {
        this.setState ({
            chooseReviewDirect: false,
            chooseReviewDelegation: true,
            reviewType: 2
        })
    }

    handleSetReviewer = (option) => {
        //console.log(option);
        let selected = option.value
        //alert(selected)
        this.setState ({
            reviewer_nip: option.nip,
            reviewer_position: selected,
        })
    }

    handleSendReview = () => {
        this.setState ({
            modalSendReview : true,
            chooseReviewDirect: true,
            chooseReviewDelegation: false
        })
    }

    handleSendLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                qrcode: this.state.thisQrcode,
                uuid: this.state.uuid,
                reviewer_nip : this.state.reviewer_nip,
                reviewer_position : this.state.reviewer_position,
                reviewer_type : this.state.reviewType,
                drafter_unit : this.state.drafter_unit,
                note: this.state.review_note || null,
            },
            confirmInfo: "Apakah anda yakin akan mengirim surat ini?",
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            statusproses:2,
            modalSendReview : false
        })
        //console.log(this.state.dataForm)
    }

    handleSendBack = () => {
        this.setState ({ modalSendBack: true })
    }

    handleRevisionReceiverTypeChange = (e) => {
        const { value } = e.target
        const isDelegateRevision = 'delegasi' === value

        this.setState({ isDelegateRevision })

        if (! isDelegateRevision) this.setRevisionReceiver() // default ke drafter
        else this.setRevisionReceiver(null) // akan dipilih
    }

    setRevisionReceiver = (value = undefined) => {
        this.setState({
            sendRevisionToPositionUuid: value !== undefined
              ? value
              : this.state.drafter?.letter_out_process_uuid,
        })
    }

    handleOtherRevisionReceiverChange = ({ value }) => {
        this.hideWarningOtherRevisionReceiver(!! value)
        this.setState({ sendRevisionToPositionUuid: value || null })
    }

    hideWarningOtherRevisionReceiver = (hidden = true) => {
        this.setState({ hideWarningOtherRevisionReceiver: hidden })
    }

    handleChangeNote = (event) => {
        const value = event.target.value

        this.setState({ response_note: value });
        this.hideWarningEmptyNote(!! value)
    }

    hideWarningEmptyNote = (hidden = true) => {
        this.setState({ hideWarningEmptyNote: hidden })
    }

    validateReturnLetter = () => {
        let isValid = true

        if (! this.state.sendRevisionToPositionUuid) {
            this.hideWarningOtherRevisionReceiver(false)
            isValid = false
        }

        if (! this.state.response_note) {
            this.hideWarningEmptyNote(false)
            isValid = false
        }

        return isValid
    }

    handleReturnLetter = () => {
        if (! this.validateReturnLetter()) {
            return
        }

        this.setState({
            dataForm: {
                uuid: this.state.uuid,
                drafter_uuid: this.state.sendRevisionToPositionUuid,
                response_note : this.state.response_note,
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan mengembalikan surat ini?</span></Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            modalSendBack: false,
            statusproses: 9,
        })
        //console.log(this.state.dataForm)
    }

    handleTambahAttachemnt= () => {
        this.setState ({
            modalAddAttachment : true
        })
    }
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                qrcode: this.state.thisQrcode,
                ...values
            },
            confirm: true,
            statusproses:8,
            modalAddAttachment:false,
        })
    }
    handleRequestUpdateForm = () =>{
        const queryParams = new URLSearchParams(this.props.location.search)
        window.location.href = '/outbox/daftardikembalikan.html?update&uuid='+queryParams.get('uuid');
    }

    handleCancelLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.uuid,
                //request_nip : this.state.reviewer_nip,
                //request_position : this.state.reviewer_nip,
                response_note : this.state.response_note
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan membatalkan surat ini ?</span> </Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            statusproses:99
        })
        //console.log(this.state.dataForm)
    }

    deleteFileHandler = (file_id) => {
        this.setState({
            dataForm: {
                uuid: file_id
            },
            confirmInfo: "Apakah anda yakin akan menghapus lampiran ini?",
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            statusproses:81
        })
    }

    submitData = async () => {
        //console.log(this.state.dataForm)
        const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratkeluar/attachment'})
        //console.log(formData)
        if(this.state.statusproses == 2){
            await this.detailBloc.fetchSendReview(formData)
        }
        if(this.state.statusproses == 8){
            //const newformData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratkeluar/attachment'} )
            //console.log(formData)
            await this.detailBloc.fetchAddAttachment(formData)
        }
        if(this.state.statusproses == 9){
            await this.reviewBloc.fetchReturnReview(formData)
        }
        if(this.state.statusproses == 81){
            //const newformData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratkeluar/attachment'} )
            //console.log(formData)
            await this.detailBloc.fetchDeleteAttachment(formData)
        }

        if(this.state.statusproses == 99){
            await this.detailBloc.fetchCancelLetter(formData)
        }
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    handleOnReloadRegenerate = () => {
        this.setState({ loading: true, autoReloadTimeout: 120_000 })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.detailBloc.fetchDetail({ uuid: queryParams.get('uuid') })
    }

    handleOnAutoReloadRegenerate = () => {
        this.setState({ showPreviewLoading: true, autoReloadTimeout: 120_000 })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.detailBloc.fetchDetail({ uuid: queryParams.get('uuid') })
    }

    componentDidMount() {
        this.detailBloc.sendLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.detailBloc.addAttachmentChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const queryParams = new URLSearchParams(this.props.location.search)
                    var url = '/outbox/daftardikembalikan.html?detail&uuid='+queryParams.get('uuid');
                    this.setState({
                        loading: false,
                        //redirect2: true,
                        //urlredirect2: url
                    });
                    window.location.reload();
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.detailBloc.deleteAttachmentChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const queryParams = new URLSearchParams(this.props.location.search)
                    var url = '/outbox/daftardikembalikan.html?detail&uuid='+queryParams.get('uuid');
                    this.setState({
                        loading: false,
                        //redirect2: true,
                        //urlredirect2: url
                    });
                    window.location.reload();
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.detailBloc.cancelLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //alert(response.letter_out_process.letter_out_process_status_review)
                        if(response.letter_out_process.letter_out_process_status_review != 9){
                            this.setState({ redirect:true})
                        }
                        this.setState({
                            loading: false,
                            showPreviewLoading: false,
                            letter_process: response.letter_out_process,
                            detail: response.letter_out,
                            signers: response.signers,
                            receiver: response.receiver,
                            participant_ext: response?.participant_ext || [],
                            attachment: response.attachment,
                            refQrcode : response.letter_out.letter_out_ref_qrcode,
                            thisQrcode : response.letter_out.letter_out_qrcode,
                            atasan: response.reviewer,
                            IurlDoc : response.document_url + "/:w:/r/_layouts/15/Doc.aspx?sourcedoc={" +  response.letter_out.letter_out_sharepoint_doc_id + "}&action=embedview",
                            IurlDocEdit : response.document_url + "/:w:/r/_layouts/15/Doc.aspx?sourcedoc={" +  response.letter_out.letter_out_sharepoint_doc_id + "}&action=default",
                            uuid:response.letter_out_process.letter_out_process_uuid,
                            reviewer_nip: (response.reviewer ? response.reviewer.position_employee_nip : ""),
                            reviewer_position: (response.reviewer ? response.reviewer.position_uuid : ""),
                            logsurat : response.logs,
                            jmlReceiver : response.receiver.length + response?.participant_ext?.length,
                            jmlCopy : response.tembusan.length,
                            revisioner : response.revisioner,
                            delegationOption: reformatOptionsDelegation(
                                response.delegation,
                                'position_name_text',
                                'position_uuid',
                                'position_employee_nip'
                            ),

                            canSendRevision: response.can_send_revision,
                            drafter: response.drafter,
                            sendRevisionToPositionUuid: response.drafter?.letter_out_process_uuid,
                            letterRevisionReceiverList: reformatOptions(
                              response?.other_revision_receivers || [],
                              'position_name_text',
                              'letter_out_process_uuid',
                            ),
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.reviewBloc.returnLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.pdfDraftRegenerateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                case apiResponse.ERROR:
                    this.setState((prev) => ({
                        loading: false,
                        detail: {
                            ...prev.detail,
                            letter_out_draftpdf_need_regen: true,
                        },
                    }));

                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('uuid')) {
            this.detailBloc.fetchDetail({ uuid: queryParams.get('uuid'), reset: 1 })
        }
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Pratinjau Surat Keluar</CardTitle>
                                    <CardSubtitle className="mb-3">
                                    Informasi & Pratinjau Surat Keluar
                                    </CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <Col md="5">
                                            <Row className="mb-3 mt-2">
                                                <label className="col-md-4">
                                                    Kode Surat
                                                </label>
                                                <Col md="8">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.letter_out_qrcode !== "" ? this.state.detail.letter_out_qrcode : '-'}</>
                                                    :
                                                        <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Jenis Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_type_name !== "" ? this.state.detail.letter_type_name : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            {
                                                this.state.detail ?
                                                    this.state.detail.letter_out_ref_qrcode !== "" ?
                                                        <Row className="mb-3 mt-2">
                                                            <label className="col-md-4">
                                                                Ref Surat Masuk
                                                            </label>
                                                            <Col md="8">
                                                            <button type="button" class="btn-sm btn-rounded waves-effect waves-light btn btn-info" onClick={() => this.handleShowReferenceLetter()}><i class="uil-eye"></i> {this.state.detail.letter_out_ref_agenda}</button>
                                                            </Col>
                                                        </Row>
                                                    : null
                                                :
                                                null
                                            }
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Nomor Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_number !== null ? this.state.detail.letter_out_number : <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>}</>
                                                        : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tanggal Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_date !== null ?  this.state.detail.letter_out_date_text : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Sifat Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.priority_name !== "" ? this.state.detail.priority_name : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Perihal
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_title !== "" ? this.state.detail.letter_out_title : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Penandatangan
                                                </label>
                                                <Col md="8">
                                                    {Array.isArray(this.state.signers) ? (
                                                        this.state.signers.length <= 1 ? (
                                                            this.state.signers.map(({ signer_position_text }) => signer_position_text)
                                                        ) : (
                                                            <ul>
                                                                {this.state.signers.map(({ signer_position_text, signer_token }) => (
                                                                    <li>
                                                                        {signer_position_text}
                                                                        {this.state.detail.letter_out_qrcode && signer_token && (
                                                                            <a
                                                                                className='btn px-2'
                                                                                href={`/outbox/sign.html?qrcode=${this.state.detail.letter_out_qrcode}&token=${signer_token}`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault()

                                                                                    navigator.clipboard.writeText(e.currentTarget.href)
                                                                                }}
                                                                            >
                                                                                <FeatherIcon icon='clipboard' size={16} />
                                                                            </a>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )
                                                    ) : (
                                                        <Shimmer>
                                                            <div style={{ width: '80%', height: 15 }}></div>
                                                        </Shimmer>
                                                    )}
                                                </Col>
                                            </Row>
                                            {this.state.receiver ?
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Penerima
                                                </label>
                                                <Col md="8">
                                                    {this.state.jmlReceiver > 1 ?
                                                        <ul >
                                                        {
                                                            this.state.receiver.map(function (value, i) {
                                                            return  <li >
                                                                        {(value.employee_name != null ? value.employee_name + ', ' : '')  + value.receiver_name}
                                                                    </li>
                                                            })
                                                        }

                                                            {this.state.participant_ext.map(value => (
                                                              <li>
                                                                  {value.letter_participant_ext_name && `${value.letter_participant_ext_name}, `}
                                                                  {value.letter_participant_ext_position}
                                                                  {' '}
                                                                  (Eksternal)
                                                              </li>
                                                            ))}
                                                        </ul>
                                                        :
                                                        this.state.receiver.map(function (value, i) {
                                                        return  <span>{(value.employee_name != null ? value.employee_name + ', ' : '') +value.receiver_name}</span>
                                                        })

                                                    }

                                                </Col>
                                            </Row>
                                            : null
                                            }
                                            {this.state.tembusan ?
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tembusan
                                                </label>
                                                <Col md="8">
                                                    {this.state.jmlCopy > 1 ?
                                                        <ul >
                                                        {
                                                            this.state.tembusan.map(function (value, i) {
                                                            return  <li >
                                                                        {value.receiver_name}
                                                                    </li>
                                                            })
                                                        } </ul>
                                                        :
                                                        this.state.tembusan.map(function (value, i) {
                                                        return  <span>{value.receiver_name}</span>
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                             : null
                                            }
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    File Lampiran
                                                </label>
                                                <Col md="8">
                                                    { this.state.attachment ?
                                                        <Preview
                                                            value={this.state.attachment}
                                                            fileName='attachment_filename'
                                                            filePath='attachment_filepath'
                                                            fileMimeType='attachment_mime'
                                                            fileSource='attachment_source'
                                                            fileDeleted={true}
                                                            fileId='attachment_id'
                                                            col='4'
                                                            deleteFile={this.deleteFileHandler}
                                                        /> : <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                                <label className="col-md-4">

                                                </label>
                                                <label className="col-md-8">
                                                    <Button color="success"  className="waves-effect btn-sm" type="button" onClick={() => this.handleTambahAttachemnt()}>
                                                        <i className="uil-plus"></i> tambah lampiran
                                                    </Button>
                                                </label>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Status
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.letter_process ?
                                                            <span class="badge bg-danger font-size-14">{this.state.letter_process.action_text}</span>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }

                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Permintaan Revisi dari
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.revisioner ?
                                                            <span>{this.state.revisioner.histories_created }
                                                            </span>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }

                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Catatan Revisi
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.letter_process.letter_out_process_response !== "" ? this.state.letter_process.letter_out_process_response : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md="4">

                                                </Col>
                                                <Col md="8">
                                                    <Button color="success"  className="waves-effect btn-sm" type="button" onClick={() => this.handleOnDoupdateform()}>
                                                        <i className="uil-pen"></i> Ubah Form
                                                    </Button>
                                                    {' '}
                                                    <Button color="success"  className="waves-effect btn-sm" type="button" onClick={() => this.handleOnDodrafting()}>
                                                        <i className="uil-pen"></i> Ubah Draft
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row className="mb-3">
                                                <Col md="4">
                                                    <Link to="/outbox/daftarsurat.html">
                                                        <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                    </Link>
                                                </Col>
                                                <Col md="8">
                                                    { this.state.canSendRevision ? <>
                                                        <Button
                                                          color="warning"
                                                          className="waves-effect me-1"
                                                          type="button"
                                                          onClick={ this.handleSendBack }
                                                        >
                                                            <i className="uil-backspace me-1"></i>
                                                            Kembalikan
                                                        </Button>
                                                    </> : null }

                                                    <Button color="danger" className="waves-effect me-1" type="button" onClick={() => this.handleCancelLetter()}>
                                                        <i className="uil-envelope-send me-1"></i>
                                                        Batalkan Surat
                                                    </Button>

                                                    {
                                                        this.state.atasan ?
                                                        <Button color="info" className="waves-effect" type="button" onClick={() => this.handleSendReview()}>
                                                            <i className="uil-envelope-send"></i> Kirim ke Atasan
                                                        </Button>
                                                        : null
                                                    }

                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col md="7" style={{marginTop: '-16px'}}>
                                            {! this.state.detail ?
                                                <Shimmer>
                                                    <div style={{ width: '80%', height: 15 }}></div>
                                                </Shimmer>
                                            :
                                                <PreviewLetterOutPdf
                                                    path={this.state.detail.letter_out_draftpdf}
                                                    isNeedRegenerate={this.state.detail.letter_out_draftpdf_need_regen}
                                                    onReloadRegenerate={this.handleOnReloadRegenerate}
                                                    onAutoReloadRegenerate={this.handleOnAutoReloadRegenerate}
                                                    showLoading={this.state.showPreviewLoading}
                                                    timeout={this.state.autoReloadTimeout}
                                                />
                                            }
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <LetterTimeline values={this.state.logsurat} />
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="lg"
                    isOpen={this.state.showReferenceLetter}
                    toggle={() => {
                        this.setState({
                            showReferenceLetter: this.state.showReferenceLetter
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Informasi Surat Masuk
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    { this.state.refQrcode != null ?
                        <DetailSuratMasuk reqQrode={this.state.refQrcode}/>
                        : null
                    }
                        {/* <TableActiveComponent changeQRCode={this.handleGetQRCode}/> */}
                    </div>
                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Tutup
                            </button>

                        </div>
                </Modal>

                <ModalSendReview
                    show={this.state.modalSendReview}

                    isEchelon1={this.state.iseselon1}
                    drafterUnitOptions={this.state.konseptorOption}
                    onSelectedDrafterUnit={this.handleSetKonseptor}

                    directReviewer={this.state.atasan}
                    onChooseDirectReviewer={this.handleChooseReviewDirect}

                    otherReviewerOptions={this.state.delegationOption}
                    onChooseOtherReviewer={this.handleChooseReviewDelegation}
                    onSelectedOtherReviewer={this.handleSetReviewer}

                    onChangedNote={(event) => {
                        this.setState({ review_note: event.target.value })
                    }}

                    onClose={() => this.setState({ modalSendReview: false })}
                    onSend={this.handleSendLetter}
                />

                <Modal
                    size="lg"
                    isOpen={this.state.modalAddAttachment}
                    toggle={() => {
                        this.setState({
                            modalAddAttachment: this.state.modalAddAttachment
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelzzzXX"
                        >
                            Tambah Lampiran
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalAddAttachment: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <AvForm
                        className="needs-validation"
                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                    >
                    <div className="modal-body">
                            <Col md="12">
                                <div className="mb-3">
                                <FileInput
                                    name="document"
                                    //validate={{ required: { value: true } }}
                                    isMultiple
                                />
                                </div>
                            </Col>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    modalAddAttachment: false
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                        </button>
                        <Button color="success" type="submit">
                            <i className="uil-arrow-up-right"></i> Simpan Lampiran
                        </Button>
                    </div>
                    </AvForm>
                </Modal>
                <Modal
                    size="md"
                    isOpen={this.state.modalUbahForm}
                    toggle={() => {
                        this.setState({
                            modalUbahForm: this.state.modalUbahForm
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelzzz"
                        >
                            Konfirmasi Ubah Form
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalUbahForm: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Col md="12">
                            <div className="mb-1">
                            <label >Proses Ubah Form akan menghapus draft Surat yang sudah di buat. <br/>Apakah Anda yakin akan melanjutkan proses ini ?
                            </label>
                            </div>
                        </Col>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    modalUbahForm: false
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                        </button>
                        <button
                            type="button"
                            onClick={() =>this.handleRequestUpdateForm()}
                            className="btn btn-primary waves-effect"
                            data-dismiss="modal"
                          >
                            Lanjutkan
                            </button>
                        </div>
                </Modal>
                <Modal
                  size="md"
                  isOpen={this.state.modalSendBack}
                  toggle={() => {
                      this.setState({
                          modalSendBack: this.state.modalSendBack
                      })
                  }}
                >
                    <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myLargeModalLabelXXX"
                        >
                            Kembalikan Surat
                        </h5>
                        <button
                          onClick={() => {
                              this.setState({
                                  modalSendBack: false
                              })
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Col md="12">
                            <fieldset className="mb-3">
                                <legend className="fs-6 fw-medium mb-2">
                                    Surat akan dikembalikan untuk direvisi ke:
                                </legend>

                                <div className="form-check form-check-end">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="tipe_penerima_revisi"
                                      value="drafter"
                                      id="penerima-revisi__drafter"
                                      checked={ ! this.state.isDelegateRevision }
                                      onChange={ this.handleRevisionReceiverTypeChange }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="penerima-revisi__drafter"
                                    >
                                        Drafter
                                    </label>

                                    <p>
                                        Nama &emsp;: { this.state.drafter?.profil_name || ""}<br/>
                                        Jabatan : { this.state.drafter?.profil_position || ""}
                                    </p>
                                </div>

                                <div className="form-check form-check-end">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="tipe_penerima_revisi"
                                      value="delegasi"
                                      id="penerima-revisi__delegasi"
                                      checked={ this.state.isDelegateRevision }
                                      onChange={ this.handleRevisionReceiverTypeChange }
                                    />

                                    <label
                                      className="form-check-label mb-2"
                                      htmlFor="penerima-revisi__delegasi"
                                    >
                                        Tujuan Lain
                                    </label>
                                </div>

                                { this.state.isDelegateRevision ? <>
                                    <Select
                                      name="other_revision_receiver"
                                      placeholder="Pilih Tujuan Revisi Surat"
                                      options={ this.state.letterRevisionReceiverList }
                                      onChange={ this.handleOtherRevisionReceiverChange }
                                      styles={{
                                          control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              borderColor:
                                                this.state.hideWarningOtherRevisionReceiver
                                                  ? baseStyles.borderColor
                                                  : 'rgb(var(--bs-danger-rgb))',
                                          }),
                                      }}
                                    />

                                    { ! this.state.hideWarningOtherRevisionReceiver ? <>
                                        <span className="text-danger">
                                            Pilih Tujuan Revisi Surat
                                        </span>
                                    </> : null }
                                </> : null }
                            </fieldset>
                        </Col>
                        <Col md="12">
                            <div className="mb-3">
                                <label >Catatan <span className="text-danger">*</span>
                                </label>
                                <Input
                                  name="response_note"
                                  placeholder="Isi Catatan.."
                                  type="textarea"
                                  errorMessage="Masukkan Catatan"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  onChange={this.handleChangeNote}
                                  id="response_note"
                                  invalid={ ! this.state.hideWarningEmptyNote }
                                />

                                { ! this.state.hideWarningEmptyNote ? <>
                                    <span className="text-danger">
                                        Masukkan catatan
                                    </span>
                                </> : null }
                            </div>
                        </Col>
                    </div>
                    <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                              this.setState({
                                  modalSendBack: false
                              })
                          }}
                          className="btn btn-danger waves-effect"
                          data-dismiss="modal"
                        >
                            Batal
                        </button>
                        <button
                          type="button"
                          onClick={ this.handleReturnLetter }
                          className="btn btn-primary waves-effect"
                          data-dismiss="modal"
                        >
                            Kirim
                        </button>
                    </div>
                </Modal>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.confirmInfo}

                    loadingTitle={this.state.loadingTitle}
                    loadingInfo={this.state.loadingInfo}

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    //failedInfo="Data gagal disimpan"
                    failedInfo="Gagal menyiapakan halaman editor dokumen"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                <Redirect to={'/outbox/daftardikembalikan.html'}/>
                //null
                    // this.state.submitStatus == 1 ?
                    // <Redirect to='/inbox/daftarterkirim.html'/>
                    // :
                    // <Redirect to='/inbox/daftardraft.html'/>
                : null }
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
