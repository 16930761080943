import React from 'react'
import { Button, Col, Label, Row } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import cn from 'clsx'

import CustomSelect from '../../../helpers/ui/CustomSelect'
import { useLinkedSchedule } from '../../../helpers/linkedSchedule'

class CitySchedule {
    /**
     * @type {?CitySchedule}
     */
    prev

    /**
     * @type {?string}
     */
    startDate = null

    /**
     * @type {?string}
     */
    endDate = null

    /**
     * @type {?string}
     */
    provinceUuid = null

    /**
     * @type {?string}
     */
    cityUuid = null

    constructor (prev) {
        this.prev = prev
    }
}

/**
 * @typedef {Object} CityScheduleShortModel
 * @property {string} start_date
 * @property {string} end_date
 * @property {string} province_uuid
 * @property {string} city_uuid
 */

/**
 * @typedef {Object} CityScheduleModel
 * @property {string} city_schedule_start_date
 * @property {string} city_schedule_end_date
 * @property {string} city_schedule_province_uuid
 * @property {string} city_schedule_city_uuid
 */

/**
 * @param {CityScheduleShortModel[]|CityScheduleModel[]} citySchedules
 * @return {CitySchedule[]}
 */
function fromArrayObjectToCitySchedules (citySchedules) {
    /**
     * @type {?CitySchedule}
     */
    let prev = null

    return citySchedules.map(raw => {
        const obj = new CitySchedule(prev)

        obj.startDate = raw.start_date || raw.city_schedule_start_date
        obj.endDate = raw.end_date || raw.city_schedule_end_date
        obj.provinceUuid = raw.province_uuid || raw.city_schedule_province_uuid
        obj.cityUuid = raw.city_uuid || raw.city_schedule_city_uuid

        prev = obj

        return obj
    })
}

/**
 * @typedef {Object} LoadingCityByProvinceOption
 * @property {true} loading
 */

/**
 * @typedef {Object} LoadedCityByProvinceOption
 * @property {false} loading
 * @property {{ label: string, value: string }[]} value
 */

/**
 * @template {string} TProvinceUuid
 * @param {string} minStartDate
 * @param {string} maxEndDate
 * @param {{ label: string, value: TProvinceUuid }[]} provinceOptions
 * @param {(provinceUuid: TProvinceUuid) => void} fetchCity
 * @param {{ [TProvinceUuid]: LoadingCityByProvinceOption|LoadedCityByProvinceOption }} cityByProvinceOptions
 * @param {CityScheduleShortModel[]|CityScheduleModel[]|null} values
 * @param {boolean} readOnly
 * @param {boolean} readOnlyDate
 * @param {boolean} readOnlySelect
 * @return {React.ReactElement}
 * @constructor
 */
export default function AssignmentOutOfTownCitiesComponent ({
    minStartDate,
    maxEndDate,
    provinceOptions,
    fetchCity,
    cityByProvinceOptions = {},
    values = null,
    readOnly = false,
    readOnlyDate = false,
    readOnlySelect = false,
}) {
    const {
        schedules: citySchedules,
        setSchedules: setCitySchedules,

        add: addSchedule,
        removeByIndex: removeScheduleByIndex,
        updateByIndex: updateScheduleByIndex,

        calculateStartDate,
        calculateMinStartDate,
        calculateMaxStartDate,
        calculateEndDate,
    } = useLinkedSchedule({
        minStartDate,
        maxEndDate,
        values,
        valueMapper: fromArrayObjectToCitySchedules,
        creator: (previous) => new CitySchedule(previous),
    })

    /**
     * @param {CitySchedule} city
     * @return {string|undefined}
     */
    function calculateProvinceUuid (city) {
        return city.provinceUuid || undefined
    }

    /**
     * @param {CitySchedule} city
     * @return {string|undefined}
     */
    function calculateCityUuid (city) {
        return city.cityUuid || undefined
    }

    return (
        <>
            <Row>
                <Label md={2} className="pt-0">
                    Tanggal Mulai <span className="text-danger">*</span>
                </Label>
                <Label md={2} className="pt-0">
                    Tanggal Akhir <span className="text-danger">*</span>
                </Label>
                <Label md={3} className="pt-0">
                    Provinsi <span className="text-danger">*</span>
                </Label>
                <Label md={3} className="pt-0">
                    Kota <span className="text-danger">*</span>
                </Label>
            </Row>

            {citySchedules.map((schedule, index, list) => {
                const currentStartDate = calculateStartDate(schedule)
                const currentMinStartDate = calculateMinStartDate(schedule)
                const currentMaxStartDate = calculateMaxStartDate(schedule)
                const currentEndDate = calculateEndDate(schedule)
                const currentProvinceUuid = calculateProvinceUuid(schedule)
                const currentCityUuid = calculateCityUuid(schedule)
                const canShowButton = ! readOnly && ! readOnlySelect

                return (
                    <Row key={`${index}-input`}>
                        <Col md={2}>
                            <AvField
                                id={`official_travel_cities_start_date-${index}`}
                                name={`official_travel_cities_start_date[${index}]`}
                                placeholder="Tanggal Mulai"
                                type="date"
                                errorMessage="Masukkan Tanggal Mulai"
                                className="form-control"
                                value={currentStartDate}
                                min={currentMinStartDate}
                                max={currentMaxStartDate}
                                readOnly={readOnly || readOnlyDate || index === 0}
                            />
                        </Col>

                        <Col md={2}>
                            <AvField
                                id={`official_travel_cities_end_date-${index}`}
                                name={`official_travel_cities_end_date[${index}]`}
                                placeholder="Tanggal Akhir"
                                type="date"
                                errorMessage="Masukkan Tanggal Akhir"
                                className="form-control"
                                value={currentEndDate}
                                min={currentStartDate}
                                max={maxEndDate}
                                validate={{ required: { value: true } }}
                                readOnly={readOnly || readOnlyDate || index === list.length - 1}
                                onChange={(e) => {
                                    schedule.endDate = e.target.value

                                    updateScheduleByIndex(index, schedule)
                                }}
                            />
                        </Col>

                        <Col md={3}>
                            <CustomSelect
                                id={`official_travel_cities_province-${index}`}
                                name={`official_travel_cities_province_uuid[${index}]`}
                                errorMessage="Pilih Provinsi"
                                options={provinceOptions}
                                defaultValue={currentProvinceUuid}
                                placeholder="Pilih Provinsi"
                                validate={{ required: { value: true } }}
                                isDisabled={readOnly || readOnlySelect}
                                onChange={(option) => {
                                    schedule.provinceUuid = option.value
                                    schedule.cityUuid = null

                                    updateScheduleByIndex(index, schedule)

                                    if (! cityByProvinceOptions[option.value]?.value) {
                                        fetchCity(option.value)
                                    }
                                }}
                            />
                        </Col>

                        <Col md={3}>
                            <CustomSelect
                                id={`official_travel_cities_city-${index}`}
                                name={`official_travel_cities_city_uuid[${index}]`}
                                key={`official_travel_cities_city_uuid-${currentProvinceUuid || 'empty'}`}
                                errorMessage="Pilih Kota"
                                options={cityByProvinceOptions[currentProvinceUuid]?.value || []}
                                defaultValue={currentCityUuid}
                                placeholder="Pilih Kota"
                                validate={{ required: { value: true } }}
                                isDisabled={readOnly || readOnlySelect || ! currentProvinceUuid}
                                isLoading={!! cityByProvinceOptions[currentProvinceUuid]?.loading}
                            />
                        </Col>

                        <Col>
                            {list.length > 1 ? (
                                <Button
                                    className={cn(
                                        'btn-block waves-effect waves-light mb-1 me-1',
                                        {'invisible': ! canShowButton}
                                    )}
                                    color="danger"
                                    onClick={() => removeScheduleByIndex(index)}
                                >
                                    <i className="uil-trash-alt"></i>
                                </Button>
                            ) : null}

                            {index === 0 ? (
                                <Button
                                    className={cn(
                                        'btn-block waves-effect waves-light mb-1',
                                        {'invisible': ! canShowButton}
                                    )}
                                    color="success"
                                    onClick={addSchedule}
                                >
                                    <i className="uil-plus-circle"></i>
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                )
            })}

            <div className="mb-3" aria-hidden="true"></div>
        </>
    )
}
