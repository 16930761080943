import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'
import CustomSelect from '../../../../helpers/ui/CustomSelect'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];
  
      return newObj;
    });
};
const mysession = JSON.parse(localStorage.getItem('userdata'))
console.log(mysession)
class IndexView extends Component {

    createBloc = new CreateBloc();

    constructor(props) {
        super(props)
        this.state = {
            unitOption: [],
            letterOption: [],
            dataForm: {}
            
        }
    }

    // End Select Area

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, '') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto'] 
        //const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratmasuk/attachment'})
        await this.createBloc.fetchCreate(formData)       
    }

    
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.employee_uuid,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.createBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.result
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.createBloc.fetchDetail({id: queryParams.get('id') })

       
        this.createBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            unitOption: reformatOptions(
                                response.result, 
                                'work_unit_name',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchUnitList({})

        this.createBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)                        
                        this.setState({
                            letterOption: reformatOptions(
                                response.result, 
                                'letter_type_name',
                                'letter_type_initial'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchLetterTypeList({})
        // get data marital status
        
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Tambah Daftar User Tata Usaha Masuk</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            Unit Kerja
                                            </label>
                                            <Col md="5">
                                                <AvField
                                                    name="initial_number_unit_uuid"
                                                    placeholder="Unit Kerja"
                                                    type="text"
                                                    errorMessage="Masukkan Kodefikasi"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="initial_number_unit_uuid"
                                                    value={ this.state.detail ? this.state.detail.work_unit_name : null}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            Jenis Surat
                                            </label>
                                            <Col md="5">
                                                <AvField
                                                    name="initial_number_type"
                                                    placeholder="Jenis Surat"
                                                    type="text"
                                                    errorMessage="Masukkan Jenis Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="initial_number_type"
                                                    value={ this.state.detail ? this.state.detail.letter_type_name : null}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            Kodefikasi
                                            </label>
                                            <Col md="5">
                                                <AvField
                                                    name="initial_number_code"
                                                    placeholder="Kodefikasi"
                                                    type="text"
                                                    errorMessage="Masukkan Kodefikasi"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="initial_number_code"
                                                    value={ this.state.detail ? this.state.detail.initial_number_code : "INCREMENT/.../YEAR"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            Tanggal Efektif
                                            </label>
                                            <Col md="2">
                                                <AvField
                                                    name="initial_number_startdate"
                                                    placeholder="Tanggal Mulai"
                                                    type="date"
                                                    errorMessage="Masukkan Tanggal Mulai"
                                                    className="form-control"
                                                    value={ this.state.detail ? this.state.detail.initial_number_startdate : null}
                                                    //validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                            <label
                                                htmlFor="gender"
                                                className="col-md-1 col-form-label"
                                            >
                                            Sampai dengan
                                            </label>
                                            <Col md="2">
                                                <AvField
                                                    name="initial_number_enddate"
                                                    placeholder="Tanggal Akhir"
                                                    type="date"
                                                    errorMessage="Masukkan Tanggal Akhir"
                                                    className="form-control"
                                                    value={ this.state.detail ? this.state.detail.initial_number_enddate : null}
                                                    //validate={{ required: { value: true } }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="gender"
                                                className="col-md-2 col-form-label"
                                            >
                                            .
                                            </label>
                                            <Col md="9">
                                                <Link to="/master/initialnomor.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="success" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Data
                                                </Button>                                               
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/master/initialnomor.html'/>                
                : null }
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);