import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import { Row, Col, Table } from "reactstrap"

const Doughnut = ({
  process = 0,
  done =  0,
  colors = ["#191970", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
  height = "120px",
  title = 'Sample Chart',
  name = ''
}) => {
  const options = [
    { value: process, name: "Proses" },
    { value: done, name: "Selesai" },
  ]

  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
        //   legend: {
        //     orient: "vertical",
        //     x: "left",
        //     data: ["Laptop", "Tablet", "Mobile", "Others", "Desktop"],
        //     textStyle: {
        //       color: ["#74788d"],
        //     },
        //   },
      color: ["#FF7F50","#1E90FF"],
      series: [
        {
          name: "Surat Masuk",
          type: "pie",
          radius: "75%",
          center: ["50%", "50%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: "15",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: options,
        },
      ],
    }
  }
  return (
      <React.Fragment>
        <Row>
            <Col md={6}>
                <ReactEcharts style={{ height: height}} option={getOption()} />
            </Col>
            <Col md={6}>
                <div className="table-responsive mt-4">
                    <table className="table table-striped" style={{fontSize:'12px'}}>
                        {/* <thead>
                            <tr>
                                <th className="table-noborder">Nama</th>
                                <th className="table-noborder">Jumlah</th>
                            </tr>
                        </thead> */}
                        <tbody>
                        {
                            options.length > 0 ? 
                                options.map((data, index) => {
                                    return (
                                        <tr key={`tr-${index}`}>
                                            <td className="table-noborder" style={{padding:"4px"}}>{data.name}</td>
                                            <td className="table-noborder" style={{padding:"4px"}}>{data.value}</td>
                                        </tr>
                                    );
                                })
                               
                            : 
                            <tr>
                                <td colSpan="2" className="text-center">Tidak Ada Data</td>
                            </tr>
                        }
                        <tr >
                            <td className="table-noborder" style={{padding:"4px"}}><b>Total</b></td>
                            <td className="table-noborder" style={{padding:"4px"}}><b>{process+done}</b></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
      </React.Fragment>
    )
}

export default Doughnut
