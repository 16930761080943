import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Modal, Tooltip
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { Preview,generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router"
import moment from "moment"
import 'moment/locale/id'
import PreviewLetterOutPdf from '../../../../helpers/ui/outbox/PreviewLetterOutPdf'
import apiResponse from '../../../../services/apiResponse'
import DetailBloc from './Blocs/DetailBloc'

class DetailView extends Component {

    detailBloc = new DetailBloc()

    constructor(props) {
        super(props)
        this.state = {
            detail: {},
            dataForm: {},
            submitStatus: 0,
            submitProses: 0,
            textconfirm : "Apakah anda yakin akan membatalkan pegawai ini?",
            logsurat: [],
            receiver: [],
            jmlReceiver : 0,
            jmlCopy : 0,
            showPrevFile: false,
            showReferenceLetter: false,
            loading: true,
            thisQrcode:""
        }
    }


    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, 'document') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        await this.detailBloc.fetchCancel(formData)
    }

    handleCancel = (id) => {
        //console.log(s);
        this.setState({
            dataForm: {
                uuid: id,
                qrcode: this.state.detail.letter_out_qrcode
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan membatalkan pegawai ini ?</span> </Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            thisQrcode:this.state.detail.letter_out_qrcode,
            confirm: true
        })

    }

    handlePrevPdf = () => {
        this.setState({
            showPrevFile : true
        })
    }
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                ...values
            },
            confirm: true
        })
    }
    handleShowReferenceLetter = () => {
        //console.log(option);
        this.setState ({
            showReferenceLetter : true
        })
    }
    hideLoad = () => {
        //alert("close frame");
        this.setState({
          loading: false
        });
    };
    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }

                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.letter_out,
                            surattugas: response.assignment,
                            partisipan: response.participant,
                            urlPdf: response.letter_out.letter_out_filepath, // sudah
                            loading: false,
                            thisQrcode: response.letter_out.letter_out_qrcode
                        })
                        //console.log(this.state.urlPdf);
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.detailBloc.cancelChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const queryParams = new URLSearchParams(this.props.location.search)
                    this.detailBloc.fetchDetail({qrcode: queryParams.get('qrcode') })
                    this.setState({
                        loading: false,
                        success: true,
                        redirect: true
                    });
                    //window.location.reload()
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Detail Surat Keluar</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi Detail Surat Keluar
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                    <Row className="mb-3">
                                        <Col md="5">
                                            <Row className="mb-3 mt-2">
                                                <label className="col-md-4">
                                                    Kode Surat
                                                </label>
                                                <Col md="8">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.letter_out_qrcode !== "" ? this.state.detail.letter_out_qrcode : '-'}</>
                                                    :
                                                        <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Nomor Surat Tugas
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_number !== null ? this.state.detail.letter_out_number : <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>}</>
                                                        : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Untuk/Tujuan
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.surattugas ?
                                                            <>{this.state.surattugas.letter_assignment_description !== "" ? this.state.surattugas.letter_assignment_description : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tanggal Tugas
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.surattugas ?
                                                            <>{this.state.surattugas.letter_assignment_date_bahasa}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Lokasi Penugasan
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.surattugas ?
                                                            <>{(this.state.surattugas.letter_assignment_oversea == 2 ? 'Luar Negeri, ' : (this.state.surattugas.letter_assignment_domicile == 1 ? 'Dalam Kota':'Luar Kota, ') )} {this.state.surattugas.letter_assignment_place}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Rincian Lokasi
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.surattugas ?
                                                            <>{this.state.surattugas.letter_assignment_destination}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Penandatangan
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_sender_name}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Pelaksana Tugas
                                                </label>
                                                <Col md="8">
                                                    {this.state.partisipan ?
                                                        <ol >
                                                        {
                                                            this.state.partisipan.map((value, i) => {
                                                            return  <li >
                                                                        {value.letter_participant_status == 9 ?
                                                                        <del class="" style={{color:'red'}}>{value.position_name_text}</del>
                                                                        :
                                                                        <span>{value.position_name_text}</span>
                                                                        }
                                                                        {' '}
                                                                        {value.letter_participant_status == 1 ?
                                                                            <Button
                                                                            className="btn btn-sm btn-danger"
                                                                            id={"detail-" + value.letter_participant_id}
                                                                            onClick={() => this.handleCancel(value.letter_participant_id)}
                                                                            >
                                                                            <i className="uil-times"></i>
                                                                        </Button>
                                                                        : null
                                                                        }
                                                                    </li>
                                                            })
                                                        } </ol>
                                                        :
                                                        null
                                                    }
                                                </Col>
                                            </Row>

                                            <hr/>
                                            <Row className="mb-3">
                                                <Col md="4">
                                                    <Link to="/laporan/suratkeluar.html">
                                                        <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="7" style={{marginTop: '-16px', height:'980px'}}>

                                            {
                                                this.state.detail ?
                                                    <div>
                                                        <PreviewLetterOutPdf path={this.state.urlPdf} />
                                                    </div>
                                                    :
                                                <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }

                                        </Col>
                                    </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={this.state.showReferenceLetter}
                    toggle={() => {
                        this.setState({
                            showReferenceLetter: this.state.showReferenceLetter
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Informasi Surat Masuk
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Tutup
                            </button>

                        </div>
                </Modal>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.textconfirm}

                    loadingTitle="Memuat data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    // <Redirect to={'/laporan/suratkeluar.html?detail&qrcode=' + this.state.thisQrcode}/>
                    // <Redirect to={'/laporan/suratkeluar.html'}/>
                    null
                : null }
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
