import {
    apiRequest,
    instructionList,
    receiverDisposisiList,
    positionDisposisiList,
    disposisiCreate
} from "../../../../../services/adapters/suratmasuk/dispositionService"

class CreateRepository {

    fetchInstructionList = async (query, cancelToken) => {
        return await apiRequest("get", instructionList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchReceiverDisposisiList = async (query, cancelToken) => {
        return await apiRequest("get", receiverDisposisiList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchPositionDisposisiList = async (query, cancelToken) => {
        return await apiRequest("get", positionDisposisiList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", disposisiCreate, {
            body: query
        }, true)
    }

}

export default CreateRepository