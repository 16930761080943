import axios from 'axios'
import { authContext } from './adalConfig'

import MinioClient from './minioClient'


export const apiCancel = () => {
    return axios.CancelToken.source()
}

/**
 * API request method.
 *
 * @typedef {"get" | "post" | "put" | "patch" | "delete" | "options"} apiWrapper~RequestMethod
 */

/**
 * API request cancel token.
 *
 * @typedef {import("axios").CancelToken} apiWrapper~RequestCancelToken
 */

/**
 * API request data.
 *
 * @typedef {Object} apiWrapper~RequestData
 * @property {any?} params
 * @property {any?} body
 * @property {apiWrapper~RequestCancelToken?} cancelToken
 */

/**
 * API response type.
 *
 * @typedef {"json" | "xml" | "jsonp" | "serialize"} apiWrapper~ResponseType
 */

/**
 * Generic response API.
 *
 * @typedef {Object} apiWrapper~Response
 * @property {boolean} status
 * @property {string} message
 * @property {Object | array} response
 * @property {number} generated
 * @property {number} tokenExpire
 * @property {number} serverTime
 */

/**
 * Wrapper untuk request ke backend service(s).
 *
 * @param {string} baseUrl
 * @param {string} initial
 * @param {string} appCode
 * @param {apiWrapper~ResponseType} outputType
 * @param {apiWrapper~RequestMethod} method
 * @param {string} route
 * @param {apiWrapper~RequestData} data
 * @returns {Promise<apiWrapper~Response | never>}
 */
export const apiWrapper = async (baseUrl, initial, appCode, outputType, method, route, data = { params: null, body: null, cancelToken: null }) => {

    const onSuccess = (response) => {
        if(response.data.tokenExpire) {
            localStorage.setItem(initial + 'accessExpired', response.data.tokenExpire)
        }
        return response.data;
    }

    const onError = (error) => {
        return Promise.reject(error.response || error.message);
    }
    
    var minioError = false;
    if(data.body !== null) {
        
        if(data.body instanceof FormData) {
            var newBody = new FormData();
            const isFile = input => 'File' in window && input instanceof File;

            var numberField = {};
            for (var pair of data.body.entries()) {
                if(!isFile(pair[1])) {
                    newBody.append(pair[0], pair[1])
                } else {
                    if(typeof(pair[1].customName) != "undefined") {
                        const minioClient = new MinioClient();
                        var currentField = pair[0].replace('[]', '')
                        numberField[currentField] = (!numberField[currentField]) ? 0 : numberField[currentField]
                        var dateObj = new Date();
                        var strmonth = (dateObj.getMonth()+1) + ""
                        var folderminio = "surat-lampiran/"+dateObj.getFullYear() + "/" + strmonth.padStart(2,"0") + "/"
                        var filePathName = folderminio +'attachment_' + new Date().getTime() + '.' + pair[1].name.split('.').pop()
                        var fileName = filePathName.split('/').pop()
                        
                        var uploadStatus = await minioClient.put(filePathName, pair[1])
                        if(uploadStatus) {
                            newBody.append(currentField+'['+numberField[currentField]+'][name]', fileName)
                            newBody.append(currentField+'['+numberField[currentField]+'][path]', filePathName)
                            newBody.append(currentField+'['+numberField[currentField]+'][size]', pair[1].size)
                            newBody.append(currentField+'['+numberField[currentField]+'][mime]', pair[1].type)
                            newBody.append(currentField+'['+numberField[currentField]+'][original]', pair[1].name)
                            newBody.append(currentField+'['+numberField[currentField]+'][ext]', pair[1].name.split('.').pop())
                        } else {
                            minioError = true
                        }

                        ++numberField[currentField]
                    } else {
                        newBody.append(pair[0], pair[1])
                    }
                }
            }
            data.body = newBody
        }
    }

    if(minioError) {
        return Promise.reject("405" || "minio Error");
    } else {
        return axios.create({
            baseURL: baseUrl,
            headers: {
                'initial'       : initial,
                'appCode'       : appCode,
                'email'         : authContext.getCachedUser()?.userName,
                'accessToken'   : localStorage.getItem(initial + 'accessToken'),
                'tokenId'       : localStorage.getItem('tokenId'),
                'accessId'      : localStorage.getItem('currentRoleId')
            }
        })({
            method,
            url: route + outputType,
            params: data.params,
            data: data.body,
            cancelToken: data.cancelToken
        }).then(onSuccess)
        .catch(onError);
    }
}
