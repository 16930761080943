import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvField } from 'availity-reactstrap-validation'

const CustomSelect = (props) => {
    const [ selectedtValue, setSelectedValue ] = useState();
    const { options, name, errorMessage, defaultValue } = props

    useEffect(() => {
        defaultVal()

    }, [defaultValue])

    const handleChange = (values) => {
        setSelectedValue(values)
    }

    const defaultVal = () => {
        const filter = options.filter(option => option.value === defaultValue)
        setSelectedValue(filter[0])
    }

    return (
        <>
            <Select 
                options={options} 
                value={selectedtValue} 
                onChange={handleChange}
                {...props} 
            />
            <AvField 
                errorMessage={errorMessage}
                name={name}
                type="hidden" 
                value={selectedtValue?.value} 
                validate={{ required: { value: true } }}
            />
        </>
    )
}

export default CustomSelect;