import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput,Preview,generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router";
import moment from "moment"
import 'moment/locale/id'

import apiResponse from '../../../../services/apiResponse'

import DetailBloc from './Blocs/DetailBloc'
import ErrorEmpty from '../../../../components/Error/Empty'

/**
 * @param {string[]} array
 * @param {string} glue
 * @param {string} finalGlue
 * @param {boolean} oxfordComma
 * @return {string}
 */
const natural_join = (array, glue = ', ', finalGlue = ' dan ', oxfordComma = true) => {
    const lastItem = array.pop()

    if (array.length <= 0) {
        return lastItem
    }

    const join = array.join(glue)
    const lastComma = oxfordComma && array.length >= 2 ? ',' : ''

    return `${join}${lastComma}${finalGlue}${lastItem}`
}

class DetailView extends Component {

    detailBloc = new DetailBloc()

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {},
            submitStatus: 0,
            submitProses: 0,
            textconfirm : "Apakah anda yakin akan menyimpan data ini?",
            detail: {},
            receiver: {},
            //attachment: [],
            disposition: {},
            dispositionForwardReceivers: [],
            attachmentdisposition: [],
            attachmentresponse: [],
            responseStatus: 0,
            logsurat: [],
            showInputCancelDisposition: false,
            mysession: JSON.parse(localStorage.getItem('userdata') || '[]'),
            hasAccess: true,
        }
    }
    submitData = async () => {
        console.log("ZSZSZSZSZSZSZSZSZS");
        console.log(this.state.dataForm);
        console.log("XXXXXXXXXXXXXXXXXXXX");
        const formData = generateFormData(this.state.dataForm, 'document') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        //console.log(formData);
        await this.detailBloc.fetchResponse(formData)
    }

    showInputCancelDisposition = (show = true) => {
        this.setState({ showInputCancelDisposition: show })
    }

    handleJawabDisposisi = (s) => {
        //console.log(s);
        //alert(s);
        this.setState({
            responseStatus: s
        })

    }

    handleSaveDraft = (s) => {
        //console.log(s);
        this.setState({
            submitStatus: s
        })
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()

        this.setState({
            dataForm: {
                // uuid: this.state.dataForm.employee_uuid,
                disposition_status_process: this.state.submitStatus,
                disposition_code: this.state.disposition.disposition_code,
                ...values
            },
            confirm: true,
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.letter_in,
                            receiver: response.receiver,
                            attachment: response.attachment,
                            disposition: response.disposition,
                            dispositionForwardReceivers: response?.disposition_forward_receivers || [],
                            attachmentdisposition: response.attachmentdisposition,
                            attachmentresponse : response.attachmentresponse,
                            logsurat: response.logs,
                            hasAccess: (
                              // Surat masuk valid
                              response.letter_in && Number(response.letter_in.letter_in_status) !== 9
                            ) && (
                              // Disposisi valid
                              response.disposition && Number(response.disposition.disposition_status) !== 9
                            ),
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.detailBloc.responseChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }

    render() {
        if (! this.state.hasAccess) {
            return <ErrorEmpty/>
        }

        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Detil Disposisi</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi Detil Surat Masuk
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Nomor Agenda
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_agenda_number !== "" ? this.state.detail.letter_in_agenda_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Nomor Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_number !== "" ? this.state.detail.letter_in_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Sifat Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.priority_name !== "" ? this.state.detail.priority_name : '-'}</>
                                                : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Tanggal Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_date !== "" ?  moment(this.state.detail.letter_in_date).format("DD MMMM YYYY") : '-'}</>
                                                : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Tanggal Diterima
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_receive_date !== "" ? moment(this.state.detail.letter_in_receive_date).format("DD MMMM YYYY") : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Lampiran
                                        </label>
                                        <Col md="9">
                                            {this.state.detail
                                                ? this.state.detail.letter_in_number_of_attachment
                                                    ? `${this.state.detail.letter_in_number_of_attachment} ${this.state.detail.attachment_type_name}`
                                                    : '-'
                                                : (
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Jenis Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_type_name !== "" ? this.state.detail.letter_type_name : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Asal Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_sender !== "" ? this.state.detail.letter_in_sender : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Perihal
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_title !== "" ? this.state.detail.letter_in_title : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Catatan Mengenai Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_notes !== "" ? this.state.detail.letter_in_notes : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Unit yang dituju
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.receiver ?
                                                    <>{this.state.receiver.work_unit_name !== "" ? this.state.receiver.work_unit_name: '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            File Lampiran
                                        </label>
                                        <Col md="9">
                                        {this.state.attachment != null ?
                                            <Preview
                                                value={this.state.attachment}
                                                fileName='attachment_filename'
                                                filePath='attachment_filepath'
                                                fileMimeType='attachment_mime'
                                                fileSource='attachment_source'
                                            />
                                            : null
                                        }
                                        </Col>
                                    </Row>
                                    <CardSubtitle className="mb-3">
                                        Informasi Detil Disposisi
                                    </CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Dari
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.disposition ?
                                                    <>{this.state.disposition.disposition_sender !== "" ? this.state.disposition.disposition_sender : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Kepada
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.disposition ?
                                                    <>{this.state.disposition.disposition_receiver !== "" ? this.state.disposition.disposition_receiver : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            CC Kepada
                                        </label>
                                        <Col md="9">
                                            {this.state.dispositionForwardReceivers?.length >= 1 ? <>
                                                {natural_join(
                                                  this.state.dispositionForwardReceivers
                                                    .map(({ position_name_text }) => position_name_text)
                                                )}
                                            </> : '-'}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Perintah Disposisi
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.disposition ?
                                                    <>{this.state.disposition.disposition_instruction !== "" ? this.state.disposition.disposition_instruction : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Catatan Disposisi
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.disposition ?
                                                    <>{this.state.disposition.disposition_note !== "" ? this.state.disposition.disposition_note : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            File Lampiran Disposisi
                                        </label>
                                        <Col md="9">
                                        {this.state.attachmentdisposition.length > 0 ?
                                            <Preview
                                                value={this.state.attachmentdisposition}
                                                fileName='attachment_filename'
                                                filePath='attachment_filepath'
                                                fileMimeType='attachment_mime'
                                                fileSource='attachment_source'
                                            />
                                            : '-'
                                        }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Respon Disposisi { this.state.responseStatus == 1 ? <span className="text-danger">*</span> : null }
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.responseStatus == 0 ?
                                                this.state.disposition ?
                                                    <>{this.state.disposition.disposition_response !== null ? this.state.disposition.disposition_response : <span class="badge bg-secondary font-size-14">Belum ada Respon</span>}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                :
                                                <AvField
                                                    name="disposition_response"
                                                    placeholder="Isi Respon / Tanggapan"
                                                    type="textarea"
                                                    errorMessage="Masukkan Respon Disposisi"
                                                    className="form-control"
                                                    id="disposition_response"
                                                    value={this.state.disposition.disposition_response}
                                                    validate={{ required: { value: true } }}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    {
                                    this.state.responseStatus == 1 ?
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            File Lampiran
                                        </label>
                                        <Col md="9">
                                            <FileInput
                                                name="document"
                                                //validate={{ required: { value: true } }}
                                                isMultiple
                                            />
                                        </Col>
                                    </Row>
                                    : null
                                    }

                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                        File Lampiran Respon Disposisi
                                        </label>
                                        <Col md="9">
                                        {this.state.attachmentresponse.length > 0 ?
                                            <Preview
                                                value={this.state.attachmentresponse}
                                                fileName='attachment_filename'
                                                filePath='attachment_filepath'
                                                fileMimeType='attachment_mime'
                                                fileSource='attachment_source'
                                            />
                                            : '-'
                                        }
                                        </Col>
                                    </Row>

                                    <hr/>

                                        {! this.state.showInputCancelDisposition ? <>
                                            <Row className="mb-3">
                                                <Col md={{ size: 9, offset: 3 }}>
                                                    {(
                                                      this.state.mysession?.employee_nip && this.state.disposition
                                                      // Dikirim oleh pegawai sekarang
                                                      && this.state.disposition.disposition_nip_from === this.state.mysession.employee_nip
                                                      // Dan disposisi belum dicancel
                                                      && Number(this.state.disposition.disposition_status_process) !== 9
                                                    ) ? <>
                                                        <Button
                                                          color="warning"
                                                          className="me-2"
                                                          onClick={this.showInputCancelDisposition}
                                                        >
                                                            <i className="uil-file-times me-2"></i>
                                                            Tarik/Batal Disposisi
                                                        </Button>
                                                    </> : null}

                                                    {Number(this.state.responseStatus) === 1 ? <>
                                                        <Button
                                                          color="danger"
                                                          type="button"
                                                          onClick={() => this.handleJawabDisposisi(0)}
                                                        >
                                                            <i className="uil-arrow-up-right me-2"></i>
                                                            Batal
                                                        </Button>
                                                    </> : <>
                                                        <Link to="/disposition/disposisiterkirim.html">
                                                            <Button
                                                              color="danger"
                                                              type="button"
                                                            >
                                                                <i className="uil-arrow-left me-2"></i>
                                                                Kembali ke daftar
                                                            </Button>
                                                        </Link>
                                                    </>}
                                                </Col>
                                            </Row>
                                        </> : <>
                                            <Row className="mb-3">
                                                <label className="col-md-3">
                                                    Catatan Tarik/Batal Disposisi
                                                    <span className="text-danger ms-1">*</span>
                                                </label>

                                                <Col md={9}>
                                                    <AvField
                                                      type="textarea"
                                                      className="form-control"
                                                      id="disposition_cancel_note"
                                                      name="disposition_cancel_note"
                                                      placeholder="Catatan Tarik/Batal Disposisi ..."
                                                      errorMessage="Masukkan Catatan Tarik/Batal Disposisi"
                                                      validate={{ required: { value: true } }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={{ size: 9, offset: 3 }}>
                                                    <Button
                                                      color="warning"
                                                      type="submit"
                                                      className="me-2"
                                                      onClick={() => this.handleSaveDraft(9)}
                                                    >
                                                        <i className="uil-file-times me-2"></i>
                                                        Tarik/Batal Disposisi
                                                    </Button>

                                                    <Button
                                                      color="danger"
                                                      onClick={() => this.showInputCancelDisposition(false)}
                                                    >
                                                        <i className="uil-arrow-left me-2"></i>
                                                        Batal
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>}
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle>Riwayat Surat</CardTitle>
                                    <hr />
                                    <ul className="verti-timeline list-unstyled">
                                    {
                                            this.state.logsurat.map(function (value, i) {
                                                return  <li key={i} className="event-list" style={{paddingBottom:'5px'}}>
                                                        <div className="event-date text-primar">{moment(value.histories_date).format("DD MMM")}</div>
                                                        <p className="text-muted" style={{fontSize:'12px', marginBottom:'5px'}}>{moment(value.histories_date).format("YYYY, HH:mm:ss")}</p>
                                                        <h6 style={{ whiteSpace: 'pre-line' }}>{value.histories_desc}</h6>
                                                        <p className="text-muted" style={{fontSize:'12px'}}>{value.histories_created}</p>
                                                        </li>

                                            })
                                        }
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.textconfirm}

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/disposition/disposisiterkirim.html'/>
                : null }
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
