import {
    apiRequest,
    penomoranList,
    letterTypeList,
    unitList,
    requestNomor,
    getLastNomor,
    getDrafter
} from "../../../../../services/adapters/common/laporanService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", penomoranList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchLetterTypeList = async (query, cancelToken) => {
        return await apiRequest("get", letterTypeList, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchUnitList = async (query, cancelToken) => {
        return await apiRequest("get", unitList, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchRequestNomor = async (query, cancelToken) => {
        return await apiRequest("post", requestNomor, {
            body: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchGetLastNomor = async (query, cancelToken) => {
        return await apiRequest("post", getLastNomor, {
            body: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchGetDrafter = async (query, cancelToken) => {
        return await apiRequest("get", getDrafter, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }

    
}

export default DataTableRepository