import {
    apiRequest,
    daftarSuratList
} from "../../../../../services/adapters/suratkeluar/outboxService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", daftarSuratList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

   
}

export default DataTableRepository