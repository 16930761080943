import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import SimpleBar from 'simplebar-react'

import { connect } from 'react-redux'

import { Link, useHistory, withRouter } from 'react-router-dom'

import TimeAgo from 'react-timeago'
import idStrings from 'react-timeago/lib/language-strings/id'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import {
  apiRequest as baseApi,
  notificationFlagRead,
  notificationFlagReadAll,
} from '../../../services/adapters/base'
import store from '../../../store'
import { changeNotificationCount, changeNotificationList } from '../../../store/notification/actions'

const NotificationDropdown = props => {

  let history = useHistory();
  const formatter = buildFormatter(idStrings)

  const notifCount = parseInt(props.notificationCount)
  const notifList = props.notificationList
  const [menu, setMenu] = useState(false)
  const [gotoLink, setGotoLink] = useState(null)
  const [loadingReadAllFlag, setLoadingReadAllFlag] = useState(false)

  async function setFlag(notification_id, link) {
    await baseApi("post", notificationFlagRead, {body: {notification_id : notification_id}})
    .then(result => {
      console.log('success', result)
      //history.replace(link)
      window.open(link,'_self')
    })
    .catch(result => {
      console.log('failed', result)
    })
  }

  async function setFlagReadAll() {
    await baseApi('post', notificationFlagReadAll)
      .then(result => {
        console.debug('success', result)

        setLoadingReadAllFlag(false)
        store.dispatch(changeNotificationCount(0))
        store.dispatch(changeNotificationList([]))
      })
      .catch(result => {
        console.debug('failed', result)
      })
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell"></i>
          { notifCount > 0 ? <span className="badge bg-danger rounded-pill">{notifCount}</span> : null }
        </DropdownToggle>

        { notifCount > 0 ? <>
          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col className="d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-size-16">Notifikasi</h6>
                  <button
                    className={`btn btn-sm btn-link font-size-14 position-relative ${loadingReadAllFlag ? 'disabled' : ''}`}
                    onClick={() => {
                      setFlagReadAll()
                      setLoadingReadAllFlag(true)
                    }}
                  >
                    Tandai sudah dibaca

                    {loadingReadAllFlag && (
                      <span className="spinner-grow spinner-grow-sm position-absolute" style={{ inset: 0 }}></span>
                    )}
                  </button>
                </Col>
              </Row>
            </div>

            <SimpleBar>
              { notifList.length > 0 ? notifList.map((notif) => {
                return (
                  <Fragment key={notif.notification_id}>
                    {/* <Link to={notif.notification_link} className="text-reset notification-item"> */}
                    <div className="text-reset notification-item" style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if(notif.notification_read === 0) {
                            // this.setState({
                            //     loadingTable: true,
                            //     notificationSelectedLink: record.notification_link
                            // }, async () => {
                            //     await this.dataTableBloc.fetchFlagRead({'notification_id': record.notification_id})
                            // })
                            setFlag(notif.notification_id, notif.notification_link)
                        } else {
                            setGotoLink(notif.notification_link)
                        }
                      }}
                    >

                      <div className="d-flex align-items-start">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="uil-align-center-v"></i>
                          </span>
                        </div>
                        <div className="flex-1">
                          <h6 className="mt-0 mb-1">
                            { notif.notification_title }
                          </h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              { notif.notification_description }
                            </p>
                            <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                              <TimeAgo date={ notif.notification_date } formatter={formatter} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </Fragment>
                )
              }) : <></> }
            </SimpleBar>
            <div className="p-2 border-top d-grid">
              <Link
                className="btn btn-sm btn-link font-size-14 text-center"
                to="/home/notification.html"
              ><i className="uil-arrow-circle-right me-1"></i>
                Lihat Semua Notifikasi
              </Link>
            </div>
          </DropdownMenu>
        </> : null }
      </Dropdown>
    </>
  )
}

NotificationDropdown.propTypes = {
  notificationCount: PropTypes.number,
  notificationList: PropTypes.any
}
const mapStatetoProps = state => {
  return {
    ...state.Layout,
    ...state.Notification
  }
}
export default withRouter(
  connect(mapStatetoProps, {})(NotificationDropdown)
)

/* export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
} */
