import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {

    createChannel = new Subject()
    trainingListChannel = new Subject()
    letterPriorityListChannel = new Subject()
    letterTypeListChannel = new Subject()
    attachmentTypeListChannel = new Subject()
    originSenderListChannel = new Subject()
    instansiListChannel = new Subject()
    redactionListChannel = new Subject()
    unitListChannel = new Subject()
    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.letterPriorityListChannel.next({ status: apiResponse.INITIAL })
        this.letterTypeListChannel.next({ status: apiResponse.INITIAL })
        this.attachmentTypeListChannel.next({ status: apiResponse.INITIAL })
        this.originSenderListChannel.next({ status: apiResponse.INITIAL })
        this.redactionListChannel.next({ status: apiResponse.INITIAL })
        this.instansiListChannel.next({ status: apiResponse.INITIAL })
        this.unitListChannel.next({ status: apiResponse.INITIAL })
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchLetterPriorityList = async (query) => {
        this.letterPriorityListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchLetterPriorityList(query).then((result) => {
                this.letterPriorityListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.letterPriorityListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchLetterTypeList = async (query) => {
        this.letterTypeListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchLetterTypeList(query).then((result) => {
                this.letterTypeListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.letterTypeListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchAttachmentTypeList = async (query) => {
        this.attachmentTypeListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchAttachmentTypeList(query).then((result) => {
                this.attachmentTypeListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.attachmentTypeListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchOriginSenderList = async (query) => {
        this.originSenderListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchOriginSenderList(query).then((result) => {
                this.originSenderListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.originSenderListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchRedactionList = async (query) => {
        this.redactionListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRedactionList(query).then((result) => {
                this.redactionListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.redactionListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchInstansiList = async (query) => {
        this.instansiListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchInstansiList(query).then((result) => {
                this.instansiListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.instansiListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchUnitList = async (query) => {
        this.unitListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchUnitList(query).then((result) => {
                this.unitListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.unitListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }


}

export default CreateBloc