import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, useState, useRef, useEffect  } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Modal,
    Input
} from "reactstrap"
import Select from "react-select"
import TableActiveComponent from './Components/TableActiveComponent'
import DataTableBloc from "./Blocs/DataTableBloc"
import apiResponse from "../../../../services/apiResponse"
var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];
  
      return newObj;
    });
};
var reformatOptions2 = function(raw, label, value, code, parent) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];
      newObj["code"] = data[code];
      newObj["parent"] = data[parent];
      return newObj;
    });
};
const mysession = JSON.parse(localStorage.getItem('userdata'))

const IndexView = (props) => {
    let dataTableBloc = new DataTableBloc();
    const [activeTab, setactiveTab] = useState("1")    
    const activeRef = useRef();
    const inactiveRef = useRef();

    //const reloadActiveRef = () => activeRef.current.setTokenAPI()
    //const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()
    const [optionLetterType, setOptionLetterType] = useState([
    ]);
    const [optionUnitList, setOptionUnitList] = useState([
    ]);
    const [optionDraftList, setOptionDraftList] = useState([
    ]);
    const [optionStatus, setOptionStatus] = useState([
        { value: '0', label : 'Belum Input'},
        { value: '1', label : 'Sudah Input'}, 
    ]);
    const [selectedUnit, setSelectedUnit] = useState("")
    const [selectedType, setSelectedType] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("")
    const [modalBooking, setModalBooking] = useState(false)
    const [modalFailed, setModalFailed] = useState(false)
    const [infoGagal, setInfoGagal] = useState("")
    const [lastNomor, setLastNomor] = useState("")
    const [formDrafter, setFormDrafter] = useState(false)
    const [selectedDrafter, setSelectedDrafter] = useState("")

    const handleSelectUnit = (selectedUnit) => {
        if(selectedUnit != null){
            if(selectedUnit.code == 11 && selectedUnit.parent != '0'){
                dataTableBloc.fetchGetDrafter({
                    parentid : selectedUnit.value
                });
                dataTableBloc.getDrafterChannel.subscribe((result) => {
                    switch (result.status) {
                        case apiResponse.COMPLETED:
                            if(result.data.status) {
                                let response = result.data.response
                                //console.log(response)
                                setOptionDraftList(
                                    reformatOptions( response.result, 
                                        'work_unit_name',
                                        'work_unit_uuid'
                                        )
                                )                    
                            }
                            break
                        case apiResponse.ERROR:
                            
                            break
                        default:
                            break
                    }
                });
                setFormDrafter(true)
            }else{
                setFormDrafter(false)
                setSelectedDrafter("")
            }
        }else{
            setFormDrafter(false)
            setSelectedDrafter("")
        }
        setSelectedUnit(selectedUnit)
        
        
    }

    const handleSelectDrafter = (selected) => {
        setSelectedDrafter(selected)
    }
    const handleSelectType = (selectedType) => {
        setSelectedType(selectedType)
    }

    const handleSelectStatus = (selectedStatus) => {
        setSelectedStatus(selectedStatus)
    }

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }
    useEffect( () => {
        dataTableBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        setOptionUnitList(
                            reformatOptions2( response.result, 
                                'work_unit_name',
                                'work_unit_uuid',
                                'work_unit_echelon_code',
                                'work_unit_parent_uuid',
                                )
                        )                    
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        dataTableBloc.fetchUnitList({})

        dataTableBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        setOptionLetterType(
                            reformatOptions( response.result, 
                                'letter_type_name',
                                'letter_type_id')
                        )     
                        
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        dataTableBloc.fetchLetterTypeList({})
        //setSelectedUnit(mysession.employee_current_work_unit_uuid)
       
    }, [])

    // Filter
    //const [nip, setNip] = useState('')
    //const [name, setName] = useState('')
    const [tgl_awal, setTglAwal] = useState('')
    const [tgl_akhir, setTglAkhir] = useState('')
    let additionalFilter = {
        unitid: (selectedUnit ? selectedUnit.value : "") || "",
        lettertype: (selectedType ? selectedType.value : "") || "",
        status: (selectedStatus ? selectedStatus.value : "") || "",
        drafterid: (selectedDrafter ? selectedDrafter.value : "") || "",
        tglawal : tgl_awal,
        tglakhir : tgl_akhir
    };

    const reloadActiveRef = () => activeRef.current.setTokenAPI()
    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()

    function handleButtonSearch() {
      reloadActiveRef()
    }

    function handleButtonReset() {
        setTglAwal('');
        setTglAkhir('');
        setSelectedUnit('')
        setSelectedType('')

        setTimeout(function() {
            reloadActiveRef()
        }, 100);
    }
    

    function handleButtonRequestNomor () {
        let e = 0
        if(!selectedUnit){
            e++
        }
        if(!selectedType){
            e++
        }
        if(e == 0){
            dataTableBloc.fetchGetLastNomor({
                ...additionalFilter
            });
            dataTableBloc.getLastNomorChannel.subscribe((result) => {
                switch (result.status) {
                    case apiResponse.COMPLETED:
                        console.log(result)
                        if(result.data.status){
                            setLastNomor(result.data.message)
                            setModalBooking(true)
                        }else{
                            setInfoGagal("Kodefikasi Nomor untuk Unit & Jenis Surat terpilih belum diatur")
                            setModalFailed(true)
                        }
                        
                        //const link = result.data.response
                        //alert(link.file_url);
                        //window.open(link.file_url, "_blank")
                        //window.location.href = link.file_url
                        
                        break
                    case apiResponse.ERROR:
                        
                        break
                    default:
                        break
                }
            });
        }else{
            setInfoGagal("Silahkan pilih Unit Kerja & Jenis Surat terlebih dahulu !")
            setModalFailed(true)
        }
        //        
    }

    function handleRequestNomor(){
        dataTableBloc.fetchRequestNomor({
            ...additionalFilter
        });
        dataTableBloc.requestNomorChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    console.log(result)
                    if(result.data.status){
                        setModalBooking(false)
                        reloadActiveRef()
                    }else{
                        setInfoGagal(result.data.message)
                        setModalBooking(false)
                        setModalFailed(true)
                    }
                    
                    //const link = result.data.response
                    //alert(link.file_url);
                    //window.open(link.file_url, "_blank")
                    //window.location.href = link.file_url
                    
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        });
    }
    
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                {
                                    (accessRole('create')) ?
                                        /*<Link to="/sample.html?create">
                                            <Button color="primary">Tambah Data</Button>
                                        </Link>*/
                                        null
                                    : null
                                }
                                </div>
                                <CardTitle>Penomoran Surat</CardTitle>
                                <hr />
                                <Row className="mb-3">
                                    <label
                                        htmlFor="gender"
                                        className="col-md-2 col-form-label"
                                    >
                                    Unit Kerja
                                    </label>
                                    <Col md="5">
                                        <Select
                                            value={optionUnitList.filter(option => option.value === 
                                                (localStorage.getItem('currentRoleIdreal') < 4 ? (selectedUnit ? selectedUnit.value : "") : mysession.employee_current_work_unit_uuid) )}
                                            onChange={handleSelectUnit}
                                            required="required"
                                            options={optionUnitList}
                                            classNamePrefix="select2-unit"
                                            isDisabled={(localStorage.getItem('currentRoleIdreal') < 4 ? false : true)}
                                            isClearable={true}
                                            placeholder="Pilih Unit kerja"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3" style={formDrafter ? {} : { display: 'none' }}>
                                    <label
                                        htmlFor="gender"
                                        className="col-md-2 col-form-label"
                                    >
                                    Unit Pengaju Draft
                                    </label>
                                    <Col md="5">
                                        <Select
                                            value={optionUnitList.filter(option => option.value === (selectedDrafter ? selectedDrafter.value : ""))}
                                            onChange={handleSelectDrafter}
                                            required="required"
                                            options={optionDraftList}
                                            classNamePrefix="select2-unit"
                                            //isDisabled={(localStorage.getItem('currentRoleIdreal') < 4 ? false : true)}
                                            isClearable={true}
                                            placeholder="Pilih Unit Pengaju Draft"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label
                                        htmlFor="gender"
                                        className="col-md-2 col-form-label"
                                    >
                                    Jenis Surat
                                    </label>
                                    <Col md="5">
                                        <Select
                                            value={optionLetterType.filter(option => 
                                            option.value === (selectedType ? selectedType.value : ""))}
                                            onChange={handleSelectType}
                                            required="required"
                                            options={optionLetterType}
                                            classNamePrefix="select2-unit"
                                            isClearable={true}
                                            placeholder="Pilih Jenis Surat"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label
                                        htmlFor="gender"
                                        className="col-md-2 col-form-label"
                                    >
                                    Status
                                    </label>
                                    <Col md="5">
                                        <Select
                                            value={optionStatus.filter(option => 
                                            option.value === (selectedStatus ? selectedStatus.value : ""))}
                                            onChange={handleSelectStatus}
                                            required="required"
                                            options={optionStatus}
                                            classNamePrefix="select2-unit"
                                            isClearable={true}
                                            placeholder="Pilih Status"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md="2"></Col>
                                    <Col md="10">
                                        <Button 
                                            color="primary" 
                                            type="button"
                                            onClick={() => {
                                                handleButtonSearch()
                                            }}
                                        >
                                            <i className="uil-search-alt"></i> Cari
                                        </Button>
                                        {' '}
                                        <Button 
                                          color="success" 
                                          type="button"
                                          onClick={() => {
                                            handleButtonRequestNomor()
                                          }}>
                                            <i className="uil-sync-exclamation"></i> Ambil Nomor
                                        </Button>
                                        {' '}
                                        {/* <Button 
                                          color="warning" 
                                          type="button"
                                          onClick={() => {
                                              handleButtonReset()
                                          }}>
                                            <i className="uil-refresh"></i> Reset
                                        </Button> */}
                                    </Col>
                                </Row>

                                <hr />
                                <TableActiveComponent ref={activeRef} handleInactiveSuccess={reloadInactiveRef} additionalFilter={additionalFilter}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal
                size="md"
                isOpen={modalBooking}
                toggle={() => {
                    setModalBooking(modalBooking)
                   
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabelzzz"
                    >
                        Ambil Nomor
                    </h5>
                    <button
                        onClick={() => {
                            setModalBooking(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Col md="12">
                        <div className="mb-1">
                        <label >Nomor terakhir yang tercatat dalam sistem <b>{lastNomor}</b>
                        </label>                               
                        </div>
                        <div className="mb-1">
                        <label >Anda yakin akan akan mengambil Nomor & Tanggal Surat sekarang ?
                        </label>                               
                        </div>
                    </Col>             
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            setModalBooking(false)
                        }}
                        className="btn btn-danger waves-effect"
                        data-dismiss="modal"
                        >
                        Batal
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            handleRequestNomor()
                          }}
                        className="btn btn-primary waves-effect"
                        data-dismiss="modal"
                        >
                        Ya Lanjutkan
                        </button>
                    </div>
            </Modal>
            <Modal
                size="md"
                isOpen={modalFailed}
                toggle={() => {
                    setModalFailed(modalFailed)
                   
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabelzzz"
                    >
                        Informasi
                    </h5>
                    <button
                        onClick={() => {
                            setModalFailed(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Col md="12">
                        <div className="mb-1">
                        <label >{infoGagal}
                        </label>                               
                        </div>
                    </Col>             
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            setModalFailed(false)
                        }}
                        className="btn btn-danger waves-effect"
                        data-dismiss="modal"
                        >
                        Tutup
                    </button>                   
                </div>
            </Modal>
            
        </Fragment>
    )
}

export default IndexView