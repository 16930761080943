import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import UpdateRepository from '../Repositories/UpdateRepository'

class UpdateBloc {

    detailChannel = new Subject()
    updateChannel = new Subject()
    instructionListChannel = new Subject()
    receiverDisposisiListChannel = new Subject()
    positionDisposisiListChannel = new Subject()
    repository = new UpdateRepository()

    constructor() {
        this.detailChannel.next({status: apiResponse.INITIAL})
        this.updateChannel.next({ status: apiResponse.INITIAL })
        this.instructionListChannel.next({ status: apiResponse.INITIAL })
        this.receiverDisposisiListChannel.next({ status: apiResponse.INITIAL })
        this.positionDisposisiListChannel.next({ status: apiResponse.INITIAL })  
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchUpdate = async (query) => {
        this.updateChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchUpdate(query).then((result) => {
                this.updateChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.updateChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchInstructionList = async (query) => {
        this.instructionListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchInstructionList(query).then((result) => {
                this.instructionListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.instructionListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchReceiverDisposisiList = async (query) => {
        this.receiverDisposisiListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchReceiverDisposisiList(query).then((result) => {
                this.receiverDisposisiListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.receiverDisposisiListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchPositionDisposisiList = async (query) => {
        this.positionDisposisiListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchPositionDisposisiList(query).then((result) => {
                this.positionDisposisiListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.positionDisposisiListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }


}

export default UpdateBloc