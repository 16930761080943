import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AvField } from 'availity-reactstrap-validation'

const CustomSelect = (props) => {
    const [ selectedtValue, setSelectedValue ] = useState();
    const { 
        options, 
        name, 
        errorMessage, 
        defaultValue, 
        validate, 
        optionLabel = 'label', 
        optionValue = 'value',
        onChange = null,
        onInputChange = null,
        isClearable,
        isSearchable = true,
        isDisabled,
        isLoading = false,
        isRtl,
        isMulti,
        placeholder = "Select...",
        isAvForm = true,
        isOptionDisabled = null
    } = props

    useEffect(() => {
        if(options?.length && defaultValue != undefined) {
            defaultVal()
        }
    }, [defaultValue, options])

    const handleChange = (values) => {
        setSelectedValue(values)
        if(onChange) {
            onChange(values)
        }
    }

    const defaultVal = () => {
        const filter = options.filter(option => option[optionValue] == defaultValue)
        setSelectedValue(filter[0])
    }

    return (
        <>
            <Select 
                options={options} 
                value={selectedtValue} 
                onChange={handleChange}
                getOptionLabel={String(optionLabel).includes('=>') ? optionLabel : opt => opt[optionLabel]}
                getOptionValue={opt => opt[optionValue]}
                onInputChange={onInputChange}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isRtl={isRtl}
                isMulti={isMulti}
                placeholder={placeholder}
                isOptionDisabled={isOptionDisabled}
            />
            { isAvForm && 
                <AvField 
                    errorMessage={errorMessage}
                    name={name}
                    type="hidden" 
                    value={selectedtValue?.[optionValue]} 
                    validate={validate}
                />
            }
        </>
    )
}

export default CustomSelect;
