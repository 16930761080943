import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card } from 'reactstrap';
import MinioClient from '../../services/minioClient';

const FilePreview = ({
    value = [],
    col = 2,
    fileName = 'documents_name',
    filePath = 'documents_path',
    fileMimeType = 'documents_mime_type',
    fileSize = 'documents_file_size',
    fileSource = 'documents_source',
    fileExists = 'documents_exists',
    fileDeleted = false,
    fileId = 'documents_id',
    deleteFile = null,
}) => {
    const minioClient = new MinioClient();
    const fileIntervalIds = useRef([]);

    const [files, setFiles] = useState([]);

    const getMinioUrl = (path, index) => {
        return minioClient.get(path).then((url) => {
            setFiles((prev) => {
                const nextValue = [...prev]

                nextValue[index] = { ...nextValue[index], url }

                return nextValue
            });
        });
    };

    const getAllUrl = () => {
        value.forEach((f, i) => {
            if (f[fileSource] === 'minio') {
                getMinioUrl(f[filePath], i).then(() => {
                    const fileIntervalId = setInterval(() => {
                        console.debug(`Try regenerate URL for path ${f[filePath]}`);
                        getMinioUrl(f[filePath], i);
                    }, 550 * 1000);

                    console.debug(`Auto-regenerate file URL registered with ID ${fileIntervalId} for path ${f[filePath]}`);
                    fileIntervalIds.current.push(fileIntervalId);
                });
            } else {
                setFiles((prev) => {
                    const nextValue = [...prev]

                    nextValue[i] = { ...nextValue[i], url: f[filePath] }

                    return nextValue
                });
            }

            setFiles((prev) => {
                const nextValue = [...prev]

                nextValue[i] = { ...nextValue[i], exists: f[fileExists] || true }

                return nextValue
            });
        });
    };

    useEffect(() => {
        getAllUrl();

        return () => {
            fileIntervalIds.current.forEach(clearInterval);
        };
    }, [value]);

    return (
        <>
            {value.length > 0 ? (
                <Row className="dropzone-previews" id="file-previews">
                    {value.map((f, i) => (
                        files[i]?.url && (
                            <Col md={col} key={i}>
                                <Card style={{ alignItems: 'center', textAlign: 'center', padding: 10 }}>
                                    <Row className="align-items-center" style={{ width: '-webkit-fill-available' }}>
                                        <Col md="12" style={{ padding: 10 }}>
                                            {f[fileMimeType] ? (
                                                <>
                                                    {f[fileMimeType]?.includes('image') && (
                                                        <img
                                                            data-dz-thumbnail=""
                                                            style={{ maxHeight: 400, width: 'inherit', objectFit: 'cover' }}
                                                            className="rounded bg-light"
                                                            alt={f[fileName]}
                                                            src={files[i].url}
                                                        />
                                                    )}

                                                    {f[fileMimeType]?.includes('pdf') && (
                                                        <i className="bx bxs-file-pdf avatar-sm rounded" style={{ fontSize: 60 }}></i>
                                                    )}

                                                    {!f[fileMimeType]?.includes('image') && !f[fileMimeType].includes('pdf') && (
                                                        <i className="bx bxs-file avatar-sm rounded" style={{ fontSize: 60 }}></i>
                                                    )}
                                                </>
                                            ) : (
                                                <i className="bx bxs-file avatar-sm rounded" style={{ fontSize: 60 }}></i>
                                            )}
                                        </Col>

                                        <Col md="12">
                                            {f[fileName].length > 15 ? (
                                                '...' + f[fileName].substring(f[fileName].length - 15)
                                            ) : (
                                                f[fileName]
                                            )}
                                        </Col>

                                        <Col md="12" className="mt-3" style={{ textAlign: 'right' }}>
                                            {files[i]?.exists && (
                                                <a
                                                    href={files[i].url}
                                                    className="btn btn-sm btn-default btn-outline-secondary"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className="uil uil-eye" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                                </a>
                                            )}
                                            {' '}
                                            {fileDeleted && (
                                                <a
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        deleteFile(f[fileId]);
                                                    }}
                                                >
                                                    <i className="uil uil-trash" style={{ fontSize: '20px', cursor: 'pointer' }}></i>
                                                </a>
                                            )}
                                        </Col>

                                        {!files[i]?.exists ? (
                                            <Col md="12">
                                                <i className="text-danger">
                                                    File tidak tersedia.
                                                </i>
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Card>
                            </Col>
                        )
                    ))}
                </Row>
            ) : (
                <Row className="dropzone-previews" id="file-previews">
                    <Col md={col}>
                        <Card style={{ alignItems: 'center', textAlign: 'center', padding: 10 }}>
                            <Row className="align-items-center" style={{ width: '-webkit-fill-available' }}>
                                <Col md="12" style={{ padding: 10 }}>
                                    <i className="uil uil-document-info avatar-sm rounded" style={{ fontSize: 80 }}></i>
                                </Col>
                                <Col md="12">
                                    Tidak ada File
                                    <br />
                                    <label className="text-muted" style={{ fontSize: 12 }}>-</label>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default FilePreview;
