import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, useState, useRef, useEffect  } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"
import Select from "react-select"
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input
} from "reactstrap"
import moment from "moment"
import TableActiveComponent from './Components/TableActiveComponent'


const IndexView = (props) => {
    
    const [activeTab, setactiveTab] = useState("1")    
    const activeRef = useRef();
    const inactiveRef = useRef();

    const reloadActiveRef = () => activeRef.current.setTokenAPI()
    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }
    

    const historySdate = 'historyFilterStartOutboxDraft'
    const historyEdate = 'historyFilterEndOutboxDraft'
    const historyStatus = 'historyFilterStatusOutboxDraft'
    const historyType = 'historyFilterTypeOutboxDraft'
    const [filterStatus, setfilterStatus] = useState((sessionStorage.getItem(historyStatus) ? sessionStorage.getItem(historyStatus) : 0))
    const optionStatus = [
        { value:0, label: "Semua" },
        { value:1, label: "Biasa" },
        { value:2, label: "Segera" },
        { value:3, label: "Rahasia" },
        { value:4, label: "Penting" },
        { value:5, label: "Sangat Penting" }, 
        { value:6, label: "Sangat Segera" },
    ]
    
    const optionLetterType = [
        { value:0, label: "Semua" },
        { value:3, label: "Surat Dinas" },
        { value:4, label: "Nota Dinas" },
        { value:5, label: "Memorandum" },
        { value:6, label: "Surat Tugas" },
        { value:7, label: "Surat Undangan" },
        { value:9, label: "Letter of Intent (LOI)" },
        { value:10, label: "Surat Edaran" },
        { value:12, label: "Surat Keterangan" },
        { value:14, label: "Instruksi" },
        { value:15, label: "Perjanjian Kerjasama" },
        { value:16, label: "Surat Pengantar" },
        { value:17, label: "Pengumuman" },
        { value:18, label: "Nota Kesepahaman" },
        { value:19, label: "Surat Kuasa" },
        { value:20, label: "Berita Acara" },
        { value:21, label: "Surat Keputusan" },
        { value:22, label: "Surat Lainnya" },
    ]

    const sdate = new Date();
    const nowdate = moment(sdate).format('YYYY-MM-DD');
    const startdate = moment(sdate).subtract(30, 'days').format('YYYY-MM-DD');
    const [tgl_awal, setTglAwal] = useState((sessionStorage.getItem(historySdate) ? sessionStorage.getItem(historySdate) : startdate))
    const [tgl_akhir, setTglAkhir] = useState(sessionStorage.getItem(historyEdate) ? sessionStorage.getItem(historyEdate) : nowdate)
    const [selectedType, setSelectedType] = useState((sessionStorage.getItem(historyType) ? sessionStorage.getItem(historyType) : 0))
    function handleButtonSearch() {
        reloadActiveRef()
    }
    let additionalFilter = {
        //startdate : tgl_awal,
        //enddate : tgl_akhir,
        filtersifat: filterStatus,
        filtertype: selectedType,
    }

    function setSdate(event) {
        setTglAwal(event.target.value)
        sessionStorage.setItem(historySdate,event.target.value)
    }
    function setEdate(event) {
        setTglAkhir(event.target.value)
        sessionStorage.setItem(historyEdate,event.target.value)
    }
    const changefilterStatus = (val)=>{
        setfilterStatus(val != null ? val.value : "")
        sessionStorage.setItem(historyStatus,val != null ? val.value : "")
    }

    const handleSelectType = (val) => {
        setSelectedType(val != null ? val.value : "")
        sessionStorage.setItem(historyType,val != null ? val.value : "")
    }

    
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                {
                                    (accessRole('read')) ?
                                        <Link to="daftardraft.html?create">
                                            <Button color="primary"><i className="uil-plus"></i> Tambah Data</Button>
                                        </Link>
                                    : null
                                }
                                </div>
                                <CardTitle>Daftar Draft Surat Keluar</CardTitle>
                                <hr />
                                <Row className="mb-3">
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label"
                                        style={{display:"none"}}
                                    >
                                    Tanggal Terima
                                    </label>
                                    <Col md="2" style={{display:"none"}}>
                                        <Input
                                            name="tgl_awal"
                                            placeholder="Tanggal Surat"
                                            type="date"
                                            value={tgl_awal}
                                            onChange={event => setSdate(event)}
                                        />
                                    </Col>
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label text-center"
                                        style={{display:"none"}}
                                    >
                                    s.d
                                    </label>
                                    <Col md="2" style={{display:"none"}}>
                                        <Input
                                            name="tgl_akhir"
                                            placeholder="Tanggal Surat"
                                            type="date"
                                            value={tgl_akhir}
                                            onChange={event => setEdate(event)}
                                        />
                                    </Col>
                                   
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label"
                                    >
                                    Jenis Surat
                                    </label>
                                    <Col md="2">
                                        
                                        <Select
                                            value={optionLetterType.filter(option => option.value == (selectedType != null ? selectedType : ""))}
                                            onChange={handleSelectType}
                                            required="required"
                                            options={optionLetterType}
                                            //defaultValue={optionLetterType[selectedType]}
                                            classNamePrefix="select2-unit"
                                            //isClearable={true}
                                            placeholder="SEMUA"
                                            
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label float-end"
                                    >
                                    Sifat Surat
                                    </label>
                                    <Col md="2">
                                        <Select 
                                            name="filter_status"
                                            errorMessage="Pilih Status"
                                            options={optionStatus} 
                                            //value={filterStatus}
                                            defaultValue={optionStatus[filterStatus]}
                                            // value={optionStatus.filter(option => 
                                            //     option.value === (filterStatus != null ? filterStatus.value : ""))}
                                            onChange={changefilterStatus}
                                            placeholder="SEMUA"
                                            //isClearable={true}
                                            classNamePrefix="select2-unit"
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Button 
                                            color="primary" 
                                            type="button"
                                            onClick={() => {
                                                handleButtonSearch()
                                            }}
                                        >
                                            <i className="uil-search-alt"></i> Tampilkan
                                        </Button>      
                                    </Col>
                                </Row>
                                <hr />
                                <TableActiveComponent ref={activeRef} handleInactiveSuccess={reloadInactiveRef} additionalFilter={additionalFilter}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView