import { useState } from 'react'
import moment from 'moment'

import { maxDateString, minDateString } from './compareDate'

/**
 * @typedef {Object} ScheduleInterface
 * @property {?this} prev
 * @property {?string} startDate
 * @property {?string} endDate
 */

/**
 * Hapus start date jadwal paling awal dan end date jadwal paling akhir.
 *
 * @template {ScheduleInterface} T
 * @param {T[]} values
 * @return T[]
 */
function unsetOuterDate(values) {
    if (! values) {
        return values
    }

    if (! values.length <= 0) {
        return values
    }

    values[0].startDate = null
    values[values.length - 1].endDate = null

    return values
}

/**
 * @template {ScheduleInterface} T
 * @param {string} minStartDate
 * @param {string} maxEndDate
 * @param {T[]} values
 * @param {(values: unknown[]) => T[]} valueMapper
 * @param {(previous: ?T) => T} creator
 * @param {() => T[]} initCreator
 */
export function useLinkedSchedule ({
    minStartDate,
    maxEndDate,
    values,
    valueMapper,
    creator,
    initCreator = () => [creator(null)],
}) {
    const [schedules, setSchedules] = useState(
        values ? valueMapper(values) : initCreator()
    )

    function add () {
        setSchedules(list => {
            const prev = list.length <= 0 ? null : list[list.length - 1]
            const schedule = creator(prev)

            return [...list, schedule]
        })
    }

    /**
     * @param {int} index
     */
    function removeByIndex (index) {
        setSchedules(list => {
            // Jika bukan schedule terakhir yang dihapus
            if (index < list.length - 1) {
                const prevIndex = index - 1

                list[index + 1].prev = prevIndex < 0 ? null : list[prevIndex]
            }

            return unsetOuterDate(list.toSpliced(index, 1))
        })
    }

    /**
     * @param {int} index
     * @param {T} schedule
     */
    function updateByIndex (index, schedule) {
        setSchedules(list => {
            return list.toSpliced(index, 1, schedule)
        })
    }

    /**
     * Ambil tanggal antara schedule min start date dan max start date.
     *
     * @param {T} schedule
     * @return {string}
     */
    function calculateStartDate (schedule) {
        const minSavedStartDate = maxDateString(schedule.startDate, calculateMinStartDate(schedule))

        return minDateString(minSavedStartDate, calculateMaxStartDate(schedule))
    }

    /**
     * @param {T} schedule
     * @return {string}
     */
    function calculateMinStartDate (schedule) {
        if (schedule.prev === null) {
            return minStartDate
        }

        const prevEndDate = calculateMinEndDate(schedule.prev)

        return maxDateString(prevEndDate, minStartDate)
    }

    /**
     * Schedule min start date + 1 hari, jika memungkinkan.
     *
     * @param {T} schedule
     * @return {string}
     */
    function calculateMaxStartDate (schedule) {
        const scheduleMinStartDate = calculateMinStartDate(schedule)
        const scheduleMaxStartDate = moment(scheduleMinStartDate)
            .add(1, 'day')
            .format(moment.HTML5_FMT.DATE)

        return minDateString(scheduleMaxStartDate, calculateEndDate(schedule))
    }

    /**
     * @param {T} schedule
     * @return {string}
     */
    function calculateMinEndDate (schedule) {
        if (schedule.endDate === null) {
            return maxEndDate
        }

        return minDateString(schedule.endDate, maxEndDate)
    }

    /**
     * @param {T} schedule
     * @return {string}
     */
    function calculateEndDate (schedule) {
        return maxDateString(calculateMinStartDate(schedule), calculateMinEndDate(schedule))
    }

    return {
        schedules,
        setSchedules,

        add,
        removeByIndex,
        updateByIndex,

        calculateStartDate,
        calculateMinStartDate,
        calculateMaxStartDate,
        calculateEndDate,
    }
}
