import {
    apiRequest,
    prosesReview,
    sendReview,
    returnReview,
    signLetter,
    signNonDsLetter,
    cancelLetter,
    requestNomor,
    addattachment,
    deleteattachment,
    suratKeluarDetail,
    cancelparticipant,
    rollbackLetter,
    markDraftRegenerate,
    pdfDraftRegenerate,
} from '../../../../../services/adapters/suratkeluar/outboxService'

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", prosesReview, {
            params: query
        }, true)
    }

    fetchSendReview = async (query) => {
        return await apiRequest("post", sendReview, {
            body: query
        }, true)
    }

    fetchReturnReview = async (query) => {
        return await apiRequest("post", returnReview, {
            body: query
        }, true)
    }

    fetchSignLetter = async (query) => {
        return await apiRequest("post", signLetter, {
            body: query
        }, true)
    }

    fetchSignNonDsLetter = async (query) => {
        return await apiRequest("post", signNonDsLetter, {
            body: query
        }, true)
    }

    fetchRequestNomor = async (query) => {
        return await apiRequest("post", requestNomor, {
            body: query
        }, true)
    }

    fetchCancelLetter = async (query) => {
        return await apiRequest("post", cancelLetter, {
            body: query
        }, true)
    }

    fetchAddAttachment = async (query) => {
        return await apiRequest("post", addattachment, {
            body: query
        }, true)
    }

    fetchDeleteAttachment = async (query) => {
        return await apiRequest("post", deleteattachment, {
            body: query
        }, true)
    }

    fetchCancelParticipant = async (query) => {
        return await apiRequest("post", cancelparticipant, {
            body: query
        }, true)
    }

    fetchRollbackLetter = async (query) => {
        return await apiRequest("post", rollbackLetter, {
            body: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("get", suratKeluarDetail, {
            params: query
        }, true)
    }

    fetchMarkDraftRegenerate = async (query) => {
        return await apiRequest("post", markDraftRegenerate, { body: query }, true)
    }

    fetchPdfDraftRegenerate = async (query) => {
        return await apiRequest("post", pdfDraftRegenerate, { body: query }, true)
    }
}

export default DetailRepository
