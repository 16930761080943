import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'
import CustomSelect from '../../../../helpers/ui/CustomSelect'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];
  
      return newObj;
    });
};


class IndexView extends Component {

    createBloc = new CreateBloc();
    
    constructor(props) {
        const mysession = JSON.parse(localStorage.getItem('userdata'))
        super(props)
        
        this.state = {
            // optionGender : [
            //     { label: "Laki-laki", value: "L" },
            //     { label: "Perempuan", value: "P" }
            // ],
            letterPriorityOption: [],
            letterTypeOption: [],
            attachmentTypeOption: [],
            originSenderOption: [],
            redactionOption: [],
            instansiOption: [],
            unitOption: [],
            multiPenerima:[],
            multiTembusan:[],
            dataForm: {},
            submitStatus: 0,
            LockUnit : (localStorage.getItem('currentRoleIdreal') == 4 ? true : false),
            letter_in_sender_validate : true,
            letter_in_sender_mandatory_teks : "*",
            letter_in_sender_show : true,
            letter_in_sender_detail_validate : true,
            letter_in_sender_detail_mandatory_teks : "*",
            current_work_unit_uuid: mysession.employee_current_work_unit_uuid
        }
        this.handleMultiPenerima = this.handleMultiPenerima.bind(this);
        this.handleMultiTembusan = this.handleMultiTembusan.bind(this);
        //this.state.multiPenerima.push()
    }

    // End Select Area

    submitData = async () => {
        //const formData = generateFormData(this.state.dataForm, 'document') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto'] 
        const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratmasuk/attachment'})
        await this.createBloc.fetchCreate(formData)       
    }

    handleMultiPenerima(option) {
        this.setState({
            multiPenerima: (option != null) ? option : []
        });

        //console.log(option)
    }
    handleMultiTembusan(option) {
        this.setState({
            multiTembusan: (option != null) ? option : []
        });

        //console.log(option)
    }

    handleSaveDraft = (s) => {
        //console.log(s);
        this.setState({
            submitStatus: s
        })
    }

    handleTipeAsalSurat = (option) => {
        //console.log(option);
        let selected = option.value;
        if(selected == 1){
            this.setState ({
                letter_in_sender_validate : false,
                letter_in_sender_mandatory_teks : "",
                letter_in_sender_show : false,
                letter_in_sender_detail_validate : true,
                letter_in_sender_detail_mandatory_teks : "*"
            })     
        }else if(selected == 2){
            this.setState ({
                letter_in_sender_validate : true,
                letter_in_sender_mandatory_teks : "*",
                letter_in_sender_show : true,
                letter_in_sender_detail_validate : false,
                letter_in_sender_detail_mandatory_teks : ""
            })     
            this.getInstansiOptions()
        }else{
            this.setState ({
                letter_in_sender_validate : true,
                letter_in_sender_mandatory_teks : "*",
                letter_in_sender_show : true,
                letter_in_sender_detail_validate : false,
                letter_in_sender_detail_mandatory_teks : ""
            })     
            this.getUnitOptions()
        }
    }

    getInstansiOptions = () => {
        this.createBloc.instansiListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            instansiOption: reformatOptions(
                                response.result, 
                                'agency_name',
                                'agency_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchInstansiList({})
    }

    getUnitOptions = () => {
        this.createBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            instansiOption: reformatOptions(
                                response.result, 
                                'work_unit_name_tabs',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchUnitList({})
    }

    
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.employee_uuid,
                letter_in_status: this.state.submitStatus,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.createBloc.createChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

       
        // get data referensi
        this.createBloc.letterPriorityListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            letterPriorityOption: reformatOptions(
                                response.result, 
                                'priority_name',
                                'priority_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchLetterPriorityList({})

        
        this.createBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            letterTypeOption: reformatOptions(
                                response.result, 
                                'letter_type_name',
                                'letter_type_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchLetterTypeList({})

        this.createBloc.attachmentTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            attachmentTypeOption: reformatOptions(
                                response.result, 
                                'attachment_type_name',
                                'attachment_type_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchAttachmentTypeList({})

        this.createBloc.originSenderListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            originSenderOption: reformatOptions(
                                response.result, 
                                'origin_sender_name',
                                'origin_sender_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchOriginSenderList({})

        this.createBloc.redactionListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            redactionOption: reformatOptions(
                                response.result, 
                                'redaction_name',
                                'redaction_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchRedactionList({})

        // this.createBloc.instansiListChannel.subscribe((result) => {
        //     switch (result.status) {
        //         case apiResponse.COMPLETED:
        //             if(result.data.status) {
        //                 let response = result.data.response
        //                 //console.log(response)
                        
        //                 this.setState({
        //                     instansiOption: reformatOptions(
        //                         response.result, 
        //                         'agency_name',
        //                         'agency_uuid'
        //                     )
        //                 })
        //             }
        //             break
        //         case apiResponse.ERROR:
                    
        //             break
        //         default:
        //             break
        //     }
        // })
        //this.createBloc.fetchInstansiList({})

        this.createBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            unitOption: reformatOptions(
                                response.result, 
                                'work_unit_name',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.createBloc.fetchUnitList({})
        // get data marital status
        
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Tambah Surat Masuk</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row>
                                            <Col md="4">
                                                <div className="mb-3">
                                                <label >Nomor Surat <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_in_number"
                                                    placeholder="No Surat..."
                                                    type="text"
                                                    errorMessage="Masukkan Nomor Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="letter_in_number"
                                                />
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                <label >Sifat Surat <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect 
                                                        name="letter_in_priority"
                                                        errorMessage="Pilih Sifat Surat"
                                                        options={this.state.letterPriorityOption} 
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Pilih Sifat Surat"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                <label >Tanggal Surat <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                        name="letter_in_date"
                                                        placeholder="Tanggal Surat"
                                                        type="date"
                                                        errorMessage="Masukkan Tanggal Surat"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="letter_in_date"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md="4">
                                            <div className="mb-3">
                                            <label >Tanggal Diterima <span className="text-danger">*</span>
                                            </label>
                                            <AvField
                                                name="letter_in_receive_date"
                                                placeholder="Tanggal Diterima"
                                                type="date"
                                                errorMessage="Masukkan Tanggal Surat Diterima"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="letter_in_receive_date"
                                            />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                            <label >Lampiran 
                                            </label>
                                            <AvField
                                                name="letter_in_number_of_attachment"
                                                placeholder="Jumlah"
                                                type="number"
                                                errorMessage="Masukkan Nomor Surat"
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                    pattern: {
                                                      value: "^[0-9]+$",
                                                      errorMessage: "Only Digits",
                                                    },
                                                  }}
                                                id="letter_in_number_of_attachment"
                                            />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                            <label >&nbsp; 
                                            </label>
                                            <CustomSelect 
                                                    name="letter_in_attachment_type"
                                                    errorMessage="Pilih Tipe Berkas"
                                                    options={this.state.attachmentTypeOption} 
                                                    placeholder="Tipe Berkas"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                            <label >Jenis Surat <span className="text-danger">*</span>
                                            </label>
                                            <CustomSelect 
                                                    name="letter_in_type"
                                                    errorMessage="Pilih Jenis Surat"
                                                    options={this.state.letterTypeOption} 
                                                    validate={{ required: { value: true } }}
                                                    placeholder="Pilih Jenis Surat"
                                                />
                                            </div>
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Perihal <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_in_title"
                                                    placeholder="Perihal Surat..."
                                                    type="textarea"
                                                    errorMessage="Masukkan Perihal Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="letter_in_title"
                                                />
                                                </div>
                                            </Col>                                        
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                            <div className="mb-3">
                                                <label >Tipe Asal Surat <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect 
                                                        name="letter_in_sender_type"
                                                        errorMessage="Pilih Tipe Asal Surat"
                                                        options={this.state.originSenderOption} 
                                                        validate={{ required: { value: true } }}
                                                        onChange={this.handleTipeAsalSurat}
                                                        placeholder="Pilih Tipe Asal Surat"
                                                        id="letter_in_sender_type"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="8" style={this.state.letter_in_sender_show ? {} : { display: 'none' }} >
                                                <div className="mb-3">
                                                <label >Asal Surat <span className="text-danger"> {this.state.letter_in_sender_mandatory_teks}</span>
                                                </label>
                                                <CustomSelect 
                                                        name="letter_in_sender"
                                                        errorMessage="Pilih Asal Surat"
                                                        options={this.state.instansiOption} 
                                                        validate={{ required: { value: this.state.letter_in_sender_validate } }}
                                                        placeholder="Pilih Asal Surat"
                                                        id="letter_in_sender"
                                                    />
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Rincian Asal Surat <span className="text-danger">{this.state.letter_in_sender_detail_mandatory_teks}</span>
                                                </label>
                                                <AvField
                                                    name="letter_in_sender_detail"
                                                    placeholder="Rincian Asal Surat ..."
                                                    type="textarea"
                                                    errorMessage="Masukkan Rincian Asal Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: this.state.letter_in_sender_detail_validate } }}
                                                    id="letter_in_sender_detail"
                                                />
                                                </div>
                                            </Col>                                        
                                        </Row>
                                        <Row>
                                            <Col md="8">
                                                <div className="mb-3">
                                                <label >Unit Penerima <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect 
                                                        name="receiver"
                                                        errorMessage="Pilih Unit Kerja Yang DItuju"
                                                        options={this.state.unitOption} 
                                                        //validate={{ required: { value: true } }}
                                                        defaultValue={(this.state.LockUnit ? this.state.current_work_unit_uuid : null)}
                                                        placeholder="Pilih Unit Penerima"
                                                        isDisabled={this.state.LockUnit}
                                                        onChange={this.handleMultiPenerima}
                                                        value={this.state.multiPenerima}
                                                        isMulti="true"
                                                    />
                                                {
                                                    this.state.multiPenerima.length > 0 ?
                                                        this.state.multiPenerima.map((data, index) => {
                                                            return (
                                                                <AvField
                                                                    key={index}
                                                                    name={`letter_in_receiver[${index}]`}
                                                                    type="hidden"
                                                                    errorMessage="Masukkan Filter"
                                                                    className="form-control"
                                                                    //validate={{ required: { value: true } }}
                                                                    value={data.value}
                                                                />
                                                            )
                                                        })
                                                    :
                                                    <AvField
                                                        name='multiselectvalidationXXX'
                                                        type="hidden"
                                                        errorMessage="Masukkan Filter"
                                                        className="form-control"
                                                        //validate={{ required: { value: true } }}
                                                        value={this.state.multiPenerima.length > 0 ? this.state.multiPenerima.length : ""}
                                                    />
                                                }      
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col md="8">
                                                <div className="mb-3">
                                                <label >Tembusan 
                                                </label>
                                                <Select 
                                                        name="letter_in_receiver_copy"
                                                        errorMessage="Pilih Unit Kerja Yang DItuju"
                                                        options={this.state.unitOption} 
                                                        onChange={this.handleMultiTembusan}
                                                        placeholder="Pilih Penerima Tembusan"
                                                        isMulti="true"
                                                        value={this.state.multiTembusan}
                                                        //isDisabled={this.state.LockUnit}
                                                    />
                                                    {
                                                    this.state.multiTembusan.length > 0 ?
                                                        this.state.multiTembusan.map((data, index) => {
                                                            return (
                                                                <AvField
                                                                    key={index}
                                                                    name={`letter_in_receiver_copy[${index}]`}
                                                                    type="hidden"
                                                                    errorMessage="Masukkan Filter"
                                                                    className="form-control"
                                                                    //validate={{ required: { value: true } }}
                                                                    value={data.value}
                                                                />
                                                            )
                                                        })
                                                    :
                                                    <AvField
                                                        name='multiselectvalidation_copy'
                                                        type="hidden"
                                                        errorMessage="Masukkan Filter"
                                                        className="form-control"
                                                        //validate={{ required: { value: true } }}
                                                        value={this.state.multiTembusan.length > 0 ? this.state.multiTembusan.length : ""}
                                                    />
                                                }  
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                            <div className="mb-3">
                                                <label >Catatan Mengenai Surat
                                                </label>
                                                <AvField
                                                    name="letter_in_note"
                                                    placeholder="Catatan"
                                                    type="textarea"
                                                    errorMessage="Masukkan Rincian Asal Surat"
                                                    className="form-control"                                                   
                                                    id="letter_in_note"
                                                />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >File Lampiran <span className="text-danger">*</span>
                                                </label>
                                                <FileInput 
                                                    name="document"
                                                    validate={{ required: { value: true } }}
                                                    isMultiple
                                                />
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Link to="/inbox/daftarterkirim.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="success" type="submit" onClick={() => this.handleSaveDraft(0)}>
                                                    <i className="uil-arrow-up-right"></i> Simpan Draft
                                                </Button>
                                                {' '}
                                                <Button color="primary" type="submit" onClick={() => this.handleSaveDraft(1)}>
                                                    <i className="uil-arrow-up-right"></i> Simpan Kirim
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    this.state.submitStatus == 1 ? 
                    <Redirect to='/inbox/daftarterkirim.html'/>
                    : 
                    <Redirect to='/inbox/daftardraft.html'/>                   
                : null }
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);