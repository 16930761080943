import {
    apiRequest,
    daftarSuratDetail,
    suratMasukReturned
} from "../../../../../services/adapters/suratmasuk/inboxService"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", daftarSuratDetail, {
            params: query
        }, true)
    }

    fetchReturned = async (query) => {
        return await apiRequest("post", suratMasukReturned, {
            body: query
        }, true)
    }
}

export default DetailRepository