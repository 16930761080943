import {
    apiRequest,
    daftarDikembalikanDetail,
    sendReview,
    addattachment,
    deleteattachment,
    cancelLetter

} from "../../../../../services/adapters/suratkeluar/outboxService"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", daftarDikembalikanDetail, {
            params: { ...query, isweb: 1 }
        }, true)
    }

    fetchSendReview = async (query) => {
        return await apiRequest("post", sendReview, {
            body: query
        }, true)
    }

    fetchAddAttachment = async (query) => {
        return await apiRequest("post", addattachment, {
            body: query
        }, true)
    }

    fetchDeleteAttachment = async (query) => {
        return await apiRequest("post", deleteattachment, {
            body: query
        }, true)
    }

    fetchCancelLetter = async (query) => {
        return await apiRequest("post", cancelLetter, {
            body: query
        }, true)
    }
}

export default DetailRepository
