import React, { Fragment, Component } from "react"
import { Redirect } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { ConfirmAlert } from '../../../../helpers/ui/alert'

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Label
} from "reactstrap"


import {
    apiRequest,
    accessCreate
} from '../../../../services/adapters/base'

class CreateView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {
                code: "HKL",
                name: "Hukum"
            }
        }
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    submitData = () => {
        apiRequest('post', accessCreate, {
            body: this.state.dataForm
        }).then(result => {
            console.log(result)
            this.setState({
                loading: false,
                success: true
            })
        })
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: values,
            confirm: true
        })

        
    }

    

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Jurul Form</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Keterangan menu-menu. Lorem ipsum dolor sit amet...
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom01">Code</Label>
                                                    <AvField
                                                        name="code"
                                                        placeholder="First name"
                                                        type="text"
                                                        errorMessage="Enter First Name"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom01"
                                                        value={this.state.dataForm.code}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom02">Name</Label>
                                                    <AvField
                                                        name="name"
                                                        placeholder="Last name"
                                                        type="text"
                                                        errorMessage="Enter Last name"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom02"
                                                        value={this.state.dataForm.name}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit">
                                            Submit form
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menghapus data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/master/role.html'/>
                : null }
            </Fragment>
        )
    }
}
export default CreateView