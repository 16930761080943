import {
    apiRequest,
    sumeries,
    driildown
} from "../../../services/adapters/common/laporanService"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", sumeries, {
            params: query
        }, true)
    }

    fetchDrill = async (query) => {
        return await apiRequest("get", driildown, {
            params: query
        }, true)
    }
}

export default DetailRepository