import {
    apiRequest,
    daftarDikembalikanList
} from "../../../../../services/adapters/suratmasuk/inboxService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", daftarDikembalikanList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

   
}

export default DataTableRepository