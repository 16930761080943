import {
    apiRequest,
    disposisiDetail,
    disposisiResponse
} from "../../../../../services/adapters/suratmasuk/dispositionService"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", disposisiDetail, {
            params: query
        }, true)
    }   

    fetchResponse = async (query) => {
        return await apiRequest("post", disposisiResponse, {
            body: query
        }, true)
    }   
}

export default DetailRepository