import {
    apiRequest,
    suratmasukList,
    letterTypeList,
    unitList,
    suratmasukExport
} from "../../../../../services/adapters/common/laporanService"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", suratmasukList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchLetterTypeList = async (query, cancelToken) => {
        return await apiRequest("get", letterTypeList, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchUnitList = async (query, cancelToken) => {
        return await apiRequest("get", unitList, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }

    fetchExportSurat = async (query, cancelToken) => {
        return await apiRequest("get", suratmasukExport, {
            params: query,
            //cancelToken: cancelToken
        }, true)
    }   
}

export default DataTableRepository