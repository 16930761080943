import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,Input
} from "reactstrap"
import moment from "moment"
import Select from "react-select"
import TableActiveComponent from './Components/TableActiveComponent'


const IndexView = (props) => {

    const [activeTab, setactiveTab] = useState("1")    
    const activeRef = useRef();
    const inactiveRef = useRef();   

    const reloadActiveRef = () => activeRef.current.setTokenAPI()
    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }
    const historySdate = 'historyFilterStartDisposisi'
    const historyEdate = 'historyFilterEndDisposisi'
    const historyStatus = 'historyFilterStatusDisposisi'
    const historyStatusDate = 'historyFilterStatusDateDisposisi'
    const sdate = new Date();
    const nowdate = moment(sdate).format('YYYY-MM-DD');
    const startdate = moment(sdate).subtract(30, 'days').format('YYYY-MM-DD');
    const [tgl_awal, setTglAwal] = useState((sessionStorage.getItem(historySdate) ? sessionStorage.getItem(historySdate) : startdate))
    const [tgl_akhir, setTglAkhir] = useState(sessionStorage.getItem(historyEdate) ? sessionStorage.getItem(historyEdate) : nowdate)
    const [filterStatus, setfilterStatus] = useState((sessionStorage.getItem(historyStatus) ? sessionStorage.getItem(historyStatus) : 1))
    const [filterStatusDate, setfilterStatusDate] = useState((sessionStorage.getItem(historyStatusDate) ? sessionStorage.getItem(historyStatusDate) : "7"))
    //alert(filterStatus)
    const optionStatus = [
        { label: "Semua", value: 0 },
        { label: "Belum Ditindaklanjuti", value: 1 },
        { label: "Selesai", value: 2 }
    ]

    const optionStatusDate = [
        { label: "7 Hari Terakhir", value: "7" },
        { label: "30 Hari Terakhir", value: "30" },               
        { label: "Semua", value: "0" }
    ]
    function handleButtonSearch() {
        reloadActiveRef()
    }
    let additionalFilter = {
        //startdate : tgl_awal,
        //enddate : tgl_akhir
        filterStatus: filterStatus,
        filterStatusDate: filterStatusDate
    }

    function setSdate(event) {
        setTglAwal(event.target.value)
        sessionStorage.setItem(historySdate,event.target.value)
    }
    function setEdate(event) {
        setTglAkhir(event.target.value)
        sessionStorage.setItem(historyEdate,event.target.value)
    }
    function changefilterStatus(event){
        setfilterStatus(event.value)
        sessionStorage.setItem(historyStatus,event.value)
    }
    function changefilterStatusDate(event){
        setfilterStatusDate(event.value)
        sessionStorage.setItem(historyStatusDate,event.value)
    }
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Daftar Disposisi</CardTitle>
                                <hr />
                                <Row className="mb-3">
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label"
                                        style={{display:"none"}}
                                    >
                                    Tanggal Terima
                                    </label>
                                    <Col md="2" style={{display:"none"}}>
                                        <Input
                                            name="tgl_awal"
                                            placeholder="Tanggal Surat"
                                            type="date"
                                            value={tgl_awal}
                                            onChange={event => setSdate(event)}
                                        />
                                    </Col>
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label text-center"
                                        style={{display:"none"}}
                                    >
                                    s.d
                                    </label>
                                    <Col md="2" style={{display:"none"}}>
                                        <Input
                                            name="tgl_akhir"
                                            placeholder="Tanggal Surat"
                                            type="date"
                                            value={tgl_akhir}
                                            onChange={event => setEdate(event)}
                                        />
                                    </Col>
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label"
                                    >
                                    Status
                                    </label>
                                    <Col md="2">
                                        <Select 
                                                name="filter_status"
                                                errorMessage="Pilih Status"
                                                options={optionStatus} 
                                                //value={filterStatus}
                                                defaultValue={optionStatus[filterStatus]}
                                                onChange={event => changefilterStatus(event)}
                                                placeholder="Pilih Status"
                                            />
                                    </Col>
                                    <Col md="2">
                                        <Select 
                                                name="filter_status_date"
                                                errorMessage="Pilih Status"
                                                options={optionStatusDate} 
                                                //value={filterStatus}
                                                value = {
                                                    optionStatusDate.filter(option => 
                                                       option.value === filterStatusDate)
                                                 }
                                                //defaultValue={optionStatusDate[filterStatusDate]}
                                                onChange={event => changefilterStatusDate(event)}
                                                placeholder="Pilih Status"
                                            />
                                    </Col>        
                                    <Col md="2">
                                        <Button 
                                            color="primary" 
                                            type="button"
                                            onClick={() => {
                                                handleButtonSearch()
                                            }}
                                        >
                                            <i className="uil-search-alt"></i> Tampilkan
                                        </Button>      
                                    </Col>
                                </Row>
                                <hr />
                                <TableActiveComponent ref={activeRef} handleInactiveSuccess={reloadInactiveRef} additionalFilter={additionalFilter}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView