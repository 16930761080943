import * as minio from 'minio'
import { endpoint } from "./endpoint"

class MinioClient {

    mc
    minioConfig = endpoint('minio')

    constructor() {
        this.mc = new minio.Client(this.minioConfig.credential)
    }

    put = async (filePathName, file) => {
        try {
            await fetch(await this.mc.presignedPutObject(this.minioConfig.bucket, filePathName), {method: 'PUT', body: file})
            return true
        } catch (error) {
            return false
        }
    }

    get = async (filePathName, expiry=600) => {
        try {
            return await this.mc.presignedGetObject(this.minioConfig.bucket, filePathName, expiry)
        } catch (error) {
            return null
        }
    }

    isFileExists = async (filePathName) => {
        return this.mc
            .statObject(this.minioConfig.bucket, filePathName)
            .then(() => true)
            .catch(() => false)
    }

}

export default MinioClient
