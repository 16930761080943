import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannel = new Subject();
    letterTypeListChannel = new Subject()
    unitListChannel = new Subject()
    requestNomorChannel = new Subject()
    getLastNomorChannel = new Subject()
    getDrafterChannel = new Subject()
    repository = new DataTableRepository()

    constructor() {
        this.rowtableChannel.next({status: apiResponse.INITIAL})
        this.letterTypeListChannel.next({ status: apiResponse.INITIAL })
        this.unitListChannel.next({ status: apiResponse.INITIAL })
        this.requestNomorChannel.next({ status: apiResponse.INITIAL })
        this.getLastNomorChannel.next({ status: apiResponse.INITIAL })
        this.getDrafterChannel  .next({ status: apiResponse.INITIAL })
    }
   
    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
            this.rowtableChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }  

    fetchLetterTypeList  = async (query) => {
        this.letterTypeListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchLetterTypeList (query).then((result) => {
                this.letterTypeListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.letterTypeListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchUnitList = async (query) => {
        this.unitListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchUnitList(query).then((result) => {
                this.unitListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.unitListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchRequestNomor = async (query, cancelToken) => {
        this.requestNomorChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRequestNomor(query, cancelToken).then((result) => {
            this.requestNomorChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.requestNomorChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'Channel Unsubscribed')}
    }
    
    fetchGetLastNomor = async (query, cancelToken) => {
        this.getLastNomorChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchGetLastNomor(query, cancelToken).then((result) => {
            this.getLastNomorChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.getLastNomorChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'Channel Unsubscribed')}
    }

    fetchGetDrafter = async (query, cancelToken) => {
        this.getDrafterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchGetDrafter(query, cancelToken).then((result) => {
            this.getDrafterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.getDrafterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'Channel Unsubscribed')}
    }

}

export default DataTableBloc