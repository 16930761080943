import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card,CardHeader,CardTitle,CardText,
    Button, Modal,
    FormGroup,
    Input, Label,
    CustomInput,
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { Preview,generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router"
import moment from "moment"
import 'moment/locale/id'
import PdfViewer  from '../../../../helpers/ui/PdfViewer'
import apiResponse from '../../../../services/apiResponse'
import DetailBloc from './Blocs/DetailBloc'
import PreferenceBloc from './Blocs/PreferenceBloc'

class DetailView extends Component {

    detailBloc = new DetailBloc()
    preferenceBloc = new PreferenceBloc()

    constructor(props) {
        super(props)

        this.state = {
            jmlSM: 0,
            jmlDispo: 0,
            jmlSK: 0,
            jmlDispoK: 0,
            listReview: [],

            receiveEmailPreference: false,
        }
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            jmlSM: response.total_suratmasuk,
                            jmlDispo: response.total_disposisi,
                            jmlDispoK: response.total_disposisi_terkirim,
                            jmlSK: response.total_suratkeluar,
                            listReview: response.list_review,
                        })
                        //console.log(this.state.urlPdf);
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        const preferenceObserver = (result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response

                        this.setState({
                            receiveEmailPreference: response.receive_email,
                        })
                    }

                    break

                default:
                    //

                    break
            }
        }

        this.preferenceBloc.preferenceChannel.subscribe(preferenceObserver)
        this.preferenceBloc.emailPreferenceChannel.subscribe(preferenceObserver)

        this.preferenceBloc.fetchPreference()

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }

    handleToggleReceiveEmail = () => {
        console.debug(
            "Toggle email preference from "
            + (this.state.receiveEmailPreference ? "enabled" : "disabled")
            + " to "
            + (!this.state.receiveEmailPreference ? "enabled" : "disabled"),
        )

        this.setState({ receiveEmailPreference: !this.state.receiveEmailPreference })
        this.preferenceBloc.fetchEmailPreference({ receive_email: !this.state.receiveEmailPreference })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                    <Col lg={4}>
                        <Card>
                        <CardHeader className="h4">Selamat Datang</CardHeader>
                        <CardBody>
                            <CardTitle className="h4 mt-0">
                            Shortcut Aplikasi
                            </CardTitle>
                            <CardText>
                            <ul className="list-group list-group-flush">

                            <li className="list-group-item"><Link to={"/outbox/daftardraft.html?create"}><i class="fas fa-plus-circle"></i> Buat Surat</Link></li>
                            <li className="list-group-item" style={localStorage.getItem('currentRoleIdreal') < 5 ? {display: 'block'} : { display: 'none' }}><Link to={"/inbox/daftardraft.html?create"}><i class="fas fa-plus-circle"></i> Tambah Surat Masuk</Link></li>
                            <li className="list-group-item"><Link to={"/inbox/daftarsurat.html"}><i class="fas fa-list-ul"></i> Daftar Surat Masuk</Link> </li>
                            <li className="list-group-item"><Link to={"/disposition/daftardisposisi.html"}><i class="fas fa-list-ul"></i> Daftar Disposisi</Link> </li>
                            <li className="list-group-item"><Link to={"/outbox/daftarsurat.html"}><i class="fas fa-list-ul"></i> Daftar Surat Keluar</Link></li>

                            </ul>
                            </CardText>
                        </CardBody>
                        </Card>

                        <FormGroup check inline style={{ marginInlineStart: ".5rem" }}>
                            <Label check size="lg" style={{ fontSize: "1.25rem" }}>
                                <Input
                                    type="checkbox"
                                    checked={this.state.receiveEmailPreference}
                                    onClick={this.handleToggleReceiveEmail}
                                />
                                Terima notifikasi email
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col lg={8}>
                    <Card>
                        <CardHeader className="h4">Pending Task</CardHeader>
                        <CardBody>
                            <CardText>
                            <Row>
                                <Col className="col-md-6 col-xl-3" style={{display:"none"}}>
                                    <Card>
                                        <CardBody>
                                            <div>
                                                <h4 className="mb-1 mt-1"><span><i class="uil-envelope" style={{fontSize:'20px'}}></i> {this.state.jmlSM}</span></h4>
                                                <p className="text-muted mb-0">Surat Masuk</p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="col-md-6 col-xl-3" style={{display:"none"}}>
                                    <Card>
                                        <CardBody>
                                            <div>
                                                <h4 className="mb-1 mt-1"><span><i class="uil-fast-mail" style={{fontSize:'20px'}}></i> {this.state.jmlSK}</span></h4>
                                                <p className="text-muted mb-0">Surat Keluar</p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="col-md-6 col-xl-3" style={{display:"none"}}>
                                    <Card>
                                        <CardBody>
                                            <div>
                                                <h4 className="mb-1 mt-1"><span><i class="uil-envelope-download" style={{fontSize:'20px'}}></i> {this.state.jmlDispo}</span></h4>
                                                <p className="text-muted mb-0">Disposisi Masuk</p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="col-md-6 col-xl-3" style={{display:"none"}}>
                                    <Card>
                                        <CardBody>
                                            <div>
                                                <h4 className="mb-1 mt-1"><span><i class="uil-envelope-send" style={{fontSize:'20px'}}></i> {this.state.jmlDispoK}</span></h4>
                                                <p className="text-muted mb-0">Disposisi Terkirim</p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                            <Row>
                                <h5 className="mb-1 mt-1" style={{display:"none"}}>Pending Task</h5>
                                <p className="card-title-desc">Daftar surat untuk di review</p>
                                <div className="table-responsive">
                                <table className="table table-striped mb-0 table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Kode Surat</th>
                                            <th>Nomor Surat</th>
                                            <th>Perihal</th>
                                            <th>Profil</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.listReview.map(function (value, i) {
                                                return  <tr>
                                                            <td>{i+1}</td>
                                                            <td>{value.letter_out_qrcode}</td>
                                                            <td>{(value.letter_out_number != null || value.letter_out_number != '' ? <span class="badge bg-soft-dark font-size-14">{'Belum Penomoran'}</span>:<span class="badge bg-soft-dark font-size-14">{value.letter_out_number}</span>)}</td>
                                                            <td>{value.letter_out_title}</td>
                                                            <td><span>{value.request_name} <br />{value.request_position}</span></td>
                                                            <td><Link to={"/outbox/daftarsurat.html?review&uuid="+value.letter_out_process_uuid}><i class="uil-file-edit-alt"></i> Review</Link></td>
                                                        </tr>

                                            })
                                        }
                                    </tbody>
                                </table>
                                </div>
                            </Row>
                            </CardText>
                        </CardBody>
                        </Card>
                    </Col>
                    </Row>

                    {/* <LatestTransaction /> */}
                </Container>
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
