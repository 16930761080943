import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DataTableRepository from '../Repositories/DataTableRepository'


class DataTableBloc {

    rowtableChannel = new Subject();
    letterTypeListChannel = new Subject()
    unitListChannel = new Subject()
    exportSuratChannel = new Subject()
    //inactiveChannel = new Subject();
    //activeChannel = new Subject();
    repository = new DataTableRepository()

    constructor() {
        this.rowtableChannel.next({status: apiResponse.INITIAL})
        this.letterTypeListChannel.next({ status: apiResponse.INITIAL })
        this.unitListChannel.next({ status: apiResponse.INITIAL })
        this.exportSuratChannel.next({status: apiResponse.INITIAL})
    }
   
    fetchRowTable = async (query, cancelToken) => {
        this.rowtableChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchRowTable(query, cancelToken).then((result) => {
            this.rowtableChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.rowtableChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }  

    fetchLetterTypeList  = async (query) => {
        this.letterTypeListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchLetterTypeList (query).then((result) => {
                this.letterTypeListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.letterTypeListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchUnitList = async (query) => {
        this.unitListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchUnitList(query).then((result) => {
                this.unitListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.unitListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchExportSurat = async (query, cancelToken) => {
        this.exportSuratChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchExportSurat(query, cancelToken).then((result) => {
            this.exportSuratChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.exportSuratChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'Channel Unsubscribed')}
    } 

}

export default DataTableBloc