import { accessRole, isRoleTuUnit } from '../../../../../helpers/access_role'
import { config } from "../../../../../helpers/config_global"
import React, { useState, Component, Fragment, createRef } from 'react'
import { Redirect } from "react-router-dom"

import { Tooltip } from "reactstrap"

import MKIDatatable from '../../../../../modules/mki-datatable/lib'
import { ConfirmAlert } from '../../../../../helpers/ui/alert'

import { cancelToken } from "../../../../../services/adapters/base"

import DataTableBloc from '../Blocs/DataTableBloc'
import apiResponse from '../../../../../services/apiResponse'

import moment from "moment"
import 'moment/locale/id'

/*import ReactTimeAgo from 'react-time-ago'*/

class TableActiveComponent extends Component {
    dataTableBloc = new DataTableBloc();
    autoRefreshId = createRef()

    source
    historyTable = 'historyInboxTableActive'
    defaultOrder = 'letter_in_receive_date'
    defaultSize = 10
    defaultSort = 'desc'
    configDatatable = {
        ...config('datatable'),
        sort: {
            column: this.defaultOrder,
            order: this.defaultSort
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            tooltip: false,
            detailUuid: null,
            updateUuid: null,
            inactiveUuid: null,
            defaultSize: 10,
            rows: [],
            totalRow: 0,
            loadingTable: true,
            filter: sessionStorage.getItem(this.historyTable) ?
                JSON.parse(sessionStorage.getItem(this.historyTable)) :
                {
                    filter_value: "",
                    page_number: 1,
                    page_size: this.defaultSize,
                    sort_order: {
                        column: this.defaultOrder,
                        order: this.defaultSort
                    }
                }
        }
    }
    toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

    loadData = async () => {
        /* history Datatable */
        sessionStorage.setItem(this.historyTable, JSON.stringify(this.state.filter))
        let additionalFilter = this.props.additionalFilter;
        /* kirim ke server */
        let offset = (this.state.filter.page_number-1)*this.state.filter.page_size
        if(this.state.defaultSize!==this.state.filter.page_size) {
            this.setState({
                defaultSize: this.state.filter.page_size
            })
            offset = 0
            this.configDatatable.page_number = 1
        }
        const mysession = JSON.parse(localStorage.getItem('userdata'));
        const query = {
            search: this.state.filter.filter_value,
            limit: this.state.filter.page_size,
            offset: offset,
            order: this.state.filter.sort_order.column,
            sort: this.state.filter.sort_order.order,
            status: 1,
            unit_id : 'xxx',
            ...additionalFilter
        }
        await this.dataTableBloc.fetchRowTable(query, this.source.token)
    }

    setTokenAPI = () => {
        if (typeof this.source != typeof undefined) {
            this.source.cancel();
        }
        this.source = cancelToken()
        this.loadData()
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.inactiveData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    inactiveData = async () => {
        await this.dataTableBloc.fetchInactive({ uuid: this.state.inactiveUuid })
    }

    componentDidMount() {
        this.setTokenAPI()

        this.dataTableBloc.rowtableChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingTable: false
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingTable: true
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.response
                    this.setState({
                        totalRow: response.total,
                        rows: response.result,
                        loadingTable: false
                    })
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        if (isRoleTuUnit()) {
            this.autoRefreshId.current = setInterval(() => this.setTokenAPI(), 60 * 60 * 1_000)
        }
    }

    componentWillUnmount() {
        this.dataTableBloc.rowtableChannel.unsubscribe()
        clearInterval(this.autoRefreshId.current)
    }

    columns = [
        // {
        //     key: "letter_in_id",
        //     text: "UUID",
        //     className: "address",
        //     sortable: true,
        //     width: '20%'
        // },
        {
            key: "letter_in_agenda_number",
            text: "No Agenda",
            className: "address",
            sortable: true,
            width: '150',
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.letter_in_agenda_number
                return (<Fragment>
                    {cek == 1 ? <span>{datashow}</span> : <b>{datashow}</b> }
                    </Fragment>
                )
            }
        },
        {
            key: "letter_in_number",
            text: "Nomor Surat",
            className: "postcode",
            sortable: true,
            width: '150',
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.letter_in_number
                return (<Fragment>
                    {cek == 1 ? <span>{datashow}</span> : <b>{datashow}</b> }
                    </Fragment>
                )
            }
        },
        {
            key: "letter_in_sender",
            text: "Asal Surat",
            className: "address",
            sortable: true,
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.letter_in_sender
                return (<Fragment>
                    {cek == 1 ? <span>{datashow}</span> : <b>{datashow}</b> }
                    </Fragment>
                )
            }
        },
        {
            key: "letter_in_title",
            text: "Perihal",
            className: "address",
            sortable: true,
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.letter_in_title
                return (<Fragment>
                    {cek == 1 ? <span>{datashow}</span> : <b>{datashow}</b> }
                    </Fragment>
                )
            }
        },
        {
            key: "letter_type_name",
            text: "Jenis Surat",
            className: "address",
            sortable: true,
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.letter_type_name
                return (<Fragment>
                    {cek == 1 ? <span>{datashow}</span> : <b>{datashow}</b> }
                    </Fragment>
                )
            }
        },
        {
            key: "priority_name",
            text: "Sifat Surat",
            className: "address",
            sortable: true,
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.priority_name
                return (<Fragment>
                    {cek == 1 ? <span>{datashow}</span> : <b>{datashow}</b> }
                    </Fragment>
                )
            }
        },
        {
            key: "letter_in_date",
            text: "Tanggal Surat",
            className: "address",
            sortable: true,
            width: '150',
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.letter_in_date
                return (<Fragment>
                    {cek == 1 ? <span>{moment(datashow).format("DD MMMM YYYY")}</span> : <b>{moment(datashow).format("DD MMMM YYYY")}</b> }
                    </Fragment>
                )
            }
        },
        {
            key: "letter_in_receive_date",
            text: "Tanggal Terima",
            className: "address",
            sortable: true,
            width: '150',
            cell: record => {
                const cek = record.receiver_status_read
                const datashow = record.letter_in_receive_date
                return (<Fragment>
                    {cek == 1 ? <span>{moment(datashow).format("DD MMMM YYYY")}</span> : <b>{moment(datashow).format("DD MMMM YYYY")}</b> }
                    </Fragment>
                )
            }
        },
        {
            key: "redaction_name",
            text: "Status",
            className: "address",
            sortable: true,
            cell: record => {
                if (record.receiver_done_status === 1 || record.letter_in_status === 2) {
                    return (
                        <span className="badge bg-success font-size-14 mb-1" style={{ width: 130 }}>
                            {record.letter_in_status_text}
                        </span>
                    )
                }

                if (record.letter_in_status_process === 0) {
                    return (
                        <span className="badge bg-soft-dark font-size-14" style={{ width: 130 }}>
                            {record.letter_in_status_text}
                        </span>
                    )
                }

                return (
                    <span className="badge bg-success font-size-14" style={{ width: 130 }}>
                        Sudah di Disposisi
                    </span>
                );
            }
        },

        {
            key: 'action',
            text: 'Action',
            className: 'action',
            width: 120,
            sortable: false,
            align: 'center',
            cell: record => {
                const thisId = record.letter_in_id
                return (
                    <Fragment>
                        <span>
                            <button
                                className="btn btn-outline-secondary"
                                id={"detail-" + thisId}
                                onClick={() => {
                                    console.log('Clik ini',record)
                                    this.setState({
                                        detailUuid: record.letter_in_qrcode
                                    })
                                    console.log(this.state.detailUuid);
                                }}
                                style={{
                                    marginRight: '2px',
                                    padding: '2px 5px'
                                }}>
                                    <i className="uil-align-left"></i>
                            </button>
                            <Tooltip
                                style={{
                                    marginBottom: 2
                                }}
                                placement="top" isOpen={this.state["detail" + thisId]} target={"detail-" + thisId}
                                toggle={() => {
                                    this.setState({
                                        ["detail" + thisId] : !this.state["detail" + thisId]
                                    })
                                }}
                            >Detil</Tooltip>
                        </span>
                        { accessRole('update') && record.letter_in_status == 1 ?
                            // <span>
                            //     <button
                            //         className="btn btn-primary"
                            //         id={"update-" + thisId}
                            //         onClick={() => {
                            //             this.setState({
                            //                 updateUuid: record.letter_in_qrcode
                            //             })
                            //         }}
                            //         style={{
                            //             marginRight: '2px',
                            //             padding: '2px 5px'
                            //         }}>
                            //             <i className="uil-pen"></i>
                            //     </button>
                            //     <Tooltip
                            //         style={{
                            //             marginBottom: 2
                            //         }}
                            //         placement="top" isOpen={this.state["update" + thisId]} target={"update-" + thisId}
                            //         toggle={() => {
                            //             this.setState({
                            //                 ["update" + thisId] : !this.state["update" + thisId]
                            //             })
                            //         }}
                            //     >Perbaharui</Tooltip>
                            // </span>
                            null
                            : null
                        }

                    </Fragment>
                );
            }
        }
    ]

    render() {
        return (
            <>
                <MKIDatatable
                    className={this.configDatatable.tableClass}
                    config={this.configDatatable}
                    records={this.state.rows}
                    columns={this.columns}
                    dynamic={true}
                    total_record={this.state.totalRow}
                    initial={this.state.filter}
                    onChange={(filter) => {
                        this.setState({
                            loadingTable: true,
                            filter: filter
                        }, function () {
                            this.setTokenAPI()
                        });
                    }}
                    loading={this.state.loadingTable}
                />
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menghapus data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil dihapus"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal dihapus"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.detailUuid !== null ?
                    <Redirect to={"/inbox/daftarsurat.html?detail&qrcode=" + this.state.detailUuid}/>
                : null }
                { this.state.updateUuid !== null ?
                    <Redirect to={"/inbox/daftarsurat.html?update&qrcode=" + this.state.updateUuid}/>
                : null }
            </>
        )
    }

}

export default TableActiveComponent
