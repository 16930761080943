import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'


class IndexView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            dataForm: {}
        }
    }

    submitData = async () => {
        await this.updateBloc.fetchUpdate(this.state.dataForm)
    }

    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.uuid,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        console.log(response)
                        this.setState({
                            dataForm: {
                                uuid: response.sample_uuid,
                                code: response.sample_code,
                                title: response.sample_title,
                                description: response.sample_description,
                            }
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('uuid')) {
            this.updateBloc.fetchDetail({ uuid: queryParams.get('uuid') })
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Jurul Form</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Keterangan menu-menu. Lorem ipsum dolor sit amet...
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="fielCode"
                                                className="col-md-3 col-form-label"
                                            >
                                            Kode <span className="text-danger">*</span>
                                            </label>
                                            <Col md="3">
                                                <AvField
                                                    name="code"
                                                    placeholder="Kode..."
                                                    type="text"
                                                    errorMessage="Masukkan kode"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="fielCode"
                                                    value={this.state.dataForm.code}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="fieldTitle"
                                                className="col-md-3 col-form-label"
                                            >
                                            Judul <span className="text-danger">*</span>
                                            </label>
                                            <Col md="6">
                                                <AvField
                                                    name="title"
                                                    placeholder="Judul..."
                                                    type="text"
                                                    errorMessage="Masukkan Judul"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="fieldTitle"
                                                    value={this.state.dataForm.title}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="fielDescription"
                                                className="col-md-3 col-form-label"
                                            >
                                            Keterangan 
                                            </label>
                                            <Col md="9">
                                                <AvField
                                                    name="description"
                                                    placeholder="Keterangan..."
                                                    type="textarea"
                                                    errorMessage="Masukkan Keterangan"
                                                    className="form-control"
                                                    //validate={{ required: { value: true } }}
                                                    id="fielDescription"
                                                    value={this.state.dataForm.description}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="3"></Col>
                                            <Col md="9">
                                                <div className="form-check form-switch mb-3">
                                                <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                                                    <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="3"></Col>
                                            <Col md="9">
                                                <Link to="/sample.html">
                                                    <Button color="success"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                <Button color="danger" type="submit">
                                                    <i className="uil-arrow-up-right"></i> Simpan Sample
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/sample.html'/>
                : null }
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(IndexView);
