import {
    apiRequest,
    instructionList,
    receiverDisposisiList,
    positionDisposisiList,
    draftDetail,
    disposisiUpdate
} from "../../../../../services/adapters/suratmasuk/dispositionService"

class UpdateRepository {

    fetchInstructionList = async (query, cancelToken) => {
        return await apiRequest("get", instructionList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchReceiverDisposisiList = async (query, cancelToken) => {
        return await apiRequest("get", receiverDisposisiList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchPositionDisposisiList = async (query, cancelToken) => {
        return await apiRequest("get", positionDisposisiList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", disposisiUpdate, {
            body: query
        }, true)
    }

    fetchDetail = async (query) => {
        return await apiRequest("get", draftDetail, {
            params: query
        }, true)
    }

}

export default UpdateRepository