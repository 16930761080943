import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import DetailRepository from '../Repositories/DetailRepository'


class DetailBloc {

    detailChannel = new Subject();
    sendLetterChannel = new Subject();
    addAttachmentChannel = new Subject();
    deleteAttachmentChannel = new Subject();
    cancelLetterChannel = new Subject();
    repository = new DetailRepository()

    constructor() {
        this.detailChannel.next({status: apiResponse.INITIAL})
        this.sendLetterChannel.next({status: apiResponse.INITIAL})
        this.addAttachmentChannel.next({status: apiResponse.INITIAL})
        this.deleteAttachmentChannel.next({status: apiResponse.INITIAL})
        this.cancelLetterChannel.next({status: apiResponse.INITIAL})
    }

    fetchDetail = async (query) => {
        this.detailChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDetail(query).then((result) => {
                this.detailChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.detailChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    

    fetchSendReview = async (query) => {
        this.sendLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchSendReview(query).then((result) => {
                this.sendLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.sendLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchAddAttachment = async (query) => {
        this.addAttachmentChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchAddAttachment(query).then((result) => {
                this.addAttachmentChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.addAttachmentChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchDeleteAttachment = async (query) => {
        this.deleteAttachmentChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchDeleteAttachment(query).then((result) => {
                this.deleteAttachmentChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.deleteAttachmentChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }

    fetchCancelLetter = async (query) => {
        this.cancelLetterChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchCancelLetter(query).then((result) => {
                this.cancelLetterChannel.next({status: apiResponse.COMPLETED, data: result})
            }).catch((error) => {
                this.cancelLetterChannel.next({status: apiResponse.ERROR, data: error})
            })
        } catch (error) {console.log('rxjs', 'rowtableChannel.unsubscribed')}
    }
    
}

export default DetailBloc