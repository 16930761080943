import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment, useState, useRef } from "react"
import classnames from "classnames"
import { Link } from "react-router-dom"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input
} from "reactstrap"
import moment from "moment"
import TableActiveComponent from './Components/TableActiveComponent'


const IndexView = (props) => {

    const [activeTab, setactiveTab] = useState("1")    
    const activeRef = useRef();
    const inactiveRef = useRef();

    const reloadActiveRef = () => activeRef.current.setTokenAPI()
    const reloadInactiveRef = () => inactiveRef.current.setTokenAPI()

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    const historySdate = 'historyFilterStartOutboxBack'
    const historyEdate = 'historyFilterEndOutboxBack'
    const sdate = new Date();
    const nowdate = moment(sdate).format('YYYY-MM-DD');
    const startdate = moment(sdate).subtract(30, 'days').format('YYYY-MM-DD');
    const [tgl_awal, setTglAwal] = useState((sessionStorage.getItem(historySdate) ? sessionStorage.getItem(historySdate) : startdate))
    const [tgl_akhir, setTglAkhir] = useState(sessionStorage.getItem(historyEdate) ? sessionStorage.getItem(historyEdate) : nowdate)
    function handleButtonSearch() {
        reloadActiveRef()
    }
    let additionalFilter = {
        //startdate : tgl_awal,
        //enddate : tgl_akhir
    }

    function setSdate(event) {
        setTglAwal(event.target.value)
        sessionStorage.setItem(historySdate,event.target.value)
    }
    function setEdate(event) {
        setTglAkhir(event.target.value)
        sessionStorage.setItem(historyEdate,event.target.value)
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Daftar Surat Keluar Dikembailkan</CardTitle>
                                {/* <hr /> */}
                                <Row className="mb-3" style={{display:"none"}}>
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label"
                                    >
                                    Tanggal Surat
                                    </label>
                                    <Col md="2">
                                        <Input
                                            name="tgl_awal"
                                            placeholder="Tanggal Surat"
                                            type="date"
                                            value={tgl_awal}
                                            onChange={event => setSdate(event)}
                                        />
                                    </Col>
                                    <label
                                        htmlFor="gender"
                                        className="col-md-1 col-form-label text-center"
                                    >
                                    s.d
                                    </label>
                                    <Col md="2">
                                        <Input
                                            name="tgl_akhir"
                                            placeholder="Tanggal Surat"
                                            type="date"
                                            value={tgl_akhir}
                                            onChange={event => setEdate(event)}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Button 
                                            color="primary" 
                                            type="button"
                                            onClick={() => {
                                                handleButtonSearch()
                                            }}
                                        >
                                            <i className="uil-search-alt"></i> Tampilkan
                                        </Button>      
                                    </Col>
                                </Row>
                                <hr />
                                <TableActiveComponent ref={activeRef} handleInactiveSuccess={reloadInactiveRef} additionalFilter={additionalFilter}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView