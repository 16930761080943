import { Subject } from 'rxjs'
import apiResponse from '../../../../../services/apiResponse'
import CreateRepository from '../Repositories/CreateRepository'

class CreateBloc {

    createChannel = new Subject()
    instructionListChannel = new Subject()
    receiverDisposisiListChannel = new Subject()
    positionDisposisiListChannel = new Subject()
    repository = new CreateRepository()

    constructor() {
        this.createChannel.next({ status: apiResponse.INITIAL })
        this.instructionListChannel.next({ status: apiResponse.INITIAL })
        this.receiverDisposisiListChannel.next({ status: apiResponse.INITIAL })
        this.positionDisposisiListChannel.next({ status: apiResponse.INITIAL })       
    }

    fetchCreate = async (query) => {
        this.createChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository.fetchCreate(query).then((result) => {
                this.createChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.createChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchInstructionList = async (query) => {
        this.instructionListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchInstructionList(query).then((result) => {
                this.instructionListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.instructionListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchReceiverDisposisiList = async (query) => {
        this.receiverDisposisiListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchReceiverDisposisiList(query).then((result) => {
                this.receiverDisposisiListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.receiverDisposisiListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }

    fetchPositionDisposisiList = async (query) => {
        this.positionDisposisiListChannel.next({status: apiResponse.LOADING})
        try {
            await this.repository.fetchPositionDisposisiList(query).then((result) => {
                this.positionDisposisiListChannel.next({ status: apiResponse.COMPLETED, data: result })
            }).catch((error) => {
                this.positionDisposisiListChannel.next({ status: apiResponse.ERROR, data: error })
            })
        } catch (error) {
            console.log('rxjs', 'Channel Unsubscribed') 
        }
    }
    
}

export default CreateBloc