import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"
import Shimmer from "react-shimmer-effect";
import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'
import CustomSelect from '../../../../helpers/ui/CustomSelect'
import moment from "moment"
import 'moment/locale/id'
import { split } from 'lodash'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];
  
      return newObj;
    });
};

var checkedOptions = function(raw, label, value, checked) {
    return raw.map(function(data) {
      var newObj = {};
      var pilih = "";
      checked.map(function(c){
        if(c == data[value]){
            pilih = "checked";
        }
      });
      newObj["label"] = data[label];
      newObj["value"] = data[value];
      newObj["checked"] = pilih;
      return newObj;
    });
};

class UpdateView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            // optionGender : [
            //     { label: "Laki-laki", value: "L" },
            //     { label: "Perempuan", value: "P" }
            // ],
            detail: {},
            instructionOption: [],
            receiverOption: [],
            positionOption: [],
            dataForm: {},
            submitStatus: 0 ,
            logsurat: [],
            selectedOptions: [],
            disposition: {},
            disposisireceiver: [],
            attachmentdisposition: [],
            checkedinstruction: {},
            checkedreceiver: {}
        }
    }

    // End Select Area

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, 'document') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto'] 
        await this.updateBloc.fetchUpdate(formData)       
    }

    handleSaveDraft = (s) => {
        //console.log(s);
        this.setState({
            submitStatus: s
        })
    }

    handleCheckedOption = (list, v) => {
        console.log("XXXXXXXXXXXXXXXXXXXXXX");
        console.log(list);
        console.log(v);
        const hasil = "";
        for (let index = 0; index < list.length; index++) {
            if(list[index] == v){
                hasil = "checked";
            }
        }
        return hasil;
    }

    
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        // console.log(values)
        // return;
        this.setState({
            dataForm: {
                //uuid: this.state.dataForm.employee_uuid,
                disposition_status: this.state.submitStatus,
                disposition_group_code: this.state.disposition.disposition_group_code,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        
        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.letter_in,
                            disposition: response.disposition,
                            disposisireceiver: response.disposisireceiver,
                            attachmentdisposition: response.attachmentdisposition,
                            checkedinstruction : response.disposition.disposition_instruction.replace(", ", ",").split(','),
                            checkedreceiver : response.disposition.disposition_position_to.replace(", ", ",").split(',')
                        })                           
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

       
        // get data referensi
        this.updateBloc.instructionListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            instructionOption: checkedOptions(
                                response.result, 
                                'instruction_desc',
                                'instruction_desc',
                                this.state.checkedinstruction
                            )
                        })
                        //console.log(this.state.checkedinstruction);
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchInstructionList({})

        
        this.updateBloc.positionDisposisiListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            positionOption: reformatOptions(
                                response.result, 
                                'position_name_text',
                                'position_uuid',
                                'position_employee_nip'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchPositionDisposisiList({})

        this.updateBloc.receiverDisposisiListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        // this.setState({
                        //     receiverOption: response.result
                        // })
                        this.setState({
                            receiverOption: checkedOptions(
                                response.result, 
                                'position_name_text',
                                'position_uuid',
                                this.state.checkedreceiver
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchReceiverDisposisiList({})

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.updateBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Lanjutkan Draft Disposisi </CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <CardSubtitle className="mb-3">
                                        Informasi Surat Masuk
                                    </CardSubtitle>
                                    <Row className="">
                                        <label className="col-md-2">
                                            Nomor Agenda
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_agenda_number !== "" ? this.state.detail.letter_in_agenda_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <label className="col-md-2">
                                            Nomor Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_number !== "" ? this.state.detail.letter_in_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <label className="col-md-2">
                                            Asal Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_sender !== "" ? this.state.detail.letter_in_sender : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="">
                                        <label className="col-md-2">
                                            Perihal
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_title !== "" ? this.state.detail.letter_in_title : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Diteruskan Kepada <span className="text-danger">*</span>
                                                </label>
                                                <AvCheckboxGroup name="disposition_position_to">
                                                    {
                                                    this.state.receiverOption.map(function (val, i) {
                                                        return  <AvCheckbox 
                                                                value={val.value} 
                                                                label={val.label}     
                                                                checked = {val.checked}    
                                                                defaultChecked                                                             
                                                                />
                                                        })
                                                    }
                                                </AvCheckboxGroup>
                                                
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <CustomSelect 
                                                    name="disposition_position_to_select"
                                                    id="disposition_position_to_select"
                                                    errorMessage="Pilih Penerima Lain"
                                                    options={this.state.positionOption} 
                                                    placeholder="Pilih Penerima Lain"
                                                    isMulti
                                                />
                                                </div>
                                            </Col>                                        
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >Perintah Disposisi <span className="text-danger">*</span>
                                                </label>
                                                <AvCheckboxGroup name="disposition_instruction_1">
                                                    {
                                                    this.state.instructionOption.map(function (val, i) {
                                                        return  (i < 7 ? 
                                                                <AvCheckbox                                                                     
                                                                label={val.value}                                                                   
                                                                value={val.value}
                                                                checked = {val.checked}                                                             
                                                                />
                                                                :
                                                                null
                                                            )                                                            
                                                        })
                                                    }    
                                                </AvCheckboxGroup>                                                                                                 
                                                </div>
                                            </Col>           
                                            <Col md="3">
                                                <div className="mb-3">
                                                <label >{'.'}
                                                </label>
                                                <AvCheckboxGroup name="disposition_instruction_2">
                                                    {
                                                    this.state.instructionOption.map(function (val, i) {
                                                        return   (i > 6 && i < 14 ? 
                                                                <AvCheckbox                                                                     
                                                                label={val.value}                                                                   
                                                                value={val.value}
                                                                checked = {val.checked} 
                                                                />
                                                                :
                                                                null
                                                            )                                                            
                                                        })
                                                    }    
                                                </AvCheckboxGroup>                                                                                                   
                                                </div>
                                            </Col>        
                                            <Col md="4">
                                                <div className="mb-3">
                                                <label >{'.'}
                                                </label>
                                                <AvCheckboxGroup name="disposition_instruction_3">
                                                    {
                                                    this.state.instructionOption.map(function (val, i) {
                                                        return   (i > 13 && i < 50 ? 
                                                                <AvCheckbox  
                                                                label={val.value}                                                                   
                                                                value={val.value}
                                                                checked = {val.checked} 
                                                                />
                                                                :
                                                                null
                                                            )                                                            
                                                        })
                                                    }    
                                                </AvCheckboxGroup>                                              
                                                </div>
                                            </Col>                                                                             
                                        </Row>
                                        
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Catatan Disposisi
                                                </label>
                                                <AvField
                                                    name="disposition_note"
                                                    placeholder="Catatan Disposisi ..."
                                                    type="textarea"
                                                    errorMessage="Masukkan Catatan Disposisi"
                                                    className="form-control"
                                                    id="disposition_note"
                                                    value={this.state.disposition.disposition_note}
                                                />
                                                </div>
                                            </Col>                                        
                                        </Row>
                                        
                                        <Row>                                            
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >File Lampiran <span className="text-danger">*</span>
                                                </label>
                                                <FileInput 
                                                    name="document"
                                                    //validate={{ required: { value: true } }}
                                                    isMultiple
                                                />
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Button color="success" type="submit" onClick={() => this.handleSaveDraft(0)}>
                                                    <i className="uil-arrow-up-right"></i> Simpan Draft
                                                </Button>
                                                {' '}
                                                <Button color="primary" type="submit" onClick={() => this.handleSaveDraft(1)}>
                                                    <i className="uil-arrow-up-right"></i> Simpan Kirim
                                                </Button>
                                                {' '}
                                                <Link to={"/disposition/daftardisposisi.html"}>
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle>Riwayat Surat</CardTitle>
                                    <hr />
                                    <ul className="activity-feed mb-0 ps-2">                                    
                                        {
                                            this.state.logsurat.map(function (value, i) {
                                            return  <li className="feed-item" key="1">
                                                        <div className="feed-item-list">
                                                            <p className="text-muted mb-1">{moment(value.histories_date).format("DD MMMM YYYY HH:mm:ss")}</p>
                                                            <h5 className="font-size-16" style={{ whiteSpace: 'pre-line' }}>{value.histories_desc}</h5>
                                                            {/* <p>Ahmad Bajuri</p>
                                                            <p className="text-muted">Surat Masuk diinput</p>                                                        */}
                                                        </div>
                                                    </li>
                                            })
                                        }                                    
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    this.state.submitStatus == 1 ? 
                        <Redirect to='/disposition/disposisiterkirim.html'/>
                    : 
                        <Redirect to='/disposition/draftdisposisi.html'/>                   
                : null }
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(UpdateView);
