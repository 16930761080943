import {
    apiRequest,
    receiverGroupDetail,
    receiverGroupUpdate,
} from "../../../../../services/adapters/common/masterService"

class UpdateRepository {
    fetchDetail = async (query) => {
        return await apiRequest("get", receiverGroupDetail, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", receiverGroupUpdate, {
            body: query
        }, true)
    }
}

export default UpdateRepository
