import {
    apiRequest,
    letterPriorityList,
    letterTypeList,
    attachmentTypeList,
    originSenderList,
    redactionList,
    instansiList,
    positionList,
    senderList,
    suratKeluarCreate
} from "../../../../../services/adapters/suratkeluar/outboxService"

class CreateRepository {

    fetchLetterPriorityList = async (query, cancelToken) => {
        return await apiRequest("get", letterPriorityList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchLetterTypeList = async (query, cancelToken) => {
        return await apiRequest("get", letterTypeList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchAttachmentTypeList = async (query, cancelToken) => {
        return await apiRequest("get", attachmentTypeList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchOriginSenderList = async (query, cancelToken) => {
        return await apiRequest("get", originSenderList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchRedactionList = async (query, cancelToken) => {
        return await apiRequest("get", redactionList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchInstansiList = async (query, cancelToken) => {
        return await apiRequest("get", instansiList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchPositionList = async (query, cancelToken) => {
        return await apiRequest("get", positionList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchSenderList = async (query, cancelToken) => {
        return await apiRequest("get", senderList, {
            params: query,
            cancelToken: cancelToken
        }, true)
    }

    fetchCreate = async (query) => {
        return await apiRequest("post", suratKeluarCreate, {
            body: query
        }, true)
    }

}

export default CreateRepository