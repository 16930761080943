import React from "react"
import { Bar } from "react-chartjs-2"

const BarChart = ({
  letter_in = [127,231,181,145,65,0,0,0,0,0,0,0],
  letter_out = [65,59,81,45,56,0,0,0,0,0,0,0],
}) => {

  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul","Ags","Sep","Okt","Des"],

    datasets: [
      {
        label: "Surat Masuk",
        backgroundColor: "#00BFFF",
        borderColor: "#00BFFF",
        borderWidth: 1,
        hoverBackgroundColor: "#00BFFF",
        hoverBorderColor: "#00BFFF",
        data: letter_in,
      },
      {
        label: "Surat Keluar",
        backgroundColor: "#00FF00",
        borderColor: "#00FF00",
        borderWidth: 1,
        hoverBackgroundColor: "#00FF00",
        hoverBorderColor: "#00FF00",
        data: letter_out,
      },
    ],
  }

  const option = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.7,
        },
      ],
    },
  }

  return <Bar width={474} height={120} data={data} options={option} />
}

export default BarChart
