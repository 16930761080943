import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { Preview,generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router";
import moment from "moment"
import 'moment/locale/id'

import apiResponse from '../../../../services/apiResponse'

import DetailBloc from './Blocs/DetailBloc'

class DetailView extends Component {

    detailBloc = new DetailBloc()

    constructor(props) {
        super(props)
        this.state = {
            detail: {},
            dataForm: {},
            submitStatus: 0,
            submitProses: 0,
            textconfirm : "Apakah anda yakin akan menyimpan data ini?"
        }
    }
    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, 'document') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        await this.detailBloc.fetchReturned(formData)
    }

    handleSaveDraft = (s) => {
        //console.log(s);
        this.setState({
            submitStatus: s
        })
        if(s == 9){
            this.setState({
                textconfirm : "Apakah anda yakin akan mengembalikan surat ini?"
            })
        }
        if(s == 2){
            this.setState({
                textconfirm : "Apakah anda yakin akan menyelesaiakan surat ini?"
            })
        }
    }


    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.employee_uuid,
                letter_in_status: this.state.submitStatus,
                letter_in_qrcode: this.state.detail.letter_in_qrcode,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response.letter_in,
                            receiver: response.receiver,
                            attachment: response.attachment
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.detailBloc.returnedChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.detailBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Detail Surat Masuk</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Informasi Detail Surat Masuk
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Nomor Agenda
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_agenda_number !== "" ? this.state.detail.letter_in_agenda_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Nomor Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_number !== "" ? this.state.detail.letter_in_number : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Sifat Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.priority_name !== "" ? this.state.detail.priority_name : '-'}</>
                                                : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Tanggal Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_date !== "" ?  moment(this.state.detail.letter_in_date).format("DD MMMM YYYY") : '-'}</>
                                                : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Tanggal Diterima
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_receive_date !== "" ? moment(this.state.detail.letter_in_receive_date).format("DD MMMM YYYY") : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Lampiran
                                        </label>
                                        <Col md="9">
                                            {this.state.detail
                                                ? this.state.detail.letter_in_number_of_attachment
                                                    ? `${this.state.detail.letter_in_number_of_attachment} ${this.state.detail.attachment_type_name}`
                                                    : '-'
                                                : (
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Jenis Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_type_name !== "" ? this.state.detail.letter_type_name : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Asal Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_sender !== "" ? this.state.detail.letter_in_sender : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Perihal
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_title !== "" ? this.state.detail.letter_in_title : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Catatan Mengenai Surat
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.letter_in_notes !== "" ? this.state.detail.letter_in_notes : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Unit yang dituju
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.receiver ?
                                                    <>{this.state.receiver.work_unit_name !== "" ? this.state.receiver.work_unit_name: '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            File Lampiran
                                        </label>
                                        <Col md="9">
                                        <Preview
                                            value={this.state.attachment}
                                            fileName='attachment_filename'
                                            filePath='attachment_filepath'
                                            fileMimeType='attachment_mime'
                                        />
                                        </Col>
                                    </Row>
                                    { this.state.detail.letter_in_status == 2 ?
                                        <Row className="mb-3">
                                        <Col md="3">{' '}</Col>
                                        <Col md="9">
                                        <span class="badge bg-soft-info font-size-16">Surat ini sudah diselesaikan</span>

                                        </Col>
                                    </Row>
                                        : null
                                    }
                                    <Row className="mb-3">
                                        <Col md="3">{' '}</Col>
                                        <Col md="9">
                                            <Link to="#">
                                                <Button color="primary" type="button"><i className="uil-envelope-download"></i> Diposisi</Button>
                                            </Link>
                                            {' '}
                                            { this.state.detail.letter_in_status == 1 ?
                                                    <Button color="success" type="submit" onClick={() => this.handleSaveDraft(2)}>
                                                        <i className="uil-envelope-check"></i> Selesai
                                                    </Button>
                                                :
                                                null
                                            }
                                            {' '}
                                            { this.state.detail.letter_in_status == 1 ?
                                                    <Button color="warning" type="submit" onClick={() => this.handleSaveDraft(9)}>
                                                        <i className="uil-envelope-times"></i> Kembalikan
                                                    </Button>
                                                :
                                                null
                                            }
                                            {' '}
                                            <Link to="/inbox/daftarsurat.html">
                                                <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali ke daftar</Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle>Riwayat Surat</CardTitle>
                                    <hr />
                                    <ul className="activity-feed mb-0 ps-2">
                                        <li className="feed-item" key="1">
                                        <div className="feed-item-list">
                                            <p className="text-muted mb-1">22 Jully 2021 15:21:12</p>
                                            <h5 className="font-size-16">Surat Masuk diterima Biro Umum</h5>
                                            {/* <p>Ahmad Bajuri</p>
                                            <p className="text-muted">Surat Masuk diinput</p> */}
                                        </div>
                                        </li>
                                        <li className="feed-item" key="1">
                                        <div className="feed-item-list">
                                            <p className="text-muted mb-1">23 Jully 2021 09:02:34</p>
                                            <h5 className="font-size-16">Disposisi ke Kepala Bagian Tata Usaha</h5>
                                            {/* <p>Ahmad Bajuri</p>
                                            <p className="text-muted">Surat Masuk diinput</p> */}
                                        </div>
                                        </li>
                                        <li className="feed-item" key="1">
                                        <div className="feed-item-list">
                                            <p className="text-muted mb-1">23 Jully 2021 10:32:08</p>
                                            <h5 className="font-size-16">Disposisi ke Kepala Sub Bagian Persuratan & Dokumen</h5>
                                            {/* <p>Ahmad Bajuri</p>
                                            <p className="text-muted">Surat Masuk diinput</p> */}
                                        </div>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.textconfirm}

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    <Redirect to='/inbox/daftarsurat.html'/>
                : null }
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
