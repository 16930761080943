import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { Preview } from '../../../../helpers/ui/FileInput'
// import Select from "react-select"

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import UpdateBloc from './Blocs/UpdateBloc'
import CustomSelect from '../../../../helpers/ui/CustomSelect'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];
  
      return newObj;
    });
};

class UpdateView extends Component {

    updateBloc = new UpdateBloc();

    constructor(props) {
        super(props)
        this.state = {
            // optionGender : [
            //     { label: "Laki-laki", value: "L" },
            //     { label: "Perempuan", value: "P" }
            // ],
            letterPriorityOption: [],
            letterTypeOption: [],
            attachmentTypeOption: [],
            originSenderOption: [],
            redactionOption: [],
            instansiOption: [],
            unitOption: [],

            dataForm: {},
            submitStatus: 0,
            letterin: {},
            receiever: {},
            //attachment : {},

            letter_in_sender_validate : true,
            letter_in_sender_mandatory_teks : "*",
            letter_in_sender_show : true,
            letter_in_sender_detail_validate : true,
            letter_in_sender_detail_mandatory_teks : "*",
        }
    }

    // End Select Area

    submitData = async () => {
        const formData = generateFormData(this.state.dataForm, 'document') // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto'] 
        await this.updateBloc.fetchUpdate(formData)       
    }

    handleSaveDraft = (s) => {
        //console.log(s);
        this.setState({
            submitStatus: s
        })
    }

    handleTipeAsalSurat = (option) => {
        //console.log(option);
        let selected = option.value;
        if(selected == 1){
            this.setState ({
                letter_in_sender_validate : false,
                letter_in_sender_mandatory_teks : "",
                letter_in_sender_show : false,
                letter_in_sender_detail_validate : true,
                letter_in_sender_detail_mandatory_teks : "*"
            })     
        }else if(selected == 2){
            this.setState ({
                letter_in_sender_validate : true,
                letter_in_sender_mandatory_teks : "*",
                letter_in_sender_show : true,
                letter_in_sender_detail_validate : false,
                letter_in_sender_detail_mandatory_teks : ""
            })     
            this.getInstansiOptions()
        }else{
            this.setState ({
                letter_in_sender_validate : true,
                letter_in_sender_mandatory_teks : "*",
                letter_in_sender_show : true,
                letter_in_sender_detail_validate : false,
                letter_in_sender_detail_mandatory_teks : ""
            })     
            this.getUnitOptions()
        }
    }

    handleFirstTipeAsalSurat = (selected) => {
        //alert(selected)
        //console.log(option);
        if(selected == 1){
            this.setState ({
                letter_in_sender_validate : false,
                letter_in_sender_mandatory_teks : "",
                letter_in_sender_show : false,
                letter_in_sender_detail_validate : true,
                letter_in_sender_detail_mandatory_teks : "*"
            })     
        }else if(selected == 2){
            this.setState ({
                letter_in_sender_validate : true,
                letter_in_sender_mandatory_teks : "*",
                letter_in_sender_show : true,
                letter_in_sender_detail_validate : false,
                letter_in_sender_detail_mandatory_teks : ""
            })     
            this.getInstansiOptions()
        }else{
            this.setState ({
                letter_in_sender_validate : true,
                letter_in_sender_mandatory_teks : "*",
                letter_in_sender_show : true,
                letter_in_sender_detail_validate : false,
                letter_in_sender_detail_mandatory_teks : ""
            })     
            this.getUnitOptions()
        }        
    }

    getInstansiOptions = () => {
        this.updateBloc.instansiListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            instansiOption: reformatOptions(
                                response.result, 
                                'agency_name',
                                'agency_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchInstansiList({})
    }

    getUnitOptions = () => {
        this.updateBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            instansiOption: reformatOptions(
                                response.result, 
                                'work_unit_name_tabs',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchUnitList({})
    }

    
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.dataForm.employee_uuid,
                letter_in_status: this.state.submitStatus,
                letter_in_qrcode: this.state.letterin.letter_in_qrcode,
                ...values
            },
            confirm: true
        })
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:       
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.updateBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            letterin: response.letter_in,
                            receiver: response.receiver.receiver_unit,
                            attachment: response.attachment
                        })
                        this.handleFirstTipeAsalSurat(response.letter_in.letter_in_sender_type)
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })

        this.updateBloc.updateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

       
        // get data referensi
        this.updateBloc.letterPriorityListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            letterPriorityOption: reformatOptions(
                                response.result, 
                                'priority_name',
                                'priority_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchLetterPriorityList({})

        
        this.updateBloc.letterTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            letterTypeOption: reformatOptions(
                                response.result, 
                                'letter_type_name',
                                'letter_type_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchLetterTypeList({})

        this.updateBloc.attachmentTypeListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            attachmentTypeOption: reformatOptions(
                                response.result, 
                                'attachment_type_name',
                                'attachment_type_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchAttachmentTypeList({})

        this.updateBloc.originSenderListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            originSenderOption: reformatOptions(
                                response.result, 
                                'origin_sender_name',
                                'origin_sender_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchOriginSenderList({})

        this.updateBloc.redactionListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            redactionOption: reformatOptions(
                                response.result, 
                                'redaction_name',
                                'redaction_id'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchRedactionList({})

        this.updateBloc.instansiListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            instansiOption: reformatOptions(
                                response.result, 
                                'agency_name',
                                'agency_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchInstansiList({})

        this.updateBloc.unitListChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //console.log(response)
                        
                        this.setState({
                            unitOption: reformatOptions(
                                response.result, 
                                'work_unit_name_tabs',
                                'work_unit_uuid'
                            )
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        this.updateBloc.fetchUnitList({})

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('qrcode')) {
            this.updateBloc.fetchDetail({ qrcode: queryParams.get('qrcode') })
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Ubah Data Surat Masuk</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Setiap isian yang bertanda (<span className="text-danger">*</span>) adalah wajib diisi.
                                    </CardSubtitle>
                                    <hr />
                                    <AvForm
                                        className="needs-validation"
                                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                                    >
                                        <Row>
                                            <Col md="4">
                                                <div className="mb-3">
                                                <label >Nomor Surat <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_in_number"
                                                    placeholder="No Surat..."
                                                    type="text"
                                                    errorMessage="Masukkan Nomor Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="letter_in_number"
                                                    value={this.state.letterin.letter_in_number}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                <label >Sifat Surat <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect 
                                                        name="letter_in_priority"
                                                        errorMessage="Pilih Sifat Surat"
                                                        options={this.state.letterPriorityOption} 
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Pilih Sifat Surat"
                                                        defaultValue={this.state.letterin.letter_in_priority}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                <label >Tanggal Surat <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                        name="letter_in_date"
                                                        placeholder="Tanggal Surat"
                                                        type="date"
                                                        errorMessage="Masukkan Tanggal Surat"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="letter_in_date"
                                                        value={this.state.letterin.letter_in_date}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md="4">
                                            <div className="mb-3">
                                            <label >Tanggal Diterima <span className="text-danger">*</span>
                                            </label>
                                            <AvField
                                                name="letter_in_receive_date"
                                                placeholder="Tanggal Diterima"
                                                type="date"
                                                errorMessage="Masukkan Tanggal Surat Diterima"
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="letter_in_receive_date"
                                                value={this.state.letterin.letter_in_receive_date}
                                            />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                            <label >Lampiran 
                                            </label>
                                            <AvField
                                                name="letter_in_number_of_attachment"
                                                placeholder="Jumlah"
                                                type="number"
                                                errorMessage="Masukkan Nomor Surat"
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                    pattern: {
                                                      value: "^[0-9]+$",
                                                      errorMessage: "Only Digits",
                                                    },
                                                  }}
                                                id="letter_in_number_of_attachment"
                                                value={this.state.letterin.letter_in_number_of_attachment}
                                            />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                            <label >&nbsp; 
                                            </label>
                                            <CustomSelect 
                                                    name="letter_in_attachment_type"
                                                    errorMessage="Pilih Tipe Berkas"
                                                    options={this.state.attachmentTypeOption} 
                                                    placeholder="Tipe Berkas"
                                                    defaultValue={this.state.letterin.letter_in_attachment_type}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                            <label >Jenis Surat <span className="text-danger">*</span>
                                            </label>
                                            <CustomSelect 
                                                    name="letter_in_type"
                                                    errorMessage="Pilih Jenis Surat"
                                                    options={this.state.letterTypeOption} 
                                                    validate={{ required: { value: true } }}
                                                    placeholder="Pilih Jenis Surat"
                                                    defaultValue={this.state.letterin.letter_in_type}
                                                />
                                            </div>
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Perihal <span className="text-danger">*</span>
                                                </label>
                                                <AvField
                                                    name="letter_in_title"
                                                    placeholder="Perihal Surat..."
                                                    type="textarea"
                                                    errorMessage="Masukkan Perihal Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="letter_in_title"
                                                    value={this.state.letterin.letter_in_title}
                                                />
                                                </div>
                                            </Col>                                        
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                            <div className="mb-3">
                                                <label >Tipe Asal Surat <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect 
                                                        name="letter_in_sender_type"
                                                        errorMessage="Pilih Tipe Asal Surat"
                                                        options={this.state.originSenderOption} 
                                                        validate={{ required: { value: true } }}
                                                        onChange={this.handleTipeAsalSurat}
                                                        placeholder="Pilih Tipe Asal Surat"
                                                        id="letter_in_sender_type"
                                                        defaultValue={this.state.letterin.letter_in_sender_type}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="8" style={this.state.letter_in_sender_show ? {} : { display: 'none' }} >
                                                <div className="mb-3">
                                                <label >Asal Surat <span className="text-danger">{this.state.letter_in_sender_mandatory_teks}</span>
                                                </label>
                                                <CustomSelect 
                                                        name="letter_in_sender"
                                                        errorMessage="Pilih Asal Surat"
                                                        options={this.state.instansiOption} 
                                                        validate={{ required: { value: this.state.letter_in_sender_validate } }}
                                                        placeholder="Pilih Asal Surat"
                                                        id="letter_in_sender"
                                                        defaultValue={this.state.letterin.letter_in_sender_uuid}
                                                    />
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >Rincian Asal Surat <span className="text-danger">{this.state.letter_in_sender_detail_mandatory_teks}</span>
                                                </label>
                                                <AvField
                                                    name="letter_in_sender_detail"
                                                    placeholder="Rincian Asal Surat ..."
                                                    type="textarea"
                                                    errorMessage="Masukkan Rincian Asal Surat"
                                                    className="form-control"
                                                    validate={{ required: { value: this.state.letter_in_sender_detail_validate } }}
                                                    id="letter_in_sender_detail"
                                                    value={this.state.letterin.letter_in_sender_detail}
                                                />
                                                </div>
                                            </Col>                                        
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                            <div className="mb-3">
                                                <label >Redaksi <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect 
                                                        name="letter_in_redaction"
                                                        errorMessage="Pilih Redaksi"
                                                        options={this.state.redactionOption} 
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Pilih Redaksi"
                                                        defaultValue={this.state.letterin.letter_in_redaction}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="8">
                                                <div className="mb-3">
                                                <label >Unit Kerja Yang Dituju <span className="text-danger">*</span>
                                                </label>
                                                <CustomSelect 
                                                        name="letter_in_receiver"
                                                        errorMessage="Pilih Unit Kerja Yang DItuju"
                                                        options={this.state.unitOption} 
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Pilih Unit Kerja Yang DItuju"
                                                        defaultValue={this.state.receiver}
                                                    />
                                                </div>
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                            <div className="mb-3">
                                                <label >Catatan Mengenai Surat
                                                </label>
                                                <AvField
                                                    name="letter_in_note"
                                                    placeholder="Catatan"
                                                    type="textarea"
                                                    errorMessage="Masukkan Catatan Surat"
                                                    className="form-control"                                                   
                                                    id="letter_in_note"
                                                    value={this.state.letterin.letter_in_notes}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <label >File Lampiran
                                                </label>
                                                { this.state.attachment != null ?
                                                    <Preview
                                                        value={this.state.attachment}
                                                        fileName='attachment_filename'
                                                        filePath='attachment_filepath'
                                                        fileMimeType='attachment_mime'
                                                    />
                                                    //null
                                                    : null
                                                }
                                                <FileInput 
                                                    name="document"
                                                    //validate={{ required: { value: true } }}
                                                    isMultiple
                                                />
                                                </div>
                                            </Col>  
                                                                                    
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md="12">
                                                <Link to="/inbox/daftardikembalikan.html">
                                                    <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                </Link>
                                                {' '}
                                                { this.state.letterin.letter_in_status == 9 ?
                                                    <Button color="success" type="submit" onClick={() => this.handleSaveDraft(9)}>
                                                        <i className="uil-arrow-up-right"></i> Simpan Data
                                                    </Button> 
                                                    : 
                                                    <Button color="primary" type="submit" onClick={() => this.handleSaveDraft(1)}>
                                                        <i className="uil-arrow-up-right"></i> Simpan Data
                                                    </Button>
                                                 }
                                                 {' '}
                                                { this.state.letterin.letter_in_status == 9 ?                                                                                                  
                                                    <Button color="primary" type="submit" onClick={() => this.handleSaveDraft(1)}>
                                                        <i className="uil-arrow-up-right"></i> Simpan & Kirim
                                                    </Button>
                                                    : 
                                                    null
                                                 }
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo="Apakah anda yakin akan menyimpan data ini?"

                    loadingTitle="Mengirim data..."
                    loadingInfo="Tunggu beberapa saat"

                    successTitle="Berhasil!"
                    successInfo="Data berhasil disimpan"

                    failedTitle="Gagal!"
                    failedInfo="Data gagal disimpan"

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}

                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                    this.state.submitStatus == 1 ? 
                    <Redirect to='/inbox/daftardikembalikan.html'/>
                    : 
                    <Redirect to='/inbox/daftardikembalikan.html'/>                   
                : null }
            </Fragment>
        )
    }

}

//export default IndexView
export default withRouter(UpdateView);
