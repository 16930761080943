import React, { Fragment, Component } from 'react'
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button,
    Input,
    Label,
    FormGroup, CustomInput,
    Table,
} from 'reactstrap'
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import Select from "react-select"
import CustomSelect from '../../../../helpers/ui/CustomSelect'

import { Link, useHistory, Redirect } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../../services/apiResponse'

import DetailBloc from './Blocs/DetailBloc'

var reformatPenerima = (raw, label, uuid, nip, type) => {
    return raw.map((data, index) => ({
        ...data,
        label: data[label],
        value: 1 === Number(data[type]) ? data[uuid] : data[nip],
    }))
}

class IndexView extends Component {
    detailBloc = new DetailBloc();

    constructor(props) {
        super(props)

        this.state = {
            uuid: null,
            groupName: null,
            internalReceiverOption: [],
            selectedInternalReceiver: [],
        }
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search)
        const uuid = queryParams.get('uuid')

        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if (result.data.status) {
                        let response = result.data.response

                        this.setState({
                            uuid: response.group.receiver_group_uuid,
                            groupName: response.group.receiver_group_name,
                            selectedInternalReceiver: reformatPenerima(
                              response.receivers,
                              'position_name_text',
                              'receiver_group_member_position_uuid',
                              'receiver_group_member_employee_nip',
                              'position_type'
                            ),
                        })
                    }
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        this.detailBloc.fetchDetail({ uuid })
    }

    render() {
        return (
          <Fragment>
              <Container fluid>
                  <Row>
                      <Col className="col-12">
                          <Card>
                              <CardBody>
                                  <CardTitle>Detil Grup Penerima</CardTitle>
                                  <hr />
                                  <AvForm>
                                      <Row className="mb-3">
                                          <label
                                            htmlFor="receiver-group-name"
                                            className="col-md-2 col-form-label"
                                          >
                                              Nama
                                          </label>
                                          <Col md="5">
                                              <AvField
                                                name="name"
                                                type="text"
                                                className="form-control"
                                                id="receiver-group-name"
                                                value={ this.state.groupName }
                                                disabled
                                              />
                                          </Col>
                                      </Row>
                                      <Row className="mb-3">
                                          <label
                                            htmlFor="receiver-group-members"
                                            className="col-md-2 col-form-label"
                                          >
                                              Penerima
                                          </label>
                                          <Col md="5">
                                              <Table bordered striped hover>
                                                  <thead>
                                                  <tr>
                                                      <th style={{ width: '5ch' }} className="text-center">No.</th>
                                                      <th>Pegawai</th>
                                                      <th>Jabatan</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody>
                                                  {this.state.selectedInternalReceiver?.map(({
                                                      value,
                                                      position_employee_fullname: employeeName,
                                                      position_name_full: positionName,
                                                  }, index) => <>
                                                      <tr>
                                                          <td className="text-center">{index + 1}</td>
                                                          <td>{employeeName || <center>-</center>}</td>
                                                          <td>{positionName || <center>-</center>}</td>
                                                      </tr>
                                                  </>)}
                                                  </tbody>
                                              </Table>
                                          </Col>
                                      </Row>
                                      <Row className="mb-3">
                                          <Col md={{ size: 9, offset: 2 }}>
                                              <Link to="/master/gruppenerima.html">
                                                  <Button color="danger" type="button">
                                                      <i className="uil-arrow-left me-1"></i>
                                                      Kembali
                                                  </Button>
                                              </Link>
                                          </Col>
                                      </Row>
                                  </AvForm>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              </Container>
              { this.state.redirect ?
                <Redirect to='/master/gruppenerima.html'/>
                : null }
          </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(IndexView);
