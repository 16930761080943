import {
    apiRequest,
    menuList
} from "../../../../../services/adapters/base"

class IndexTableRepository {

    fetchTableActive = async (query, cancelToken) => {
        return await apiRequest("get", menuList, {
            cancelToken: cancelToken,
            params: query
        })
    }

    fetchTableInactive = async (query, cancelToken) => {
        return await apiRequest("get", menuList, {
            cancelToken: cancelToken,
            params: query
        })
    }
}

export default IndexTableRepository