import React, { Fragment, useState } from "react"
import DataTableComponent from './Components/DataTableComponent'
//import DataTableDoneComponent from './Components/DataTableDoneComponent'
import classnames from "classnames"

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"


const IndexView = (props) => {

    const [activeTab, setactiveTab] = useState("1")

    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Notifikasi</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Daftar notifikasi, data yang bertanda bold merupakan data notifikasi yang belum pernah dibuka.
                                </CardSubtitle>
                                <hr />
                                <DataTableComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView